import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';
import {MessageSummaryOverall} from "./messageOverall.model";
import {MessageSummaryByType} from "./messageSummaryByType.model";

export class MessageSummary {
  @JsonProperty() @JsonClassType({type: () => [MessageSummaryOverall]})
  overall: MessageSummaryOverall;
  @JsonProperty() @JsonClassType({type: () => [Array, [MessageSummaryByType]]})
  byMessageType: MessageSummaryByType[];

  constructor( overall: MessageSummaryOverall, byMessageType: MessageSummaryByType[]) {
    this.overall = overall;
    this.byMessageType = byMessageType;
  }

  public static fromService(messageSummary: any): MessageSummary {
    const objectMapper = new ObjectMapper();
    const summary = objectMapper.parse<MessageSummary>( JSON.stringify( {
      overall: MessageSummaryOverall.fromService(messageSummary.overall[0]),
      byMessageType: MessageSummaryByType.fromServiceArray(messageSummary.byMessageType)}), {mainCreator: () => [MessageSummary]}
    );
    return summary;
  }
}
