import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';
import {FaqAuthorModel} from "./faqAuthor.model";
import {FaqEntryModel} from "./faqEntry.model";

export class FaqDocumentModel {
  @JsonProperty()
  @JsonClassType({type: () => [Date]})
  updated: Date;

  @JsonProperty()
  @JsonClassType({type: () => [String]})
  documentId: string;

  @JsonProperty()
  @JsonClassType({type: () => [String]})
  title: string;

  @JsonProperty()
  @JsonClassType({type: () => [Number]})
  sort: number;

  @JsonProperty()
  @JsonClassType({type: () => [FaqAuthorModel]})
  author: FaqAuthorModel;

  @JsonProperty()
  @JsonClassType({type: () => [Array, [FaqEntryModel]]})
  entries: FaqEntryModel[];

  constructor(updated: Date, documentId: string, sort: number, author: FaqAuthorModel, title: string, entries: FaqEntryModel[]) {
    this.updated = updated;
    this.documentId = documentId;
    this.sort = sort;
    this.author = author;
    this.title = title;
    this.entries = entries;
  }

  public static fromService(documentModel: any): FaqDocumentModel {
      const objectMapper = new ObjectMapper();
      const returnDocumentModel = objectMapper.parse<FaqDocumentModel>(JSON.stringify({
        updated: new Date(documentModel.updated),
        documentId: documentModel.documentId,
        sort: documentModel.sort,
        author: FaqAuthorModel.fromService(documentModel.author),
        title: documentModel.title,
        entries: FaqEntryModel.fromServiceArray(documentModel.entries)
          }), {mainCreator: () => [FaqDocumentModel]}
      );
      return returnDocumentModel;
  }

  public static fromServiceArray(statusMessageArray: any): FaqDocumentModel[] {
    const resultStatusMessages = [];
    if (statusMessageArray) {
      for (const statusMessage of statusMessageArray) {
        resultStatusMessages.push(FaqDocumentModel.fromService(statusMessage));
      }
    }
    return resultStatusMessages;
  }
}
