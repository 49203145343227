import {Component, Input} from '@angular/core';
import {Customer} from "../../../core/models/customer/customer.model";
@Component({
  selector: 'app-contactoptioncomponent',
  templateUrl: './contactoptioncomponent.component.html',
  styleUrls: ['./contactoptioncomponent.component.scss']
})
export class ContactoptioncomponentComponent {

  @Input() customer: Customer;
  constructor() { }

  ngOnInit(): void {
  }
}
