import {JsonClassType, JsonProperty, ObjectMapper} from "jackson-js";
import {DatevAddressModel} from "./datevAddress.model";
import {DatevTaskModel} from "./datevTask.model";
import {DatevErrors} from "./datevErrors.model";
import {DatevMappingErrorsModel} from "./datevMappingErrors.model";

export class DatevOrderModel {

    @JsonProperty() @JsonClassType({type: () => [String]})
    id: string;
    @JsonProperty() @JsonClassType({type: () => [String]})
    type: string;
    @JsonProperty() @JsonClassType({type: () => [String]})
    description: string;
    @JsonProperty() @JsonClassType({type: () => [String]})
    companyId: string;
    @JsonProperty() @JsonClassType({type: () => [String]})
    customerId: string;
    @JsonProperty() @JsonClassType({type: () => [DatevAddressModel]})
    operationAddress: DatevAddressModel;
    @JsonProperty() @JsonClassType({type: () => [Date]})
    desiredDateTime: Date;
    @JsonProperty() @JsonClassType({type: () => [Array, [DatevTaskModel]]})
    tasks: DatevTaskModel[] = [];
    @JsonProperty() @JsonClassType({type: () => [Array, [DatevErrors]]})
    errors: DatevErrors[] = [];
    @JsonProperty() @JsonClassType({type: () => [Array, [DatevMappingErrorsModel]]})
    mappingErrors: DatevMappingErrorsModel[] = [];

    constructor(
        id: string,
        type: string,
        description: string,
        companyId: string,
        customerId: string,
        operationAddress: DatevAddressModel,
        desiredDateTime: Date,
        tasks: DatevTaskModel[],
        errors: DatevErrors[],
        mappingErrors: DatevMappingErrorsModel[]
    ){
        this.id = id;
        this.type = type;
        this.description = description;
        this.companyId = companyId;
        this.customerId = customerId;
        this.operationAddress = operationAddress;
        this.desiredDateTime = desiredDateTime;
        this.tasks = tasks;
        this.errors = errors
        this.mappingErrors = mappingErrors;
    }

    public static fromService(order: any): DatevOrderModel {
        if( order) {
            const objectMapper = new ObjectMapper();
            return objectMapper.parse<DatevOrderModel>(JSON.stringify({
                id: order.id,
                type: order.type,
                description: order.description,
                companyId: order.companyId,
                customerId: order.customerId,
                operationAddress: DatevAddressModel.fromService(order.operationAddress),
                desiredDateTime: order.desiredDateTime,
                tasks: DatevTaskModel.fromServiceArray(order.tasks),
                errors: DatevErrors.fromServiceArray(order.errors),
                mappingErrors: DatevMappingErrorsModel.fromServiceArray(order.mappingErrors)
            }), {mainCreator: () => [DatevOrderModel]});
        } else {
            return null;
        }
    }

    public static fromServiceArray(orderArray: any): DatevOrderModel[] {
        const resultOrders = [];
        if (orderArray) {
            for (const order of orderArray) {
                resultOrders.push(DatevOrderModel.fromService(order));
            }
        }
        return resultOrders;
    }

    public static toService(order: DatevOrderModel): any {
        return {
            id: order.id,
            type: order.type,
            description: order.description,
            companyId: order.companyId,
            customerId: order.customerId,
            operationAddress: DatevAddressModel.toService(order.operationAddress),
            tasks: DatevTaskModel.toServiceArray(order.tasks)
        };
    }
}
