<div class="card">
    <div class="card-header align-items-center d-flex">
        <h4 class="card-title mb-0 flex-grow-1">{{description}}</h4>
    </div><!-- end card header -->
    <div class="card-body">
        <div class="table-responsive table-card">
            <table class="table table-borderless table-nowrap align-middle mb-0">
                <thead class="table-light text-muted">
                <tr>
                    <th scope="col"></th>
                    <th scope="col">Mitarbeiter</th>
                    <th scope="col">Anzahl Aufgaben</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let user of tasksPerUser">
                    <td class="d-flex">
                        <div class="avatar-xs">
                            <div class="avatar-title rounded-circle userprofile" [ngStyle]="{'background-color': user.user.color }">{{user.user.initials}}</div>
                        </div>
                    </td>
                    <td>
                        <h6 class="mb-0">{{user.user.firstName}} {{user.user.lastName}}</h6>
                    </td>
                    <td>
                        <h6 class="mb-0">{{user.count}}</h6>
                    </td>
                </tr><!-- end tr -->
                </tbody><!-- end tbody -->
            </table><!-- end table -->
        </div>
    </div>
</div>
