import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';

export class Tenant {
  @JsonProperty() @JsonClassType({type: () => [String]})
  id: string;

  constructor(
    id: string
  ) {
    this.id = id;
  }

  public static fromService(tenant: any): Tenant {
    const objectMapper = new ObjectMapper();

    return objectMapper.parse<Tenant>(JSON.stringify({
      id: tenant.tenantId['tenant-id']
    }), {mainCreator: () => [Tenant]});
  }

  public static toService(tenant: Tenant): any {
    return {
      tenantId: {'tenant-id': tenant.id},
    };
  }
}
