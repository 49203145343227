<div class="card" *ngIf="customer?.contacts?.length > 0">
    <div class="card-header align-items-center d-flex border-bottom-dashed">
        <h4 class="card-title mb-0 flex-grow-1">{{'labels.contactInformationOfCustomer' | translate }}</h4>
    </div>
    <div class="card-body">
        <div class="table-card">
            <table class="table mb-0">
                <tbody>
                    <tr class="" *ngFor="let contact of customer.contacts; let i = index">
                        <td class="fw-medium">{{contact.contactType.description}}</td>
                        <td>{{contact.value}}</td>
                    </tr>
                </tbody>
            </table><!--end table-->
        </div>
    </div>
</div>
