import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {Observable, Subscription} from "rxjs";
import {DatevImportOverview} from "../../../core/models/datev/datevImportOverview.model";
import {datevImportOverviewSelect, DatevSate} from "../../../core/models/datev/store";
import {Store} from "@ngrx/store";
import {DatevImportOverviewStates} from "../../../core/models/datev/datevImportOverviewStates.model";

@Component({
  selector: 'app-datev-overview-state',
  templateUrl: './datev-overview-state.component.html',
  styleUrls: ['./datev-overview-state.component.scss']
})
export class DatevOverviewStateComponent implements OnInit, OnDestroy {

  datevImportOverview$: Observable<DatevImportOverview> = this.datevStore.select(datevImportOverviewSelect);
  datevImportOverviewSubscription: Subscription;
  datevImportOverview: DatevImportOverview;

  constructor(
      private router: Router,
      private datevStore: Store<DatevSate>,
  ) {
    this.datevImportOverviewSubscription = this.datevImportOverview$.subscribe(overview => {
      if (overview) {
        overview.states?.forEach((state: DatevImportOverviewStates) => {
          if ((state.state === 'WAITING' && state.records > 0)
              || (state.state === 'MAPPING_ISSUES' && state.records > 0)
              || (state.state === 'ERRONEOUS' && state.records > 0)) {
            //this.showRestartImport = true;
          }
        });
        this.datevImportOverview = overview;
      }
    });
  }

  ngOnDestroy(): void {
      this.datevImportOverviewSubscription.unsubscribe();
    }

  ngOnInit(): void {
  }

  openErrorView(state) {
    if ( state === 'ERRONEOUS') {
      this.router.navigate(['datev/error-list']);
    }
  }
}
