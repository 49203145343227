<!-- Start Breadcrumbs -->
<app-breadcrumbs title="menu.customerList" [breadcrumbItems]="breadCrumbItems"></app-breadcrumbs>
<!-- End Breadcrumbs -->
<div class="row">
    <div class="col-xxl-9">
        <div class="card" id="contactList">
            <div class="card-header">
                <div class="row g-3">
                    <div class="col-md-4">
                        <div class="search-box">
                            <input type="text" name="searchTerm" class="form-control"
                                   placeholder="{{ 'labels.search' | translate }}" (keyup)="updateFilter($event)">
                            <i class="ri-search-line search-icon"></i>
                        </div>
                    </div>
                    <div class="col-md-auto ms-auto">
                        <div class="d-flex align-items-center gap-2">
                            <button (click)="newCustomer()" type="button" class="btn btn-soft-success"><i
                                    class="ri-add-circle-line align-middle me-1"></i> {{ 'labels.addNewCustomer' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div>
                    <div class="table-responsive table-card mb-2">
                        <table class="table table-hover">
                            <thead>
                            <tr class="bg-light">
                                <th scope="col">{{ 'labels.nameHeader' | translate }}</th>
                                <th scope="col">{{ 'labels.street' | translate }}</th>
                                <th scope="col">{{ 'labels.zipcode' | translate }}</th>
                                <th scope="col">{{ 'labels.city' | translate }}</th>
                                <th scope="col">{{ 'labels.externalId' | translate }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let customer of filteredCustomerOverviews" class="cursor-pointer" [ngClass]="customer.customerId === selectedCustomer?.id ? 'table-active' : ''"
                                (click)="onSelect(customer)">
                                <td>
                                    <ngb-highlight [result]="customer.mainAddress | fullName"
                                                   [term]="searchTerm"></ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="customer.mainAddress.street"
                                                   [term]="searchTerm"></ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="customer.mainAddress.zipcode"
                                                   [term]="searchTerm"></ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="customer.mainAddress.city"
                                                   [term]="searchTerm"></ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="customer.externalId?.id" [term]="searchTerm"></ngb-highlight>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row justify-content-md-between align-items-md-center">
                        <div class="col col-sm-6">
                            <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
                                {{'labels.showing' | translate}}
                                {{ filteredCustomerOverviews?.length}} / {{customerOverviewPage?.totalElements}}
                                {{'labels.entries' | translate}}
                            </div>
                        </div>
                        <!-- Pagination -->
                        <div class="col col-sm-6">
                            <div class="text-sm-right float-sm-end listjs-pagination">
                                <ngb-pagination
                                        (pageChange)="pageChange($event)"
                                        [boundaryLinks]="true"
                                        [maxSize]="3"
                                        [collectionSize]="customerOverviewPage?.totalElements"
                                        [page]="page"
                                        [pageSize]="customerOverviewPage?.pageSize">
                                </ngb-pagination>
                            </div>
                        </div>
                        <!-- End Pagination -->
                    </div>
                </div>
            </div>
        </div><!--end card-->
    </div>
    <div class="col-xxl-3">
        <app-customer-edit></app-customer-edit>
    </div>
</div>
