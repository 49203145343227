import {Action, createReducer, on} from '@ngrx/store';
import * as CustomerOverviewActions from './customerOverview.actions';
import {CustomerOverview} from '../customerOverview.model';
import {Page} from '../../../page/page.model';

export interface ReducerCustomerOverviewState {
  page: Page<CustomerOverview>;
}

export const initialCustomerOverviewState: ReducerCustomerOverviewState = {
  page: new Page<CustomerOverview>(0, 0, 0, 0, 0, [])
};

const customOverviewReducer = createReducer(
  initialCustomerOverviewState,
  on(CustomerOverviewActions.customersOverviewLoadedAction, (state, {payload}) => ({...state, page: payload})),
);

export function reducer(state: ReducerCustomerOverviewState | undefined, action: Action): ReducerCustomerOverviewState {
  return customOverviewReducer(state, action);
}
