import {Injectable} from '@angular/core';
import {UserManagementApiService} from '../services/user-management-api.service';
import {AbstractControl, AsyncValidatorFn} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomEmailValidator {
  constructor(private httpApiService: UserManagementApiService) {}

  existingEmailValidator(initialEmail: () => string): AsyncValidatorFn {
    return (
      control: AbstractControl
    ):
      | Observable<{ [key: string]: any } | null> => {
      const email = initialEmail();

      if (!control.touched || control.value === email) {
        return of(null);
      } else {
        return this.httpApiService
          .existsEmailAddress(control.value)
          .pipe(
            map(res =>
              res ? {eMailAddressExists: true} : null
            )
          );
      }
    };
  }
}
