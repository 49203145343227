import {createSelector} from '@ngrx/store';
import {ReducerTaskTypeState} from './taskype.reducer';

export interface TaskTypeState {
  taskType: ReducerTaskTypeState;
}

export const selectTaskTypeState = (state: TaskTypeState) => state.taskType;

export const selectedTaskTypeSelect = createSelector(
  selectTaskTypeState,
  (state) => state.selectedTaskType
);

export const allTaskTypesSelect = createSelector(
  selectTaskTypeState,
  (state) => state.taskTypes
);

export const allTaskTypesForSelectBoxSelect = createSelector(
  selectTaskTypeState,
  (state) => state.taskTypesForSelectBox
);

export const allTaskTypesForAllCompaniesSelect = createSelector(
  selectTaskTypeState,
  (state) => state.taskTypesAllCompanies
);
