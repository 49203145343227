import {JsonClassType, JsonProperty} from 'jackson-js';

export class DatevMapping {
  @JsonProperty() @JsonClassType({type: () => [String]})
  id: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  ident: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  mappingSource: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  mappingSourceDescription: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  conversion: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  companyId: string;
  @JsonProperty() @JsonClassType({type: () => [Boolean]})
  mappingNeedCompanyId: boolean;
  @JsonProperty() @JsonClassType({type: () => [Boolean]})
  isMissingConversion: boolean;
  @JsonProperty() @JsonClassType({type: () => [Boolean]})
  ignoreMapping: boolean;


  constructor(
    id: string,
    ident: string,
    mappingSource: string,
    mappingSourceDescription: string,
    conversion: string,
    companyId: string,
    mappingNeedCompanyId: boolean,
    isMissingConversion: boolean,
    isIgnoreMapping: boolean
  ){
    this.id = id;
    this.ident = ident;
    this.mappingSource = mappingSource;
    this.mappingSourceDescription = mappingSourceDescription;
    this.conversion = conversion;
    this.companyId = companyId;
    this.mappingNeedCompanyId = mappingNeedCompanyId;
    this.isMissingConversion = isMissingConversion;
    this.ignoreMapping = isIgnoreMapping;
  }
}
