<div class="card">
    <div class="card-header align-items-center d-flex">
        <h4 class="card-title mb-0 flex-grow-1">{{header ?? 'labels.comments' | translate}}</h4>
    </div><!-- end card header -->

    <div class="card-body p-0">
        <div class="user-chat w-100 overflow-hidden user-chat-show">
            <div class="position-relative" id="users-chat">
                <ngx-simplebar class="chat-conversation p-3 p-lg-4" #scrollRef>
                    <ul class="list-unstyled chat-conversation-list" id="users-conversation">
                        <ng-container *ngFor="let data of messages">
                            <li class="chat-list left"
                                *ngIf="( data.message && data.message !== '') || data.image !== null"
                                [ngClass]="{ 'right': data.user.userId === loggedInUser.userId }">
                                <div class="conversation-list">
                                    <div class="chat-avatar"
                                         *ngIf="data.user && data.user.userId !== loggedInUser.userId">
                                        <div class="avatar-title rounded-circle userprofile profile"
                                             [style]="'background-color:' + data.user.color">{{data.user.initials}}</div>
                                    </div>
                                    <div class="user-chat-content">
                                        <div class="ctext-wrap">
                                            <div class="ctext-wrap-content">

                                                <div class="replymessage-block mb-0 d-flex align-items-start"
                                                     *ngIf="data.replyUser">
                                                    <div class="flex-grow-1">
                                                        <h5 class="conversation-name">{{data.replyUser}}</h5>
                                                        <p class="mb-0">{{data.replyMessage}}</p>
                                                    </div>
                                                    <div class="flex-shrink-0">
                                                        <button type="button"
                                                                class="btn btn-sm btn-link mt-n2 me-n3 font-size-18">
                                                        </button>
                                                    </div>
                                                </div>
                                                <p class="mb-0 ctext-content"
                                                   *ngIf="data.message && data.message !== ''">{{data.message}}</p>
                                                <ul class="list-inline message-img mt-3  mb-0"
                                                    *ngIf="data.image?.thumb">
                                                    <li class="list-inline-item message-img-list">
                                                        <a class="d-inline-block m-1">
                                                            <img [src]="data.image.thumb" alt=""
                                                                 class="rounded img-thumbnail" (click)="open(data)">
                                                        </a>
                                                    </li>
                                                </ul>

                                            </div>
                                            <div class="dropdown align-self-start message-box-drop" ngbDropdown>
                                                <a class="dropdown-toggle" href="javascript:void(0);" role="button"
                                                   data-bs-toggle="dropdown" aria-haspopup="true"
                                                   aria-expanded="false" ngbDropdownToggle>
                                                    <i class="ri-more-2-fill"></i>
                                                </a>
                                                <div class="dropdown-menu" ngbDropdownMenu>
                                                    <a class="dropdown-item reply-message"
                                                       href="javascript:void(0);"
                                                       (click)="replyMessage($event,data.user.userId === loggedInUser.userId ? 'right': 'left')"><i
                                                            class="ri-reply-line me-2 text-muted align-bottom float-start"></i>Reply</a>
                                                    <a class="dropdown-item copy-message" href="javascript:void(0);"
                                                       (click)="copyMessage($event)"><i
                                                            class="ri-file-copy-line me-2 text-muted align-bottom float-start"></i>Copy</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="conversation-name"><small
                                                class="username">{{data.user.firstName}} {{data.user.lastName}}</small><small
                                                class="text-muted time">{{data.messageDateTime | date: 'dd.MM.yyyy HH:mm'}}</small>
                                            <span
                                                    class="text-success check-message-icon"><i
                                                    class="ri-check-double-line align-bottom"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ng-container>
                        <!-- chat-list -->
                    </ul>
                    <!-- end chat-conversation-list -->
                </ngx-simplebar>
                <div class="alert alert-warning alert-dismissible copyclipboard-alert px-4 fade show"
                     id="copyClipBoard" role="alert">
                    Message copied
                </div>
            </div>

            <div class="chat-input-section p-3 p-lg-4">

                <form (ngSubmit)="addComment(chatMessage)" [formGroup]="formData" id="chatinput-form"
                      enctype="multipart/form-data">
                    <div class="row g-0 align-items-center">
                        <div class="col-auto">
                            <div class="chat-input-links me-2">
                                <div class="links-list-item">
                                    <button type="button" class="btn btn-link text-decoration-none emoji-btn"
                                            id="emoji-btn" (click)="toggleEmojiPicker()">
                                        <i class="bx bx-smile align-middle"></i>
                                    </button>
                                    <emoji-mart
                                            [i18n]="{
                                            search: 'emoji.search' | translate,
                                            emojilist: 'emoji.emojilist' | translate,
                                            notfound: 'emoji.notfound' | translate,
                                            clear: 'emoji.clear' | translate,
                                            categories: {
                                                search: 'emoji.categories.search' | translate,
                                                recent: 'emoji.categories.recent' | translate,
                                                people: 'emoji.categories.people' | translate,
                                                nature: 'emoji.categories.nature' | translate,
                                                food: 'emoji.categories.food' | translate,
                                                activity: 'emoji.categories.activity' | translate,
                                                places: 'emoji.categories.places' | translate,
                                                objects: 'emoji.categories.objects' | translate,
                                                symbols: 'emoji.categories.symbols' | translate,
                                                flags: 'emoji.categories.flags' | translate,
                                                custom: 'emoji.categories.custom' | translate
                                            },
                                            skintones: {
                                                '1': 'emoji.skintones.1' | translate,
                                                '2': 'emoji.skintones.2' | translate,
                                                '3': 'emoji.skintones.3' | translate,
                                                '4': 'emoji.skintones.4' | translate,
                                                '5': 'emoji.skintones.5' | translate,
                                                '6': 'emoji.skintones.6' | translate
                                            }
                                            }"
                                            class="emoji-mart" *ngIf="showEmojiPicker"
                                            (emojiSelect)="addEmoji($event)"
                                            title="{{'emoji.picker' | translate}}"></emoji-mart>
                                </div>
                            </div>
                        </div>

                        <div class="col">
                            <input type="text" class="form-control chat-input bg-light border-light"
                                   id="chat-input" placeholder="{{'labels.enterMessage' | translate}}"
                                   autocomplete="off"
                                   formControlName="message"
                                   [(ngModel)]="emoji" (blur)="onBlur()" (focus)="onFocus()"
                                   #chatMessage>
                        </div>
                        <div class="col-auto">
                            <div class="chat-input-links ms-2">
                                <div class="links-list-item">
                                    <input #ImagesSelectedDialog type="file" multiple (change)="addImage($event)"
                                           hidden="true"
                                           accept="image/png, image/jpeg, image/jpg">
                                    <button type="button"
                                            class="btn btn-primary chat-send waves-effect waves-light shadow me-2"
                                            (click)="ImagesSelectedDialog.click()">
                                        <i class="ri-attachment-2 align-bottom"></i>
                                    </button>
                                    <button type="submit"
                                            class="btn btn-primary chat-send waves-effect waves-light shadow">
                                        <i class="ri-send-plane-2-fill align-bottom"></i>
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </form>

            </div>

            <div class="replyCard">
                <div class="card mb-0">
                    <div class="card-body py-3">
                        <div class="replymessage-block mb-0 d-flex align-items-start text-start">
                            <div class="flex-grow-1">
                                <h5 class="conversation-name"></h5>
                                <p class="mb-0"></p>
                            </div>
                            <div class="flex-shrink-0">
                                <button type="button" id="close_toggle"
                                        class="btn btn-sm btn-link mt-n2 me-n3 fs-18 shadow-none"
                                        (click)="closeReplay()">
                                    <i class="bx bx-x align-middle"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- end card body -->
</div>
