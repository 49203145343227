<!-- Start Breadcrumbs -->
<app-breadcrumbs title="menu.dashboard" [breadcrumbItems]="breadCrumbItems"></app-breadcrumbs>
<!-- End Breadcrumbs -->
<!-- neue Kunden/neue Aufträge -->
<div class="row">
    <div class="col-12 col-xl-12">
        <div class="row flex-grow-1">
            <div class="col-md-3 grid-margin">
                <app-dashboard-stat
                        title="{{'dashboardComponent.newCustomer' | translate}}"
                        [value]="100"
                        icon="icon-user-check"
                        percentage="7"
                        profit="up"
                        month="text">
                </app-dashboard-stat>
            </div>
            <div class="col-md-3 grid-margin">
                <app-dashboard-stat
                        title="{{'dashboardComponent.newOrders' | translate}}"
                        [value]="100"
                        icon="icon-book-open"
                        percentage="5"
                        profit="down"
                        month="test">
                </app-dashboard-stat>
            </div>
            <div class="col-md-3 grid-margin">
                <app-dashboard-stat
                        title="{{'dashboardComponent.newCustomer' | translate}}"
                        [value]="100"
                        icon="icon-user-check"
                        percentage="7"
                        profit="up"
                        month="text">
                </app-dashboard-stat>
            </div>
            <div class="col-md-3 grid-margin">
                <app-dashboard-stat
                        title="{{'dashboardComponent.newOrders' | translate}}"
                        [value]="100"
                        icon="icon-book-open"
                        percentage="5"
                        profit="down"
                        month="test">
                </app-dashboard-stat>
            </div>
        </div>
    </div>
</div> <!-- row -->
<!-- Aufträge/Auftragsstatus -->
<div class="row">
    <div class="col-md-6 grid-margin">
        <app-dashboardtile
                chartType="donut"
                headline="{{'dashboardComponent.orders' | translate}}"
                [showCount]=true
                [chartData]="ordersData"
                [chartDateDescription]="ordersDataDescription"
                [colors]="ordersDataColors"
                [chartHeight]="500"
                (dashboardPeriod)="ordersPeriodChanged($event)">
        </app-dashboardtile>
    </div>
    <div class="col-md-6 grid-margin">
        <app-dashboardtile
                chartType="donut"
                headline="{{'dashboardComponent.orderState' | translate}}"
                [showCount]=true
                [chartData]="ordersStateData"
                [chartDateDescription]="ordersStateDataDescription"
                [colors]="ordersStateColors"
                [chartHeight]="500"
                (dashboardPeriod)="ordersPeriodChanged($event)">
        </app-dashboardtile>
    </div>
</div>
<!-- Auftragstyp/Auftragsstatus -->
<div class="row">
    <div class="col-md-6 grid-margin" *ngIf="ordersForPlanning && ordersForPlanning.length > 0">
        <app-orders-per-status description="{{ 'dashboardComponent.unplannedOrders' | translate}}" [orders]="ordersForPlanning"></app-orders-per-status>
    </div>
    <div class="col-md-6 grid-margin" *ngIf="ordersForCalendar && ordersForCalendar.length > 0">
        <app-orders-per-status description="{{ 'dashboardComponent.plannedOrders' | translate}}" [orders]="ordersForCalendar"></app-orders-per-status>
    </div>
</div> <!-- row -->
<!-- Auftrag/Kunden -->
<div class="row">
    <div class="col-md-4 grid-margin" *ngIf="tasksPerUser && tasksPerUser.length > 0">
        <app-orders-per-employee [tasksPerUser]="tasksPerUser"
                                 description="{{'dashboardComponent.ordersPerEmployee' | translate}}"></app-orders-per-employee>
    </div>

        <div class="col-xxl-4">
            <div class="card">
                <div class="card-header border-0">
                    <h4 class="card-title mb-0">Upcoming Schedules</h4>
                </div><!-- end cardheader -->
                <div class="card-body pt-0">
                    <h6 class="text-uppercase fw-semibold mt-4 mb-3 text-muted">Events:</h6>
                    <div class="mini-stats-wid d-flex align-items-center mt-3" *ngFor="let holiday of holidays?.content">
                        <div class="flex-shrink-0 avatar-sm">
                        <span class="mini-stat-icon avatar-title rounded-circle text-success bg-soft-success fs-4">
                            {{holiday.date | date : 'dd'}}
                        </span>
                        </div>
                        <div class="flex-grow-1 ms-3">
                            <h6 class="mb-1">{{holiday.holiday.name}}</h6>
                            <p class="text-muted mb-0">{{holiday.date | date : 'dd.MM.yyyy'}} </p>
                        </div>
                    </div><!-- end -->

                    <div class="mt-3 text-center">
                        <ngb-pagination
                                (pageChange)="changeHolidayPage($event)"
                                [boundaryLinks]="true"
                                [maxSize]="3"
                                [collectionSize]="holidays?.totalElements"
                                [page]="holidayPage"
                                [pageSize]="holidays?.pageSize">
                        </ngb-pagination>
                    </div>

                </div><!-- end cardbody -->
            </div><!-- end card -->
        </div><!-- end col -->
</div> <!-- row -->
