import {Component, OnDestroy, OnInit} from '@angular/core';
import {OrderType} from '../../../core/models/order/ordertype/ordertype.model';
import {allOrderTypesSelect, OrderTypeState} from '../../../core/models/order/ordertype/store';
import {Store} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import {orderTypeSelectedAction} from '../../../core/models/order/ordertype/store/ordertype.actions';
import {NgbModalRef} from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
import {OrdertypeEditModalDialogComponent} from "../ordertype-edit-modal-dialog/ordertype-edit-modal-dialog.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Company} from "../../../core/models/company/company.model";
import {activeCompanySelect, CompanyState} from "../../../core/models/company/store";

@Component({
  selector: 'app-ordertype-list',
  templateUrl: './order-type-list.component.html'
})
export class OrderTypeListComponent implements OnInit, OnDestroy {
  orderTypes$: Observable<OrderType[]> = this.orderTypeStore.select(allOrderTypesSelect);
  orderTypeSubscription: Subscription;

  orderTypes: OrderType[];
  orderTypesFiltered: OrderType[];

  loadingIndicator = true;

  orderTypeModal: NgbModalRef;

  activeCompany$: Observable<Company> = this.companyStore.select(activeCompanySelect);
  activeCompanySubscription: Subscription;
  activeCompany: Company;

  searchTerm: string;

  constructor(private orderTypeStore: Store<OrderTypeState>,
              private modalService: NgbModal,
              private companyStore: Store<CompanyState>,) {
  }

  ngOnInit(): void {
    this.activeCompanySubscription = this.activeCompany$.subscribe(company => {
      if (company) {
        this.activeCompany = company;
      }
    });
    this.orderTypeSubscription = this.orderTypes$.subscribe(orderTypes => {
      this.orderTypes = orderTypes;
      this.orderTypesFiltered = orderTypes;
    });
  }

  updateFilter(event): void {
    let val = event.target.value.toLowerCase();
    this.searchTerm = val;
    if (val.length > 0) {
      this.orderTypesFiltered = this.orderTypes.filter(type => type.description.toLowerCase().includes(val.toLowerCase()));
    } else {
      this.orderTypesFiltered = this.orderTypes;
    }
  }

  onSelect(selected): void {
    const orderType: OrderType = selected;
    this.orderTypeStore.dispatch(orderTypeSelectedAction({payload: orderType}));
    this.openDialog();
  }

  newOrderType(): void {
    this.orderTypeStore.dispatch(orderTypeSelectedAction({payload: null}));
    this.openDialog();
  }

  openDialog(): void {
    this.orderTypeModal = this.modalService.open(OrdertypeEditModalDialogComponent, {
      size: 'lg',
      animation: true,
      centered: true, backdrop: 'static', keyboard: false
    });
    this.orderTypeModal.componentInstance.companyId = this.activeCompany.companyId;
  }

  ngOnDestroy(): void {
    this.activeCompanySubscription.unsubscribe();
    this.orderTypeSubscription.unsubscribe();
  }
}
