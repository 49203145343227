import {User} from './user/user.model';
import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';

export class ChatMessage {

  @JsonProperty() @JsonClassType({type: () => [String]})
  id: string;
  @JsonProperty() @JsonClassType({type: () => [User]})
  user: User;
  @JsonProperty() @JsonClassType({type: () => [String]})
  message: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  replyMessage: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  replyUser: string;
  @JsonProperty() @JsonClassType({type: () => [Date]})
  messageDateTime: Date;
  @JsonProperty() @JsonClassType({type: () => [String]})
  imageId: string;
  image: any;

  @JsonProperty() @JsonClassType({type: () => [Number]})
  sort: number;

  constructor(
    id: string,
    @JsonClassType({type: () => [User]}) user: User,
    message: string,
    replyMessage: string,
    replyUser: string,
    messageDateTime: Date,
    imageId: string,
    sort: number
  ) {
    this.id = id;
    this.user = user;
    this.message = message;
    this.replyMessage = replyMessage;
    this.replyUser = replyUser;
    this.imageId = imageId
    this.messageDateTime = messageDateTime;
    this.sort = sort;
  }

  public static fromService(comment: any, users: User[]): ChatMessage {
    if (comment) {
      let commentUser = null;
      for (const user of users) {
        if (user.userId === comment.authorId) {
          commentUser = user;
          break;
        }
      }
      const objectMapper = new ObjectMapper();
      let message :any = {}
      try {
        message = JSON.parse(comment.text);
      } catch (e) {
        message.message = comment.text;
        message.replyMessage = null;
        message.replyUser = null;
        message.imageId = null;
      }

      return objectMapper.parse<ChatMessage>(JSON.stringify({
        id: comment.id,
        user: commentUser,
        message: message.message,
        replyMessage: message.replyMessage,
        replyUser: message.replyUser,
        messageDateTime: new Date(comment.createDateTime),
        imageId: message.imageId,
        sort: comment.sort
      }), {mainCreator: () => [ChatMessage]});
    } else {
      return null;
    }
  }

  public static toService(comment: ChatMessage): any {
    if (comment) {
      let createDateTime = '';
      if (typeof comment.messageDateTime === 'string') {
        createDateTime = comment.messageDateTime;
      } else {
        createDateTime = comment.messageDateTime?.toISOString();
      }

      const message = {message: comment.message, replyMessage: comment.replyMessage, replyUser: comment.replyUser, imageId: comment.imageId };

      return {
        authorId: comment.user?.userId,
        createDateTime,
        id: comment.id,
        sort: comment.sort,
        text: JSON.stringify(message)
      };
    } else {
      return null;
    }
  }

  public static fromServiceArray(commentsArray: any, users: User[]): ChatMessage[] {
    const comments = [];
    if (commentsArray) {
      for (const comment of commentsArray) {
        comments.push(ChatMessage.fromService(comment, users));
      }
    }
    return comments;
  }

  public static toServiceArray(commentsArray: ChatMessage[]): any {
    const comments = [];
    if (commentsArray) {
      for (const comment of commentsArray) {
        comments.push(ChatMessage.toService(comment));
      }
    }
    return comments;
  }
}
