import {createAction, props} from '@ngrx/store';
import {User} from '../user.model';

export const loadAllUsersAction = createAction('[USER] loadAllUsers');
export const usersLoadedAction = createAction('[USER] usersLoaded', props<{payload: User[]}>());
export const userSelectedAction = createAction('[USER] userSelected', props<{payload: User}>());
export const saveUserAction = createAction( '[USER] save', props<{payload: User}>());
export const userSavedAction = createAction( '[USER] saved', props<{payload: User}>());
export const userLoginAction = createAction( '[USER] login', props<{payload: string}>());
export const userLoggedInAction = createAction( '[USER] loggedIn', props<{payload: User}>());
export const userResendMailAction = createAction( '[USER] userResendMailAction', props<{payload: User}>());
export const userResentMaiLAction = createAction( '[USER] userResentMaiLAction', props<{payload: User}>());
