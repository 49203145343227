import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';
import {DatevErrorsDetail} from './datevErrorDetail.model';

export class DatevErrors {

  @JsonProperty() @JsonClassType({type: () => [String]})
  scope: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  scopeText: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  actionMessageCode: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  actionText: string;
  @JsonProperty() @JsonClassType({type: () => [Array, [DatevErrorsDetail]]})
  details: DatevErrorsDetail[];

  constructor(
    scope: string,
    scopeText: string,
    actionMessageCode: string,
    actionText: string,
    @JsonClassType({type: () => [Array, [DatevErrorsDetail]]})details: DatevErrorsDetail[]
  ){
      this.scope = scope;
      this.scopeText = scopeText;
      this.actionMessageCode = actionMessageCode;
      this.actionText = actionText;
      this.details = details;
  }

    public static fromService(error: any): DatevErrors {
        const objectMapper = new ObjectMapper();
        return objectMapper.parse<DatevErrors>(JSON.stringify({
            scope: error.scope,
            scopeText: error.scopeText,
            actionMessageCode: error.actionMessageCode,
            actionText: error.actionText,
            details: DatevErrorsDetail.fromServiceArray(error.details),
        }), {mainCreator: () => [DatevErrors]});
    }

    public static fromServiceArray(errorArray: any): DatevErrors[] {
        const resultErrors = [];
        if (errorArray) {
            for (const error of errorArray) {
                resultErrors.push(DatevErrors.fromService(error));
            }
        }
        return resultErrors;
    }
}
