import {Action, createReducer, on} from '@ngrx/store';
import * as DatevActions from './datev.actions';
import {DatevMapping} from '../datevMapping.model';
import {DatevImportOverview} from '../datevImportOverview.model';
import {DatevImportDtoModel} from "../datevImportDto.model";
import {Page} from "../../page/page.model";

export interface ReducerDatevState {
    datevMappings: DatevMapping[];
    datevImportOverview: DatevImportOverview;
    isLoading: boolean;
    importDto: Page<DatevImportDtoModel>;
    selectedImportDto: DatevImportDtoModel;
}

export const initialDatevState: ReducerDatevState = {
    datevMappings: [],
    datevImportOverview: new DatevImportOverview(null, null),
    isLoading: false,
    importDto: new Page<DatevImportDtoModel>(null, null, null, null, null, null),
    selectedImportDto: null
};

const datevReducer = createReducer(
    initialDatevState,
    on(DatevActions.saveDatevMappingsAction, (state) => ({...state, isLoading: true})),
    on(DatevActions.startImportRecordsImportAction, (state) => ({...state, isLoading: true})),
    on(DatevActions.importRecordsEndsAction, (state) => ({...state, isLoading: false})),
    on(DatevActions.allDatevMappingsLoadedAction, (state, {mappings}) => ({
        ...state,
        datevMappings: mappings,
        isLoading: false
    })),
    on(DatevActions.datevMappingsSavedAction, (state, {mappings}) => ({
        ...state,
        datevMappings: mappings,
        isLoading: false
    })),
    on(DatevActions.datevImportOverviewLoadedAction, (state, {overview}) => ({
        ...state,
        datevImportOverview: overview,
        isLoading: false
    })),
    on(DatevActions.loadedImportDataAction, (state, {importDto}) => ({
        ...state,
        importDto: importDto
    })),
    on(DatevActions.setImportDataAction, (state, {importDto}) => ({
        ...state,
        selectedImportDto: importDto
    })),
    on(DatevActions.importDataSavedAction, (state) => ({
        ...state
    })),
);

export function reducer(state: ReducerDatevState | undefined, action: Action): ReducerDatevState {
    return datevReducer(state, action);
}
