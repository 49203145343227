import {ReducerMessageConfigurationState} from './messageConfiguration.reducer';
import {createSelector} from '@ngrx/store';

export interface MessageConfigState {
  messageConfig: ReducerMessageConfigurationState;
}

export const configState = (state: MessageConfigState) => state.messageConfig;

export const configurationSelect = createSelector(
  configState,
  (state) => state.configuration
);
