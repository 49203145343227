import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {UserService} from "../services/user.service";

@Injectable()
export class RoleBasedGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (this.userService.isEmployee()) {
      this.router.navigate(['person-calendar']);
    } else if (this.userService.isNewAdmin()){
      this.router.navigate(['welcome']);
    } else {
      this.router.navigate(['dashboard']);
    }
    return true;
  }
}
