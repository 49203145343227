import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';

export class IdentDescription {
  @JsonProperty() @JsonClassType({type: () => [String]})
  id: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  displayValue: string;

  constructor( id: string, displayValue: string) {
    this.id = id;
    this.displayValue = displayValue;
  }

  public static fromService(identDescription: any): IdentDescription {
    const objectMapper = new ObjectMapper();
    if (identDescription) {
      const ident = objectMapper.parse<IdentDescription>(JSON.stringify({
          id: identDescription.id,
          displayValue: identDescription.displayValue
        }), {mainCreator: () => [IdentDescription]}
      );
      return ident;
    } else {
      return null;
    }
  }
}
