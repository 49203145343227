import {CanActivate, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {filter, map} from 'rxjs/operators';
import {loggedInUserSelect, UserState} from '../models/user/store';
import {User} from '../models/user/user.model';
import {CompanyState} from '../models/company/store';
import {setActiveCompanyAction} from '../models/company/store/company.actions';
import {UserService} from "../services/user.service";

@Injectable()
export class SetDefaultCompanyGuard implements CanActivate {
  constructor(
    private router: Router,
    private userStore: Store<UserState>,
    private companyStore: Store<CompanyState>,
    private userService: UserService) {

  }

  getLoggedInUser(): Observable<User> {
    return this.userStore.select(loggedInUserSelect).pipe( filter( user => user !== null));
  }

  canActivate(): Observable<boolean> | boolean {
      if (this.userService.isNewAdmin()) {
          return true
      } else {
          return this.getLoggedInUser().pipe(
              map(user => {
                  let result = true;
                  if (user?.defaultCompany) {
                      if (!JSON.parse(sessionStorage.company).activeCompany) {
                          this.companyStore.dispatch(setActiveCompanyAction({payload: user.defaultCompany}));
                      }
                  } else {
                      // @TODO irgendeine Firma setzen!
                  }
                  return result;
              })
          );
      }
  }
}
