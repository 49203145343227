<div class="card card-height-100">
    <div class="card-header align-items-center d-flex">
        <h4 class="card-title mb-0 flex-grow-1">{{headline}}</h4>
        <div class="flex-shrink-0">
            <div class="dropdown card-header-dropdown" ngbDropdown>
                <a class="dropdown-btn text-muted arrow-none" href="javascript:void(0);" data-bs-toggle="dropdown"
                   aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                    All Time <i class="mdi mdi-chevron-down ms-1"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                    <a class="dropdown-item" href="javascript:void(0);">All Time</a>
                    <a class="dropdown-item" (click)="changeDashboardPeriod(1)">30 Tage</a>
                    <a class="dropdown-item" (click)="changeDashboardPeriod(2)">60 Tage</a>
                    <a class="dropdown-item" (click)="changeDashboardPeriod(3)">90 Tage</a>
                </div>
            </div>
        </div>
    </div>

    <div class="card-body">
        <div class="card-body">
            <apx-chart class="apex-charts" dir="ltr"
                       [series]="chartData"
                       [labels]="chartDateDescription"
                       [chart]="{type: 'donut', height: 230}"
                       [plotOptions]="{pie: {offsetX: 0,offsetY: 0,donut: {size: '90%',labels: {show: false}}}}"
                       [dataLabels]="{enabled: false}"
                       [legend]="{show: false}"
                       [stroke]="{lineCap: 'round',width: 0}"
                       [colors]="colors"></apx-chart>
            <div class="mt-3">
                <div class="d-flex justify-content-center align-items-center mb-4">
                    <h2 class="me-3 ff-secondary mb-0">{{totalCount}}</h2>
                    <div>
                        <p class="text-muted mb-0">{{headline}}</p>
                    </div>
                </div>

                <ng-container *ngFor="let desc of chartDateDescription;let index = index;">
                <div class="d-flex justify-content-between border-bottom border-bottom-dashed py-2" *ngIf="chartData[index] > 0">
                    <p class="fw-medium mb-0"><i
                            class="ri-checkbox-blank-circle-fill align-middle me-2" [ngStyle]="{'color': colors[index]}"></i> {{ desc | translate}}</p>
                    <div>
                        <span class="text-muted pe-5">{{chartData[index]}}</span>
                    </div>
                </div><!-- end -->
                </ng-container>
            </div>
        </div>
    </div>
</div>
