import { Component, OnInit, Input } from '@angular/core';
import {Order} from "../../../core/models/order/order.model";
import {Router} from "@angular/router";

@Component({
  selector: 'app-orders-per-employee',
  templateUrl: './orders-per-employee.component.html',
  styleUrls: ['./orders-per-employee.component.scss']
})

export class OrdersPerEmployeeComponent implements OnInit {

  @Input() description: string;
  @Input() tasksPerUser: any[];

  constructor(
      private router: Router,
  ) { }

  ngOnInit(): void {
  }

  openOrder(order: Order): void {
    this.router.navigate(['/order-overview/' + order.customerId + '/' + order.id]);
  }
}
