import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {AddresstypeEditComponent} from "../edit/addresstype-edit.component";
import {AddressType} from "../../../core/models/addresstype/addressType.model";
import {AddressTypeState, selectedAddressTypeSelect} from "../../../core/models/addresstype/store";
import {Store} from "@ngrx/store";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-addresstype-edit-modal-dialog',
  templateUrl: './addresstype-edit-modal-dialog.component.html',
  styleUrls: ['./addresstype-edit-modal-dialog.component.scss']
})
export class AddresstypeEditModalDialogComponent implements OnInit, OnDestroy {
  @ViewChild(AddresstypeEditComponent) addressTypeComponent: AddresstypeEditComponent;
  @Input() companyId: string = null;

  @Input() closeAfterSave: boolean = false;

  // @ts-ignore
  addressType$: Observable<AddressType> = this.addressTypeStore.select(selectedAddressTypeSelect);
  addressTypeSubscription: Subscription;
  addressType: AddressType;
  selectedAddressType: AddressType;
  constructor(
      public activeModal: NgbActiveModal,
      public addressTypeStore: Store<AddressTypeState>) {
    this.addressTypeSubscription = this.addressType$.subscribe(type => {
      if (type) {
        this.addressType = type;
        if (this.closeAfterSave) {
          this.closeDialog();
        }
      }
    });
  }

  closeDialog(): void {
    this.addressTypeComponent.resetForm();
    this.activeModal.dismiss(this.addressType);
  }

  ngOnInit(): void {
    this.selectedAddressType = null;
  }

  ngOnDestroy(): void {
    this.addressTypeSubscription.unsubscribe();
    this.selectedAddressType = null;
  }
}
