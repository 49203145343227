import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, mergeMap, switchMap} from 'rxjs/operators';
import {DatevManagementApiService} from '../../../services/datev-management-api.service';
import {
    allDatevMappingsLoadedAction, datevImportOverviewLoadedAction,
    datevMappingsSavedAction, importDataSavedAction, importRecordsEndsAction,
    loadAllDatevMappingsAction,
    loadDatevImportOverviewAction, loadedImportDataAction, loadImportDataAction,
    saveDatevMappingsAction, saveImportDataAction, startImportRecordsImportAction
} from './datev.actions';
import {DatevMapping} from '../datevMapping.model';
import {DatevImportOverview} from '../datevImportOverview.model';
import {DatevSate} from './index';
import {Store} from '@ngrx/store';
import {Page} from "../../page/page.model";
import {DatevImportDtoModel} from "../datevImportDto.model";

@Injectable()
export class DatevEffects {
  constructor(
    private httpApiService: DatevManagementApiService,
    private actions$: Actions,
    private datevStore: Store<DatevSate>) {
  }

  loadDatevMappings$ = createEffect(() => this.actions$.pipe(
    ofType(loadAllDatevMappingsAction),
    mergeMap(() => this.httpApiService.getAllMappings()
      .pipe(
        map(mappings => allDatevMappingsLoadedAction({mappings}))
      ))
  ));

  saveDatevMappings$ = createEffect(() => this.actions$.pipe(
    ofType(saveDatevMappingsAction),
    switchMap(({mappings}) => this.httpApiService.addDatevMappings(mappings)
      .pipe(
        map((savedMappings: DatevMapping[]) => {
            return datevMappingsSavedAction({mappings: savedMappings});
          }
        ))
    )));

  loadDatevImportOverview$ = createEffect(() => this.actions$.pipe(
    ofType(loadDatevImportOverviewAction),
    switchMap(() => this.httpApiService.importOverview()
      .pipe(
        map((importOverview: DatevImportOverview) => {
            return datevImportOverviewLoadedAction({overview: importOverview});
          }
        ))
    )));

  importRecords$ = createEffect(() => this.actions$.pipe(
    ofType(startImportRecordsImportAction),
    switchMap(() => this.httpApiService.importRecords()
      .pipe(
        map(() => {
            this.datevStore.dispatch(loadAllDatevMappingsAction());
            this.datevStore.dispatch(loadDatevImportOverviewAction());
            return importRecordsEndsAction();
          }
        ))
    )));

    importData$ = createEffect(() => this.actions$.pipe(
        ofType(loadImportDataAction),
        switchMap(({page, size}) => this.httpApiService.getImportData(page, size)
            .pipe(
                map((data : Page<DatevImportDtoModel>) => {
                        return loadedImportDataAction({importDto: data});
                    }
                ))
        )));

    saveImportData$ = createEffect(() => this.actions$.pipe(
        ofType(saveImportDataAction),
        switchMap(({importDto}) => this.httpApiService.saveImportData(importDto)
            .pipe(
                map((result : string) => {
                    console.log(result);
                        return importDataSavedAction();
                    }
                ))
        )));
}
