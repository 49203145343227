<div class="row g-4 mb-3">
    <div class="col-sm">
        <div class="d-flex">
            <div class="search-box me-2">
                <input type="text" name="searchTerm" class="form-control"
                       placeholder="{{'labels.search' | translate}}">
                <i class="ri-search-line search-icon"></i>
            </div>
        </div>
    </div>
    <div class="col-sm-auto">
        <div *ngIf="canAddNewTasks">
            <button (click)="addNewTask()"
                    type="button" class="btn btn-danger" data-bs-toggle="modal">
                <i class="ri-add-line me-1 align-bottom"></i> {{'labels.addNewTask' | translate}}
            </button>
        </div>
    </div>
</div>

<div class="team-list list-view-filter">
    <div class="card team-box cursor-pointer" *ngFor="let task of order.tasks; let i = index">
        <app-task-card [index]="i" [task]="task"
                            (clickEvent)="selectSubOder($event)"></app-task-card>
    </div><!--end card-->
</div>
