import { Component, OnInit, Input } from '@angular/core';
import {Order} from "../../../core/models/order/order.model";
import {Router} from "@angular/router";

@Component({
  selector: 'app-orders-per-status',
  templateUrl: './orders-per-status.component.html',
  styleUrls: ['./orders-per-status.component.scss']
})

/**
 * Team Members Component
 */
export class OrdersPerStatusComponent implements OnInit {

  @Input() description: string;
  @Input() orders: Order[];

  constructor(
      private router: Router,
  ) { }

  ngOnInit(): void {
  }

  openOrder(order: Order): void {
    this.router.navigate(['/order-overview/' + order.customerId + '/' + order.id]);
  }
}
