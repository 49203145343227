import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {UserService} from "../services/user.service";

@Injectable()
export class CompanyNewGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if ( this.userService.isNewAdmin()) {
      this.router.navigate(['apps/welcome']);
      return false;
    } else {
      return true;
    }
  }
}
