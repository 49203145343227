import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {Task} from "../../../core/models/order/task.model";
import {Order} from "../../../core/models/order/order.model";


@Component({
  selector: 'app-task-edit-modal-dialog',
  templateUrl: './task-edit-modal-dialog.component.html',
  styleUrls: ['./task-edit-modal-dialog.component.scss']
})
export class TaskEditModalDialogComponent implements OnInit {

  @Input() order: Order;
  @Input() selectedCustomerId: string;
  @Input() canAddNewTasks = false;
  @Input() selectedTask: Task;
  @Input() showOpenOrder = false;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
