import {Component, Injectable, OnInit} from '@angular/core';
import {TenantState} from '../../../core/models/tenant/store';
import {Store} from '@ngrx/store';
import {registerNewTenantAction} from '../../../core/models/tenant/store/tenant.actions';
import {UserService} from "../../../core/services/user.service";

@Component({
  selector: 'app-tenant-edit',
  templateUrl: './welcome.component.html'
})
@Injectable()
export class WelcomeComponent implements OnInit {

  constructor(private tenantStore: Store<TenantState>,
              private userService: UserService) { }

  ngOnInit(): void {
    if (this.userService.isNewAdmin() ) {
      this.tenantStore.dispatch(registerNewTenantAction());
    }
  }
}
