import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';
import {IdentDescription} from '../identDescription.model';
import {OrderReference} from '../order/orderReference.model';
import {Page} from '../page/page.model';

export class MessageDocument {
  @JsonProperty()
  @JsonClassType({type: () => [String]})
  id: string;

  @JsonProperty()
  @JsonClassType({type: () => [String]})
  companyId: string;

  @JsonProperty()
  @JsonClassType({type: () => [IdentDescription]})
  sender: IdentDescription;

  @JsonProperty()
  @JsonClassType({type: () => [String]})
  message: string;

  @JsonProperty()
  @JsonClassType({type: () => [Number]})
  sequence: number;

  @JsonProperty()
  @JsonClassType({type: () => [String]})
  messageType: string;

  @JsonProperty()
  @JsonClassType({type: () => [OrderReference]})
  orderReference: OrderReference;

  @JsonProperty()
  @JsonClassType({type: () => [Date]})
  createdDate: Date;

  @JsonProperty()
  @JsonClassType({type: () => [Date]})
  lastModifiedDate: Date;

  @JsonProperty()
  @JsonClassType({type: () => [String]})
  messageIcon: string;

  @JsonProperty()
  @JsonClassType({type: () => [String]})
  displayValue: string;

  @JsonProperty()
  @JsonClassType({type: () => [String]})
  status: string;

  constructor(id: string, companyId: string, sender: IdentDescription, message: string, sequence: number, messageType: string,
              orderReference: OrderReference, createdDate: Date, lastModifiedDate: Date, messageIcon: string, displayValue: string,
              status: string) {
    this.id = id;
    this.companyId = companyId;
    this.sender = sender;
    this.message = message;
    this.sequence = sequence;
    this.messageType = messageType;
    this.orderReference = orderReference;
    this.createdDate = createdDate;
    this.lastModifiedDate = lastModifiedDate;
    this.messageIcon = messageIcon;
    this.displayValue = displayValue;
    this.status = status;
  }

  public static fromService(messageDocument: any): MessageDocument {
    const objectMapper = new ObjectMapper();

    const orderReference = OrderReference.fromService(messageDocument.orderReference);
    let displayValue = null;
    let messageIcon = 'feather icon-plus me-2 icon-md';
    if (orderReference) {
      if (orderReference.customer && orderReference.customer.displayValue) {
        displayValue = orderReference.customer.displayValue;
        messageIcon = 'feather icon-user-check me-2 icon-md';
      }
      if (orderReference.order && orderReference.order.displayValue) {
        displayValue += '\n' + orderReference.order.displayValue;
        messageIcon = 'feather icon-book-open me-2 icon-md';
      }
      if (orderReference.task && orderReference.task.displayValue) {
        displayValue += '\n' + orderReference.task.displayValue;
        messageIcon = 'feather icon-plus-square me-2 icon-md';
      }
    }
    const message = objectMapper.parse<MessageDocument>(JSON.stringify({
        id: messageDocument.id,
        companyId: messageDocument.companyId,
        sender: IdentDescription.fromService(messageDocument.sender),
        message: messageDocument.message,
        sequence: messageDocument.sequence,
        messageType: messageDocument.sourceMessageType,
        orderReference: orderReference,
        createdDate: new Date(messageDocument.createdDate),
        lastModifiedDate: new Date(messageDocument.lastModifiedDate),
        displayValue: displayValue,
        messageIcon: messageIcon,
        status: messageDocument.status
      }), {mainCreator: () => [MessageDocument]}
    );
    return message;
  }

  public static getPageFromService(messageModel: any): Page<MessageDocument> {
    if (messageModel) {
      const messageDocumentArray = [];

      messageModel.content.forEach(model => {
        messageDocumentArray.push(MessageDocument.fromService(model));
      });
      const page = new Page<MessageDocument>(
        messageModel.totalPages,
        messageModel.totalElements,
        messageModel.numberOfElements,
        messageModel.pageSize,
        messageModel.pageNumber,
        messageDocumentArray
      );
      return page;
    } else {
      return null;
    }
  }
}
