import {Action, createReducer, on} from '@ngrx/store';
import {Company} from '../company.model';
import * as CompanyActions from './company.actions';

export interface ReducerCompanyState {
  companies: Company[];
  selectedCompany: Company;
  activeCompany: Company;
}

export const initialCompanyState: ReducerCompanyState = {
  companies: [],
  selectedCompany: null,
  activeCompany: null
};

const companyReducer = createReducer(
  initialCompanyState,
  on(CompanyActions.companiesLoadedAction, (state, {payload}) => ({...state, companies: payload})),
  on(CompanyActions.companySelectedAction, (state, {payload}) => ({...state, selectedCompany: payload})),
  on(CompanyActions.companySavedAction, (state, {payload}) => ({...state, companies: filterCompanies(state.companies, payload), selectedCompany: payload })),
  on(CompanyActions.activeCompanySetAction, (state, {payload}) => ({...state, activeCompany: payload}))
);

function filterCompanies(stateCompany: Company[], newCompany: Company): Company[] {
  if (stateCompany) {
    const companies: Company[] = stateCompany.filter(x => x.companyId !== newCompany.companyId);
    companies.push(newCompany);
    return companies;
  } else {
    const companies: Company[] = [];
    companies.push(newCompany);
    return companies;
  }
}

export function reducer(state: ReducerCompanyState | undefined, action: Action): ReducerCompanyState {
  return companyReducer(state, action);
}
