import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';

export class FaqEntryModel {
  @JsonProperty()
  @JsonClassType({type: () => [String]})
  contentId: string;

  @JsonProperty()
  @JsonClassType({type: () => [String]})
  contentType: string;

  @JsonProperty()
  @JsonClassType({type: () => [Number]})
  sort: number;

  @JsonProperty()
  @JsonClassType({type: () => [String]})
  fileId: string;

  @JsonProperty()
  @JsonClassType({type: () => [Number]})
  width: number;

  @JsonProperty()
  @JsonClassType({type: () => [Number]})
  height: number;

  @JsonProperty()
  @JsonClassType({type: () => [String]})
  fileUri: string;

  @JsonProperty()
  @JsonClassType({type: () => [String]})
  text: string;

  constructor(contentId: string, contentType: string, sort: number, fileId: string, width: number, height: number, fileUri: string, text: string) {
    this.contentId = contentId;
    this.contentType = contentType;
    this.sort = sort;
    this.fileId = fileId;
    this.width = width;
    this.height = height;
    this.fileUri = fileUri;
    this.text = text;
  }

  public static fromService(entryModel: any): FaqEntryModel {
      const objectMapper = new ObjectMapper();
      const returnEntryModel = objectMapper.parse<FaqEntryModel>(JSON.stringify({
        contentId: entryModel.contentId,
        contentType: entryModel.contentType,
        sort: entryModel.sort,
        fileId: entryModel.fileId,
        width: entryModel.width,
        height: entryModel.height,
        fileUri: entryModel.fileUri,
        text: entryModel.text
          }), {mainCreator: () => [FaqEntryModel]}
      );
      return returnEntryModel;
  }

  public static fromServiceArray(entries: any): FaqEntryModel[] {
    const resultEntries = [];
    if (entries) {
      for (const entry of entries) {
        resultEntries.push(FaqEntryModel.fromService(entry));
      }
    }
    return resultEntries;
  }
}
