import {createSelector} from '@ngrx/store';
import {ReducerCompanyState} from './company.reducer';

export interface CompanyState {
  company: ReducerCompanyState;
}

export const selectCompanyState = (state: CompanyState) => state.company;

export const selectedCompanySelect = createSelector(
  selectCompanyState,
  (state) => state.selectedCompany
);

export const allCompaniesSelect = createSelector(
  selectCompanyState,
  (state) => state.companies
);

export const activeCompanySelect = createSelector(
  selectCompanyState,
  (state) => state.activeCompany
);
