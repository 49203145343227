import {Action, createReducer, on} from '@ngrx/store';
import * as AddressTypeActions from './addresstype.actions';
import {AddressType} from '../addressType.model';

export interface ReducerAddressTypeState {
  addressTypes: AddressType[];
  addressTypesForAllCompanies: AddressType[];
  addressTypesForSelectBox: AddressType[];
  selectedAddressType: AddressType;
}

export const initialAddressTypeState: ReducerAddressTypeState = {
  addressTypes: [],
  addressTypesForAllCompanies: [],
  addressTypesForSelectBox: [],
  selectedAddressType: null
};

const addresstypeReducer = createReducer(
  initialAddressTypeState,
  on(AddressTypeActions.addressTypesLoadedAction, (state, {payload}) => (
    {...state, addressTypes: payload, addressTypesForSelectBox: addCreateNewEntry(payload)})),
  on(AddressTypeActions.addressTypeSavedAction, (state, {payload}) => (
    {...state,
      addressTypes: filterAddressTypes(state.addressTypes, payload),
      selectedAddressType: payload,
      addressTypesForSelectBox: addCreateNewEntry(filterAddressTypes(state.addressTypes, payload)),
      addressTypesForAllCompanies: filterAddressTypes(state.addressTypesForAllCompanies, payload)})),
  on(AddressTypeActions.addressTypeSelectedAction, (state, {payload}) => ({...state, selectedAddressType: payload})),
  on(AddressTypeActions.addressTypesForAllCompaniesLoadedAction, (state, {payload}) => ({...state, addressTypesForAllCompanies: addCreateNewEntry(payload)}))
);

function filterAddressTypes(stateAddressTypes: AddressType[], newAddressType: AddressType): AddressType[] {
  if (stateAddressTypes) {
    const addressTypes: AddressType[] = stateAddressTypes.filter(x => x.id !== newAddressType.id);
    addressTypes.push(newAddressType);
    return addressTypes;
  } else {
    const addressTypes: AddressType[] = [];
    addressTypes.push(newAddressType);
    return addressTypes;
  }
}

function addCreateNewEntry(stateAddressTypes: AddressType[]): AddressType[] {
  const addressTypes: AddressType[] = Object.assign([], stateAddressTypes);
  addressTypes.push(new AddressType('-1', '+ Neuer Addresstyp', 'upspot'));
  return addressTypes;
}

export function reducer(state: ReducerAddressTypeState | undefined, action: Action): ReducerAddressTypeState {
  return addresstypeReducer(state, action);
}
