import {createSelector} from '@ngrx/store';
import {EmployeeVacationReducer} from "./employee-vacation.reducer";

export interface EmployeeVacationState {
  employeeVacation: EmployeeVacationReducer;
}

export const employeeVacation = (state: EmployeeVacationState) => state.employeeVacation;

export const employeeVacationEntitlementsSelect = createSelector(
    employeeVacation,
  (state) => state.entitlements
);

export const employeeVacationSelect = createSelector(
    employeeVacation,
    (state) => state.vacations
);

export const holidaysSelect = createSelector(
    employeeVacation,
    (state) => state.holidays
);

