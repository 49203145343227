import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AddressType} from '../../../core/models/addresstype/addressType.model';
import {
    AddressTypeState,
    allAddressTypesForCompaniesForAllCompaniesSelect,
} from '../../../core/models/addresstype/store';
import {Store} from '@ngrx/store';
import {
    allContactTypesForAllCompaniesSelect,
    ContactTypeState
} from '../../../core/models/contacttype/store';
import {activeCompanySelect, allCompaniesSelect, CompanyState} from '../../../core/models/company/store';
import {Observable, Subscription} from 'rxjs';
import {ContactType} from '../../../core/models/contacttype/contactType.model';
import {Company} from '../../../core/models/company/company.model';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {
    allDatevMappingsSelect, datevImportOverviewSelect, datevIsLoadingSelect,
    DatevSate
} from '../../../core/models/datev/store';
import {DatevMapping} from '../../../core/models/datev/datevMapping.model';
import {
    loadAllDatevMappingsAction,
    loadDatevImportOverviewAction,
    saveDatevMappingsAction, startImportRecordsImportAction
} from '../../../core/models/datev/store/datev.actions';
import {DatevImportOverview} from '../../../core/models/datev/datevImportOverview.model';
import {DatevImportOverviewStates} from '../../../core/models/datev/datevImportOverviewStates.model';
import {loadAllContactTypesForAllCompaniesAction} from '../../../core/models/contacttype/store/contacttype.actions';
import {loadAllAddressTypesForAlLCompaniesAction} from '../../../core/models/addresstype/store/addresstype.actions';
import {
    ContacttypeEditModalDialogComponent
} from '../../contacttype/contacttype-edit-modal-dialog/contacttype-edit-modal-dialog.component';
import {
    AddresstypeEditModalDialogComponent
} from "../../addresstype/addresstype-edit-modal-dialog/addresstype-edit-modal-dialog.component";
import {OrderType} from "../../../core/models/order/ordertype/ordertype.model";
import {
    allOrderTypesForAllCompaniesSelect,
    OrderTypeState
} from "../../../core/models/order/ordertype/store";
import {
    OrdertypeEditModalDialogComponent
} from "../../ordertype/ordertype-edit-modal-dialog/ordertype-edit-modal-dialog.component";
import {
    allTaskTypesForAllCompaniesSelect, selectedTaskTypeSelect,
    TaskTypeState
} from "../../../core/models/order/tasktype/store";
import {TaskType} from "../../../core/models/order/tasktype/tasktype.model";
import {
    loadAllOrderTypesForAllCompaniesAction
} from "../../../core/models/order/ordertype/store/ordertype.actions";
import {loadAllTaskTypesForAllCompaniesAction} from "../../../core/models/order/tasktype/store/taskype.actions";
import {
    TaskTypeEditModalDialogComponent
} from "../../taskType/tasktype-edit-modal-dialog/task-type-edit-modal-dialog.component";
import {
    BatchState, selectBatchStatus
} from "../../../core/models/batch/store";
import {loadDatevBachAction, startDatevBatch} from "../../../core/models/batch/store/batch.actions";
import {BatchModel} from "../../../core/models/batch/batch.model";
import {Router} from "@angular/router";

@Component({
    selector: 'app-datev-transformation',
    templateUrl: './datevTransformation.component.html',
    styleUrls: ['./datevTransformation.component.scss']
})
export class DatevTransformationComponent implements OnInit, OnDestroy {

    taskTypes$: Observable<TaskType[]> = this.taskTypeStore.select(allTaskTypesForAllCompaniesSelect);
    taskTypeSubscription: Subscription;
    taskTypes: TaskType[];

    orderTypes$: Observable<OrderType[]> = this.orderTypeStore.select(allOrderTypesForAllCompaniesSelect);
    orderTypeSubscription: Subscription;
    orderTypes: OrderType[];

    addressTypes$: Observable<AddressType[]> = this.addressTypeStore.select(allAddressTypesForCompaniesForAllCompaniesSelect);
    addressTypeSubscription: Subscription;
    addressTypes: AddressType[];

    contactTypes$: Observable<ContactType[]> = this.contactTypeStore.select(allContactTypesForAllCompaniesSelect);
    contactTypeSubscription: Subscription;
    contactTypes: ContactType[];

    companies$: Observable<Company[]> = this.companyStore.select(allCompaniesSelect);
    companySubscription: Subscription;
    companies: Company[];

    activeCompany$: Observable<Company> = this.companyStore.select(activeCompanySelect);
    activeCompanySubscription: Subscription;
    activeCompany: Company;

    datevMappings$: Observable<DatevMapping[]> = this.datevStore.select(allDatevMappingsSelect);
    datevMappingsSubscription: Subscription;
    datevMappings: DatevMapping[] = [];

    datevImportOverview$: Observable<DatevImportOverview> = this.datevStore.select(datevImportOverviewSelect);
    datevImportOverviewSubscription: Subscription;
    datevImportOverview: DatevImportOverview;

    batch$: Observable<BatchModel> = this.batchStore.select(selectBatchStatus);
    batchSubscription: Subscription;
    batch: BatchModel;

    datevIsLoading$: Observable<boolean> = this.datevStore.select(datevIsLoadingSelect);

    addressTypeModal: NgbModalRef;
    orderTypeModal: NgbModalRef;
    taskTypeModal: NgbModalRef;
    contactTypeModal: NgbModalRef;

    dataChanged = false;
    datevTypeMappingMap = new Map<string, Map<string, DatevMapping[]>>();
    showRestartImport = false;

    breadCrumbItems!: Array<{}>;

    @ViewChild('datevTransformation') datevTransformationModal: any;
    datevTransformation: NgbModalRef;

    constructor(
        private taskTypeStore: Store<TaskTypeState>,
        private orderTypeStore: Store<OrderTypeState>,
        private addressTypeStore: Store<AddressTypeState>,
        private contactTypeStore: Store<ContactTypeState>,
        private companyStore: Store<CompanyState>,
        private datevStore: Store<DatevSate>,
        private modalService: NgbModal,
        private batchStore: Store<BatchState>,
        private router: Router
    ) {
        this.showRestartImport = false;

        this.addressTypeStore.dispatch(loadAllAddressTypesForAlLCompaniesAction());
        this.contactTypeStore.dispatch(loadAllContactTypesForAllCompaniesAction());
        this.orderTypeStore.dispatch(loadAllOrderTypesForAllCompaniesAction());
        this.taskTypeStore.dispatch(loadAllTaskTypesForAllCompaniesAction());
        this.batchStore.dispatch(loadDatevBachAction());

        this.activeCompanySubscription = this.activeCompany$.subscribe(company => {
            if (company) {
                this.activeCompany = company;
                this.datevStore.dispatch(loadAllDatevMappingsAction());
                this.datevStore.dispatch(loadDatevImportOverviewAction());
            }
        });

        this.orderTypeSubscription = this.orderTypes$.subscribe(types => {
            if (types) {
                this.orderTypes = types;
            }
        });

        this.taskTypeSubscription = this.taskTypes$.subscribe(types => {
            if (types) {
                this.taskTypes = types;
            }
        });

        this.addressTypeSubscription = this.addressTypes$.subscribe(types => {
            if (types) {
                this.addressTypes = types;
            }
        });

        this.contactTypeSubscription = this.contactTypes$.subscribe(types => {
            if (types) {
                this.contactTypes = types;
            }
        });

        this.companySubscription = this.companies$.subscribe(companies => {
            this.companies = companies;
        });

        this.batchSubscription = this.batch$.subscribe( batch => {
            this.batch = batch;
            this.datevStore.dispatch(loadDatevImportOverviewAction());
        })

        this.datevMappingsSubscription = this.datevMappings$.subscribe(mappings => {
            if (mappings) {
                this.datevMappings = [];
                this.datevTypeMappingMap = new Map();
                mappings.forEach(mapping => {
                    const copyMapping = {...mapping};
                    let companyMappings = null;
                    if (copyMapping.ident === 'COMPANY_ID') {
                        copyMapping.companyId = copyMapping.conversion;
                    }
                    if (this.datevTypeMappingMap.has(copyMapping.companyId)) {
                        companyMappings = this.datevTypeMappingMap.get(copyMapping.companyId);
                    } else {
                        companyMappings = new Map();
                    }
                    if (companyMappings) {
                        let mappingsByIdent = null;
                        if (companyMappings.has(copyMapping.ident)) {
                            mappingsByIdent = companyMappings.get(copyMapping.ident);
                        } else {
                            mappingsByIdent = [];
                        }
                        mappingsByIdent.push(copyMapping);
                        companyMappings.set(copyMapping.ident, mappingsByIdent);
                    }

                    this.datevTypeMappingMap.set(copyMapping.companyId, companyMappings);
                    this.datevMappings.push(copyMapping);
                });
            }
        });

        this.datevImportOverviewSubscription = this.datevImportOverview$.subscribe(overview => {
            if (overview) {
                overview.states?.forEach((state: DatevImportOverviewStates) => {
                    if ((state.state === 'WAITING' && state.records > 0)
                        || (state.state === 'MAPPING_ISSUES' && state.records > 0)
                        || (state.state === 'ERRONEOUS' && state.records > 0)) {
                        this.showRestartImport = true;
                    }
                });
                this.datevImportOverview = overview;
            }
        });
    }

    ngOnInit() {
        /**
         * BreadCrumb
         */
        this.breadCrumbItems = [
            {label: 'menu.datev'},
            {label: 'menu.datevTransformation', active: true}
        ];
    }

    ngOnDestroy(): void {
        this.taskTypeSubscription.unsubscribe();
        this.orderTypeSubscription.unsubscribe();
        this.contactTypeSubscription.unsubscribe();
        this.addressTypeSubscription.unsubscribe();
        this.companySubscription.unsubscribe();
        this.datevMappingsSubscription.unsubscribe();
        this.datevImportOverviewSubscription.unsubscribe();
        this.batchSubscription.unsubscribe();
    }

    selectedContactType(value: any, company: string, datevType): void {
        const contactType = value.target.value.substr(3, value.target.value.length);
        if (contactType === '-1') {
            this.contactTypeModal = this.openModalDialog(ContacttypeEditModalDialogComponent);
            this.contactTypeModal.componentInstance.companyId = company;
            this.contactTypeModal.componentInstance.closeAfterSave = true;

            this.contactTypeModal.dismissed.subscribe(type => {
                this.datevMappings.forEach((mapping, index, mappings) => {
                    if (mapping.id === datevType.id) {
                        mapping.conversion = type.id;
                    }
                });
            });
        }
        this.dataChanged = true;
    }

    selectedAddressType(value: any, company: string, datevType): void {
        const addressType = value.target.value.substr(3, value.target.value.length);
        if (addressType === '-1') {
            this.addressTypeModal = this.openModalDialog(AddresstypeEditModalDialogComponent);
            this.addressTypeModal.componentInstance.companyId = company;
            this.addressTypeModal.componentInstance.closeAfterSave = true;

            this.addressTypeModal.dismissed.subscribe(type => {
                this.datevMappings.forEach((mapping, index, mappings) => {
                    if (mapping.id === datevType.id) {
                        mapping.conversion = type.id;
                    }
                });
            });
        }
        this.dataChanged = true;
    }

    selectedOrderType(value: any, company: string, datevType): void {
        const orderType = value.target.value.substr(3, value.target.value.length);
        if (orderType === '-1') {
            this.orderTypeModal = this.openModalDialog(OrdertypeEditModalDialogComponent);
            this.orderTypeModal.componentInstance.companyId = company;
            this.orderTypeModal.componentInstance.closeAfterSave = true;

            this.orderTypeModal.dismissed.subscribe(type => {
                this.datevMappings.forEach((mapping, index, mappings) => {
                    if (mapping.id === datevType.id) {
                        mapping.conversion = type.id;
                    }
                });
            });
        }
        this.dataChanged = true;
    }

    selectedTaskTypeAction(value: any, company: string, datevType): void {
        const taskType = value.target.value.substr(3, value.target.value.length);
        if (taskType === '-1') {
            this.taskTypeModal = this.openModalDialog(TaskTypeEditModalDialogComponent);
            this.taskTypeModal.componentInstance.companyId = company;
            this.taskTypeModal.componentInstance.closeAfterSave = true;

            this.taskTypeModal.dismissed.subscribe(type => {
               this.datevMappings.forEach((mapping, index, mappings) => {
                  if (mapping.id === datevType.id) {
                    mapping.conversion = type.id;
                  }
               });
            });
        }
        this.dataChanged = true;
    }

    selectedCompany(value: any): void {
        this.dataChanged = true;
    }

    isIgnored(): void {
        this.dataChanged = true;
    }

    openModalDialog(content): NgbModalRef {
        return this.modalService.open(content, {size: 'lg', animation: true, centered: true, backdrop: 'static', keyboard: false});
    }

    openTransformation() {
        this.datevTransformation = this.openModalDialog(this.datevTransformationModal);
    }

    getUpspotName(datevMapping): string {
        let companyName = 'Nicht zugeordnet';

        datevMapping.value.forEach(mappings => {
            mappings.forEach(mapping => {
                if (mapping.ident === 'COMPANY_ID') {
                    const company = this.companies.find(company => company.companyId === mapping.conversion);
                    if (company) {
                        companyName = company.address.companyName;
                    }
                }
            });
        });
        return companyName;
    }

    getDatevName(datevMapping): string {
        let companyName = '';

        datevMapping.value.forEach(mappings => {
            mappings.forEach(mapping => {
                if (mapping.ident === 'COMPANY_ID' && mapping.conversion) {
                    companyName = 'Datev: ' + mapping.mappingSource;
                }
            });
        });
        return companyName;
    }

    hasMappingIssues(datevMapping): boolean {
        let hasErrors = false;
        datevMapping.value.forEach(mappings => {
            mappings.forEach(mapping => {
                if( mapping.isMissingConversion ) {
                    hasErrors = true;
                }
            });
        });
        return hasErrors;
    }

    save(): void {
        this.datevStore.dispatch(saveDatevMappingsAction({mappings: this.datevMappings}));
        this.dataChanged = false;
    }

    startImportRecords(): void {
        this.batchStore.dispatch(startDatevBatch());
    }
}
