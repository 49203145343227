import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';
import {FaqDocumentModel} from "./faqDocument.model";

export class FaqCategoryModel {
  @JsonProperty()
  @JsonClassType({type: () => [String]})
  categoryId: string;

  @JsonProperty()
  @JsonClassType({type: () => [String]})
  title: string;

  @JsonProperty()
  @JsonClassType({type: () => [Number]})
  sort: number;

  @JsonProperty()
  @JsonClassType({type: () => [Array, [FaqDocumentModel]]})
  documents: FaqDocumentModel[];


  constructor(categoryId: string, title: string, sort: number, documents: FaqDocumentModel[]) {
    this.categoryId = categoryId;
    this.title = title;
    this.sort = sort;
    this.documents = documents;
  }

  public static fromService(category: any): FaqCategoryModel {
      const objectMapper = new ObjectMapper();
      const returnCategory = objectMapper.parse<FaqCategoryModel>(JSON.stringify({
        categoryId: category.categoryId,
        title: category.title,
        sort: category.sort,
        documents: FaqDocumentModel.fromServiceArray(category.documents)
          }), {mainCreator: () => [FaqCategoryModel]}
      );
      return returnCategory;
  }

  public static fromServiceArray(categories: any): FaqCategoryModel[] {
    const resultCategories = [];
    if (categories) {
      for (const category of categories) {
        resultCategories.push(FaqCategoryModel.fromService(category));
      }
    }
    return resultCategories;
  }
}
