import {Action, createReducer, on} from '@ngrx/store';
import * as OrderTypeActions from './ordertype.actions';
import {OrderType} from '../ordertype.model';

export interface ReducerOrderTypeState {
  orderTypes: OrderType[];
  orderTypesForSelectBox: OrderType[];
  orderTypesAllCompanies: OrderType[];
  selectedOrderType: OrderType;
}

export const initialOrderTypeState: ReducerOrderTypeState = {
  orderTypes: [],
  orderTypesForSelectBox: [],
  orderTypesAllCompanies: [],
  selectedOrderType: null
};

const ordertypeReducer = createReducer(
  initialOrderTypeState,
  on(OrderTypeActions.orderTypesLoadedAction, (state, {payload}) => (
    {...state, orderTypes: payload, orderTypesForSelectBox: addCreateNewEntry(payload)})),
  on(OrderTypeActions.orderTypeSavedAction, (state, {payload}) => (
    {...state,
      orderTypes: filterOrderTypes(state.orderTypes, payload),
      selectedOrderType: payload,
      orderTypesForSelectBox: addCreateNewEntry(filterOrderTypes(state.orderTypes, payload)),
      orderTypesAllCompanies: filterOrderTypes(state.orderTypesAllCompanies, payload)})),
  on(OrderTypeActions.orderTypeSelectedAction, (state, {payload}) => ({...state, selectedOrderType: payload})),
  on(OrderTypeActions.orderTypesForAllCompaniesLoadedAction, (state, {payload}) => ({...state, orderTypesAllCompanies: payload}))
);

function filterOrderTypes(stateOrderTypes: OrderType[], newOrderType: OrderType): OrderType[] {
  if ( stateOrderTypes) {
    const orderTypes: OrderType[] = stateOrderTypes.filter(x => x.id !== newOrderType.id);
    orderTypes.push(newOrderType);
    return orderTypes;
  } else {
    const orderTypes: OrderType[] = [];
    orderTypes.push(newOrderType);
    return orderTypes;
  }
}

function addCreateNewEntry(stateOrderTypes: OrderType[]): OrderType[] {
  const orderTypes: OrderType[] = Object.assign([], stateOrderTypes);
  orderTypes.splice(0, 0, new OrderType('-1', '+ Neuer Auftragstyp', null, null, null, null, 'upspot', []));
  return orderTypes;
}

export function reducer(state: ReducerOrderTypeState | undefined, action: Action): ReducerOrderTypeState {
  return ordertypeReducer(state, action);
}
