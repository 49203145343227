import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';
import {StatusMessageAuthorModel} from "./statusMessageAuthor.model";

export class StatusMessageModel {
  @JsonProperty()
  @JsonClassType({type: () => [Date]})
  updated: Date;

  @JsonProperty()
  @JsonClassType({type: () => [String]})
  documentId: string;

  @JsonProperty()
  @JsonClassType({type: () => [Number]})
  sort: number;

  @JsonProperty()
  @JsonClassType({type: () => [StatusMessageAuthorModel]})
  author: StatusMessageAuthorModel;

  @JsonProperty()
  @JsonClassType({type: () => [String]})
  message: string;

  @JsonProperty()
  @JsonClassType({type: () => [String]})
  type: string;

  @JsonProperty()
  @JsonClassType({type: () => [Date]})
  showMessageFromUTC: Date;

  @JsonProperty()
  @JsonClassType({type: () => [Date]})
  messageExpireAfterUTC: Date;

  @JsonProperty()
  @JsonClassType({type: () => [Boolean]})
  messageRead: boolean;

  constructor(updated: Date, documentId: string, sort: number, author: StatusMessageAuthorModel, message: string, type: string, showMessageFromUTC: Date, messageExpireAfterUTC: Date, messageRead: boolean) {
    this.updated = updated;
    this.documentId = documentId;
    this.sort = sort;
    this.author = author;
    this.message = message;
    this.type = type;
    this.showMessageFromUTC = showMessageFromUTC;
    this.messageExpireAfterUTC = messageExpireAfterUTC;
    this.messageRead = messageRead;
  }

  public static fromService(statusMessageModel: any): StatusMessageModel {
      const objectMapper = new ObjectMapper();
      const returnStatusMessageModel = objectMapper.parse<StatusMessageModel>(JSON.stringify({
        updated: new Date(statusMessageModel.updated),
        documentId: statusMessageModel.documentId,
        sort: statusMessageModel.sort,
        author: StatusMessageAuthorModel.fromService(statusMessageModel.author),
        message: statusMessageModel.message,
        type: statusMessageModel.type,
        showMessageFromUTC: statusMessageModel.showMessageFromUTC,
        messageExpireAfterUTC: statusMessageModel.messageExpireAfterUTC,
        messageRead: statusMessageModel.messageRead
          }), {mainCreator: () => [StatusMessageModel]}
      );
      return returnStatusMessageModel;
  }

  public static fromServiceArray(statusMessageArray: any): StatusMessageModel[] {
    const resultStatusMessages = [];
    if (statusMessageArray) {
      for (const statusMessage of statusMessageArray) {
        resultStatusMessages.push(StatusMessageModel.fromService(statusMessage));
      }
    }
    return resultStatusMessages;
  }
}
