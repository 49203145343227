<ng-container>
    <div class="modal-header bg-soft-info p-3">
        <h5 class="modal-title" id="exampleModalLabel">{{'labels.order' | translate}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal"
                (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <div class="row" *ngIf="selectedCustomer$ | async">
        <form [formGroup]="orderForm" (ngSubmit)="saveOrder()" class="">
            <div class="modal-body">
                <div class="row g-3">
                    <div class="col-12 col-md-6">
                        <ng-template #rtOrdertype let-resultTemplateOrderType="result" let-t="term">
                            <div>{{resultTemplateOrderType.description}}</div>
                        </ng-template>

                        <small class="text-uppercase text-muted">{{ 'labels.orderType' | translate }}</small>
                        <div class="input-group">
                            <input
                                    id="orderType"
                                    type="text"
                                    class="form-control"
                                    formControlName="orderType"
                                    [ngbTypeahead]="searchOrderType"
                                    (focus)="focusOrderType$.next($any($event).target.value)"
                                    (click)="clickOrderType$.next($any($event).target.value)"
                                    [inputFormatter]="Orderformatter"
                                    [resultTemplate]="rtOrdertype"
                                    (selectItem)="selectOrderType($event)"
                                    #instanceOrderType="ngbTypeahead"
                            />
                            <span class="input-group-text"><em class="feather icon-search"></em></span>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <small class="text-muted text-uppercase">{{ 'labels.externalOrderId' | translate }}</small>
                        <input type="text" class="form-control" id="externalOrderId" formControlName="externalId">
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-12">
                        <small class="text-uppercase text-muted">{{ 'labels.description' | translate }}
                            *</small>
                        <textarea class="form-control" id="description" formControlName="description"
                                  rows="6"
                                  [class.invalid-Input-Formfeedback]="orderForm.controls.description.errors?.required && orderForm.controls.description.touched"></textarea>
                        <div class="invalid-Formfeedback"
                             *ngIf="orderForm.controls.description.errors?.required && orderForm.controls.description.touched">
                            <small>{{ 'labels.requiredField' | translate}}</small>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-12 col-md-4">
                        <small class="text-muted text-uppercase">{{ 'labels.startdate' | translate }}</small>
                        <div class="input-group">
                            <input class="form-control" placeholder="dd.MM.yyyy"
                                   name="dp" id="startDate" disabled
                                   [value]="selectedOrder?.startDate | date: 'dd.MM.yyyy'">
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <small class="text-uppercase text-muted">{{ 'labels.enddate' | translate }}</small>
                        <div class="input-group">
                            <input class="form-control" placeholder="dd.MM.yyyy"
                                   name="dp" id="endDate" disabled
                                   [value]="selectedOrder?.endDate | date: 'dd.MM.yyyy'">
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <small class="text-muted text-uppercase">{{ 'labels.desiredDate' | translate }}</small>
                        <div class="input-group">
                            <input class="form-control flatpickr-input" type="text" formControlName="desiredDate"
                                   autocomplete="off" placeholder="dd.MM.yyyy" mwlFlatpickr [allowInput]="true"
                                   [convertModelValue]="true" dateFormat="d.m.Y" locale="de">
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-12">
                        <div class="mt-3 form-check form-check-flat form-check-primary">
                            <label class="form-check-label">
                                <input type="checkbox" class="form-check-input"
                                       name="differentOperationAddress"
                                       formControlName="differentOperationAddress"
                                       (click)="clickIsDifferentOperationAddress()">
                                <em class="input-frame"></em>
                                <small class="text-uppercase text-muted">{{ 'labels.differentOperationAddress' | translate }}</small>
                            </label>
                        </div>
                    </div>
                </div>
                <div formGroupName="operationAddress" *ngIf="differentOperationAddress">
                    <div class="row mb-3">
                        <div class="col-12">
                            <small class="text-uppercase text-muted">{{ 'labels.name' | translate }}</small>
                            <input type="text" class="form-control" id="name" formControlName="name">
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12 col-md-4">
                            <small class="text-uppercase text-muted">{{ 'labels.street' | translate }}</small>
                            <input type="text" class="form-control" id="mainStreet"
                                   formControlName="street">
                        </div>
                        <div class="col-12 col-md-4">
                            <small class="text-uppercase text-muted">{{ 'labels.zipcode' | translate }}</small>
                            <input type="text" class="form-control" id="mainZipcode"
                                   formControlName="zipcode">
                        </div>
                        <div class="col-12 col-md-4">
                            <small class="text-uppercase text-muted">{{ 'labels.city' | translate }}</small>
                            <input type="text" class="form-control" id="mainCity" formControlName="city">
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="hstack gap-2 justify-content-end">

                    <button type="submit" class="btn btn-success"
                            [disabled]="!orderForm.valid"
                            (click)="saveOrder()">{{ 'labels.save' | translate}}</button>
                </div>
            </div>
        </form>
    </div>
</ng-container>

<ng-template #createNewOrderType let-createNewOrderType>
    <div class="modal-header">
        <button type="button" class="btn-close" (click)="createNewOrderType.close('by: close icon')"
                aria-label="Close"></button>
    </div>
    <div class="modal-body">
        <app-ordertype-edit></app-ordertype-edit>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark"
                (click)="createNewOrderType.close('Save click')">{{ 'labels.close' | translate}}</button>
    </div>
</ng-template>

