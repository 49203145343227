import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {DashboardComponent} from "./dashboard/dashboard.component";
import {CompanyOverviewComponent} from "./company/company-overview/company-overview.component";
import {CompanyNewGuard} from "../core/guards/company-new.guard";
import {AppAuthGuard} from "../core/guards/appAuthGuard .guard";
import {RoleBasedGuard} from "../core/guards/roleBased.guard";
import {WelcomeComponent} from "./welcome/welcome/welcome.component";
import {
    CompanyEmployeesOverviewComponent
} from "./company/company-employees-overview/company-employees-overview.component";
import {
    CompanyBasicdataOverviewComponent
} from "./company/company-basicdata-overview/company-basicdata-overview.component";
import {CompanyEditComponent} from "./company/edit/company-edit.component";
import {CalendarComponent} from "./calendar/calendar.component";
import {DatevTransformationComponent} from "./datev/transformation/datevTransformation.component";
import {CustomerListComponent} from "./customer/customer-list/customer-list.component";
import {OrderEditComponent} from "./orders/edit/order-edit.component";
import {LoginGuard} from "../core/guards/login.guard";
import {MessageComponent} from "./message/message.component";
import {PersonTaskListComponent} from "./employee/person-task-list/person-task-list.component";
import {EditOrderMobileComponent} from "./orders/edit-mobile/edit-order-mobile.component";
import {TaskEditMobileComponent} from "./orders/task-edit-mobile/task-edit-mobile.component";
import {DatevErrorComponent} from "./datev/datev-error/datev-error.component";
import {DatevErrorListComponent} from "./datev/datev-error-list/datev-error-list.component";
import {FaqsComponent} from "./faqs/faqs.component";

// Component pages

const routes: Routes = [
    {
        path: "",
        component: DashboardComponent,
        canActivate: [AppAuthGuard,RoleBasedGuard],
    },
    {
        path: 'redirect',
        component: WelcomeComponent,
        canActivate: [RoleBasedGuard]
    },
    {
        path: 'welcome',
        component: WelcomeComponent,
        canActivate: [AppAuthGuard],
        data: {
            roles: ['ROLE_NEWADMIN', 'ROLE_ADMIN']
        }
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AppAuthGuard, CompanyNewGuard],
        data: {
            roles: ['ROLE_OPENDASHBOARD']
        }
    },
    {
        path: 'calendar',
        component: CalendarComponent,
        canActivate: [AppAuthGuard, CompanyNewGuard],
        data: {
            roles: ['ROLE_OPENCALENDAR']
        }
    },
    {
        path: 'order-edit',
        component: OrderEditComponent,
        canActivate: [AppAuthGuard, CompanyNewGuard],
        data: {
            roles: ['ROLE_OPENORDEREDIT']
        }
    },
    /*
    {
        path: 'company',
        component: CompanyComponent,
        canActivate: [AppAuthGuard, CompanyNewGuard],
        data: {
            roles: ['ROLE_OPENCOMPANY']
        }
    },

     */
    {
        path: 'company-edit',
        component: CompanyEditComponent,
        canActivate: [AppAuthGuard, CompanyNewGuard],
        data: {
            roles: ['ROLE_OPENCOMPANYEDIT']
        }
    },
    {
        path: 'company-overview',
        component: CompanyOverviewComponent,
        canActivate: [AppAuthGuard, CompanyNewGuard],
        data: {
            roles: ['ROLE_OPENCOMPANYOVERVIEW']
        }
    },/*
    {
        path: 'company-customer-overview',
        component: CompanyCustomerOverviewComponent,
        canActivate: [AppAuthGuard, CompanyNewGuard],
        data: {
            roles: ['ROLE_OPENCOMPANYCUSTOMEROVERVIEW']
        }
    },
    {
        path: 'company-customer-overview/:createNew',
        component: CompanyCustomerOverviewComponent,
        canActivate: [AppAuthGuard, CompanyNewGuard],
        data: {
            roles: ['ROLE_OPENCOMPANYCUSTOMEROVERVIEWNEW']
        }
    },
    */
    {
        path: 'company-employee-overview',
        component: CompanyEmployeesOverviewComponent,
        canActivate: [AppAuthGuard, CompanyNewGuard],
        data: {
            roles: ['ROLE_OPENCOMPANYEMPLOYEEOVERVIEW']
        }
    },
    {
        path: 'company-employee-overview/:createNew',
        component: CompanyEmployeesOverviewComponent,
        canActivate: [AppAuthGuard, CompanyNewGuard],
        data: {
            roles: ['ROLE_OPENCOMPANYEMPLOYEEOVERVIEW']
        }
    },
    {
        path: 'company-basicdata',
        component: CompanyBasicdataOverviewComponent,
        canActivate: [AppAuthGuard, CompanyNewGuard],
        data: {
            roles: ['ROLE_OPENCOMPANYBASICDATA']
        }
    },

    {
        path: 'order-overview',
        component: OrderEditComponent,
        canActivate: [AppAuthGuard, CompanyNewGuard],
        data: {
            roles: ['ROLE_OPENORDEROVERVIEW']
        }
    },

    {
        path: 'order-overview/:customerId/:orderId',
        component: OrderEditComponent,
        canActivate: [AppAuthGuard, CompanyNewGuard],
        data: {
            roles: ['ROLE_OPENORDEROVERVIEW']
        }
    },
    /*
    {
        path: 'order-list',
        component: OrderListComponent,
        canActivate: [AppAuthGuard, CompanyNewGuard],
        data: {
            roles: ['ROLE_OPENORDERLIST']
        }
    },
    */
    {
        path: 'person-calendar',
        component: PersonTaskListComponent,
        canActivate: [AppAuthGuard, CompanyNewGuard],
        data: {
            roles: ['ROLE_OPENEMPLOYEECALENDAR']
        }
    },

    {
        path: 'order-edit-mobile/:customerId/:orderId/:editable',
        component: OrderEditComponent,
        canActivate: [AppAuthGuard, CompanyNewGuard],
        data: {
            roles: ['ROLE_OPENEDITORDERMOBILE']
        }
    },
    {
        path: 'task-edit-mobile/:customerId/:orderId/:taskId',
        component: TaskEditMobileComponent,
        canActivate: [AppAuthGuard, CompanyNewGuard],
        data: {
            roles: ['ROLE_OPENEDITORDERMOBILE']
        }
    },

    {
        path: 'datev/transformation',
        component: DatevTransformationComponent,
        canActivate: [AppAuthGuard, CompanyNewGuard],
        data: {
            roles: ['ROLE_DATEVTRANSFORMATION']
        }
    },
    {
        path: 'datev/error',
        component: DatevErrorComponent,
        canActivate: [AppAuthGuard, CompanyNewGuard],
        data: {
            roles: ['ROLE_DATEVTRANSFORMATION']
        }
    },
    {
        path: 'datev/error-list',
        component: DatevErrorListComponent,
        canActivate: [AppAuthGuard, CompanyNewGuard],
        data: {
            roles: ['ROLE_DATEVTRANSFORMATION']
        }
    },
    {
        path: 'customer-list',
        component: CustomerListComponent,
    },
    {
        path: 'customer-list/:customerId',
        component: CustomerListComponent,
    },
    {
        path: 'messages',
        component: MessageComponent,
        canActivate: [AppAuthGuard, CompanyNewGuard],
        data: {
            roles: []
        }
    },
    {
        path: 'faq',
        component: FaqsComponent,
        canActivate: [AppAuthGuard, CompanyNewGuard],
        data: {
            roles: []
        }
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
