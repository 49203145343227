<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <div class="card-title">{{ 'labels.tasks' | translate }}</div>
        <div class="row mb-3">
          <div class="col-12 col-md-6">
            <small class="text-uppercase text-muted">{{ 'labels.startdate' | translate }}</small>
            <input class="form-control" placeholder="dd.MM.yyyy" [(ngModel)]="startDate" (ngModelChange)="loadOrders()"
                   name="dp" ngbDatepicker #startDatePicker="ngbDatepicker" id="taskStartDate"
                   autocomplete="off" (click)="startDatePicker.toggle()">
          </div>
          <div class="col-12 col-md-6">
            <small class="text-uppercase text-muted">{{ 'labels.enddate' | translate }}</small>
            <input class="form-control" placeholder="dd.MM.yyyy" [(ngModel)]="endDate" (ngModelChange)="loadOrders()"
                   name="dp" ngbDatepicker #endDatePicker="ngbDatepicker" id="taskEndDate"
                   autocomplete="off" (click)="endDatePicker.toggle()">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="cardContainer mt-3">
  <div class="card mt-3" *ngFor="let orderObject of tasksByOrders">
    <div class="card-header d-flex justify-content-between cursor-pointer" (click)="openOrder(orderObject.order)">
      <div>{{ orderObject?.order?.orderType?.description }} <br>
        <small class="text-uppercase text-muted"
               *ngIf="orderObject.order.externalId">{{ orderObject.order.externalId?.id }}</small>
      </div>
      <div>{{ orderObject.order.startDate | date: 'dd.MM.yyyy'}}
        - {{orderObject.order.endDate | date: 'dd.MM.yyyy'}}</div>
      <div>
        <small class="text-uppercase text-muted">{{ orderObject.order.orderState | orderStatus | translate}}</small>
      </div>
    </div>
    <div class="card-body">
      <div class="upspot-card-container">
        <div class="taskListTaskCard" *ngFor="let task of orderObject.tasks">
          <div class="text-truncate">
            {{ orderObject?.order?.operationAddress | fullAddress }}
          </div>
          <div class="text-truncate">
            {{ task.description }}
          </div>
          <div>
            <small class="text-uppercase text-muted">{{ task.startDate | date: 'dd.MM.yyyy'}} - {{task.endDate | date: 'dd.MM.yyyy'}}</small>
          </div>
          <div>
            <small class="text-uppercase text-muted">{{ task.state | taskStatus | translate}}</small>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</div>

<div class="row mt-3" *ngIf="tasksByOrders">
  <div class="col-md-12">
    <div class="card">
      <div class="card-footer d-flex justify-content-between">
        <div>
          <small class="text-uppercase text-muted">{{ 'labels.orders' | translate }}</small> <br>
          {{tasksByOrders.length}}
        </div>
        <div>
        </div>
      </div>
    </div>
  </div>
</div>
