import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';

export class BatchProgress {
    @JsonProperty() @JsonClassType({type: () => [Number]})
    total: number;
    @JsonProperty() @JsonClassType({type: () => [Number]})
    finished: number;
    @JsonProperty() @JsonClassType({type: () => [Number]})
    progressPct: number;

    constructor(
        total: number,
        finished: number,
        progressPct: number
    ) {
        this.total = total;
        this.finished = finished;
        this.progressPct = progressPct;
    }

    public static fromService(progress: any): BatchProgress {
        const objectMapper = new ObjectMapper();
        return objectMapper.parse<BatchProgress>(JSON.stringify({
            total: progress.total,
            finished: progress.finished,
            progressPct: progress.progressPct
            }
        ), {mainCreator: () => [BatchProgress]});
    }
}
