import {Action, createReducer, on} from '@ngrx/store';
import * as ContactTypeActions from './contacttype.actions';
import {ContactType} from '../contactType.model';

export interface ReducerContactTypeState {
  contactTypes: ContactType[];
  contactTypesAllCompanies: ContactType[];
  contactTypesForSelectBox: ContactType[];
  selectedContactType: ContactType;
}

export const initialContactTypeState: ReducerContactTypeState = {
  contactTypes: [],
  contactTypesAllCompanies: [],
  contactTypesForSelectBox: [],
  selectedContactType: null
};

const contactTypeReducer = createReducer(
  initialContactTypeState,
  on(ContactTypeActions.contactTypesLoadedAction, (state, {payload}) => (
    {...state, contactTypes: payload, contactTypesForSelectBox: addCreateNewEntry(payload)})),
  on(ContactTypeActions.contactTypeSavedAction, (state, {payload}) => (
    {...state,
      contactTypes: filterContactTypes(state.contactTypes, payload),
      selectedContactType: payload,
      contactTypesForSelectBox: addCreateNewEntry(filterContactTypes(state.contactTypes, payload)),
      contactTypesAllCompanies: filterContactTypes(state.contactTypesAllCompanies, payload)})),
  on(ContactTypeActions.contactTypeSelectedAction, (state, {payload}) => ({...state, selectedContactType: payload})),
  on(ContactTypeActions.contactTypesForAllCompaniesLoadedAction, (state, {payload}) => ({...state, contactTypesAllCompanies: addCreateNewEntry(payload)}))
);

function filterContactTypes(stateContactTypes: ContactType[], newContactType: ContactType): ContactType[] {
  if (stateContactTypes) {
    const contactTypes: ContactType[] = stateContactTypes.filter(x => x.id !== newContactType.id);
    contactTypes.push(newContactType);
    return contactTypes;
  } else {
    const contactTypes: ContactType[] = [];
    contactTypes.push(newContactType);
    return contactTypes;
  }
}

function addCreateNewEntry(stateContactTypes: ContactType[]): ContactType[] {
  const contactTypes: ContactType[] = Object.assign([], stateContactTypes);
  contactTypes.push(new ContactType('-1', '+ Neuer Kontakttyp', 'upspot'));
  return contactTypes;
}

export function reducer(state: ReducerContactTypeState | undefined, action: Action): ReducerContactTypeState {
  return contactTypeReducer(state, action);
}
