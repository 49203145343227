import {Component, OnDestroy, OnInit} from '@angular/core';
import {ContactType} from '../../../core/models/contacttype/contactType.model';
import {Observable, Subscription} from 'rxjs';
import {allContactTypesSelect, ContactTypeState} from '../../../core/models/contacttype/store';
import {Store} from '@ngrx/store';
import {contactTypeSelectedAction} from '../../../core/models/contacttype/store/contacttype.actions';
import {
  ContacttypeEditModalDialogComponent
} from "../contacttype-edit-modal-dialog/contacttype-edit-modal-dialog.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {activeCompanySelect, CompanyState} from "../../../core/models/company/store";
import {Company} from "../../../core/models/company/company.model";
import {NgbModalRef} from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";

@Component({
  selector: 'app-contacttype-list',
  templateUrl: './contact-type-list.component.html'
})
export class ContactTypeListComponent implements OnInit, OnDestroy {
  contactTypes$: Observable<ContactType[]> = this.contactTypeStore.select(allContactTypesSelect);
  contactTypesSubscription: Subscription;

  contactTypes: ContactType[];
  contactTypesFiltered: ContactType[];

  loadingIndicator = true;

  contactTypeModal: NgbModalRef;

  activeCompany$: Observable<Company> = this.companyStore.select(activeCompanySelect);
  activeCompanySubscription: Subscription;
  activeCompany: Company;

  searchTerm: string;

  selectedContactType: ContactType

  constructor(private contactTypeStore: Store<ContactTypeState>,
              private modalService: NgbModal,
              private companyStore: Store<CompanyState>,) { }

  ngOnInit(): void {
    this.activeCompanySubscription = this.activeCompany$.subscribe(company => {
      if (company) {
        this.activeCompany = company;
      }
    });

    this.contactTypesSubscription = this.contactTypes$.subscribe(contactTypes => {
      this.contactTypes = contactTypes;
      this.contactTypesFiltered = contactTypes;
    });
  }

  onSelect(selected): void {
    this.selectedContactType = selected;
    this.contactTypeStore.dispatch(contactTypeSelectedAction({payload: this.selectedContactType}));
    this.openDialog();
  }

  updateFilter(event): void {
    let val = event.target.value.toLowerCase();
    this.searchTerm = val;
    if (val.length > 0) {
      this.contactTypesFiltered = this.contactTypes.filter(type => type.description.toLowerCase().includes(val.toLowerCase()));
    } else {
      this.contactTypesFiltered = this.contactTypes;
    }
  }

  newContactType(): void {
    this.contactTypeStore.dispatch(contactTypeSelectedAction({payload: null}));
    this.openDialog();
  }

  openDialog(): void {
    this.contactTypeModal = this.modalService.open(ContacttypeEditModalDialogComponent, {size: 'lg', animation: true, centered: true, backdrop: 'static', keyboard: false});
    this.contactTypeModal.componentInstance.companyId = this.activeCompany.companyId;
  }

  ngOnDestroy(): void {
    this.activeCompanySubscription.unsubscribe();
    this.contactTypesSubscription.unsubscribe();
  }
}
