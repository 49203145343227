import {createAction, props} from '@ngrx/store';
import {DatevMapping} from '../datevMapping.model';
import {DatevImportOverview} from '../datevImportOverview.model';
import {Page} from "../../page/page.model";
import {DatevImportDtoModel} from "../datevImportDto.model";

export const loadAllDatevMappingsAction = createAction('[DATEV] loadAllDatevMappingsAction');
export const allDatevMappingsLoadedAction = createAction('[DATEV] allDatevMappingsLoadedAction', props<{mappings: DatevMapping[]}>());
export const saveDatevMappingsAction = createAction( '[DATEV] saveDatevMappingsAction', props<{mappings: DatevMapping[]}>());
export const datevMappingsSavedAction = createAction( '[DATEV] datevMappingsSavedAction', props<{mappings: DatevMapping[]}>());
export const loadDatevImportOverviewAction = createAction('[DATEV] loadDatevImportOverviewAction');
export const datevImportOverviewLoadedAction = createAction('[DATEV] datevImportOverviewLoadedAction', props<{overview: DatevImportOverview}>());
export const startImportRecordsImportAction = createAction('[DATEV] startImportRecordsImportAction');
export const importRecordsEndsAction = createAction('[DATEV] importRecordsEndsAction');
export const loadImportDataAction = createAction('[DATEV] loadImportDataAction', props<{page: number, size: number}>());
export const loadedImportDataAction = createAction('[DATEV] loadedImportDataAction', props<{importDto: Page<DatevImportDtoModel>}>());
export const setImportDataAction = createAction('[DATEV] setImportDataAction', props<{importDto: DatevImportDtoModel}>());
export const saveImportDataAction = createAction('[DATEV] saveImportDataAction', props<{importDto: DatevImportDtoModel}>());
export const importDataSavedAction = createAction('[DATEV] importDataSavedAction');
