import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  configurationLoadedAction, ConfigurationSavedAction, loadConfigurationAction, saveConfigurationAction
} from './messageConfiguration.actions';
import {map, mergeMap, switchMap} from 'rxjs/operators';
import {NotificationService} from '../../../services/notification.service';
import {MessageConfigurations} from '../messageConfigurations.model';

@Injectable()
export class MessageConfigurationEffects {
  constructor(
    private httpApiService: NotificationService,
    private actions$: Actions) {
  }

  loadConfigurations$ = createEffect(() => this.actions$.pipe(
    ofType(loadConfigurationAction),
    mergeMap(({userId}) => this.httpApiService.getConfiguration(userId)
      .pipe(
        map(config => (configurationLoadedAction({payload: config})))
      ))
  ));

  saveConfiguration$ = createEffect(() => this.actions$.pipe(
    ofType(saveConfigurationAction),
    switchMap(({payload}) => this.httpApiService.setConfiguration(payload)
      .pipe(
        map((config: MessageConfigurations) => {
          return ConfigurationSavedAction({payload: config});
        })
      )
    )));
}
