import {Component, Input} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {Store} from "@ngrx/store";
import {EmployeeVacationState} from "../../../core/models/employee-vacation/store";
import {addVacationAction} from "../../../core/models/employee-vacation/store/employee-vacation.actions";

@Component({
  selector: 'app-vacation-edit',
  templateUrl: './vacation-edit.component.html',
  styleUrls: ['./vacation-edit.component.scss']
})
export class VacationEditComponent {
  vacationForm: FormGroup;

  @Input() employeeId: string;

  constructor(
      private employeeVacationStore: Store<EmployeeVacationState>,
  ) {
    this.vacationForm = new FormGroup({
      employeeId: new FormControl(),
      startDate: new FormControl(),
      endDate: new FormControl(),
    });
  }

  resetForm(): void {
    this.vacationForm.reset();
  }

  saveVacation(): void {
    const vacation = this.vacationForm.value;
    vacation.employeeId = this.employeeId;
    this.employeeVacationStore.dispatch(addVacationAction({vacation}))
  }
}
