import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Order} from '../../../../core/models/order/order.model';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {
  TaskEditModalDialogComponent
} from "../../../orders/task-edit-modal-dialog/task-edit-modal-dialog.component";
@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss']
})
export class TaskComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() order: Order;
  @Input() selectedCustomerId: string;
  @Input() canAddNewTasks = false;
  @Input() verticalScroll = false;

  constructor(
    private modalService: NgbModal,
  ) {
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  addNewTask(): void {
    const dialog = this.modalService.open(TaskEditModalDialogComponent, {size: 'lg', animation: true, centered: true, backdrop: 'static', keyboard: false})
    dialog.componentInstance.order = this.order;
    dialog.componentInstance.selectedCustomerId = this.selectedCustomerId;
    dialog.componentInstance.canAddNewTasks = this.canAddNewTasks;
  }

  selectSubOder(index: number): void {
    const dialog = this.modalService.open(TaskEditModalDialogComponent, {size: 'lg', animation: true, centered: true, backdrop: 'static', keyboard: false})
    dialog.componentInstance.selectedTask = this.order?.tasks[index];
    dialog.componentInstance.order = this.order;
    dialog.componentInstance.selectedCustomerId = this.selectedCustomerId;
    dialog.componentInstance.canAddNewTasks = this.canAddNewTasks;
  }

}
