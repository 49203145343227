import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TaskType} from "../../../core/models/order/tasktype/tasktype.model";
import {ContactTypeState, selectedContactTypeSelect} from "../../../core/models/contacttype/store";
import {Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {ContacttypeEditComponent} from "../edit/contacttype-edit.component";

@Component({
  selector: 'app-contacttype-edit-modal-dialog',
  templateUrl: './contacttype-edit-modal-dialog.component.html',
  styleUrls: ['./contacttype-edit-modal-dialog.component.scss']
})
export class ContacttypeEditModalDialogComponent implements OnInit, OnDestroy {
  @ViewChild(ContacttypeEditComponent) contactTypeComponent: ContacttypeEditComponent;
  @Input() companyId: string = null;

  @Input() closeAfterSave: boolean = false;

  // @ts-ignore
  contactType$: Observable<TaskType> = this.contactTypeStore.select(selectedContactTypeSelect);
  contactTypeSubscription: Subscription;
  contactType: TaskType;
  selectedContactType: TaskType;
  constructor(public activeModal: NgbActiveModal,
              public contactTypeStore: Store<ContactTypeState>) {
    this.contactTypeSubscription = this.contactType$.subscribe(type => {
      if (type) {
        this.contactType = type;
        if (this.closeAfterSave) {
          this.closeDialog();
        }
      }
    });
  }

  closeDialog(): void {
    this.contactTypeComponent.resetForm();
    this.activeModal.dismiss(this.contactType);
  }

  ngOnInit(): void {
    this.selectedContactType = null;
  }

  ngOnDestroy(): void {
    this.contactTypeSubscription.unsubscribe();
    this.selectedContactType = null;
  }

}
