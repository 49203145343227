import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {User} from '../../../core/models/user/user.model';
import {Store} from '@ngrx/store';
import {allUsersSelect, UserState} from '../../../core/models/user/store';
import {userSelectedAction} from '../../../core/models/user/store/user.actions';
import {Observable, Subscription} from 'rxjs';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {activeCompanySelect, CompanyState} from "../../../core/models/company/store";
import {Company} from "../../../core/models/company/company.model";
import {NgbModalRef} from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
import {PersonEditModalDialogComponent} from "../person-edit-modal-dialog/person-edit-modal-dialog.component";

@Component({
  selector: 'app-person-list',
  templateUrl: './person-list.component.html'
})
export class PersonListComponent implements OnInit, AfterViewInit {
  @Input() crateNew;
  loadingIndicator = true;

  users$: Observable<User[]> = this.userStore.select(allUsersSelect);
  usersSubscription: Subscription;

  users: User[];
  usersFiltered: User[];

  employeeModal: NgbModalRef;

  activeCompany$: Observable<Company> = this.companyStore.select(activeCompanySelect);
  activeCompanySubscription: Subscription;
  activeCompany: Company;

  searchTerm: string;
  constructor(
    private userStore: Store<UserState>,
    private modalService: NgbModal,
    private companyStore: Store<CompanyState>,) { }

  ngAfterViewInit(): void {
        if (this.crateNew){
          this.openDialog();
        }
    }

  ngOnInit(): void {
    this.activeCompanySubscription = this.activeCompany$.subscribe(company => {
      if (company) {
        this.activeCompany = company;
      }
    });
    this.usersSubscription = this.users$.subscribe(users => {
      this.users = users;
      this.usersFiltered = users;
    });
  }


  onSelect(selected): void {
    const selectedUser: User = selected;
    this.userStore.dispatch(userSelectedAction({payload: selectedUser}));
    this.openDialog();
  }

  newEmployee(): void {
    this.userStore.dispatch(userSelectedAction({payload: null}));
    this.openDialog();
  }

  openDialog(): void {
    this.employeeModal = this.modalService.open(PersonEditModalDialogComponent, {size: 'lg', animation: true, centered: true, backdrop: 'static', keyboard: false});
    this.employeeModal.componentInstance.companyId = this.activeCompany.companyId;
  }

  updateFilter(event): void {
    let val = event.target.value.toLowerCase();
    this.searchTerm = val;
    if (val.length > 0) {
      this.usersFiltered = this.users.filter(type => type.lastName.toLowerCase().includes(val.toLowerCase()) || type.firstName.toLowerCase().includes(val.toLowerCase()) || type.emailAddress.toLowerCase().includes(val.toLowerCase()));
    } else {
      this.usersFiltered = this.users;
    }
  }
}
