import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';

export class AddressType {
  @JsonProperty() @JsonClassType({type: () => [String]})
  id: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  description: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  companyId: string;

  constructor(
    id: string,
    description: string,
    companyId: string
  ) {
    this.id = id;
    this.description = description;
    this.companyId = companyId;
  }

  public static fromServiceArray(addressTypeArray: any): AddressType[] {
    const addressTypes: AddressType[] = [];
    for (const addressType of addressTypeArray) {
      addressTypes.push( AddressType.fromService(addressType));
    }
    return addressTypes;
  }

  public static fromService(addressType: any): AddressType {
    const objectMapper = new ObjectMapper();
    return objectMapper.parse<AddressType>(JSON.stringify({
        id: addressType.id,
        description: addressType.description,
        companyId: addressType.companyId
      }
    ), {mainCreator: () => [AddressType]});
  }

  public static toService(addressType: AddressType, companyId: string): any {
    return {
      id: addressType.id,
      description: addressType.description,
      companyId
    };
  }
}
