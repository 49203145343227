import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {datevImportDto, datevImportOverviewSelect, DatevSate} from "../../../core/models/datev/store";
import {Observable, Subscription} from "rxjs";
import {DatevImportOverview} from "../../../core/models/datev/datevImportOverview.model";
import {DatevImportDtoModel} from "../../../core/models/datev/datevImportDto.model";
import {Page} from "../../../core/models/page/page.model";
import {loadImportDataAction, saveImportDataAction} from "../../../core/models/datev/store/datev.actions";
import {NgbModalRef} from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-datev-error',
  templateUrl: './datev-error.component.html',
  styleUrls: ['./datev-error.component.scss']
})
export class DatevErrorComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() datevImport: DatevImportDtoModel;

  dataChanged: boolean = false;
  constructor(
      public activeModal: NgbActiveModal,
      private datevStore: Store<DatevSate>,
  ) {
  }

  save(): void {
    this.datevStore.dispatch(saveImportDataAction({importDto: this.datevImport}))
    this.activeModal.close();
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {

  }

  ngAfterViewInit(): void {
    if (this.datevImport) {
      this.datevImport = this.datevImport.deepCopy();
    }
  }
}
