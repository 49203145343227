import {createAction, props} from '@ngrx/store';
import {Order} from '../order.model';
import {Task} from '../task.model';
import {UpSpotFileModel} from '../../upSpotFile.model';
import {Customer} from '../../customer/customer.model';
import {DocumentMetaDataModel} from '../../file/documentMetaData-model';

export const selectAllOrdersByPeriodAction = createAction('[ORDER] selectAllOrdersByPeriodAction', props<{ start: Date, end: Date, userId: string }>());
export const allOrdersByPeriodSelectedAction = createAction('[ORDER] allOrdersByPeriodSelectedAction', props<{ payload: Customer[] }>());
export const selectAllOrdersAction = createAction('[ORDER] selectAllOrdersAction', props<{ startDate: Date, endDate: Date, filterActiveCompany: boolean}>());
export const allOrdersSelectedAction = createAction('[ORDER] allOrdersSelectedAction', props<{ payload: Customer[] }>());
export const selectOrderAction = createAction('[ORDER] selectOrderAction', props<{ customerId: string, orderId: string }>());
export const orderSelectedAction = createAction('[ORDER] orderSelectedAction', props<{ payload: Order }>());
export const saveOrderAction = createAction('[ORDER] saveOrderAction', props<{ payload: Order, customerId: string }>());
export const orderSavedAction = createAction('[ORDER] orderSavedAction', props<{ payload: Order }>());
export const changeTaskAction = createAction('[ORDER] changeTaskAction', props<{ task: Task, customerId: string, orderId: string }>());
export const taskChangedAction = createAction('[ORDER] taskChangedAction', props<{ task: Task, orderId: string }>());
export const addImageToOrderAction = createAction('[ORDER] addImageToOrderAction', props<{ order: Order, customer: Customer, file: UpSpotFileModel, markAsTemplate: boolean}>());
export const imageAddedToOrderAction = createAction('[ORDER] ImageAddedToOrderAction', props<{ files: DocumentMetaDataModel[] }>());
export const loadImagesOfOrderAction = createAction('[ORDER] loadImagesOfOrderAction', props<{ orderId: string, customerId: string }>());
export const imageUploadedAction = createAction('[ORDER] imageUploadedAction', props<{ file: DocumentMetaDataModel }>());
