import {FormField} from './formField.model';
import {JsonClassType, JsonProperty} from 'jackson-js';

export class FormTemplateModel {
  @JsonProperty() @JsonClassType({type: () => [Number]})
  id: string;
  @JsonProperty() @JsonClassType({type: () => [Number]})
  sort: number;
  @JsonProperty() @JsonClassType({type: () => [String]})
  name: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  description: string;
  @JsonProperty() @JsonClassType({type: () => [Array, [FormField]]})
  formFields: FormField[];

  constructor(
    id: string,
    sort: number,
    name: string,
    description,
    formFields: FormField[]
  ) {
    this.id = id;
    this.sort = sort;
    this.name = name;
    this.description = description;
    this.formFields = formFields;
  }

  public static fromService(form: any): FormTemplateModel[] {
    if (form) {
      return JSON.parse(form);
    } else {
      return null;
    }
  }

  public static toService(form: FormTemplateModel[]): string {
    if (form) {
      return JSON.stringify(form);
    } else {
      return null;
    }
  }

  public static emptyTemplate(): FormTemplateModel {
    return new FormTemplateModel( '-1',-1, '', '', []);
  }
}
