import { Component } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-company-employees-overview',
  templateUrl: './company-employees-overview.component.html'
})
export class CompanyEmployeesOverviewComponent {
  breadCrumbItems!: Array<{}>;
  param = '0';

  constructor(private route: ActivatedRoute) {
    this.param = this.route.snapshot.paramMap.get('createNew');
  }

  ngOnInit() {
    /**
     * BreadCrumb
     */
    this.breadCrumbItems = [
      { label: 'menu.my_company' },
      { label: 'menu.customer', active: true }
    ];
  }
}
