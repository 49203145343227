import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {OrderType} from '../../../core/models/order/ordertype/ordertype.model';
import {Observable, Subscription} from 'rxjs';
import {OrderTypeState, selectedOrderTypeSelect} from '../../../core/models/order/ordertype/store';
import {Store} from '@ngrx/store';
import {
    orderTypeSelectedAction,
    saveOrderTypeAction
} from '../../../core/models/order/ordertype/store/ordertype.actions';
import {FormField} from '../../../core/models/form/formField.model';
import {FormTemplateModel} from '../../../core/models/form/formTemplate.model';
import {ObjectMapper} from 'jackson-js';
import {v4 as uuidv4} from 'uuid';
import {DocumentManagementApiService} from "../../../core/services/document-management-api.service";
import {TranslateService} from "@ngx-translate/core";
import {UpSpotFileModel} from "../../../core/models/upSpotFile.model";
import {DocumentMetaDataModel} from "../../../core/models/file/documentMetaData-model";
import {ToastService} from "../../../core/services/Toast/toast-service";

@Component({
    selector: 'app-ordertype-edit',
    templateUrl: './ordertype-edit.component.html'
})
export class OrdertypeEditComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() resetSelectedAfterSave = false;
    @Input() companyId = null;

    @ViewChild('description') description: ElementRef;

    orderTypeForm: FormGroup;
    selectedOrderType$: Observable<OrderType> = this.orderTypeStore.select(selectedOrderTypeSelect);
    selectedOrderTypeSub: Subscription;
    selectedOrderType: OrderType;
    selectedColor: string;

    types = {
        string: 'Text',
        number: 'Zahl'
    };

    formTemplates: FormTemplateModel[] = [];
    save = false;

    files: DocumentMetaDataModel[] = [];
    constructor(
        private orderTypeStore: Store<OrderTypeState>,
        private documentService: DocumentManagementApiService,
        private toastrService: ToastService,
        private translate: TranslateService) {
    }

    ngAfterViewInit(): void {
        if (this.description) {
            this.description.nativeElement.focus();
        }
    }

    ngOnInit(): void {
        this.orderTypeForm = new FormGroup({
            id: new FormControl(),
            description: new FormControl(null, Validators.required),
            initials: new FormControl(null, Validators.required),
            color: new FormControl(null, Validators.required)
        });

        this.selectedOrderTypeSub = this.selectedOrderType$.subscribe(
            (orderType: OrderType) => {
                this.selectOrderType(orderType);
                orderType.documentIds?.forEach(id => {
                    this.documentService.getFileInfoById(id).subscribe(file => {
                        this.files.push(file);
                    })
                });
            }
        );
    }

    saveOrderType(): void {
        const orderType = this.orderTypeForm.value;
        this.orderTypeStore.dispatch(saveOrderTypeAction({payload: orderType, companyId: this.companyId}));
        if (this.resetSelectedAfterSave) {
            this.save = true;
        }
    }

    selectOrderType(orderType: OrderType): void {
        if (orderType && this.orderTypeForm) {
            if (this.save) {
                this.orderTypeStore.dispatch(orderTypeSelectedAction({payload: null}));
                this.save = false;
            }
            this.orderTypeForm.patchValue(orderType);
            this.selectedOrderType = orderType;
            this.formTemplates = [];
            if (orderType.formTemplate) {
                this.formTemplates = JSON.parse(orderType.formTemplate);
            }

        } else {
            this.resetForm();
        }
    }

    resetForm(): void {
        this.orderTypeForm?.reset();
        this.selectedOrderType = null;
    }

    colorChanged(selectedColor: string): void {
        this.selectedColor = selectedColor;
        this.orderTypeForm.patchValue({
            color: selectedColor
        });
    }

    ngOnDestroy(): void {
        this.selectedOrderTypeSub.unsubscribe();
    }

    addForm(): void {
        this.formTemplates.push(new FormTemplateModel(uuidv4(), this.formTemplates.length + 1, null, null, []));
    }

    addFormField(index: number): void {
        this.formTemplates[index].formFields.push(
            new FormField(
                this.formTemplates[index].formFields.length + 1, null, null, null, null, null, null));
    }

    up(formIndex: number, index: number): void {
        this.array_move(formIndex, index, index + 1);
    }

    down(formIndex: number, index: number): void {
        this.array_move(formIndex, index, index - 1);
    }

    delete(formIndex: number, index: number): void {
        this.formTemplates[formIndex].formFields.splice(index, 1);
    }

    saveForm(): void {
        const objectMapper = new ObjectMapper();
        const orderType: OrderType = objectMapper.parse<OrderType>(JSON.stringify(this.orderTypeForm.value), {mainCreator: () => [OrderType]});
        this.formTemplates.forEach(formTemplate => {
            formTemplate.formFields.forEach(formField => {
                if (!formField.key) {
                    formField.key = uuidv4();
                }
            });
        });
        orderType.formTemplate = JSON.stringify(this.formTemplates);
        this.orderTypeStore.dispatch(saveOrderTypeAction({payload: orderType, companyId: this.companyId}));
    }

    array_move(formIndex: number, oldIndex: number, newIndex: number): void {
        if (newIndex >= this.formTemplates[formIndex].formFields.length) {
            let k = newIndex - this.formTemplates[formIndex].formFields.length + 1;
            while (k--) {
                this.formTemplates[formIndex].formFields.push(undefined);
            }
        }
        this.formTemplates[formIndex].formFields.splice(newIndex, 0, this.formTemplates[formIndex].formFields.splice(oldIndex, 1)[0]);
    }

    addDocument(filesEvent: Event): void {
        let files = (<HTMLInputElement>filesEvent.target).files;
        for (let i = 0; i < files.length; i++) {
            const upSpotFile = new UpSpotFileModel(files[i], null);
            const reader = new FileReader();
            reader.readAsDataURL(upSpotFile.file);
            const idxDot = upSpotFile.file.name.lastIndexOf('.') + 1;
            const extFile = upSpotFile.file.name.substr(idxDot, upSpotFile.file.name.length).toLowerCase();
            if (extFile === 'pdf') {
                this.documentService.addFileToOrderType(upSpotFile).subscribe(saved => {
                    if (saved) {
                        const documentIds = [...this.selectedOrderType.documentIds];
                        documentIds.push(saved.id);
                        const orderTypeToSave = {...this.selectedOrderType, documentIds};
                        this.orderTypeStore.dispatch(saveOrderTypeAction({payload: orderTypeToSave, companyId: this.companyId}));
                    }
                });
            } else {
                this.toastrService.error(this.translate.instant('labels.onlyDocuments'));
            }
        }
    }

    public openFile(document: DocumentMetaDataModel): void {
        this.documentService.openFile(document);
    }
}
