import {Address} from '../address.model';
import {ContactPerson} from '../contactperson.model';
import {Contact} from '../contact.model';
import {ContactType} from '../contacttype/contactType.model';
import {User} from '../user/user.model';
import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';
import {TaskType} from "../order/tasktype/tasktype.model";
import {Order} from "../order/order.model";
import {AddressType} from "../addresstype/addressType.model";
import {ExternalIdModel} from "./externalId.model";

export class Customer {
  @JsonProperty() @JsonClassType({type: () => [String]})
  id: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  companyId: string;
  @JsonProperty() @JsonClassType({type: () => [ExternalIdModel]})
  externalId: ExternalIdModel;
  @JsonProperty() @JsonClassType({type: () => [Boolean]})
  isCompany: boolean;
  @JsonProperty() @JsonClassType({type: () => [Address]})
  mainAddress: Address;
  @JsonProperty() @JsonClassType({type: () => [Array, [Address]]})
  customerAddresses: Address[] = [];
  @JsonProperty() @JsonClassType({type: () => [Array, [Contact]]})
  contacts: Contact[] = [];
  @JsonProperty() @JsonClassType({type: () => [Array, [ContactPerson]]})
  contactPersons: ContactPerson[];
  @JsonProperty() @JsonClassType({type: () => [Array, [Order]]})
  orders: Order[];
  @JsonProperty() @JsonClassType({type: () => [String]})
  remark: string;

  constructor(
    id: string,
    companyId: string,
    externalId: ExternalIdModel,
    isCompany: boolean,
    @JsonClassType({type: () => [Address]})mainAddress: Address,
    remark: string
  ){
    this.id = id;
    this.companyId = companyId;
    this.externalId = externalId;
    this.isCompany = isCompany;
    this.mainAddress = mainAddress;
    this.remark = remark;
  }

  public static fromService(customer: any, contactTypes: ContactType[], users: User[], taskTypes: TaskType[], addressTypes: AddressType[]): Customer {
    let customerResult: Customer;
    if ( customer) {
      const objectMapper = new ObjectMapper();
      customerResult = objectMapper.parse<Customer>( JSON.stringify( {
        id: customer.id,
        companyId: customer.companyId,
        externalId: ExternalIdModel.fromService(customer.externalId),
        isCompany: customer.customerType === 'CORPORATE' ,
        mainAddress: new Address(null,
          customer.mainAddress?.firstName,
          customer.mainAddress?.name,
          customer.mainAddress?.companyName,
          customer.mainAddress?.city,
          customer.mainAddress?.zipcode,
          customer.mainAddress?.street,
          null, null, null ),
        customerAddresses: Address.fromServiceArray(customer.customerAddresses, addressTypes),
        contacts: Contact.fromServiceArray(customer.contacts, contactTypes),
        contactPersons: ContactPerson.fromServiceArray(customer.contactPeople, contactTypes),
        orders: Order.fromServiceArray(customer.orders, contactTypes, users, taskTypes, customer.id, null, addressTypes),
        remark: customer.remark
      }), {mainCreator: () => [Customer]});
    }
    return customerResult;
  }

  public static toService(customer: Customer): any {
    return {
      id: customer.id,
      companyId: customer.companyId,
      externalId: ExternalIdModel.toService(customer.externalId),
      mainAddress: {
        companyName: customer.mainAddress?.companyName,
        name: customer.mainAddress?.name,
        firstName: customer.mainAddress?.firstName,
        street: customer.mainAddress?.street,
        zipcode: customer.mainAddress?.zipcode,
        city: customer.mainAddress?.city,
      },
      customerAddresses: Address.toServiceArray(customer.customerAddresses, false),
      contacts: Contact.toServiceArray(customer.contacts),
      customerType: customer.isCompany ? 'CORPORATE' : 'PRIVATE',
      contactPeople: ContactPerson.toServiceArray(customer.contactPersons),
      remark: customer.remark
    };
  }
}
