import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Order} from '../../../core/models/order/order.model';
import {Observable, Subscription} from 'rxjs';
import {allOrdersByPeriodSelect, OrderState} from '../../../core/models/order/store';
import {Store} from '@ngrx/store';
import {selectAllOrdersByPeriodAction} from '../../../core/models/order/store/order.actions';
import {allUsersSelect, loggedInUserSelect, UserState} from '../../../core/models/user/store';
import {User} from '../../../core/models/user/user.model';
import {Task} from '../../../core/models/order/task.model';
import {Router} from '@angular/router';
import {Customer} from "../../../core/models/customer/customer.model";

@Component({
    selector: 'app-person-task-list',
    templateUrl: './person-task-list.component.html',
    styleUrls: ['./person-task-list.component.scss']
})
export class PersonTaskListComponent implements OnInit, OnDestroy {
    orderList$: Observable<Customer[]> = this.orderStore.select(allOrdersByPeriodSelect);
    orderListSubscription: Subscription;
    orderList: Customer[];

    user$: Observable<User> = this.userStore.select(loggedInUserSelect);
    userSubscription: Subscription;
    user: User;

    users$: Observable<User[]> = this.userStore.select(allUsersSelect);
    usersSubscription: Subscription;
    users: User[];

    breadCrumbItems!: Array<{}>;
    taskList: Task[] = [];

    startDate: Date = new Date();
    endDate: Date = new Date();

    constructor(
        private orderStore: Store<OrderState>,
        private userStore: Store<UserState>,
        private router: Router,
    ) {
        this.userSubscription = this.user$.subscribe(user => {
            if (user) {
                this.user = user;
                let newEvent: {
                    target: {
                        value: string
                    }
                };
                newEvent = {
                    target: {
                        value: '2'
                    }
                };
                this.datesChanged(newEvent);
            }
        });

        this.usersSubscription = this.users$.subscribe(users => {
            if (users) {
                this.users = users;
            }
        });

        this.startDate = this.getFirstDayOfWeek(new Date());
        this.endDate = this.getLastDayOfWeek(new Date());
    }

    public datesChanged(event): void {
        switch (event.target.value) {
            case '1': {
                // letzte Woche
                this.startDate = new Date(this.getFirstDayOfWeek(new Date()).getTime() - (1000 * 60 * 60 * 24 * 17))
                this.endDate = new Date(this.getLastDayOfWeek(new Date()).getTime() - (1000 * 60 * 60 * 24 * 7));
                break;
            }
            case '2': {
                // heute
                const today = this.dateToEpoch(new Date(), true, 0);
                const tomorrow = this.dateToEpoch(new Date(), false,0);
                this.startDate = today;
                this.endDate = tomorrow;
                break;
            }
            case '3': {
                // morgen
                const today = this.dateToEpoch(new Date(), true, 1);
                const tomorrow = this.dateToEpoch(new Date(), false,1);
                this.startDate = today;
                this.endDate = tomorrow;
                break;
            }
            case '4': {
                // diese Woche
                this.startDate = this.getFirstDayOfWeek(new Date());
                this.endDate = this.getLastDayOfWeek(new Date());
                break;
            }
            case '5': {
                // nächste Woche
                this.startDate = new Date(this.getFirstDayOfWeek(new Date()).getTime() + (1000 * 60 * 60 * 24 * 7));
                this.endDate = new Date(this.getLastDayOfWeek(new Date()).getTime() + (1000 * 60 * 60 * 24 * 7));
                break;
            }
        }
        this.searchForOrders();
    }

    dateToEpoch(date: Date, startDay: boolean, addDays: number): Date {
        if (startDay) {
            const newDate = new Date(date.setHours(0, 0, 0, 0));
            return new Date( newDate.setDate(newDate.getDate() + addDays))
        } else {
            const newDate = new Date(date.setHours(23, 59, 59, 999));
            return new Date( newDate.setDate(newDate.getDate() + addDays))
        }
    }

    handleEventClick(order, task): void {
        const customer = order.customerId;
        const orderId = order.id;
        const taskId = task.id;
        this.gotoOrderView(orderId, customer, taskId);
    }

    ngOnDestroy(): void {
        this.userSubscription.unsubscribe();
        this.orderListSubscription.unsubscribe();
    }

    ngOnInit(): void {
        this.orderListSubscription = this.orderList$.subscribe(customers => {
            if (customers) {
                this.orderList = customers;
                this.addOrdersToList(customers);
            }
        });

        this.breadCrumbItems = [
            {label: 'menu.my_company'},
            {label: 'menu.companies', active: true}
        ];
    }

    searchForOrders(): void {
        this.orderList = [];
        this.orderStore.dispatch(selectAllOrdersByPeriodAction({
            start: this.startDate,
            end: this.endDate,
            userId: this.user.userId
        }));
    }

    private addOrdersToList(customers: Customer[]): void {
        this.taskList = [];
        customers.forEach(customer => {
            customer?.orders?.forEach((order: Order) => {
                    const taskOrderObject = {taskList: [], order};
                    order.tasks.forEach(task => {
                        if (this.taskContainsUser(task) && (
                            (this.startDate <= task.endDate )
                            && (this.endDate >= task.startDate))) {
                            const newTask = {...task, order};
                            this.taskList.push(newTask);
                            taskOrderObject.taskList.push(task);
                        }
                    });
                }
            );
        });
        this.taskList = this.taskList.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());
    }

    taskContainsUser(task: Task): boolean {
        return task?.userIds?.findIndex(id => id === this.user.userId) > -1;
    }

    gotoOrderView(orderId: string, customerId: string, taskId: string): void {
        this.router.navigate(['task-edit-mobile/' + customerId + '/' + orderId + '/' + taskId]);
    }

    getFirstDayOfWeek(d) {
        const date = new Date(d);
        const day = date.getDay();
        const diff = date.getDate() - day + (day === 0 ? -6 : 1);

        return new Date(date.setDate(diff));
    }

    getLastDayOfWeek(d) {
        const date = new Date(d);
        const day = date.getDay();
        const diff = date.getDate() - day + 7;

        return new Date(date.setDate(diff));
    }
}
