import {Component, Inject, OnInit} from '@angular/core';
import {SwPush, SwUpdate, VersionReadyEvent} from '@angular/service-worker';
import {NotificationService} from './core/services/notification.service';
import {UserState} from './core/models/user/store';
import {Store} from '@ngrx/store';
import {activeCompanySelect, allCompaniesSelect, CompanyState} from './core/models/company/store';
import {Observable} from 'rxjs';
import {Company} from './core/models/company/company.model';
import {filter, map} from "rxjs/operators";
import {Platform} from "@angular/cdk/platform";
import {UserService} from "./core/services/user.service";
import {loadAllCompaniesAction} from "./core/models/company/store/company.actions";
import {loadAllUsersAction, userLoginAction} from "./core/models/user/store/user.actions";
import {
    loadAllTaskTypesAction,
    loadAllTaskTypesForAllCompaniesAction
} from "./core/models/order/tasktype/store/taskype.actions";
import {
    loadAllContactTypesAction,
    loadAllContactTypesForAllCompaniesAction
} from "./core/models/contacttype/store/contacttype.actions";
import {
    loadAllOrderTypesAction,
    loadAllOrderTypesForAllCompaniesAction
} from "./core/models/order/ordertype/store/ordertype.actions";
import {
    loadAllAddressTypesAction,
    loadAllAddressTypesForAlLCompaniesAction
} from "./core/models/addresstype/store/addresstype.actions";
import {OrderTypeState} from "./core/models/order/ordertype/store";
import {ContactTypeState} from "./core/models/contacttype/store";
import {TaskTypeState} from "./core/models/order/tasktype/store";
import {AddressTypeState} from "./core/models/addresstype/store";
import {flatpickrFactory} from "./app.module";
import {RxStompService} from "./core/services/stomp/rx-stomp.service";
import {RxStompConfig} from "@stomp/rx-stomp";
import {BatchModel} from "./core/models/batch/batch.model";
import {BatchState} from "./core/models/batch/store";
import {datevBatchLoadedAction} from "./core/models/batch/store/batch.actions";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'Planung1.0';
    readonly VAPID_PUBLIC_KEY = 'BD88Fqfq-dLWNg_9M5vFfvahXY_vkFrl1OFJRPcRrCyc_P6CT6u7XfyI4okAdmjadrEMSgnipy7CmbVEmlKlO80';

    companies$: Observable<Company[]> = this.companyStore.select(allCompaniesSelect);
    activeCompany$: Observable<Company> = this.companyStore.select(activeCompanySelect);
    modalVersion: boolean;
    modalPwaEvent: any;
    modalPwaPlatform: string | undefined;

    constructor(
        private platform: Platform,
        private swUpdate: SwUpdate,
        private swPush: SwPush,
        private notificationService: NotificationService,
        private userStore: Store<UserState>,
        private companyStore: Store<CompanyState>,
        private userService: UserService,
        private orderTypeStore: Store<OrderTypeState>,
        private taskTypeStore: Store<TaskTypeState>,
        private contactTypeStore: Store<ContactTypeState>,
        private addressTypeStore: Store<AddressTypeState>,
        private stompService: RxStompService,
        private batchStore: Store<BatchState>,
        @Inject('STOMP_URL') private stompUrl: string,) {

        this.modalVersion = false;

        if (this.userService.isAuthenticated) {
            this.companyStore.dispatch(loadAllCompaniesAction());
            this.companies$.subscribe(companies => {
                const userId: string = this.userService.getId();
                this.userStore.dispatch(userLoginAction({payload: userId}));
                this.userStore.dispatch(loadAllUsersAction());
            });
        }

        this.activeCompany$.subscribe(company => {
            if (company) {
                if (!this.userService.isNewAdmin()) {
                    this.taskTypeStore.dispatch(loadAllTaskTypesAction());
                    this.taskTypeStore.dispatch(loadAllTaskTypesForAllCompaniesAction());
                    this.contactTypeStore.dispatch(loadAllContactTypesAction());
                    this.contactTypeStore.dispatch(loadAllContactTypesForAllCompaniesAction());
                    this.orderTypeStore.dispatch(loadAllOrderTypesAction());
                    this.orderTypeStore.dispatch(loadAllOrderTypesForAllCompaniesAction());
                    this.addressTypeStore.dispatch(loadAllAddressTypesAction());
                    this.addressTypeStore.dispatch(loadAllAddressTypesForAlLCompaniesAction());
                    this.userStore.dispatch(loadAllUsersAction());

                    this.runRxStomp(company);
                }
            }
        });
    }

    ngOnInit(): void {
        flatpickrFactory();

        if (this.swUpdate.isEnabled) {
            this.swUpdate.versionUpdates.pipe(
                filter((evt: any): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
                map((evt: any) => {
                    console.info(`currentVersion=[${evt.currentVersion} | latestVersion=[${evt.latestVersion}]`);
                    this.modalVersion = true;
                }),
            );
        }

        if (this.swPush.isEnabled) {
            this.swPush.requestSubscription({
                serverPublicKey: this.VAPID_PUBLIC_KEY
            })
                .then(sub => this.notificationService.subscribeNotifications(sub).subscribe(x => {
                }))
                .catch(err => console.error('Could not subscribe to notifications', err));

            this.swPush.notificationClicks.subscribe(
                (event => {
                    console.log(event);
                    window.focus();
                    window.open(event.notification.data, '_self');
                })
            );

            this.swPush.messages.subscribe(
                (event => {
                    console.log(event);
                })
            );
        }
        this.loadModalPwa();
    }

    public updateVersion(): void {
        this.modalVersion = false;
        window.location.reload();
    }

    public closeVersion(): void {
        this.modalVersion = false;
    }

    private loadModalPwa(): void {
        if (this.platform.ANDROID) {
            window.addEventListener('beforeinstallprompt', (event: any) => {
                event.preventDefault();
                this.modalPwaEvent = event;
                this.modalPwaPlatform = 'ANDROID';
            });
        }

        if (this.platform.IOS && this.platform.SAFARI) {
            const isInStandaloneMode = ('standalone' in window.navigator) && ((<any>window.navigator)['standalone']);
            if (!isInStandaloneMode) {
                this.modalPwaPlatform = 'IOS';
            }
        }
    }

    public addToHomeScreen(): void {
        this.modalPwaEvent.prompt();
        this.modalPwaPlatform = undefined;
    }

    public closePwa(): void {
        this.modalPwaPlatform = undefined;
    }

    public runRxStomp(company: Company): void {
        this.stompService.deactivate().then(value => {
            if (company.virtualHostData) {
                const config: RxStompConfig = {
                    brokerURL: this.stompUrl,
                    connectHeaders: {
                        login: company.virtualHostData.virtualHostCredentials.username,
                        passcode: company.virtualHostData.virtualHostCredentials.password,
                        host: company.virtualHostData.virtualHostIdent.virtualHostName
                    },
                    heartbeatIncoming: 0, // Typical value 0 - disabled
                    heartbeatOutgoing: 20000, // Typical value 20000 - every 20 seconds
                    reconnectDelay: 1500,
                };
                this.stompService.configure(config);
                this.stompService.activate();

                this.stompService.watch({
                    destination: '/amq/queue/' + company.virtualHostData.virtualNotificationQueueName
                }).subscribe(message => {
                    if (message.body) {
                        const body = JSON.parse(message.body);
                        if ( body.messageType === 'NOTIFICATION_BATCH_INFORMATION') {
                            const batch = BatchModel.fromService(body.payload);
                            this.batchStore.dispatch(datevBatchLoadedAction({batch}));
                        }
                    }
                    console.log(message);
                });
            }
        });
    }
}
