import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  loadMessagesAction, loadMessagesForNavbarAction,
  markMessageAsDeletedAction,
  markMessageAsReadAction, markMessageAsUnReadAction,
  messageAsDeletedMarkedAction,
  messageAsReadMarkedAction, messageAsUnReadMarkedAction, messagesForNavbarLoadedAction,
  messagesLoadedAction,
} from './messages.actions';
import {map, mergeMap} from 'rxjs/operators';
import {NotificationService} from '../../../services/notification.service';

@Injectable()
export class MessagesEffects {
  constructor(
    private httpApiService: NotificationService,
    private actions$: Actions) {
  }

  loadMessages$ = createEffect(() => this.actions$.pipe(
    ofType(loadMessagesAction),
    mergeMap(({page, size}) => this.httpApiService.getMessages(page, size)
      .pipe(
        map(messages => (messagesLoadedAction({payload: messages})))
      ))
  ));

  loadMessagesForNavbar$ = createEffect(() => this.actions$.pipe(
    ofType(loadMessagesForNavbarAction),
    mergeMap(({page, size}) => this.httpApiService.getMessages(page, size)
      .pipe(
        map(messages => (messagesForNavbarLoadedAction({payload: messages})))
      ))
  ));

  markMessageAsRead$ = createEffect(() => this.actions$.pipe(
    ofType(markMessageAsReadAction),
    mergeMap(({payload}) => this.httpApiService.markAsRead(payload.id, payload.companyId)
      .pipe(
        map(messages => (messageAsReadMarkedAction({payload: messages})))
      ))
  ));

  markMessageAsUnRead$ = createEffect(() => this.actions$.pipe(
    ofType(markMessageAsUnReadAction),
    mergeMap(({payload}) => this.httpApiService.markAsUnread(payload.id, payload.companyId)
      .pipe(
        map(messages => (messageAsUnReadMarkedAction({payload: messages})))
      ))
  ));

  markMessageAsDelete$ = createEffect(() => this.actions$.pipe(
    ofType(markMessageAsDeletedAction),
    mergeMap(({payload}) => this.httpApiService.markAsRemove(payload.id, payload.companyId)
      .pipe(
        map(messages => (messageAsDeletedMarkedAction({payload: messages})))
      ))
  ));
}
