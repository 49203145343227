import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {TaskType} from "../../../core/models/order/tasktype/tasktype.model";
import {selectedTaskTypeSelect} from "../../../core/models/order/tasktype/store";
import {OrderTypeState, selectedOrderTypeSelect} from "../../../core/models/order/ordertype/store";
import {OrderType} from "../../../core/models/order/ordertype/ordertype.model";
import {Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {OrdertypeEditComponent} from "../edit/ordertype-edit.component";

@Component({
  selector: 'app-ordertype-edit-modal-dialog',
  templateUrl: './ordertype-edit-modal-dialog.component.html',
  styleUrls: ['./ordertype-edit-modal-dialog.component.scss']
})
export class OrdertypeEditModalDialogComponent implements OnInit, OnDestroy {

  @ViewChild(OrdertypeEditComponent) orderTypeComponent: OrdertypeEditComponent;
  @Input() companyId: string = null;

  @Input() closeAfterSave: boolean = false;

  // @ts-ignore
  orderType$: Observable<OrderType> = this.orderTypeStore.select(selectedOrderTypeSelect);
  orderTypeSubscription: Subscription;
  orderType: OrderType;
  selectedOrderType: OrderType;
  constructor(
      public activeModal: NgbActiveModal,
      public orderTypeStore: Store<OrderTypeState>) {

    this.orderTypeSubscription = this.orderType$.subscribe(type => {
      if (type) {
        this.orderType = type;
        if (this.closeAfterSave) {
          this.closeDialog();
        }
      }
    });
  }

  closeDialog(): void {
    this.orderTypeComponent.resetForm();
    this.activeModal.dismiss(this.orderType);
  }

  ngOnInit(): void {
    this.selectedOrderType = null;
  }

  ngOnDestroy(): void {
    this.orderTypeSubscription.unsubscribe();
    this.selectedOrderType = null;
  }

}
