import {HttpClient} from '@angular/common/http';
import {EMPTY, forkJoin, Observable, of} from 'rxjs';
import {catchError, concatMap, map, tap} from 'rxjs/operators';
import {Inject, Injectable} from '@angular/core';
import {Customer} from '../models/customer/customer.model';
import {Page} from '../models/page/page.model';
import {CustomerOverview} from '../models/customer/customerOverview/customerOverview.model';
import {ContactType} from '../models/contacttype/contactType.model';
import {Store} from '@ngrx/store';
import {activeCompanySelect, CompanyState} from '../models/company/store';
import {OrderType} from '../models/order/ordertype/ordertype.model';
import {OrderOverview} from '../models/order/orderOverview/orderOverview.model';
import {CustomerState, selectedCustomerSelect} from '../models/customer/store';
import {SearchResult} from '../models/searchResult.model';
import {TranslateService} from '@ngx-translate/core';
import {AddressType} from '../models/addresstype/addressType.model';
import {ChatMessage} from '../models/chatMessage.model';
import {allUsersSelect, UserState} from '../models/user/store';
import {User} from '../models/user/user.model';
import {OrderStateOverview} from '../models/order/orderStateOverview/orderStateOverview.model';
import {Task} from '../models/order/task.model';
import {DatePipe} from '@angular/common';
import {allOrderTypesSelect, OrderTypeState} from '../models/order/ordertype/store';
import {allContactTypesSelect, ContactTypeState} from '../models/contacttype/store';
import {Company} from '../models/company/company.model';
import {TaskType} from "../models/order/tasktype/tasktype.model";
import {allTaskTypesForAllCompaniesSelect, allTaskTypesSelect, TaskTypeState} from "../models/order/tasktype/store";
import {loadAllTaskTypesForAllCompaniesAction} from "../models/order/tasktype/store/taskype.actions";
import {Order} from "../models/order/order.model";
import {
    AddressTypeState,
    allAddressTypesForCompaniesForAllCompaniesSelect,
    allAddressTypesSelect
} from "../models/addresstype/store";
import {CustomerHistoryModel} from "../models/customer/customerHistory.model";
import {ToastService} from "./Toast/toast-service";

@Injectable()
export class OrderManagementApiService {
  selectedCustomer$: Observable<Customer> = this.customerStore.select(selectedCustomerSelect);
  selectedCustomer: Customer;

  activeCompany$: Observable<Company> = this.companyStore.select(activeCompanySelect);
  activeCompany: Company;
  allUsers$: Observable<User[]> = this.userStore.select(allUsersSelect);
  allUser: User[];
  orderTypes$: Observable<OrderType[]> = this.orderTypeStore.select(allOrderTypesSelect);
  orderTypes: OrderType[];
  contactTypes$: Observable<ContactType[]> = this.contactTypeStore.select(allContactTypesSelect);
  contactTypes: ContactType[];
  taskTypes$: Observable<TaskType[]> = this.taskTypeStore.select(allTaskTypesSelect);
  taskTypes: TaskType[];
  addressTypesForAllCompanies$: Observable<AddressType[]> = this.addressTypeStore.select(allAddressTypesForCompaniesForAllCompaniesSelect);
  addressTypesForAllCompanies: AddressType[];
  taskTypesForAllCompanies$: Observable<TaskType[]> = this.taskTypeStore.select(allTaskTypesForAllCompaniesSelect);
  taskTypesForAllCompanies: TaskType[];

  private REST_API_SERVER = this.orderManagementUrl;
  cache = {};

  constructor(
    private httpClient: HttpClient,
    private companyStore: Store<CompanyState>,
    private customerStore: Store<CustomerState>,
    private toastrService: ToastService,
    private translate: TranslateService,
    private userStore: Store<UserState>,
    private datePipe: DatePipe,
    private orderTypeStore: Store<OrderTypeState>,
    private contactTypeStore: Store<ContactTypeState>,
    private taskTypeStore: Store<TaskTypeState>,
    private addressTypeStore: Store<AddressTypeState>,
    @Inject('ORDER_MANAGEMENT') private orderManagementUrl: string) {
    this.selectedCustomer$.subscribe(
      (customer: Customer) => this.selectedCustomer = customer
    );
    this.allUsers$.subscribe(
      (users: User[]) => this.allUser = users
    );
    this.orderTypes$.subscribe(
      orderTypes => this.orderTypes = orderTypes
    );
    this.contactTypes$.subscribe(
      contactTypes => this.contactTypes = contactTypes
    );
    this.taskTypes$.subscribe(
      taskTypes => this.taskTypes = taskTypes
    );
    this.taskTypesForAllCompanies$.subscribe(
      types => this.taskTypesForAllCompanies = types
    );
    this.activeCompany$.subscribe(
      company => this.activeCompany = company
    );
    this.addressTypesForAllCompanies$.subscribe(
        types => this.addressTypesForAllCompanies = types
    );
  }


  public saveCustomer(customer: Customer): Observable<Customer> {
    const body = Customer.toService(customer);
    if (customer.id) {
      return this.httpClient.put<Customer>(this.REST_API_SERVER + '/order-management', body).pipe(
        catchError(error => {
          this.toastrService.error(this.translate.instant('orderManagementService.error_save_customer'));
          return of([]);
        }),
        map((item: any) => {
            this.toastrService.success(this.translate.instant('orderManagementService.customer_successfully_saved'));
            return Customer.fromService(item, this.contactTypes, this.allUser, this.taskTypes, this.addressTypesForAllCompanies);
          }
        ));
    } else {
      return this.httpClient.post<Customer>(this.REST_API_SERVER + '/order-management', body).pipe(
        catchError(error => {
          this.toastrService.error(this.translate.instant('orderManagementService.error_save_customer'));
          return of([]);
        }),
        map((item: any) => {
            this.toastrService.success(this.translate.instant('orderManagementService.customer_successfully_saved'));
            return Customer.fromService(item, this.contactTypes, this.allUser, this.taskTypes, this.addressTypesForAllCompanies);
          }
        ));
    }
  }

  public getAllCustomersOverview(page: number, size: number, companyId: string, customerId: string): Observable<Page<CustomerOverview>> {
    size = size === 0 ? 10 : size;
    let url: string =
      this.REST_API_SERVER +
      '/order-management?page=' + page +
      '&size=' + size +
      '&companyId=' + companyId;
    if (customerId) {
        url += '&customerId=' + customerId;
    }

    return this.httpClient.get<any>(url).pipe(
      catchError(error => {
        this.toastrService.error(this.translate.instant('orderManagementService.error_load_customer'));
        return of([]);
      }),
      map(
        (data: any) => {
          const customerOverviewArray = [];
          data?.content?.forEach( data => {
              customerOverviewArray.push(CustomerOverview.fromService(data));
          })

          const resultPage = new Page<CustomerOverview>(
            data.totalPages,
            data.totalElements,
            data.numberOfElements,
            data.pageSize,
            data.pageNumber,
            customerOverviewArray);
          return resultPage;
        }
      )
    );
  }

    public getCustomerHistory(customerId: string): Observable<CustomerHistoryModel[]> {
        let url: string =
            this.REST_API_SERVER +
            '/order-management/history' +
            '?customerIdValue=' + customerId;

        return this.httpClient.get<any>(url).pipe(
            catchError(error => {
                this.toastrService.error(this.translate.instant('orderManagementService.error_load_customer'));
                return of([]);
            }),
            map(
                (data: any) => {
                    return CustomerHistoryModel.fromServiceArray(data);
                }
            )
        );
    }

    public findCustomersOverview(page: number, size: number, companyId: string, search: string): Observable<Page<CustomerOverview>> {
        size = size === 0 ? 10 : size;
        let url: string =
            this.REST_API_SERVER +
            '/order-management/search?page=' + page +
            '&size=' + size +
            '&companyId=' + companyId +
            '&q=' + search;

        return this.httpClient.get<any>(url).pipe(
            catchError(error => {
                this.toastrService.error(this.translate.instant('orderManagementService.error_load_customer'));
                return of([]);
            }),
            map(
                (data: any) => {
                    const customerOverviewArray = [];
                    data?.content?.forEach( data => {
                        customerOverviewArray.push(CustomerOverview.fromService(data));
                    })
                    const resultPage = new Page<CustomerOverview>(
                        data.totalPages,
                        data.totalElements,
                        data.numberOfElements,
                        data.pageSize,
                        data.pageNumber,
                        customerOverviewArray);
                    return resultPage;
                }
            )
        );
    }

  public getCustomerById(customerId: string): Observable<Customer> {
    const url: string = this.REST_API_SERVER + '/order-management/' + customerId;
    return this.httpClient.get<any>(url).pipe(
      catchError(error => {
        this.toastrService.error(this.translate.instant('orderManagementService.error_load_customer'));
        return of([]);
      }),
      map(
        data => Customer.fromService(data, this.contactTypes, this.allUser, this.taskTypes, this.addressTypesForAllCompanies)
      )
    );
  }

  public getAllContactTypes(): Observable<ContactType[]> {
    const url: string = this.REST_API_SERVER + '/configuration/contact-types/' + this.activeCompany?.companyId;
    return this.httpClient.get<any>(url).pipe(
      catchError(error => {
        this.toastrService.error(this.translate.instant('orderManagementService.error_load_contacttype'));
        return of([]);
      }),
      map(
        data => {
          if (data.length > 0) {
            return ContactType.fromServiceArray(data);
          } else {
            return null;
          }
        }
      )
    );
  }

  public getAllContactTypesWithoutCompany(): Observable<ContactType[]> {
    const url: string = this.REST_API_SERVER + '/configuration/contact-types';
    return this.httpClient.get<any>(url).pipe(
      catchError(error => {
        this.toastrService.error(this.translate.instant('orderManagementService.error_load_contacttype'));
        return of([]);
      }),
      map(
        data => {
          if (data.length > 0) {
            return ContactType.fromServiceArray(data);
          } else {
            return null;
          }
        }
      )
    );
  }

  public addContactType(contactType: ContactType, companyId: string): Observable<ContactType> {
    const url: string = this.REST_API_SERVER + '/configuration/contact-type';
    if (!companyId) {
      companyId = this.activeCompany?.companyId;
    }
    const body = ContactType.toService(contactType, companyId);
    return this.httpClient.post<ContactType>(url, body).pipe(
      catchError(error => {
        this.toastrService.error(this.translate.instant('orderManagementService.error_save_contacttype'));
        return of([]);
      }),
      map((item: any) => {
        this.toastrService.success(this.translate.instant('orderManagementService.contacttype_successfully_saved'));
        return ContactType.fromService(item);
      })
    );
  }

  public getAllAddressTypes(): Observable<AddressType[]> {
    const url: string = this.REST_API_SERVER + '/configuration/address-types/' + this.activeCompany?.companyId;
    return this.httpClient.get<any>(url).pipe(
      catchError(error => {
        this.toastrService.error(this.translate.instant('orderManagementService.error_load_addresstype'));
        return of([]);
      }),
      map(
        data => {
          if (data.length > 0) {
            return AddressType.fromServiceArray(data);
          } else {
            return null;
          }
        }
      )
    );
  }

  public getAllAddressTypesWithoutCompany(): Observable<AddressType[]> {
    const url: string = this.REST_API_SERVER + '/configuration/address-types';
    return this.httpClient.get<any>(url).pipe(
      catchError(error => {
        this.toastrService.error(this.translate.instant('orderManagementService.error_load_addresstype'));
        return of([]);
      }),
      map(
        data => {
          if (data.length > 0) {
            return AddressType.fromServiceArray(data);
          } else {
            return null;
          }
        }
      )
    );
  }

  public addAddressType(addressType: AddressType, companyId: string): Observable<AddressType> {
    const url: string = this.REST_API_SERVER + '/configuration/address-type';
    if (!companyId) {
      companyId = this.activeCompany?.companyId;
    }
    const body = AddressType.toService(addressType, companyId);
    return this.httpClient.post<AddressType>(url, body).pipe(
      catchError(error => {
        this.toastrService.error(this.translate.instant('orderManagementService.error_save_addresstype'));
        return of([]);
      }),
      map((item: any) => {
        this.toastrService.success(this.translate.instant('orderManagementService.addresstype_successfully_saved'));
        return AddressType.fromService(item);
      })
    );
  }

  public getAllOrderTypes(): Observable<OrderType[]> {
    const url: string = this.REST_API_SERVER + '/configuration/order-types/' + this.activeCompany?.companyId;
    return this.httpClient.get<any>(url).pipe(
      catchError(error => {
        this.toastrService.error(this.translate.instant('orderManagementService.error_load_ordertype'));
        return of([]);
      }),
      map(
        data => {
          if (data.length > 0) {
            return OrderType.fromServiceArray(data);
          } else {
            return null;
          }
        }
      )
    );
  }

  public getAllOrderTypesWithoutCompany(): Observable<OrderType[]> {
    const url: string = this.REST_API_SERVER + '/configuration/order-types';
    return this.httpClient.get<any>(url).pipe(
      catchError(error => {
        this.toastrService.error(this.translate.instant('orderManagementService.error_load_ordertype'));
        return of([]);
      }),
      map(
        data => {
          if (data.length > 0) {
            return OrderType.fromServiceArray(data);
          } else {
            return null;
          }
        }
      )
    );
  }

  public addOrderType(orderType: OrderType, companyId: string): Observable<OrderType> {
    const url: string = this.REST_API_SERVER + '/configuration/order-type';
    if (!companyId) {
      companyId = this.activeCompany?.companyId;
    }
    const body = OrderType.toService(orderType, companyId);
    return this.httpClient.post<OrderType>(url, body).pipe(
      catchError(error => {
        this.toastrService.error(this.translate.instant('orderManagementService.error_save_ordertype'));
        return of([]);
      }),
      map((item: any) => {
        this.toastrService.success(this.translate.instant('orderManagementService.ordertype_successfully_saved'));
        return OrderType.fromService(item);
      })
    );
  }

  public getAllTaskTypes(): Observable<TaskType[]> {
    const url: string = this.REST_API_SERVER + '/configuration/task-types/' + this.activeCompany?.companyId;
    return this.httpClient.get<any>(url).pipe(
      catchError(error => {
        this.toastrService.error(this.translate.instant('orderManagementService.error_load_tasktype'));
        return of([]);
      }),
      map(
        data => {
          if (data.length > 0) {
            return TaskType.fromServiceArray(data);
          } else {
            return null;
          }
        }
      )
    );
  }

  public getAllTaskTypesWithoutCompany(): Observable<TaskType[]> {
    const url: string = this.REST_API_SERVER + '/configuration/task-types';
    return this.httpClient.get<any>(url).pipe(
      catchError(error => {
        this.toastrService.error(this.translate.instant('orderManagementService.error_load_tasktype'));
        return of([]);
      }),
      map(
        data => {
          if (data.length > 0) {
            return TaskType.fromServiceArray(data);
          } else {
            return null;
          }
        }
      )
    );
  }

  public addTaskType(taskType: TaskType, companyId: string): Observable<OrderType> {
    const url: string = this.REST_API_SERVER + '/configuration/task-type';
    if (!companyId) {
      companyId = this.activeCompany?.companyId;
    }
    const body = TaskType.toService(taskType, companyId);
    return this.httpClient.post<OrderType>(url, body).pipe(
      catchError(error => {
        this.toastrService.error(this.translate.instant('orderManagementService.error_save_tasktype'));
        return of([]);
      }),
      map((item: any) => {
        this.toastrService.success(this.translate.instant('orderManagementService.tasktype_successfully_saved'));
        return OrderType.fromService(item);
      })
    );
  }

  public getAllOrdersOverview(page: number, size: number): Observable<Page<OrderOverview>> {
    const url: string = this.REST_API_SERVER;
    return this.httpClient.get<any>(url).pipe(
      catchError(error => {
        this.toastrService.error(this.translate.instant('orderManagementService.error_load_company'));
        return of([]);
      }),
      map(
        (data: any) => {
          return new Page<OrderOverview>(null, null, null, null, null, null);
        }
      )
    );
  }

  public getOrderById(customerId: string, orderId: string): Observable<Order> {
    if (!customerId || !orderId || orderId === '0') {
      return EMPTY;
    }
    const url: string = this.REST_API_SERVER + '/order-management/' + customerId + '/orders/' + orderId;
    return this.httpClient.get<any>(url).pipe(
      catchError(error => {
        this.toastrService.error(this.translate.instant('orderManagementService.error_load_order'));
        return of([]);
      }),
      map(
        (data) => {
          const orderType = this.orderTypes?.find(value => value.id === data.orderTypeId);
          return Order.fromService(data, this.allUser, customerId, orderType, this.contactTypes, this.taskTypesForAllCompanies, this.addressTypesForAllCompanies);
        }
      )
    );
  }

  public saveOrder(order: Order, customerId: string): Observable<Order> {
    const url = this.REST_API_SERVER + '/order-management/' + customerId + '/orders';
    const body = Order.toService(order);
    if (order.id !== null) {
      return this.httpClient.put<Order>(url, body).pipe(
        catchError(error => {
          this.toastrService.error(this.translate.instant('orderManagementService.error_save_order'));
          return of([]);
        }),
        map((item: any) => {
            this.toastrService.success(this.translate.instant('orderManagementService.order_successfully_saved'));
            const orderType = this.orderTypes?.find(value => value.id === item.orderTypeId);
            return Order.fromService(item, this.allUser, customerId, orderType, this.contactTypes, this.taskTypes, this.addressTypesForAllCompanies);
          }
        ));
    } else {
      return this.httpClient.post<Order>(url, body).pipe(
        catchError(error => {
          this.toastrService.error(this.translate.instant('orderManagementService.error_save_order'));
          return of([]);
        }),
        map((item: any) => {
            this.toastrService.success(this.translate.instant('orderManagementService.order_successfully_saved'));
            let orderType = null;
            if ( item.orderTypeId) {
                orderType = this.orderTypes.find(value => value.id === item.orderTypeId);
            }
            return Order.fromService(item, this.allUser, customerId, orderType, this.contactTypes, this.taskTypes, this.addressTypesForAllCompanies);
          }
        ));
    }
  }

  public updateTask(task: Task, orderId: string, customerId: string): Observable<Task> {
    const url = this.REST_API_SERVER + '/order-management/' + customerId + '/orders/' + orderId + '/suborders/' + task.id;
    const body = Task.toService(task);
    return this.httpClient.post<Task>(url, body).pipe(
      catchError(error => {
        this.toastrService.error(this.translate.instant('orderManagementService.error_save_order'));
        return of([]);
      }),
      map((item: any) => {
          this.toastrService.success(this.translate.instant('orderManagementService.order_successfully_saved'));
          return Task.fromService(item, this.allUser, this.taskTypes);
        }
      ));
  }

  public addCommentToOrder(orderId: string, comment: ChatMessage): Observable<ChatMessage> {
    const url = this.REST_API_SERVER + '/order-management/' + this.selectedCustomer.id + '/orders/' + orderId + '/comments';
    const body = ChatMessage.toService(comment);
    return this.httpClient.post<ChatMessage>(url, body).pipe(
      catchError(error => {
        this.toastrService.error(this.translate.instant('orderManagementService.error_save_order_comment'));
        return of([]);
      }),
      map((item: any) => {
          this.toastrService.success(this.translate.instant('orderManagementService.order_comment_successfully_saved'));
          return ChatMessage.fromService(item, this.allUser);
        }
      ));
  }

  public addCommentToTask(orderId: string, taskId: string, comment: ChatMessage): Observable<ChatMessage> {
    const url = this.REST_API_SERVER + '/order-management/' + this.selectedCustomer.id + '/orders/' + orderId + '/suborders/' + taskId + '/comments';
    const body = ChatMessage.toService(comment);
    return this.httpClient.post<ChatMessage>(url, body).pipe(
      catchError(error => {
        this.toastrService.error(this.translate.instant('orderManagementService.error_save_order_comment'));
        return of([]);
      }),
      map((item: any) => {
          this.toastrService.success(this.translate.instant('orderManagementService.order_comment_successfully_saved'));
          return ChatMessage.fromService(item, this.allUser);
        }
      ));
  }

  public globalSearch(query: string): Observable<SearchResult[]> {
    const url: string = this.REST_API_SERVER + '/order-management-search?query=' + query + '&companyId=' + this.activeCompany?.companyId;
    return this.httpClient.get<any>(url).pipe(
      catchError(error => {
        this.toastrService.error(this.translate.instant('orderManagementService.error_globalSearch'));
        return of([]);
      }),
      map(
        data => SearchResult.fromServiceArray(data)
      )
    );
  }

  public getOrderStateOverview(page: number, size: number, userId: string = null, startDate = '1900-01-01', endDate = '2999-01-01', orderScope = 'ORDER', filterActiveCompany = true): Observable<Page<OrderStateOverview>> {
    if (!this.activeCompany) {
      return EMPTY;
    }

    size = size === 0 ? 10 : size;
    let url: string =
      this.REST_API_SERVER +
      '/order-management-search/orders?page=' + page +
      '&size=' + size +
      '&dateScope=' + orderScope +
      (( startDate) ? ('&startDate=' + startDate) : '' ) +
      (( endDate ) ? ( '&endDate=' + endDate ) : '') +
      (filterActiveCompany === true ?
      '&companyId=' + this.activeCompany?.companyId : '') +
      '&expand=ALL';

    if (userId) {
      url += '&userIds=' + userId;
    }

    return this.httpClient.get<any>(url).pipe(
      catchError(error => {
        this.toastrService.error(this.translate.instant('orderManagementService.error_load_orderStateOverview'));
        return of([]);
      }),
      map(
        (data: any) => {
          if( data && data.content) {
            const orderStateOverviewArray = [];
            for (const overview of data.content) {
              orderStateOverviewArray.push(new OrderStateOverview(
                overview.orderId,
                overview.customerId,
                overview.companyId,
                Customer.fromService(overview.customer, this.contactTypes, this.allUser, this.taskTypesForAllCompanies, this.addressTypesForAllCompanies)));
            }
            const resultPage = new Page<OrderStateOverview>(
              data.totalPages,
              data.totalElements,
              data.numberOfElements,
              data.pageSize,
              data.pageNumber,
              orderStateOverviewArray);
            return resultPage;
          }
          return null;
        }
      )
    );
  }

  getOrdersForCalendar(startDate: Date, endDate: Date, filterActiveCompany: boolean): Observable<Customer[]> {
    const startDateString = this.datePipe.transform(startDate, 'yyyy-MM-dd');
    const endDateString = this.datePipe.transform(endDate, 'yyyy-MM-dd');
    return this.getOrderStateOverview(0, 9999, null, startDateString, endDateString, 'SUBORDER', filterActiveCompany)
      .pipe(map( res => {
        const result: Customer[] = [];
        res.content.forEach(overview => {
          result.push(overview.customer);
        });
        return result;
      }));
  }

  getOrders(startDate: Date, endDate: Date): Observable<any> {
    const startDateString = this.datePipe.transform(startDate, 'yyyy-MM-dd');
    const endDateString = this.datePipe.transform(endDate, 'yyyy-MM-dd');
    return this.getOrderStateOverview(0, 9999, null, startDateString, endDateString)
      .pipe(map( res => {
        const result: Customer[] = [];
        res.content.forEach(overview => {
          result.push(overview.customer);
        });
        return result;
      }));
  }

  getOrdersForPeriodAndUserIds(startDate: Date, endDate: Date, userId: string = null): Observable<any> {
    const startDateString = this.datePipe.transform(startDate, 'yyyy-MM-dd');
    const endDateString = this.datePipe.transform(endDate, 'yyyy-MM-dd');
    return this.getOrderStateOverview(0, 9999, userId, startDateString, endDateString, 'SUBORDER', false)
      .pipe(map( res => {
        const result: Customer[] = [];
        res.content.forEach(overview => {
          result.push(overview.customer);
        });
        return result;
      }));
  }

  public getCustomerValidationModel(): Observable<ContactType[]> {
    const url: string = this.REST_API_SERVER + '/configuration/validation-info/customer';
    return this.httpClient.get<any>(url).pipe(
      catchError(error => {
        this.toastrService.error(this.translate.instant('orderManagementService.error_load_contacttype'));
        return of([]);
      }),
      map(
        data => {
          if (data.length > 0) {
            return ContactType.fromServiceArray(data);
          } else {
            return null;
          }
        }
      )
    );
  }
}
