import {createSelector} from '@ngrx/store';
import {ReducerDatevState} from './datev.reducer';

export interface DatevSate {
  datev: ReducerDatevState;
}

export const selectDatevState = (state: DatevSate) => state.datev;

export const allDatevMappingsSelect = createSelector(
  selectDatevState,
  (state) => state.datevMappings
);

export const datevImportOverviewSelect = createSelector(
  selectDatevState,
  (state) => state.datevImportOverview
);

export const datevIsLoadingSelect = createSelector(
  selectDatevState,
  (state) => state.isLoading
);

export const datevImportDto = createSelector(
    selectDatevState,
    (state) => state.importDto
);

export const selectedDatevImportDto = createSelector(
    selectDatevState,
    (state) => state.selectedImportDto
);
