import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import {UserService} from "../services/user.service";

@Injectable()
export class LoginGuard implements CanActivate {


    constructor(
        private authService: UserService,
    ) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> {
        return this.authService.isDoneLoading$.pipe(
            filter(isDone => isDone),
            switchMap(_ => this.authService.isAuthenticated),
            tap(isAuthenticated => isAuthenticated || this.authService.login(state.url)),
        );
    }
}
