import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {TaskTypeEditComponent} from "../edit/task-type-edit.component";
import {TaskType} from "../../../core/models/order/tasktype/tasktype.model";
import {
    allTaskTypesForAllCompaniesSelect,
    selectedTaskTypeSelect,
    TaskTypeState
} from "../../../core/models/order/tasktype/store";
import {Store} from "@ngrx/store";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-task-type-edit-modal-dialog',
    templateUrl: './task-type-edit-modal-dialog.component.html',
    styleUrls: ['./task-type-edit-modal-dialog.component.scss']
})
export class TaskTypeEditModalDialogComponent implements OnInit, OnDestroy {

    @ViewChild(TaskTypeEditComponent) taskTypeComponent: TaskTypeEditComponent;
    @Input() companyId: string = null;

    @Input() closeAfterSave: boolean = false;

    // @ts-ignore
    taskType$: Observable<TaskType> = this.taskTypeStore.select(selectedTaskTypeSelect);
    taskTypeSubscription: Subscription;
    taskType: TaskType;
    selectedTaskType: TaskType;

    constructor(
        public activeModal: NgbActiveModal,
        public taskTypeStore: Store<TaskTypeState>) {
        this.taskTypeSubscription = this.taskType$.subscribe(type => {
            if (type) {
                this.taskType = type;
                if (this.closeAfterSave) {
                    this.closeDialog();
                }
            }
        });
    }

    closeDialog(): void {
        this.taskTypeComponent.resetForm();
        this.activeModal.dismiss(this.taskType);
    }

    ngOnInit(): void {
        this.selectedTaskType = null;
    }

    ngOnDestroy(): void {
        this.taskTypeSubscription.unsubscribe();
        this.selectedTaskType = null;
    }


}
