<div class="row g-3">
    <div class="col-md-12">
        <div class="search-box">
            <input type="text" name="searchTerm" class="form-control" placeholder="{{ 'labels.search' | translate }}"
                   (keyup)="updateFilter($event)">
            <i class="ri-search-line search-icon"></i>
        </div>
    </div>
    <!--
    <div class="col-md-auto ms-auto">
        <div class="d-flex align-items-center gap-2">
            <button type="button" class="btn btn-soft-success"><i
                    class="ri-add-circle-line align-middle me-1"></i> {{ 'labels.addNewAddressType' | translate }}
            </button>
        </div>
    </div>
    -->
</div>

<hr>

<div class="" [perfectScrollbar]>
    <ng-container *ngFor="let order of ordersFiltered; let i = index">
        <ng-container *ngIf="order.tasks && order.tasks.length > 0">
            <ng-container *ngFor="let task of order.tasks; let i = index">
                <ng-container *ngIf="task?.state?.toString() === 'UNSCHEDULED'">
                    <div class="draggableCalendarEventUnscheduled sidebarTask"
                         [ngStyle]="{'border-right': '5px solid ' + task.taskType?.color}"
                         [attr.bgColor]="task.taskType?.rgbaColor"
                         [attr.bdcolor]="task.taskType?.color"
                         [attr.orderId]="order.id"
                         [attr.taskId]="task.id"
                         [attr.customer]="objectToJson(order.customer)"
                         [attr.task]="objectToJson(task)"
                         [attr.order]="objectToJson(order)"
                    (click)="planTask(task)">
                        <div class="small">
                        {{order?.operationAddress|fullAddress}}
                            <br>
                        {{ order?.externalId?.id ? 'Auftragsnummer ' + order?.externalId?.id : '' }}
                        </div>
                        <div class="fw-bold">
                            Aufgabe
                            {{ task.sortOrder + 1 }}
                            <br>
                            {{ task.description }}
                        </div>
                        <div class="small">{{ task.startDate | date: 'dd.MM.yyyy HH:mm:ss'}}
                            - {{task.endDate | date: 'dd.MM.yyyy HH:mm:ss'}}</div>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
</div>
