import {Tenant} from '../tenant.model';
import {Action, createReducer, on} from '@ngrx/store';
import * as TenantActions from './tenant.actions';

export interface ReducerTenantState {
  tenant: Tenant;
}

export const initialTenantState: ReducerTenantState = {
  tenant: null
};

const tenantReducer = createReducer(
  initialTenantState,
  on(TenantActions.tenantRegisteredAction, (state, {payload}) => ({...state, tenant: payload}))
);

export function reducer(state: ReducerTenantState | undefined, action: Action): ReducerTenantState {
  return tenantReducer(state, action);
}
