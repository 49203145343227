import { Injectable, TemplateRef } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts: any[] = [];

  error(textOrTpl: string | TemplateRef<any>) {
    const options: any =  { classname: 'bg-danger text-center text-white', delay: 5000 }
    this.toasts.push({ textOrTpl, ...options });
  }
  success(textOrTpl: string | TemplateRef<any>) {
    const options: any = { classname: 'bg-success text-center text-white', delay: 5000 }
    this.toasts.push({ textOrTpl, ...options });
  }

  remove(toast: any) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }
}
