import {JsonClassType, JsonProperty, ObjectMapper} from "jackson-js";
import {Address} from "../address.model";
import {DatevAddressModel} from "./datevAddress.model";
import {DatevCommunicationModel} from "./datevCommunication.model";
import {DatevErrors} from "./datevErrors.model";
import {DatevMappingErrorsModel} from "./datevMappingErrors.model";
import {DatevTaskModel} from "./datevTask.model";

export class DatevCustomerModel {

    @JsonProperty() @JsonClassType({type: () => [String]})
    id: string;
    @JsonProperty() @JsonClassType({type: () => [String]})
    datevCompanyId: string;
    @JsonProperty() @JsonClassType({type: () => [Boolean]})
    isCorporateCustomer: boolean;
    @JsonProperty() @JsonClassType({type: () => [DatevAddressModel]})
    mainAddress: DatevAddressModel;
    @JsonProperty() @JsonClassType({type: () => [Array, [DatevAddressModel]]})
    customerAddresses: DatevAddressModel[] = [];
    @JsonProperty() @JsonClassType({type: () => [Array, [DatevCommunicationModel]]})
    contacts: DatevCommunicationModel[] = [];
    @JsonProperty() @JsonClassType({type: () => [Array, [DatevErrors]]})
    errors: DatevErrors[] = [];
    @JsonProperty() @JsonClassType({type: () => [Array, [DatevMappingErrorsModel]]})
    mappingErrors: DatevMappingErrorsModel[] = [];

    constructor(
        id: string,
        datevCompanyId: string,
        isCorporateCustomer: boolean,
        @JsonClassType({type: () => [DatevAddressModel]}) mainAddress: DatevAddressModel,
        customerAddresses: DatevAddressModel[],
        contacts: DatevCommunicationModel[],
        errors: DatevErrors[],
        mappingErrors: DatevMappingErrorsModel[]
    ){
        this.id = id;
        this.datevCompanyId = datevCompanyId;
        this.isCorporateCustomer = isCorporateCustomer;
        this.mainAddress = mainAddress;
        this.customerAddresses = customerAddresses;
        this.contacts = contacts;
        this.errors = errors;
        this.mappingErrors = mappingErrors;
    }

    public static fromService(customer: any): DatevCustomerModel {
        if (customer) {
            const objectMapper = new ObjectMapper();
            return objectMapper.parse<DatevCustomerModel>(JSON.stringify({
                id: customer.customerId,
                datevCompanyId: customer.companyId,
                isCorporateCustomer: customer.isCorporateCustomer,
                mainAddress: DatevAddressModel.fromService(customer.mainAddress),
                customerAddresses: DatevAddressModel.fromServiceArray(customer.customerAddresses),
                contacts: DatevCommunicationModel.fromServiceArray(customer.contacts),
                errors: DatevErrors.fromServiceArray(customer.errors),
                mappingErrors: DatevMappingErrorsModel.fromServiceArray(customer.mappingErrors)
            }), {mainCreator: () => [DatevCustomerModel]});
        } else {
            return null;
        }
    }

    public static toService(customer: DatevCustomerModel): any {
        return {
            id: customer.id,
            companyId: customer.datevCompanyId,
            mainAddress: DatevAddressModel.toService(customer.mainAddress),
            contacts: DatevCommunicationModel.toServiceArray(customer.contacts)
        };
    }
}
