import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {User} from '../../../core/models/user/user.model';
import {selectedUserSelect, UserState} from '../../../core/models/user/store';
import {Store} from '@ngrx/store';
import {allOrdersByPeriodSelect, OrderState} from '../../../core/models/order/store';
import {selectAllOrdersByPeriodAction} from '../../../core/models/order/store/order.actions';
import {Order} from '../../../core/models/order/order.model';
import {Task} from '../../../core/models/order/task.model';
import {Router} from '@angular/router';
import {Customer} from "../../../core/models/customer/customer.model";

@Component({
  selector: 'app-task-list-person',
  templateUrl: './task-list-person.component.html',
  styleUrls: ['./task-list-person.component.scss']
})
export class TaskListPersonComponent implements OnInit, OnDestroy {
  selectedUser$: Observable<User> = this.userStore.select(selectedUserSelect);
  selectedUserSub: Subscription;
  selectedUser: User;

  orderList$: Observable<Customer[]> = this.orderStore.select(allOrdersByPeriodSelect);
  orderListSubscription: Subscription;
  orderList: Customer[];

  startDate: Date = new Date();
  endDate: Date = new Date();

  tasksByOrders: any[];

  constructor(
    private userStore: Store<UserState>,
    private orderStore: Store<OrderState>,
    private router: Router,
  ) {
    this.selectedUserSub = this.selectedUser$.subscribe(
      (user: User) => this.selectPerson(user)
    );

    this.orderListSubscription = this.orderList$.subscribe(customers => {
      if (customers) {
        this.orderList = customers;
        this.tasksByOrders = [];
        customers.forEach(customer => {
          customer.orders.forEach(order => {
            const tasks: Task[] = [];
            order.tasks.forEach(task => {
              if (task.userIds?.find(id => id === this.selectedUser?.userId)) {
                tasks.push(task);
              }
            });
            const object = {order, tasks};
            this.tasksByOrders.push(object);
          });
        });
      }
    });
  }

  selectPerson(person: User): void {
    this.selectedUser = null;
    if (person) {
      this.selectedUser = person;
      this.loadOrders();
    }
  }

  loadOrders(): void {
    this.orderStore.dispatch(selectAllOrdersByPeriodAction({
      start: this.startDate,
      end: this.endDate,
      userId: this.selectedUser.userId
    }));
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.selectedUserSub.unsubscribe();
    this.orderListSubscription.unsubscribe();
  }

  openOrder(order: Order): void {
    this.router.navigate(['/order-overview/' + order.customerId + '/' + order.id]);
  }
}
