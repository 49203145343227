import {JsonClassType, JsonProperty} from "jackson-js";

export class Page<T> {
  @JsonProperty()
  @JsonClassType({type: () => [Number]})
  totalPages: number;

  @JsonProperty()
  @JsonClassType({type: () => [Number]})
  totalElements: number;

  @JsonProperty()
  @JsonClassType({type: () => [Number]})
  numberOfElements: number;

  @JsonProperty()
  @JsonClassType({type: () => [Number]})
  pageSize: number;

  @JsonProperty()
  @JsonClassType({type: () => [Number]})
  pageNumber: number;

  @JsonProperty()
  @JsonClassType({type: () => [Number]})
  actualMinValue: number;

  @JsonProperty()
  @JsonClassType({type: () => [Number]})
  actualMaxValue: number;

  @JsonProperty()
  @JsonClassType({type: () => [Array]})
  content: T[];

  constructor(
    totalPages: number, totalElements: number, numberOfElements: number, pageSize: number, pageNumber: number, content: T[]
  ) {
    this.totalPages = totalPages;
    this.totalElements = totalElements;
    this.numberOfElements = numberOfElements;
    this.pageSize = pageSize;
    this.pageNumber = pageNumber;
    this.content = content;

    this.actualMinValue = (((pageNumber+1) * pageSize) - (pageSize)) + 1;
    this.actualMaxValue = (pageNumber+1) * pageSize;
    if (this.actualMaxValue > totalElements) {
      this.actualMaxValue = this.totalElements;
    }
  }
}
