import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Inject, Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {activeCompanySelect, CompanyState} from '../models/company/store';
import {TranslateService} from '@ngx-translate/core';
import {DatePipe} from '@angular/common';
import {Company} from '../models/company/company.model';
import {NewCustomerStatistic} from '../models/statistic/newCustomerStatistic.model';
import {NewCustomerStatisticGroupedByYearAndMonth} from '../models/statistic/newCustomerStatisticGroupedByYearAndMonth.model';
import {NewCustomerStatisticGroupedByYearAndMonthAndWeek} from '../models/statistic/newCustomerStatisticGroupedByYearAndMonthAndWeek.model';
import {
  NewCustomerStatisticGroupedByYearAndMonthAndWeekAndDay
} from '../models/statistic/newCustomerStatisticGroupedByYearAndMonthAndWeekAndDay.model';
import {NewOrdersStatistic} from '../models/statistic/newOrdersStatistic.model';
import {NewOrdersStatisticGroupedByYearAndMonth} from '../models/statistic/newOrdersStatisticGroupedByYearAndMonth.model';
import {NewOrdersStatisticGroupedByYearAndMonthAndWeek} from '../models/statistic/newOrdersStatisticGroupedByYearAndMonthAndWeek.model';
import {
  NewOrdersStatisticGroupedByYearAndMonthAndWeekAndDay
} from '../models/statistic/newOrdersStatisticGroupedByYearAndMonthAndWeekAndDay.model';
import {ToastService} from "./Toast/toast-service";

@Injectable()
export class StatisticManagementApiService {
  activeCompany$: Observable<Company> = this.companyStore.select(activeCompanySelect);
  activeCompany: Company;

  private REST_API_SERVER = this.orderManagementUrl;
  cache = {};

  constructor(
    private httpClient: HttpClient,
    private toastrService: ToastService,
    private translate: TranslateService,
    private companyStore: Store<CompanyState>,
    private datePipe: DatePipe,
    @Inject('ORDER_MANAGEMENT') private orderManagementUrl: string) {
    this.activeCompany$.subscribe(
      company => this.activeCompany = company
    );
  }

  public getNewCustomersByDateRange(startDate: Date, endDate: Date): Observable<NewCustomerStatistic[]> {
    const url: string =
      this.REST_API_SERVER +
      '/statistic/newCustomers' +
      '?companyId=' + this.activeCompany?.companyId +
      '&startDate=' + this.datePipe.transform(startDate, 'yyyy-MM-dd') +
      '&endDate=' + this.datePipe.transform(endDate, 'yyyy-MM-dd');
    return this.httpClient.get<any>(url).pipe(
      catchError(error => {
        this.toastrService.error(this.translate.instant('@TODO'));
        return of([]);
      }),
      map(
        data => {
          if (data) {
            return data;
          } else {
            return null;
          }
        }
      )
    );
  }

  public getNewCustomersByDateRangeGroupByYearAndMonth(startDate: Date, endDate: Date): Observable<NewCustomerStatisticGroupedByYearAndMonth[]> {
    const url: string =
      this.REST_API_SERVER +
      '/statistic/newCustomersGroupedByMonthAndYear' +
      '?companyId=' + this.activeCompany?.companyId +
      '&startDate=' + this.datePipe.transform(startDate, 'yyyy-MM-dd') +
      '&endDate=' + this.datePipe.transform(endDate, 'yyyy-MM-dd');
    return this.httpClient.get<any>(url).pipe(
      catchError(error => {
        this.toastrService.error(this.translate.instant('@TODO'));
        return of([]);
      }),
      map(
        data => {
          if (data) {
            return data;
          } else {
            return null;
          }
        }
      )
    );
  }

  public getNewCustomersByDateRangeGroupByWeekYearAndMonth(startDate: Date, endDate: Date): Observable<NewCustomerStatisticGroupedByYearAndMonthAndWeek[]> {
    const url: string =
      this.REST_API_SERVER +
      '/statistic/newCustomersGroupedByWeekAndMonthAndYear' +
      '?companyId=' + this.activeCompany?.companyId +
      '&startDate=' + this.datePipe.transform(startDate, 'yyyy-MM-dd') +
      '&endDate=' + this.datePipe.transform(endDate, 'yyyy-MM-dd');
    return this.httpClient.get<any>(url).pipe(
      catchError(error => {
        this.toastrService.error(this.translate.instant('@TODO'));
        return of([]);
      }),
      map(
        data => {
          if (data) {
            return data;
          } else {
            return null;
          }
        }
      )
    );
  }

  public getNewCustomersByDateRangeGroupByDayWeekYearAndMonth(startDate: Date, endDate: Date): Observable<NewCustomerStatisticGroupedByYearAndMonthAndWeekAndDay[]> {
    const url: string =
      this.REST_API_SERVER +
      '/statistic/newCustomersGroupedByDayAndWeekAndMonthAndYear' +
      '?companyId=' + this.activeCompany?.companyId +
      '&startDate=' + this.datePipe.transform(startDate, 'yyyy-MM-dd') +
      '&endDate=' + this.datePipe.transform(endDate, 'yyyy-MM-dd');
    return this.httpClient.get<any>(url).pipe(
      catchError(error => {
        this.toastrService.error(this.translate.instant('@TODO'));
        return of([]);
      }),
      map(
        data => {
          if (data) {
            return data;
          } else {
            return null;
          }
        }
      )
    );
  }

  public getNewOrdersByDateRange(startDate: Date, endDate: Date): Observable<NewOrdersStatistic[]> {
    const url: string =
      this.REST_API_SERVER +
      '/statistic/newOrders' +
      '?companyId=' + this.activeCompany?.companyId +
      '&startDate=' + this.datePipe.transform(startDate, 'yyyy-MM-dd') +
      '&endDate=' + this.datePipe.transform(endDate, 'yyyy-MM-dd');
    return this.httpClient.get<any>(url).pipe(
      catchError(error => {
        this.toastrService.error(this.translate.instant('@TODO'));
        return of([]);
      }),
      map(
        data => {
          if (data) {
            return data;
          } else {
            return null;
          }
        }
      )
    );
  }

  public getNewOrdersByDateRangeGroupByYearAndMonth(startDate: Date, endDate: Date): Observable<NewOrdersStatisticGroupedByYearAndMonth[]> {
    const url: string =
      this.REST_API_SERVER +
      '/statistic/newOrdersGroupedByMonthAndYear' +
      '?companyId=' + this.activeCompany?.companyId +
      '&startDate=' + this.datePipe.transform(startDate, 'yyyy-MM-dd') +
      '&endDate=' + this.datePipe.transform(endDate, 'yyyy-MM-dd');
    return this.httpClient.get<any>(url).pipe(
      catchError(error => {
        this.toastrService.error(this.translate.instant('@TODO'));
        return of([]);
      }),
      map(
        data => {
          if (data) {
            return data;
          } else {
            return null;
          }
        }
      )
    );
  }

  public getNewOrdersByDateRangeGroupByWeekYearAndMonth(startDate: Date, endDate: Date): Observable<NewOrdersStatisticGroupedByYearAndMonthAndWeek[]> {
    const url: string =
      this.REST_API_SERVER +
      '/statistic/newOrdersGroupedByWeekAndMonthAndYear' +
      '?companyId=' + this.activeCompany?.companyId +
      '&startDate=' + this.datePipe.transform(startDate, 'yyyy-MM-dd') +
      '&endDate=' + this.datePipe.transform(endDate, 'yyyy-MM-dd');
    return this.httpClient.get<any>(url).pipe(
      catchError(error => {
        this.toastrService.error(this.translate.instant('@TODO'));
        return of([]);
      }),
      map(
        data => {
          if (data) {
            return data;
          } else {
            return null;
          }
        }
      )
    );
  }

  public getNewOrdersByDateRangeGroupByDayWeekYearAndMonth(startDate: Date, endDate: Date): Observable<NewOrdersStatisticGroupedByYearAndMonthAndWeekAndDay[]> {
    const url: string =
      this.REST_API_SERVER +
      '/statistic/newOrdersGroupedByDayAndWeekAndMonthAndYear' +
      '?companyId=' + this.activeCompany?.companyId +
      '&startDate=' + this.datePipe.transform(startDate, 'yyyy-MM-dd') +
      '&endDate=' + this.datePipe.transform(endDate, 'yyyy-MM-dd');
    return this.httpClient.get<any>(url).pipe(
      catchError(error => {
        this.toastrService.error(this.translate.instant('@TODO'));
        return of([]);
      }),
      map(
        data => {
          if (data) {
            return data;
          } else {
            return null;
          }
        }
      )
    );
  }
}
