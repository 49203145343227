<!--
<full-calendar [options]='calendarOptions' #calendar></full-calendar>
-->

<!-- Start Breadcrumbs -->
<app-breadcrumbs title="Aufgaben" [breadcrumbItems]="breadCrumbItems"></app-breadcrumbs>
<!-- End Breadcrumbs -->


<div class="row g-4 mb-3">
    <div class="col-sm">
        <div class="d-flex justify-content-sm-end gap-2">
            <select class="form-control" data-choices data-choices-search-false (change)="datesChanged($event)">
                <option value="1">letzte Woche</option>
                <option value="2" selected>Heute</option>
                <option value="3">Morgen</option>
                <option value="4">diese Woche</option>
                <option value="5">nächste Woche</option>
            </select>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-xxl-3 col-sm-6 project-card" *ngFor="let task of taskList; let i = index"
         id="pl1_{{i}}">
        <div class="card cursor-pointer" (click)="handleEventClick(task.order, task)">
            <div class="card-body">

                <div class="p-3 mt-n3 mx-n3 rounded-top" [ngStyle]="{'background-color': task?.taskType?.color}">
                    <div class="d-flex align-items-center">
                        <div class="flex-grow-1">
                            <h5 class="mb-0 fs-14"><a class="text-dark">{{task.taskType?.description}}</a></h5>
                        </div>
                    </div>
                </div>

                <div class="py-3">
                    <div class="row gy-3">
                        <div class="col-6">
                            <div>
                                <p class="text-muted mb-1">Status</p>
                                <div class="badge badge-soft-warning fs-12">{{ task.state.toString() | taskStatus | translate}}</div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div>
                                <p class="text-muted mb-1">Beschreibung</p>
                                <p class="text-muted mb-1">{{task.description}}</p>
                            </div>
                        </div>
                    </div>

                    <div class="mt-3">
                        <p class="text-muted mb-0 me-2">Ausführungsort</p>
                        <div class="lh-1" style="white-space: pre-line">
                            <small>{{task?.order?.operationAddress | fullAddress}}</small>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end card body -->
            <div class="card-footer bg-transparent border-top-dashed py-2">
                <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                        <div class="avatar-group">
                            <div class="avatar-group-item" *ngFor="let taskUser of task.userIds">
                                <ng-container *ngFor="let user of users">
                                    <ng-container *ngIf="user.userId === taskUser">
                                        <a href="javascript: void(0);" class="d-block"
                                           ngbTooltip="{{user.firstName + ' ' + user.lastName}}"
                                           placement="top">
                                            <div class="avatar-xxs">
                                                <span class="avatar-title rounded-circle"
                                                      [ngStyle]="{'background-color': user?.color}">
                                                    {{ user.firstName?.charAt(0) }}{{ user.lastName?.charAt(0) }}
                                                </span>
                                            </div>
                                        </a>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="flex-shrink-0">
                        <div class="text-muted">
                            <small>{{task.startDate | date : 'dd.MM.yyyy HH:mm' }}
                                - {{task.endDate | date : 'dd.MM.yyyy HH:mm' }}</small>
                        </div>
                    </div>
                </div>

            </div>

            <!-- end card footer -->
        </div>
    </div>
    <!-- end card -->
    <!-- end col -->
</div>
<!-- end row -->

