import { Component } from '@angular/core';

@Component({
  selector: 'app-company-basicdata-overview',
  templateUrl: './company-basicdata-overview.component.html'
})
export class CompanyBasicdataOverviewComponent {
  breadCrumbItems!: Array<{}>;

  ngOnInit() {
    /**
     * BreadCrumb
     */
    this.breadCrumbItems = [
      { label: 'menu.my_company' },
      { label: 'menu.basicData', active: true }
    ];
  }
}
