import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';
import {EmployeeVacationPeriodModel} from "./employee-vacation-period.model";
import {EmployeeVacationValidFromModel} from "./employee-vacation-valid-from.model";

export class EmployeeVacationModel {
    @JsonProperty()
    @JsonClassType({type: () => [String]})
    employeeId: string;

    @JsonProperty()
    @JsonClassType({type: () => [String]})
    vacationId: string;

    @JsonProperty()
    @JsonClassType({type: () => [EmployeeVacationPeriodModel]})
    period: EmployeeVacationPeriodModel;


    constructor(employeeId: string, vacationId: string, period: EmployeeVacationPeriodModel) {
        this.employeeId = employeeId;
        this.vacationId = vacationId;
        this.period = period;
    }

    public static fromService(vacation: any): EmployeeVacationModel {
        const objectMapper = new ObjectMapper();
        const returnVacation = objectMapper.parse<EmployeeVacationModel>(JSON.stringify({
                employeeId: vacation.employeeId,
                vacationId: vacation.vacationId,
                period: EmployeeVacationPeriodModel.fromService(vacation.vacationPeriod)
            }), {mainCreator: () => [EmployeeVacationModel]}
        );
        return returnVacation;
    }


    public static fromServiceArray(vacations: any): EmployeeVacationModel[] {
        const resultVacations = [];
        if (vacations) {
            for (const vacation of vacations) {
                resultVacations.push(EmployeeVacationModel.fromService(vacation));
            }
        }
        return resultVacations;
    }

    public static toService(vacation: EmployeeVacationModel): any {
        return {
            employeeId: vacation.employeeId,
            vacationId: vacation.vacationId,
            vacationPeriod: EmployeeVacationPeriodModel.toService(vacation.period)
        }
    }

    public static toServiceNewVacation(vacation: any): any {
        return {
            startDate: vacation.startDate,
            endDate: vacation.endDate
        }
    }
}
