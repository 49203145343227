import {createSelector} from '@ngrx/store';
import {ReducerMessagesState} from './messages.reducer';

export interface MessageState {
  messages: ReducerMessagesState;
}

export const configState = (state: MessageState) => state.messages;

export const messagesSelect = createSelector(
  configState,
  (state) => state.messages
);

export const messagesForNavbarSelect = createSelector(
  configState,
  (state) => state.messagesForNavbar
);
