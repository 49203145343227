import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {LayoutComponent} from './layouts/layout.component';
import {AppAuthGuard} from "./core/guards/appAuthGuard .guard";
import {SetDefaultCompanyGuard} from "./core/guards/setDefaultCompany.guard";
import {LoginGuard} from "./core/guards/login.guard";
import {PagesModule} from "./pages/pages.module";
import {ErrorPageComponent} from "./pages/error-page/error-page.component";

// Auth

export function getPagesModule() { return PagesModule; }

const routes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        loadChildren: getPagesModule,
        canActivate: [LoginGuard, AppAuthGuard, SetDefaultCompanyGuard]
    },
    {
        path: 'error',
        component: ErrorPageComponent,
    },
    {
        path: 'error/:type',
        component: ErrorPageComponent
    },
    { path: '**', redirectTo: 'error', pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
