import { Pipe, PipeTransform } from '@angular/core';
import {OrderStateEnum, OrderStateLabel} from '../models/order/orderstate.enum';
import {Address} from "../models/address.model";
@Pipe({
  name: 'fullName'
})
export class FullNamePipe implements PipeTransform {
  transform(address: Address): string {
      let result: string = '';
      if (address?.companyName) {
        result = address.companyName;
      } else if (address?.firstName && address.name) {
        result = address.firstName + ' ' + address.name
      } else if ( address?.name) {
        result = address.name
      } else {
        result = '';
      }
      return result;
  }
}
