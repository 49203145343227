import {HttpClient} from '@angular/common/http';
import {EMPTY, Observable, of} from 'rxjs';
import {Company} from '../models/company/company.model';
import {catchError, map} from 'rxjs/operators';
import {Inject, Injectable} from '@angular/core';
import {Role} from '../models/user/role.model';
import {Group} from '../models/user/group.model';
import {User} from '../models/user/user.model';
import {Tenant} from '../models/tenant/tenant.model';
import {TranslateService} from '@ngx-translate/core';
import {loggedInUserSelect, UserState} from '../models/user/store';
import {Store} from '@ngrx/store';
import {allCompaniesSelect, CompanyState} from '../models/company/store';
import {UserService} from "./user.service";
import {ToastService} from "./Toast/toast-service";

@Injectable()
export class UserManagementApiService {
  loggedInUser$: Observable<User> = this.userStore.select(loggedInUserSelect);
  loggedInUser: User;

  companies$: Observable<Company[]> = this.companyStore.select(allCompaniesSelect);
  companies: Company[];

  private REST_API_SERVER = this.userManagementUrl;

  constructor(
    private httpClient: HttpClient,
    private toastrService: ToastService,
    private translate: TranslateService,
    private userStore: Store<UserState>,
    private companyStore: Store<CompanyState>,
    private userService: UserService,
    @Inject('USER_MANAGEMENT') private userManagementUrl: string) {
    this.loggedInUser$.subscribe(user => this.loggedInUser = user);
    this.companies$.subscribe(companies => this.companies = companies);
  }

  public registerNewTenant(): Observable<Tenant> {
    return this.httpClient.put(
      this.REST_API_SERVER + '/user-management/tenant/register/' + this.userService.getId(), {}).pipe(
      catchError(error => {
        if (error?.error?.errorCode) {
          this.toastrService.error(this.translate.instant('userManagementService.' + error?.error?.errorCode));
        }
        return of(error);
      }),
      map((tenant: any) => {
        if (!tenant?.error) {
          return Tenant.fromService(tenant);
        } else {
          return null;
        }
      })
    );
  }

  public getAllRoles(): Observable<Role[]> {
    return this.httpClient.get<Role[]>(this.REST_API_SERVER + '/user-management/tenant/configuration/roles').pipe(
      map((roles: any[]) =>
        roles.map(
          (role: any) =>
            new Role(null, role.value, role.description)
        ))
    );
  }

  public getAllGroups(): Observable<Group[]> {
    return this.httpClient.get<Group[]>(this.REST_API_SERVER + '/user-management/tenant/configuration/groups').pipe(
      map((groups: any[]) =>
        groups.map(
          (group: any) =>
            new Group(group.value, group.description)
        ))
    );
  }

  public loadCompanies(): Observable<Company[]> {
    if (!this.userService.isNewAdmin()) {
      const url: string = this.REST_API_SERVER + '/user-management/tenant/companies';
      return this.httpClient.get<Company[]>(url).pipe(
        catchError(error => {
          this.toastrService.error(this.translate.instant('userManagementService.error_load_company'));
          return of([]);
        }),
        map((data: any[]) =>
          data.map((item: any) => {
            const resultCompany: Company = Company.fromService(item);
            resultCompany.isDefault = this.loggedInUser?.defaultCompany === resultCompany.companyId;
            return resultCompany;
          })
        )
      );
    } else {
      return EMPTY;
    }
  }

  public loadCompanyById(companyId: string): Observable<Company> {
    if (!this.userService.isNewAdmin()) {
      return this.httpClient.get<Company>(this.REST_API_SERVER + '/user-management/tenant/companies/' + companyId).pipe(
        catchError(error => {
          this.toastrService.error(this.translate.instant('userManagementService.error_load_company'));
          return of([]);
        }),
        map((item: any) => {
          const resultCompany: Company = Company.fromService(item);
          resultCompany.isDefault = this.loggedInUser?.defaultCompany === resultCompany.companyId;
          return resultCompany;
        })
      );
    } else {
      return EMPTY;
    }
  }

  public saveCompany(company: Company): Observable<Company> {
    const companyCopy = {...company, tenantId: this.userService.getTenantId()};
    const body = Company.toService(companyCopy);
    return this.httpClient.post<Company>(this.REST_API_SERVER + '/user-management/tenant/companies', body).pipe(
      catchError(error => {
        if (error?.error?.errorCode) {
          this.toastrService.error(this.translate.instant('userManagementService.' + error?.error?.errorCode));
        }
        return of(error);
      }),
      map((item: any) => {
          if (!item?.error) {
            this.toastrService.success(this.translate.instant('userManagementService.company_successfully_saved'));
            const resultCompany: Company = Company.fromService(item);
            resultCompany.isDefault = this.loggedInUser?.defaultCompany === resultCompany.companyId;
            return resultCompany;
          } else {
            return null;
          }
        }
      )
    );
  }

  public getAllUsers(): Observable<User[]> {
    if (!this.userService.isNewAdmin()) {
      return this.httpClient.get<User[]>(this.REST_API_SERVER + '/user-management/tenant/user').pipe(
        catchError(error => {
          this.toastrService.error(this.translate.instant('userManagementService.error_load_person'));
          return of([]);
        }),
        map((data: any[]) =>
          data.map(
            (item: any) =>
              User.fromService(item, this.companies)
          )
        )
      );
    } else {
      return EMPTY;
    }
  }

  public getUserById(userId: string): Observable<User> {
    if (!this.userService.isNewAdmin()) {
      return this.httpClient.get<User>(this.REST_API_SERVER + '/user-management/tenant/user/' + userId).pipe(
        catchError(error => {
          this.toastrService.error(this.translate.instant('userManagementService.error_load_person'));
          return of([]);
        }),
        map((item: any) => User.fromService(item, this.companies)
        ),
      );
    } else {
      return EMPTY;
    }
  }

  public saveUser(user: User): Observable<User> {
    const newUser = {...user, tenantId: this.userService.getTenantId()};
    const body = User.toService(newUser);
    return this.httpClient.post<User>(this.REST_API_SERVER + '/user-management/tenant/user', body).pipe(
      catchError(error => {
        if (error?.error?.errorCode) {
          this.toastrService.error(this.translate.instant('userManagementService.' + error?.error?.errorCode));
        }
        return of(error);
      }),
      map((item: any) => {
          if (!item?.error) {
            this.toastrService.success(this.translate.instant('userManagementService.person_successfully_saved'));
            return User.fromService(item, this.companies);
          } else {
            return null;
          }
        }
      ));
  }

  public resendEmails(user: User): Observable<User> {
    return this.httpClient.put<User>(this.REST_API_SERVER + '/user-management/tenant/user/' + user.userId + '/resendActivationLink', {}).pipe(
      catchError(error => {
        if (error?.error?.errorCode) {
          this.toastrService.error(this.translate.instant('userManagementService.' + error?.error?.errorCode));
        }
        return of(error);
      }),
      map((item: any) => {
          if (!item?.error) {
            this.toastrService.success(this.translate.instant('userManagementService.resend_mail_action'));
            return User.fromService(item, this.companies);
          }
          return null;
        }
      ));
  }

  public existsEmailAddress(emailAddress: string): Observable<boolean> {
    return this.httpClient.get<boolean>(this.REST_API_SERVER + '/user-management/tenant/emails/' + emailAddress).pipe(
      map((value: boolean) => {
        return value;
      }));
  }
}
