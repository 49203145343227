import {JsonClassType, JsonProperty, ObjectMapper} from "jackson-js";
import {Address} from "../address.model";
import {RecordType} from "./datevImportDto.model";
import {DatevUsageTypeModel} from "./datevUsageType.model";
import {DatevErrors} from "./datevErrors.model";
import {DatevMappingErrorsModel} from "./datevMappingErrors.model";
import {DatevAddressModel} from "./datevAddress.model";

export class DatevCommunicationModel {

    @JsonProperty() @JsonClassType({type: () => [String]})
    id: string;
    @JsonProperty() @JsonClassType({type: () => [String]})
    type: string;
    @JsonProperty() @JsonClassType({type: () => [String]})
    content: string;
    @JsonProperty() @JsonClassType({type: () => [Array, [DatevErrors]]})
    errors: DatevErrors[] = [];
    @JsonProperty() @JsonClassType({type: () => [Array, [DatevMappingErrorsModel]]})
    mappingErrors: DatevMappingErrorsModel[] = [];

    constructor(
        id: string,
        type: string,
        content: string,
        errors: DatevErrors[],
        mappingErrors: DatevMappingErrorsModel[]
    ){
        this.id = id;
        this.type = type;
        this.content = content;
        this.errors = errors;
        this.mappingErrors = mappingErrors;
    }

    public static fromService(contact: any): DatevCommunicationModel {
        const objectMapper = new ObjectMapper();
        return objectMapper.parse<DatevCommunicationModel>(JSON.stringify({
            id: contact.id,
            type: contact.type,
            content: contact.content,
            errors: DatevErrors.fromServiceArray(contact.errors),
            mappingErrors: DatevMappingErrorsModel.fromServiceArray(contact.mappingErrors)
    }), {mainCreator: () => [DatevCommunicationModel]});
    }

    public static fromServiceArray(contactsArray: any): DatevCommunicationModel[] {
        const resultContacts = [];
        if (contactsArray) {
            for (const contact of contactsArray) {
                resultContacts.push(DatevCommunicationModel.fromService(contact));
            }
        }
        return resultContacts;
    }

    public static toService(contact: DatevCommunicationModel): any {
        return {
            id: contact.id,
            type: contact.type,
            content: contact.content
        };
    }

    public static toServiceArray(contactsArray: DatevCommunicationModel[]): any {
        const contacts = [];
        if (contactsArray) {
            for (const task of contactsArray) {
                contacts.push(DatevCommunicationModel.toService(task));
            }
        }
        return contacts;
    }
}
