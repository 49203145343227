import {Injectable} from "@angular/core";

@Injectable()
export class DateHelper {
    convertDateToString(date: Date): string {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return year + '-' + (month < 10 ? '0' + month : month)  + '-' + ( day < 10 ? '0' + day : day)
    }
}
