import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';
import {EmployeeVacationValidFromModel} from "./employee-vacation-valid-from.model";

export class EmployeeVacationPeriodModel {
    @JsonProperty()
    @JsonClassType({type: () => [Date]})
    startDate: Date;

    @JsonProperty()
    @JsonClassType({type: () => [Date]})
    endDate: Date;

    constructor(startDate: Date, endDate: Date) {
        this.startDate = startDate;
        this.endDate = endDate;
    }

    public static fromService(period: any): EmployeeVacationPeriodModel {
        const objectMapper = new ObjectMapper();
        const returnPeriod = objectMapper.parse<EmployeeVacationPeriodModel>(JSON.stringify({
                startDate: new Date(period.startDate),
                endDate: new Date(period.endDate)
            }), {mainCreator: () => [EmployeeVacationPeriodModel]}
        );
        return returnPeriod;
    }

    public static toService(period: EmployeeVacationPeriodModel): any {
        return {
            startDate: period.startDate,
            endDate: period.endDate
        }
    }

}
