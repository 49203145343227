import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, mergeMap, switchMap} from 'rxjs/operators';
import {OrderManagementApiService} from '../../../services/order-management-api.service';
import {
  addressTypeSavedAction, addressTypesForAllCompaniesLoadedAction,
  addressTypesLoadedAction,
  loadAllAddressTypesAction,
  loadAllAddressTypesForAlLCompaniesAction,
  saveAddressTypeAction
} from './addresstype.actions';
import {AddressType} from '../addressType.model';

@Injectable()
export class AddressTypeEffects {
  constructor(
    private httpApiService: OrderManagementApiService,
    private actions$: Actions) {}

  loadAddressTypes$ = createEffect(() => this.actions$.pipe(
    ofType(loadAllAddressTypesAction),
    mergeMap(() => this.httpApiService.getAllAddressTypes()
      .pipe(
        map(addressTypes => addressTypesLoadedAction({payload: addressTypes}))
      ))
  ));

  loadAddressTypesForAllCompanies$ = createEffect(() => this.actions$.pipe(
    ofType(loadAllAddressTypesForAlLCompaniesAction),
    mergeMap(() => this.httpApiService.getAllAddressTypesWithoutCompany()
      .pipe(
        map(addressTypes => addressTypesForAllCompaniesLoadedAction({payload: addressTypes}))
      ))
  ));

  saveAddressType$ = createEffect(() => this.actions$.pipe(
    ofType(saveAddressTypeAction),
    switchMap(({payload, companyId}) => this.httpApiService.addAddressType(payload, companyId)
      .pipe(
        map(( addressType: AddressType) => {
          return addressTypeSavedAction({payload: addressType});
          }
        ))
    )));
}
