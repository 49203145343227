import { OAuthModuleConfig } from 'angular-oauth2-oidc';

export const authModuleConfig: OAuthModuleConfig = {
  resourceServer: {
    allowedUrls: ['https://order.upspot.de', 'https://ordertest.upspot.de',
      'https://user.upspot.de', 'https://usertest.upspot.de',
      'https://document.upspot.de', 'https://documenttest.upspot.de',
      'https://notification.upspot.de', 'https://notificationtest.upspot.de',
      'https://ui.upspot.de', 'https://uitest.upspot.de',
      'https://employee.upspot.de', 'https://employeetest.upspot.de',],
    sendAccessToken: true,
  }
};
