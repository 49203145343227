import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {MessageConfigurations} from '../../../core/models/messageConfiguration/messageConfigurations.model';
import {configurationSelect, MessageConfigState} from '../../../core/models/messageConfiguration/store';
import {Store} from '@ngrx/store';
import {
  configurationLoadedAction,
  loadConfigurationAction,
  saveConfigurationAction
} from '../../../core/models/messageConfiguration/store/messageConfiguration.actions';
import {User} from '../../../core/models/user/user.model';
import {MessageConfiguration} from '../../../core/models/messageConfiguration/messageConfiguration.model';
import {selectedUserSelect, UserState} from '../../../core/models/user/store';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent implements OnInit, OnDestroy {
  user$: Observable<User> = this.userStore.select(selectedUserSelect);
  userSub: Subscription;
  user: User;

  messageConfig$: Observable<MessageConfigurations> = this.messageConfigStore.select(configurationSelect);
  messageConfigSub: Subscription;
  messageConfig: MessageConfigurations;

  constructor(
    private messageConfigStore: Store<MessageConfigState>,
    private userStore: Store<UserState>) {
    this.userSub = this.user$.subscribe(user => {
        this.user = null;
        if (user && user.userId) {
          this.user = user;
          this.messageConfigStore.dispatch(loadConfigurationAction({userId: this.user?.emailAddress}));
        }
      }
    );
  }

  ngOnInit(): void {
    this.messageConfigSub = this.messageConfig$.subscribe(
      (configs) => {
        if (configs) {
          this.messageConfig = configs;
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.messageConfigSub.unsubscribe();
    this.messageConfigStore.dispatch(configurationLoadedAction({payload: null}));
    this.userSub.unsubscribe();
  }

  change(event, messageConfig: MessageConfiguration) {
    const configArray = [];

    this.messageConfig.configuration.forEach(config => {
      configArray.push({...config});
    });

    const toModify = configArray.find(findConfig => findConfig.messageType === messageConfig.messageType);
    const index = configArray.indexOf(toModify);
    toModify.forwardType = event;
    configArray.splice(index, 1);
    configArray.push(toModify);
    const configs = {...this.messageConfig, configuration: configArray};

    this.messageConfigStore.dispatch(saveConfigurationAction({payload: configs}));
  }
}
