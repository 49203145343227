import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';

export class ExternalIdModel {
  @JsonProperty() @JsonClassType({type: () => [String]})
  id: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  provider: string;

  constructor(
    id: string,
    provider: string
  ){
    this.id = id;
    this.provider = provider;
  }

  public static fromService(externalId: any): ExternalIdModel {
    if ( externalId) {
      const objectMapper = new ObjectMapper();
      return objectMapper.parse<ExternalIdModel>( JSON.stringify( {
        id: externalId.id,
        provider: externalId.provider
      }), {mainCreator: () => [ExternalIdModel]});
    } else {
      return null;
    }
  }

  public static toService(id: ExternalIdModel): any {
    if (id) {
      return {
        id: id.id,
        provider: id.provider
      };
    } else {
      return null;
    }
  }
}
