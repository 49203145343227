import {ChatMessage} from '../chatMessage.model';
import {TaskStateEnum} from './taskStateEnum';
import {User} from '../user/user.model';
import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';
import {FormTemplateModel} from "../form/formTemplate.model";
import {TaskType} from "./tasktype/tasktype.model";
import {OrderType} from "./ordertype/ordertype.model";
import {Order} from "./order.model";

export class Task {
  @JsonProperty() @JsonClassType({type: () => [String]})
  id: string;
  @JsonProperty() @JsonClassType({type: () => [TaskType]})
  taskType: TaskType;
  @JsonProperty() @JsonClassType({type: () => [Date]})
  startDate: Date;
  @JsonProperty() @JsonClassType({type: () => [Date]})
  endDate: Date;
  @JsonProperty() @JsonClassType({type: () => [Array, [ChatMessage]]})
  chatMessages: ChatMessage[] = [];
  @JsonProperty() @JsonClassType({type: () => [String]})
  description: string;
  @JsonProperty() @JsonClassType({type: () => [Number]})
  sortOrder: number;
  @JsonProperty() @JsonClassType({type: () => [Array, [String]]})
  userIds: string[] = [];
  @JsonProperty() @JsonClassType({type: () => [String]})
  state: TaskStateEnum;
  @JsonProperty() @JsonClassType({type: () => [Array, [String]]})
  allowedStateTransitions: TaskStateEnum[] = [];
  @JsonProperty() @JsonClassType({type: () => [Array, [FormTemplateModel]]})
  formTemplate: FormTemplateModel[];
  order: Order;

  constructor(
    id: string,
    @JsonClassType({type: () => [TaskType]})taskType: TaskType,
    startDate: Date,
    endDate: Date,
    description: string,
    sortOrder: number,
    state: TaskStateEnum,
  ){
    this.id = id;
    this.taskType = taskType;
    this.startDate = startDate;
    this.endDate = endDate;
    this.description = description;
    this.sortOrder = sortOrder;
    this.state = state;
  }

  public static fromService(task: any, users: User[], taskTypes: TaskType[]): Task {
    const allowedStateTransitions = [];
    const taskType = taskTypes?.find(value => value.id === task.taskTypeId);
    const state = task.state ?? 'UNSCHEDULED';
    task.allowedStateTransitions?.forEach( state => {
      allowedStateTransitions.push(state);
    });
    let startDate = new Date(task.startDateTime);
    let endDate = new Date(task.endDateTime);
    if ( endDate.toDateString() === new Date(0).toDateString()) {
      endDate = startDate;
    }
    if( startDate.toDateString() === new Date(0).toDateString() ) {
      startDate = null;
    }
    if( endDate.toDateString() === new Date(0).toDateString() ) {
      endDate = null;
    }

    const objectMapper = new ObjectMapper();
    return objectMapper.parse<Task>( JSON.stringify( {
      id: task.id,
      taskType,
      startDate,
      endDate,
      chatMessages: ChatMessage.fromServiceArray(task.comments, users),
      description: task.description,
      sortOrder: task.sortOrder,
      userIds: task.userIds,
      state,
      formTemplate: FormTemplateModel.fromService(task.formTemplate),
      allowedStateTransitions}), {mainCreator: () => [Task]}
    );
  }

  public static toService(task: Task): any {
    return {
      id: task.id,
      taskTypeId: task.taskType?.id,
      startDateTime: task.startDate?.toISOString(),
      endDateTime: task.endDate?.toISOString(),
      comments: ChatMessage.toServiceArray(task.chatMessages),
      description: task.description,
      sortOrder: task.sortOrder,
      userIds: task.userIds,
      formTemplate: FormTemplateModel.toService(task.formTemplate),
      state: (Number.isInteger(task.state) ? TaskStateEnum[task.state] : task.state)
    };
  }

  public static fromServiceArray(tasksArray: any, users: User[], taskTypes: TaskType[]): Task[] {
    const tasks = [];
    if (tasksArray) {
      for (const task of tasksArray) {
        tasks.push(Task.fromService(task, users, taskTypes));
      }
    }
    return tasks;
  }

  public static toServiceArray(tasksArray: Task[]): any {
    const tasks = [];
    if (tasksArray) {
      for (const task of tasksArray) {
        tasks.push(Task.toService(task));
      }
    }
    return tasks;
  }
}
