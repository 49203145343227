import {createSelector} from '@ngrx/store';
import {ReducerOrderState} from './order.reducer';

export interface OrderState {
  order: ReducerOrderState;
}

export const selectOrderState = (state: OrderState) => state.order;

export const selectedOrderSelect = createSelector(
  selectOrderState,
  (state) => state.selectedOrder
);

export const filesOfSelectedOrderSelect = createSelector(
  selectOrderState,
  (state) => state.filesOfSelectedOrder
);

export const allOrdersByPeriodSelect = createSelector(
  selectOrderState,
  (state) => state.ordersByPeriod
);

export const allOrdersSelect = createSelector(
  selectOrderState,
  (state) => state.orders
);

export const uploadedImageSelect = createSelector(
    selectOrderState,
    (state) => state.uploadedImage
);

