import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {User} from '../../../core/models/user/user.model';
import {Group} from '../../../core/models/user/group.model';
import {UserManagementApiService} from '../../../core/services/user-management-api.service';
import {selectedUserSelect, UserState} from '../../../core/models/user/store';
import {Store} from '@ngrx/store';
import {saveUserAction, userResendMailAction, userSelectedAction} from '../../../core/models/user/store/user.actions';
import {Observable, Subscription} from 'rxjs';
import {activeCompanySelect, allCompaniesSelect, CompanyState} from '../../../core/models/company/store';
import {Company} from '../../../core/models/company/company.model';
import {CustomEmailValidator} from '../../../core/validator/customEmail.validator';
import {ObjectMapper} from 'jackson-js';
import {configurationSelect, MessageConfigState} from '../../../core/models/messageConfiguration/store';
import {configurationLoadedAction} from '../../../core/models/messageConfiguration/store/messageConfiguration.actions';
import {MessageConfigurations} from '../../../core/models/messageConfiguration/messageConfigurations.model';
@Component({
    selector: 'app-person-edit',
    templateUrl: './person-edit.component.html'
})
export class PersonEditComponent implements OnInit, OnDestroy, AfterViewInit {
    personForm: FormGroup;
    selectedColor: string;
    groups: Group[] = [];
    selectedUser$: Observable<User> = this.userStore.select(selectedUserSelect);
    selectedUserSub: Subscription;
    selectedUser: User;

    activeCompany$: Observable<Company> = this.companyStore.select(activeCompanySelect);
    activeCompanySub: Subscription;
    activeCompany: Company;

    companies$: Observable<Company[]> = this.companyStore.select(allCompaniesSelect);
    companiesSub: Subscription;
    companies: Company[];

    messageConfig$: Observable<MessageConfigurations> = this.messageConfigStore.select(configurationSelect);
    messageConfigSub: Subscription;
    messageConfig: MessageConfigurations;
    companiesForTag = [];

    constructor(
        private httpApiAService: UserManagementApiService,
        private userStore: Store<UserState>,
        private companyStore: Store<CompanyState>,
        private messageConfigStore: Store<MessageConfigState>,
        private emailValidator: CustomEmailValidator
    ) {
        //this.userStore.dispatch(userSelectedAction({payload: null}));

        this.activeCompanySub = this.activeCompany$.subscribe(
            company => {
                if (company) {
                    this.activeCompany = company;
                    this.addDefaultCompanyToForm();
                }
            }
        );

        this.companiesSub = this.companies$.subscribe(
            (companies: Company[]) => {
                this.companies = companies;
                this.companiesForTag = [];
                for (const company of companies) {
                    const name = company.address.companyName;
                    const companiesForTag = {
                        display: name,
                        value: company.companyId
                    };
                    this.companiesForTag.push(companiesForTag);
                }
            }
        );

        this.messageConfigSub = this.messageConfig$.subscribe(
            (config) => {
                this.messageConfig = config;
            }
        );
    }

    addDefaultCompanyToForm(): void {
        const companies = [];
        const companyId = this.companiesForTag.find(value => value?.value === this.activeCompany?.companyId);
        if (companyId) {
            companies.push(companyId.value);
        }
        if (companies.length > 0) {
            this.personForm.patchValue({companies});
        }
    }

    ngOnDestroy(): void {
        this.selectedUserSub.unsubscribe();
        this.activeCompanySub.unsubscribe();
        this.messageConfigSub.unsubscribe();
        this.userStore.dispatch(userSelectedAction({payload: null}));
        this.messageConfigStore.dispatch(configurationLoadedAction({payload: null}));
    }

    ngAfterViewInit(): void {
    }

    ngOnInit(): void {
        this.httpApiAService.getAllGroups().subscribe(
            (groups: Group[]) => this.groups = groups
        );

        this.personForm = new FormGroup({
            tenantId: new FormControl(),
            userId: new FormControl(),
            firstName: new FormControl('', Validators.required),
            lastName: new FormControl('', Validators.required),
            emailAddress: new FormControl('', [Validators.required, Validators.email], [this.emailValidator.existingEmailValidator(this.getDefaultEmail.bind(this))]),
            initials: new FormControl('', Validators.required),
            color: new FormControl('', Validators.required),
            group: new FormControl(),
            roles: new FormControl(),
            companies: new FormControl()
        });

        this.selectedUserSub = this.selectedUser$.subscribe(
            (user: User) => this.selectPerson(user)
        );
    }

    getDefaultEmail(): string {
        return this.selectedUser?.emailAddress;
    }

    savePerson(): void {
        const objectMapper = new ObjectMapper();
        const newPerson = this.personForm.value;
        newPerson.defaultCompany = this.activeCompany?.companyId;
        const saveUser = objectMapper.parse<User>(JSON.stringify(newPerson), {mainCreator: () => [User]});
        this.userStore.dispatch(saveUserAction({payload: saveUser}));
        // this.resetForm();
    }

    resetForm(): void {
        this.personForm.reset();
        this.addDefaultCompanyToForm();
    }

    selectPerson(person: User): void {
        this.selectedUser = null;
        if (person) {
            this.selectedUser = person;
            this.personForm.patchValue(person);

            const companies = [];
            person?.companies?.forEach(company => {
                    const companyId = this.companiesForTag.find(value => value?.value === company?.companyId);
                    if (companyId) {
                        companies.push(companyId.value);
                    }
                }
            );
            this.personForm.patchValue({companies});
        }
    }

    nameChanged(): void {
        const firstName = this.personForm.value.firstName;
        const lastName = this.personForm.value.lastName;

        if (firstName != null && firstName !== '' && lastName != null && lastName !== '') {
            const initials = firstName.substring(0, 1) + lastName.substring(0, 1);
            this.personForm.patchValue({
                initials: initials.toUpperCase(),
            });
        }
    }

    emailChanged(): void {
        const color = this.personForm.get('color').value;
        if (!color) {
            this.selectedColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
            this.personForm.patchValue({
                color: this.selectedColor
            });
        }
    }

    colorChanged(selectedColor: string): void {
        this.selectedColor = selectedColor;
        this.personForm.patchValue({
            color: selectedColor
        });
    }

    compare(val1, val2): boolean {
        return val1 && val2 && val1.name === val2.name;
    }

    resendEmails(): void {
        this.userStore.dispatch(userResendMailAction({payload: this.selectedUser}));
    }
}
