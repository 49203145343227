<div class="card" id="contactList">
  <div class="card-header">
    <div class="row g-3">
      <div class="col-md-4">
        <div class="search-box">
          <input type="text" name="searchTerm" class="form-control" placeholder="{{ 'labels.search' | translate }}" (keyup)="updateFilter($event)">
          <i class="ri-search-line search-icon"></i>
        </div>
      </div>
      <div class="col-md-auto ms-auto">
        <div class="d-flex align-items-center gap-2">
          <button (click)="newContactType()" type="button" class="btn btn-primary"><i
                  class="ri-add-circle-line align-middle me-1"></i> {{ 'labels.addNewContactType' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div>
      <div class="table-responsive table-card mb-2">
        <table class="table table-hover">
          <thead>
          <tr class="bg-light">
            <th scope="col">{{ 'labels.description' | translate }}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let contactType of contactTypesFiltered" class="cursor-pointer" (click)="onSelect(contactType)">
            <td><ngb-highlight [result]="contactType.description" [term]="searchTerm"></ngb-highlight></td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="row justify-content-md-between align-items-md-center">
        <div class="col col-sm-6">
          <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
            {{'labels.showing' | translate}}
            {{ contactTypesFiltered?.length}} / {{contactTypes?.length}}
            {{'labels.entries' | translate}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div><!--end card-->
