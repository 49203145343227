<div>
  <div class="row mb-4">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body" id="welcomeCard">
          <div class="card-title"><h4>{{ 'welcomecomponent.header' | translate }}</h4></div>
          {{ 'welcomecomponent.body' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>


<app-company-edit></app-company-edit>
<app-company-list></app-company-list>

