import {Customer} from "../../customer/customer.model";

export class OrderStateOverview {
  constructor(
    public orderId: string,
    public customerId: string,
    public companyId: string,
    public customer: Customer
  ){}
}
