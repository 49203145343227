import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';
import {BatchTimeStamps} from "./batch-timestamps.model";
import {BatchProgress} from "./progress.model";

export class BatchModel {
    @JsonProperty() @JsonClassType({type: () => [String]})
    type: String;
    @JsonProperty() @JsonClassType({type: () => [String]})
    state: String;
    @JsonProperty() @JsonClassType({type: () => [BatchProgress]})
    progress: BatchProgress;
    @JsonProperty() @JsonClassType({type: () => [BatchTimeStamps]})
    timestamps: BatchTimeStamps;

    constructor(
        type: string,
        state: string,
        progress: BatchProgress,
        timestamps: BatchTimeStamps
    ) {
        this.type = type;
        this.state = state;
        this.progress = progress;
        this.timestamps = timestamps
    }
    public static fromService(batch: any): BatchModel {
        const objectMapper = new ObjectMapper();
        return objectMapper.parse<BatchModel>(JSON.stringify({
                type: batch.type,
                state: batch.state,
                progress: BatchProgress.fromService(batch.progress),
                timestamps: BatchTimeStamps.fromService(batch.timestamps)
            }
        ), {mainCreator: () => [BatchModel]});
    }
}
