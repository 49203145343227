import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';

export class ContactType {

  @JsonProperty() @JsonClassType({type: () => [String]})
  id: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  description: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  companyId: string;

  constructor(
    id: string,
    description: string,
    companyId: string
  ) {
    this.id = id;
    this.description = description;
    this.companyId = companyId;
  }

  public static fromServiceArray(contactTypeArray: any): ContactType[] {
    const contactTypes = [];
    for (const contactType of contactTypeArray) {
      contactTypes.push(ContactType.fromService(contactType));
    }
    return contactTypes;
  }

  public static fromService(contactType: any): ContactType {
    const objectMapper = new ObjectMapper();
    return objectMapper.parse<ContactType>(JSON.stringify({
      id: contactType.id,
      description: contactType.description,
      companyId: contactType.companyId
    }), {mainCreator: () => [ContactType]});
  }

  public static toService(contactType: ContactType, companyId: string): any {
    return {
      id: contactType.id,
      description: contactType.description,
      companyId
    };
  }
}
