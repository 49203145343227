import {Action, createReducer, on} from '@ngrx/store';
import * as MessageActions from './messageConfiguration.actions';
import {MessageConfigurations} from '../messageConfigurations.model';

export interface ReducerMessageConfigurationState {
  configuration: MessageConfigurations;
}

export const initialMessageState: ReducerMessageConfigurationState = {
  configuration: null
};

const messageConfigurationReducer = createReducer(
  initialMessageState,
  on(MessageActions.configurationLoadedAction, (state, {payload}) => ({...state, configuration: payload})),
  on(MessageActions.ConfigurationSavedAction, (state, {payload}) => ({...state, configuration: payload})),
);

export function reducer(state: ReducerMessageConfigurationState | undefined, action: Action): ReducerMessageConfigurationState {
  return messageConfigurationReducer(state, action);
}
