import {Address} from '../../address.model';
import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';
import {ExternalIdModel} from "../externalId.model";

export class CustomerOverview {

  @JsonProperty() @JsonClassType({type: () => [String]})
  customerId: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  description: string;
  @JsonProperty() @JsonClassType({type: () => [ExternalIdModel]})
  externalId: ExternalIdModel;
  @JsonProperty() @JsonClassType({type: () => [Address]})
  mainAddress: Address;
  @JsonProperty() @JsonClassType({type: () => [Number]})
  numberOfOrders: number;
  @JsonProperty() @JsonClassType({type: () => [Number]})
  numberOfAddresses: number;

  constructor(
    customerId: string,
    description: string,
    externalId: ExternalIdModel,
    @JsonClassType({type: () => [Address]}) mainAddress: Address,
    numberOfOrders: number,
    numberOfAddresses: number
  ){
    this.customerId = customerId;
    this.description = description;
    this.externalId = externalId;
    this.mainAddress = mainAddress;
    this.numberOfOrders = numberOfOrders;
    this.numberOfAddresses = numberOfAddresses;
  }

  public static fromService(overview: any): CustomerOverview {
    const objectMapper = new ObjectMapper();
    return objectMapper.parse<CustomerOverview>(JSON.stringify({
      customerId: overview.customerId,
      description: overview.description,
      externalId: ExternalIdModel.fromService(overview.externalId),
      mainAddress: new Address(null,
        overview.mainAddress?.firstName,
        overview.mainAddress?.name,
        overview.mainAddress?.companyName,
        overview.mainAddress?.city,
        overview.mainAddress?.zipcode,
        overview.mainAddress?.street,
        null, null, null),
      numberOfOrders: overview.numberOfOrders,
      numberOfAddresses: overview.numberOfAddresses}), {mainCreator: () => [CustomerOverview]});
  }
}
