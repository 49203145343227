import {Address} from '../address.model';
import {ContactPerson} from '../contactperson.model';
import {Contact} from '../contact.model';
import {ContactType} from '../contacttype/contactType.model';
import {User} from '../user/user.model';
import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';
import {TaskType} from "../order/tasktype/tasktype.model";
import {Order} from "../order/order.model";
import {AddressType} from "../addresstype/addressType.model";
import {ExternalIdModel} from "./externalId.model";

export class CustomerHistoryModel {
  @JsonProperty() @JsonClassType({type: () => [String]})
  id: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  scope: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  eventDescription: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  changeDescription: string;
  @JsonProperty() @JsonClassType({type: () => [Date]})
  modificationTime: Date;
  @JsonProperty() @JsonClassType({type: () => [String]})
  changedBy: string;

  constructor(
    id: string,
    scope: string,
    eventDescription: string,
    changeDescription: string,
    modificationTime: Date,
    changedBy: string
  ){
    this.id = id;
    this.scope = scope;
    this.changeDescription = changeDescription;
    this.eventDescription = eventDescription;
    this.modificationTime = modificationTime;
    this.changedBy = changedBy;
  }

  public static fromService(history: any): CustomerHistoryModel {
    if ( history) {
      const objectMapper = new ObjectMapper();
      return objectMapper.parse<CustomerHistoryModel>( JSON.stringify( {
        id: history.id,
        scope: history.scope,
        changeDescription: history.changeDescription,
        eventDescription: history.eventDescription,
        modificationTime: history.modificationTime,
        changedBy: history.changedBy
      }), {mainCreator: () => [CustomerHistoryModel]});
    } else {
      return null;
    }
  }

  public static fromServiceArray(historyArray: any): CustomerHistoryModel[] {
    const _historyArray = [];
    if (historyArray) {
      for (const history of historyArray) {
        _historyArray.push(CustomerHistoryModel.fromService(history));
      }
    }
    return _historyArray;
  }
}
