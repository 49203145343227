import {createAction, props} from '@ngrx/store';
import {CustomerOverview} from '../customerOverview.model';
import {Page} from '../../../page/page.model';

export const loadCustomerOverviewAction = createAction(
  '[CUSTOMEROVERVIEW] loadCustomerOverviewAction', props<{page: number, size: number, companyId: string, customerId: string}>());
export const findCustomerOverviewAction = createAction(
    '[CUSTOMEROVERVIEW] findCustomerOverviewAction', props<{page: number, size: number, companyId: string, search: string}>());
export const customersOverviewLoadedAction = createAction(
  '[CUSTOMEROVERVIEW] customersOverviewLoadedAction', props<{payload: Page<CustomerOverview>}>());
