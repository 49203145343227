import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbNavModule, NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';

import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import {TranslateModule} from "@ngx-translate/core";
import {PerfectScrollbarModule} from "ngx-perfect-scrollbar";

@NgModule({
  declarations: [
    BreadcrumbsComponent,
  ],
    imports: [
        CommonModule,
        PerfectScrollbarModule,
        NgbNavModule,
        NgbAccordionModule,
        TranslateModule
    ],
  exports: [BreadcrumbsComponent]
})
export class SharedModule { }
