<div class="row">
    <div class="col-lg-12">
        <div class="card rounded-0 bg-soft-success mx-n4 mt-n4 border-top">
            <div class="px-4">
                <div class="row">
                    <div class="col-xxl-5 align-self-center">
                        <div class="py-4">
                            <h4 class="display-6 coming-soon-text">Häufig gestellte Fragen</h4>
                            <p class="text-success fs-15 mt-3">Wenn Sie keine Antwort auf Ihre Frage in unseren FAQ finden, können Sie uns jederzeit eine E-Mail schicken. Wir werden Ihnen in Kürze antworten!</p>
                            <div class="hstack flex-wrap gap-2">
                                <button type="button" class="btn btn-primary btn-label rounded-pill"><i class="ri-mail-line label-icon align-middle rounded-pill fs-16 me-2"></i>Frage per E-Mail</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-xxl-3 ms-auto">
                        <div class="mb-n5 pb-1 faq-img d-none d-xxl-block">
                            <img src="assets/images/faq-img.png" alt="" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
            <!-- end card body -->
        </div>
        <!-- end card -->


        <div class="row justify-content-evenly mb-3">
            <ng-container *ngIf="faqs$">
                <ng-container *ngFor="let category of faqs$|async">
                    <div class="col-lg-4">
                        <div class="mt-3">
                            <div class="d-flex align-items-center mb-2">
                                <div class="flex-shrink-0 me-1">
                                    <i class="ri-question-line fs-24 align-middle text-success me-1"></i>
                                </div>
                                <div class="flex-grow-1">
                                    <h5 class="fs-16 mb-0 fw-semibold">{{ category.title }}</h5>
                                </div>
                            </div>
                            <ngb-accordion #acc="ngbAccordion" activeIds="static-1" [closeOthers]="true" *ngFor="let document of category.documents; let i = index">
                                <ngb-panel id="static-{{i}}">
                                    <ng-template ngbPanelTitle class="accordion-header" id="gen-ques-headingOne">
                                        <button class="border-0 bg-transparent" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#gen-ques-collapseOne" aria-expanded="false"
                                                aria-controls="gen-ques-collapseOne">
                                            {{document.title}}
                                        </button>
                                    </ng-template>
                                    <ng-template ngbPanelContent>
                                        <div class="accordion-body" *ngFor="let entry of document.entries">
                                            {{entry.text}}
                                        </div>
                                    </ng-template>
                                </ngb-panel>
                            </ngb-accordion>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div><!--end col-->
</div><!--end row-->
