import {createSelector} from '@ngrx/store';
import {ReducerCustomerOverviewState} from './customerOverview.reducer';

export interface CustomerOverviewState {
  customerOverview: ReducerCustomerOverviewState;
}

export const selectCustomerOverviewState = (state: CustomerOverviewState) => state.customerOverview;

export const allCustomersOverviewSelect = createSelector(
  selectCustomerOverviewState,
  (state) => state.page
);
