import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';

export class MessageStatus {
  @JsonProperty() @JsonClassType({type: () => [String]})
  status: string;
  @JsonProperty() @JsonClassType({type: () => [Number]})
  count: number;

  constructor( status: string, count: number) {
    this.status = status;
    this.count = count;
  }

  public static fromServiceArray(summaryArray: any): MessageStatus[] {
    const returnSummaryArray: MessageStatus[] = [];
    if (summaryArray) {
      for (const summary of summaryArray) {
        returnSummaryArray.push(MessageStatus.fromService(summary));
      }
    }
    return returnSummaryArray;
  }

  public static fromService(messageSummary: any): MessageStatus {
    if (messageSummary) {
      const objectMapper = new ObjectMapper();
      const summary = objectMapper.parse<MessageStatus>(JSON.stringify({
            status: messageSummary.status,
            count: messageSummary.count
          }), {mainCreator: () => [MessageStatus]}
      );
      return summary;
    } else {
      return null;
    }
  }
}
