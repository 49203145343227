import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, mergeMap} from 'rxjs/operators';
import {
    FAQsLoadedAction,
    loadFAQsAction
} from "./faq.actions";
import {UiManagementApiService} from "../../../services/ui-management-api.service";

@Injectable()
export class FaqEffects {
  constructor(
    private httpApiService: UiManagementApiService,
    private actions$: Actions) {
  }

  loadFaqs$ = createEffect(() => this.actions$.pipe(
    ofType(loadFAQsAction),
    mergeMap(() => this.httpApiService.loadFAQs()
      .pipe(
        map(faqs => (FAQsLoadedAction({payload: faqs})))
      ))
  ));
}
