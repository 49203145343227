import {Contact} from './contact.model';
import {ContactType} from './contacttype/contactType.model';
import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';

export class ContactPerson {

  @JsonProperty() @JsonClassType({type: () => [String]})
  contactPersonId: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  name: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  firstName: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  personRole: string;
  @JsonProperty() @JsonClassType({type: () => [Array, [Contact]]})
  contactPersonContacts: Contact[];

  constructor(
    contactPersonId: string,
    name: string,
    firstName: string,
    personRole: string,
    contactPersonContacts: Contact[]
  ) {
    this.contactPersonId = contactPersonId;
    this.name = name;
    this.firstName = firstName;
    this.personRole = personRole;
    this.contactPersonContacts = contactPersonContacts;
  }

  public static fromServiceArray(contactPersonsArray: any, contactTypes: ContactType[]): ContactPerson[] {
    const contactPersons = [];
    if (contactPersonsArray) {
      for (const contactPerson of contactPersonsArray) {
        contactPersons.push(ContactPerson.fromService(contactPerson, contactTypes));
      }
    }
    return contactPersons;
  }

  public static fromService(contactPerson: any, contactTypes: ContactType[]): ContactPerson {
    const contactPersonContactsArray = Contact.fromServiceArray(contactPerson.contacts, contactTypes);
    const objectMapper = new ObjectMapper();

    return objectMapper.parse<ContactPerson>(JSON.stringify({
      contactPersonId: contactPerson.id,
      name: contactPerson.name,
      firstName: contactPerson.firstName,
      personRole: contactPerson.personRole,
      contactPersonContacts: contactPersonContactsArray
    }), {mainCreator: () => [ContactPerson]});
  }

  public static toService(contactPerson: ContactPerson): any {
    const contactPersonContactsArray = Contact.toServiceArray(contactPerson.contactPersonContacts);
    return {
      id: contactPerson.contactPersonId,
      name: contactPerson.name,
      firstName: contactPerson.firstName,
      personRole: contactPerson.personRole,
      contacts: contactPersonContactsArray
    };
  }

  public static toServiceArray(contactPersonsArray: ContactPerson[]): any {
    const contactPersons = [];
    if (contactPersonsArray) {
      for (const contactPerson of contactPersonsArray) {
        contactPersons.push(ContactPerson.toService(contactPerson));
      }
    }
    return contactPersons;
  }
}
