import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import {allTaskTypesSelect, TaskTypeState} from "../../../core/models/order/tasktype/store";
import {TaskType} from "../../../core/models/order/tasktype/tasktype.model";
import {
  taskTypeSelectedAction
} from "../../../core/models/order/tasktype/store/taskype.actions";
import {NgbModalRef} from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Company} from "../../../core/models/company/company.model";
import {activeCompanySelect, CompanyState} from "../../../core/models/company/store";
import {TaskTypeEditModalDialogComponent} from "../tasktype-edit-modal-dialog/task-type-edit-modal-dialog.component";

@Component({
  selector: 'app-task-type-list',
  templateUrl: './task-type-list.component.html'
})
export class TaskTypeListComponent implements OnInit, OnDestroy {
  taskTypes$: Observable<TaskType[]> = this.taskTypeStore.select(allTaskTypesSelect);

  taskTypesSubscription: Subscription;

  taskTypes: TaskType[];
  taskTypesFiltered: TaskType[];

  loadingIndicator = true;

  taskTypeModal: NgbModalRef;

  activeCompany$: Observable<Company> = this.companyStore.select(activeCompanySelect);
  activeCompanySubscription: Subscription;
  activeCompany: Company;

  searchTerm: string;

  constructor(private taskTypeStore: Store<TaskTypeState>,
              private modalService: NgbModal,
              private companyStore: Store<CompanyState>,) { }

  ngOnInit(): void {
    this.activeCompanySubscription = this.activeCompany$.subscribe(company => {
      if (company) {
        this.activeCompany = company;
      }
    });

    this.taskTypesSubscription = this.taskTypes$.subscribe(taskTypes => {
      this.taskTypes = taskTypes;
      this.taskTypesFiltered = taskTypes;
    });
  }

  updateFilter(event): void {
    let val = event.target.value.toLowerCase();
    this.searchTerm = val;
    if (val.length > 0) {
      this.taskTypesFiltered = this.taskTypes.filter(type => type.description.toLowerCase().includes(val.toLowerCase()));
    } else {
      this.taskTypesFiltered = this.taskTypes;
    }
  }

  onSelect(selected): void {
    const taskType: TaskType = selected;
    this.taskTypeStore.dispatch(taskTypeSelectedAction({payload: taskType}));
    this.openDialog();
  }

  newTaskType(): void {
    this.taskTypeStore.dispatch(taskTypeSelectedAction({payload: null}));
    this.openDialog();
  }

  openDialog(): void {
    this.taskTypeModal = this.modalService.open(TaskTypeEditModalDialogComponent, {size: 'lg', animation: true, centered: true, backdrop: 'static', keyboard: false});
    this.taskTypeModal.componentInstance.companyId = this.activeCompany.companyId;
  }

  ngOnDestroy(): void {
    this.activeCompanySubscription.unsubscribe();
    this.taskTypesSubscription.unsubscribe();
  }
}
