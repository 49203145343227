import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {AddressType} from '../../../core/models/addresstype/addressType.model';
import {AddressTypeState, selectedAddressTypeSelect} from '../../../core/models/addresstype/store';
import {addressTypeSelectedAction, saveAddressTypeAction} from '../../../core/models/addresstype/store/addresstype.actions';
import {ObjectMapper} from 'jackson-js';

@Component({
  selector: 'app-addresstype-edit',
  templateUrl: './addresstype-edit.component.html',
  styleUrls: ['./addresstype-edit.component.scss']
})
export class AddresstypeEditComponent implements OnInit, OnDestroy, AfterViewInit{
  addressTypeForm: FormGroup;
  selectedAddressType$: Observable<AddressType> = this.addressTypeStore.select(selectedAddressTypeSelect);
  selectedAddressTypeSub: Subscription;
  selectedAddressType: AddressType;

  @Input() autoFocus = false;
  @ViewChild('description') description: ElementRef;
  @Input() resetSelectedAfterSave = false;
  @Input() companyId = null;
  @Output() saved = new EventEmitter<boolean>();

  save = false;
  constructor(
    private addressTypeStore: Store<AddressTypeState>
  ) { }

  ngOnInit(): void {
    this.addressTypeForm = new FormGroup( {
      id: new FormControl(),
      description: new FormControl(null, Validators.required)
    });

    this.selectedAddressTypeSub = this.selectedAddressType$.subscribe(
        (addressType: AddressType) => this.selectAddressType(addressType)
    );

  }

  saveAddressType(): void {
    const objectMapper = new ObjectMapper();
    const addressType: AddressType = objectMapper.parse<AddressType>(JSON.stringify(this.addressTypeForm.value), {mainCreator: () => [AddressType]});

    this.addressTypeStore.dispatch(saveAddressTypeAction({payload: addressType, companyId: this.companyId}));
    this.saved.emit(true);

    if (this.resetSelectedAfterSave) {
      this.save = true;
    }
    this.resetForm();
  }

  selectAddressType(addressType: AddressType): void {
    if (addressType) {
      if ( this.save) {
        this.addressTypeStore.dispatch(addressTypeSelectedAction({payload: null}));
        this.save = false;
      }
      this.addressTypeForm.patchValue(addressType);
      this.selectedAddressType = addressType;
    } else {
      this.resetForm();
    }
  }

  resetForm(): void {
    this.addressTypeForm?.reset();
    this.selectedAddressType = null;
  }

  ngOnDestroy(): void {
    this.selectedAddressTypeSub.unsubscribe();
    //this.addressTypeStore.dispatch(addressTypeSelectedAction({payload: null}));
  }

  ngAfterViewInit(): void {
    if (this.description && this.autoFocus) {
      this.description.nativeElement.focus();
    }
  }
}
