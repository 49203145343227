import { Pipe, PipeTransform } from '@angular/core';
import {TaskStateEnum, TaskStateLabel} from '../models/order/taskStateEnum';
@Pipe({
  name: 'taskStatus'
})
export class TaskStatusPipe implements PipeTransform {
  transform(state: string): string {
    if (state) {
      return TaskStateLabel.get(TaskStateEnum[state]);
    } else {
      return TaskStateLabel.get(TaskStateEnum.UNSCHEDULED);
    }
  }
}
