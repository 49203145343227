import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';
import {ExternalIdModel} from "../customer/externalId.model";
import {Address} from "../address.model";
import {Contact} from "../contact.model";
import {ContactPerson} from "../contactperson.model";

export class EmployeeVacationValidFromModel {
    @JsonProperty()
    @JsonClassType({type: () => [Number]})
    year: number;

    @JsonProperty()
    @JsonClassType({type: () => [Number]})
    month: number;

    constructor(year: number, month: number) {
        this.year = year;
        this.month = month;
    }

    public static fromService(validFrom: any): EmployeeVacationValidFromModel {
        const objectMapper = new ObjectMapper();
        const returnAuthor = objectMapper.parse<EmployeeVacationValidFromModel>(JSON.stringify({
                year: validFrom.year,
                month: validFrom.month
            }), {mainCreator: () => [EmployeeVacationValidFromModel]}
        );
        return returnAuthor;
    }

    public static toService(validFrom: EmployeeVacationValidFromModel): any {
        return {
            year: validFrom.year,
            month: validFrom.month
        };
    }

}
