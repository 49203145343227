import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, switchMap} from 'rxjs/operators';
import {OrderManagementApiService} from '../../../services/order-management-api.service';
import {
    customerHistoryLoaded,
    customerSavedAction,
    customerSelectedAction,
    loadCustomerHistory,
    saveCustomerAction,
    selectCustomerAction
} from './customer.actions';
import {Customer} from '../customer.model';
import {CustomerHistoryModel} from "../customerHistory.model";

@Injectable()
export class CustomerEffects {
  constructor(
    private httpApiService: OrderManagementApiService,
    private actions$: Actions) {}

  loadCustomer$ = createEffect(() => this.actions$.pipe(
    ofType(selectCustomerAction),
    switchMap(({customerId}) => this.httpApiService.getCustomerById(customerId)
      .pipe(
        map(( customer: Customer) => {
          return customerSelectedAction({payload: customer});
        })
      )
    )));

  saveCustomer$ = createEffect(() => this.actions$.pipe(
    ofType(saveCustomerAction),
    switchMap(({payload}) => this.httpApiService.saveCustomer(payload)
      .pipe(
        map(( customer: Customer) => {
          return customerSavedAction({payload: customer});
        })
      )
    )));

    loadCustomerHistory$ = createEffect(() => this.actions$.pipe(
        ofType(loadCustomerHistory),
        switchMap(({customerId}) => this.httpApiService.getCustomerHistory(customerId)
            .pipe(
                map(( history: CustomerHistoryModel[]) => {
                    return customerHistoryLoaded({history});
                })
            )
        )));
}
