import {ReducerUserState} from './user.reducer';
import {createSelector} from '@ngrx/store';
import {User} from '../user.model';

export interface UserState {
  user: ReducerUserState;
  loggedInUser: User;
}

export const selectUserState = (state: UserState) => state.user;

export const selectedUserSelect = createSelector(
  selectUserState,
  (state) => state.selectedUser
);

export const allUsersSelect = createSelector(
  selectUserState,
  (state) => state.users
);

export const loggedInUserSelect = createSelector(
  selectUserState,
  (state) => state.loggedInUser
);
