<div class="row">
    <div class="col-xl-12">
        <!-- crm-widget style liegt "irgendwo" rum - sollte dann mit zur component -->
        <div class="card crm-widget">
            <div class="card-body p-0">
                <div class="row row-cols-6 g-0">
                    <!-- eigentlich crm-stat.component - etwas ausgedünnt -->
                    <!-- icons im line style, um nicht zu auffällig zu sein-->
                    <ng-container *ngFor="let state of datevImportOverview?.states">
                        <div class="col" *ngIf="!state.ignoreInView" (click)="openErrorView(state.state)" [ngClass]="{'cursor-pointer': state.state === 'ERRONEOUS' && state.records > 0}">
                            <div class="py-md-4 py-1 px-md-3 px-1">
                                <h5 class="text-muted text-uppercase fs-13">{{ 'datevImportState.' + state.state | translate }}</h5>
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0">
                                        <i class="display-6 text-muted" [ngClass]="state.icon"></i>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <h2 class="mb-0">{{state.records}}</h2>
                                    </div>
                                </div>
                            </div>
                        </div><!-- end col -->
                    </ng-container>
                </div><!-- end row -->
            </div><!-- end card body -->
        </div><!-- end card -->
    </div><!-- end col -->
</div>
