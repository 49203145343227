import {User} from '../user.model';
import {Action, createReducer, on} from '@ngrx/store';
import * as UserActions from './user.actions';
import {userResentMaiLAction} from './user.actions';

export interface ReducerUserState {
  users: User[];
  selectedUser: User;
  loggedInUser: User;
}

export const initialUserState: ReducerUserState = {
  users: [],
  selectedUser: null,
  loggedInUser: null
};

const userReducer = createReducer(
  initialUserState,
  on(UserActions.usersLoadedAction, (state, {payload}) => ({...state, users: payload})),
  on(UserActions.userSelectedAction, (state, {payload}) => ({...state, selectedUser: payload})),
  on(UserActions.userSavedAction, (state, {payload}) => ({...state, users: filterUsers(state.users, payload), selectedUser: payload, loggedInUser: setLoggedInUser(state.loggedInUser, payload) })),
  on(UserActions.userLoggedInAction, (state, {payload}) => ({...state, loggedInUser: payload})),
  on(UserActions.userResentMaiLAction, (state, {payload}) => ({...state, loggedInUser: payload})),
);

function filterUsers(stateUsers: User[], newUser: User): User[] {
  if (newUser) {
    if (stateUsers) {
      const users: User[] = stateUsers.filter(x => x.userId !== newUser.userId);
      users.push(newUser);
      return users;
    } else {
      const users: User[] = [];
      users.push(newUser);
      return users;
    }
  }
  return stateUsers;
}

function setLoggedInUser(loggedInUser: User, savedUser: User): User {
  let result: User = loggedInUser;
  if (loggedInUser.userId === savedUser.userId) {
    result = savedUser;
  }
  return result;
}

export function reducer(state: ReducerUserState | undefined, action: Action): ReducerUserState {
  return userReducer(state, action);
}
