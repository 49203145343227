import {JsonClassType, JsonProperty, ObjectMapper} from "jackson-js";
import {Address} from "../address.model";
import {RecordType} from "./datevImportDto.model";

export class DatevUsageTypeModel {

    @JsonProperty() @JsonClassType({type: () => [Boolean]})
    is_correspondence_address: boolean;
    @JsonProperty() @JsonClassType({type: () => [Boolean]})
    is_delivery_address: boolean;
    @JsonProperty() @JsonClassType({type: () => [Boolean]})
    is_main_post_office_box_address: boolean;
    @JsonProperty() @JsonClassType({type: () => [Boolean]})
    is_main_street_address: boolean;
    @JsonProperty() @JsonClassType({type: () => [Boolean]})
    is_management_address: boolean;
    @JsonProperty() @JsonClassType({type: () => [Boolean]})
    is_default_delivery_address: boolean;
    @JsonProperty() @JsonClassType({type: () => [Boolean]})
    is_default_payment_address: boolean;

    constructor(
        is_correspondence_address: boolean,
        is_delivery_address: boolean,
        is_main_post_office_box_address: boolean,
        is_main_street_address: boolean,
        is_management_address: boolean,
        is_default_delivery_address: boolean,
        is_default_payment_address: boolean
    ){
        this.is_correspondence_address = is_correspondence_address;
        this.is_delivery_address = is_delivery_address;
        this.is_main_post_office_box_address = is_main_post_office_box_address;
        this.is_main_street_address = is_main_street_address;
        this.is_management_address = is_management_address;
        this.is_default_delivery_address = is_default_delivery_address;
        this.is_default_payment_address = is_default_payment_address;
    }

    public static fromService(type: any): DatevUsageTypeModel {
        const objectMapper = new ObjectMapper();
        return objectMapper.parse<DatevUsageTypeModel>(JSON.stringify({
            is_correspondence_address: type.is_correspondence_address,
            is_delivery_address: type.is_delivery_address,
            is_main_post_office_box_address: type.is_main_post_office_box_address,
            is_main_street_address: type.is_main_street_address,
            is_management_address: type.is_management_address,
            is_default_delivery_address: type.is_default_delivery_address,
            is_default_payment_address: type.is_default_payment_address
    }), {mainCreator: () => [DatevUsageTypeModel]});
    }
}
