import {createSelector} from '@ngrx/store';
import {ReducerAddressTypeState} from './addresstype.reducer';
import {allContactTypesForAllCompaniesSelect} from '../../contacttype/store';


export interface AddressTypeState {
  addressType: ReducerAddressTypeState;
}

export const selectAddressTypeState = (state: AddressTypeState) => state.addressType;

export const selectedAddressTypeSelect = createSelector(
  selectAddressTypeState,
  (state) => state.selectedAddressType
);

export const allAddressTypesSelect = createSelector(
  selectAddressTypeState,
  (state) => state.addressTypes
);

export const allAddressTypesForCompaniesForAllCompaniesSelect = createSelector(
  selectAddressTypeState,
  (state) => state.addressTypesForAllCompanies
);

export const allAddressTypesForSelectBoxSelect = createSelector(
  selectAddressTypeState,
  (state) => state.addressTypesForSelectBox
);
