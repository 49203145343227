import {Component, OnDestroy} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {User} from "../../../core/models/user/user.model";
import {selectedUserSelect, UserState} from "../../../core/models/user/store";
import {Store} from "@ngrx/store";
import {employeeVacationSelect, EmployeeVacationState} from "../../../core/models/employee-vacation/store";
import {EmployeeVacationModel} from "../../../core/models/employee-vacation/employee-vacation.model";
import {
  loadVacationAction, vacationLoadedAction
} from "../../../core/models/employee-vacation/store/employee-vacation.actions";

@Component({
  selector: 'app-vacation-list',
  templateUrl: './vacation-list.component.html',
  styleUrls: ['./vacation-list.component.scss']
})
export class VacationListComponent implements OnDestroy{
  selectedUser$: Observable<User> = this.userStore.select(selectedUserSelect);
  selectedUserSub: Subscription;
  selectedUser: User;

  employeeVacation$: Observable<EmployeeVacationModel[]> = this.employeeVacationStore.select(employeeVacationSelect);
  employeeVacationSub: Subscription;
  employeeVacation: EmployeeVacationModel[];

  constructor(
      private employeeVacationStore: Store<EmployeeVacationState>,
      private userStore: Store<UserState>,
  ) {

    this.employeeVacationSub = this.employeeVacation$.subscribe(
        (vacation: EmployeeVacationModel[]) => {
          if (vacation && vacation.length > 0 ){
            this.employeeVacation = vacation;
          }
        }
    );

    this.selectedUserSub = this.selectedUser$.subscribe(
        (user: User) => {
          if (user) {
            this.selectedUser = user;
            const userIds = [];
            userIds.push(user.userId);
            this.employeeVacationStore.dispatch(loadVacationAction({employeeIds: userIds, year: 2023}))
          }
        }
    );
  }
  ngOnDestroy(): void {
    this.employeeVacationSub.unsubscribe();
    this.employeeVacationStore.dispatch(vacationLoadedAction({vacation: null}));
  }
}
