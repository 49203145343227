<div class="card" id="contactList">
    <div class="card-header">
        <div class="row g-3">
            <div class="col-md-4">
                <div class="search-box">
                    <input type="text" name="searchTerm" class="form-control" placeholder="{{ 'labels.search' | translate }}" (keyup)="updateFilter($event)">
                    <i class="ri-search-line search-icon"></i>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body">
        <div>
            <div class="table-responsive table-card mb-2">
                <table class="table">
                    <thead>
                    <tr class="bg-light">
                        <th scope="col">{{ 'labels.type' | translate }}</th>
                        <th scope="col">{{ 'labels.status' | translate }}</th>
                        <th scope="col">{{ 'labels.id' | translate }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let importData of importDataFiltered" class="cursor-pointer" (click)="onSelect(importData)">
                        <td><ngb-highlight [result]="importData.recordType" [term]="searchTerm"></ngb-highlight></td>
                        <td><ngb-highlight [result]="importData.importState | translate" [term]="searchTerm"></ngb-highlight></td>
                        <td><ngb-highlight [result]="importData.recordType === 'ORDER' ? importData.orderRecord.id : importData.customerRecord.id" [term]="searchTerm"></ngb-highlight></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="row justify-content-md-between align-items-md-center">
                <div class="col col-sm-6">
                    <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
                        {{'labels.showing' | translate}}
                        {{ importDataFiltered?.length}} / {{importData?.length}}
                        {{'labels.entries' | translate}}
                    </div>
                </div>
                <!-- Pagination -->
                <div class="col col-sm-6">
                    <div class="text-sm-right float-sm-end listjs-pagination">
                        <ngb-pagination
                                (pageChange)="pageChange($event)"
                                [boundaryLinks]="true"
                                [maxSize]="3"
                                [collectionSize]="datevImport?.totalElements"
                                [page]="page"
                                [pageSize]="datevImport?.pageSize">
                        </ngb-pagination>
                    </div>
                </div>
                <!-- End Pagination -->
            </div>
        </div>
    </div>
</div><!--end card-->
