import {Group} from './group.model';
import {Role} from './role.model';
import {Company} from '../company/company.model';
import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';

export class User {

  @JsonProperty()
  @JsonClassType({type: () => [String]})
  tenantId: string;

  @JsonProperty()
  @JsonClassType({type: () => [String]})
  userId: string;

  @JsonProperty()
  @JsonClassType({type: () => [String]})
  firstName: string;

  @JsonProperty()
  @JsonClassType({type: () => [String]})
  lastName: string;

  @JsonProperty()
  @JsonClassType({type: () => [String]})
  emailAddress: string;

  @JsonProperty()
  @JsonClassType({type: () => [String]})
  color: string;

  @JsonProperty()
  @JsonClassType({type: () => [String]})
  initials: string;

  @JsonProperty()
  @JsonClassType({type: () => [Group]})
  group: Group;

  @JsonProperty()
  @JsonClassType({type: () => [Array, [Role]]})
  roles: Role[] = [];

  @JsonProperty()
  @JsonClassType({type: () => [String]})
  defaultCompany: string;

  @JsonProperty()
  @JsonClassType({type: () => [Array, [Company]]})
  companies: Company[] = [];

  constructor(
    tenantId: string,
    userId: string,
    firstName: string,
    lastName: string,
    emailAddress: string,
    color: string,
    initials: string,
    group: Group,
    defaultCompany: string,
  ) {
    this.tenantId = tenantId;
    this.userId = userId;
    this.firstName = firstName;
    this.lastName = lastName;
    this.emailAddress = emailAddress;
    this.color = color;
    this.initials = initials;
    this.group = group;
    this.defaultCompany = defaultCompany;
  }

  public static fromService(user: any, companies: Company[]): User {
    const objectMapper = new ObjectMapper();
    return objectMapper.parse<User>(JSON.stringify({
      tenantId: user.tenantId['tenant-id'],
      userId: user.userId['user-id'],
      firstName: user.name?.firstName,
      lastName: user.name?.lastName,
      emailAddress: user.emailAddress?.value,
      color: user.color?.color,
      initials: user.initials?.value,
      group: Group.fromService(user.group),
      roles: Role.fromServiceArray(user.exceptionalRoles),
      defaultCompany: user.defaultCompany ? user.defaultCompany['company-id'] : null,
      companies: User.companiesToArray(user.companyIds, companies)
      }), {mainCreator: () => [User]});
  }

  public static toService(user: User): any {
    let result;
    if (user.userId && user.defaultCompany) {
      result = {
        tenantId: {'tenant-id': user.tenantId},
        userId: {'user-id': user.userId},
        group: {value: user.group?.name},
        name: {firstName: user.firstName, lastName: user.lastName},
        emailAddress: {value: user.emailAddress},
        color: {color: user.color},
        initials: {value: user.initials},
        defaultCompany: {'company-id': user.defaultCompany},
        exceptionalRoles: Role.toServiceArray(user.roles),
        companyIds: User.companyArrayToObject(user.companies)
      };
    } else if (!user.userId && user.defaultCompany) {
      result = {
        tenantId: {'tenant-id': user.tenantId},
        group: {value: user.group?.name},
        name: {firstName: user.firstName, lastName: user.lastName},
        emailAddress: {value: user.emailAddress},
        color: {color: user.color},
        initials: {value: user.initials},
        defaultCompany: {'company-id': user.defaultCompany},
        exceptionalRoles: Role.toServiceArray(user.roles),
        companyIds: User.companyArrayToObject(user.companies)
      };
    } else if (!user.userId && !user.defaultCompany) {
      result = {
        tenantId: {'tenant-id': user.tenantId},
        group: {value: user.group?.name},
        name: {firstName: user.firstName, lastName: user.lastName},
        emailAddress: {value: user.emailAddress},
        color: {color: user.color},
        initials: {value: user.initials},
        exceptionalRoles: Role.toServiceArray(user.roles),
        companyIds: User.companyArrayToObject(user.companies)
      };
    } else if (user.userId && !user.defaultCompany) {
      result = {
        tenantId: {'tenant-id': user.tenantId},
        userId: {'user-id': user.userId},
        group: {value: user.group?.name},
        name: {firstName: user.firstName, lastName: user.lastName},
        emailAddress: {value: user.emailAddress},
        color: {color: user.color},
        initials: {value: user.initials},
        exceptionalRoles: Role.toServiceArray(user.roles),
        companyIds: User.companyArrayToObject(user.companies)
      };
    }
    return result;
  }

  public static companiesToArray(companyIds, companies: Company[]): Company[] {
    const companyArray = [];
    for (let i = 0, len = companyIds?.length; i < len; i++) {
      const company = companies.find(value => value.companyId === companyIds[i]['company-id']);
      if (company) {
        companyArray.push(company);
      }
    }
    return companyArray;
  }

  public static companyArrayToObject(companiesArray): any[] {
    const companies = [];
    if (companiesArray) {
      companiesArray.forEach(company => {
        companies.push({'company-id': company.companyId ?? company});
      });
    }
    return companies;
  }
}
