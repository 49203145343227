import {ContactType} from './contacttype/contactType.model';
import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';

export class Contact {
  @JsonProperty() @JsonClassType({type: () => [String]})
  id: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  value: string;
  @JsonProperty() @JsonClassType({type: () => [ContactType]})
  contactType: ContactType;

  constructor(
    id: string,
    value: string,
    @JsonClassType({type: () => [ContactType]}) contactType: ContactType
  ){
    this.id = id;
    this.value = value;
    this.contactType = contactType;
  }

  public static fromServiceArray(contactsArray: any, contactTypes: ContactType[]): Contact[] {
    const contacts: Contact[] = [];
    if (contactsArray) {
      for (const contact of contactsArray) {
        contacts.push(Contact.fromService(contact, contactTypes));
      }
    }
    return contacts;
  }

  public static fromService(contact: any, contactTypes: ContactType[]): Contact {
    const contactType = contactTypes.find(value => value.id === contact.contactTypeId);
    const objectMapper = new ObjectMapper();
    return objectMapper.parse<Contact>( JSON.stringify({
        id: contact.id,
        value: contact.content,
        contactType
      }), {mainCreator: () => [Contact]});
  }

  public static toService(contact: Contact): any {
    return {
      id: contact.id,
      contactTypeId: contact.contactType.id,
      content: contact.value
    };
  }

  public static toServiceArray(contacts: Contact[]): any {
    const contactsArray = [];
    if (contacts) {
      for (const contact of contacts) {
        contactsArray.push(Contact.toService(contact));
      }
    }
    return contactsArray;
  }
}
