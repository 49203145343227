import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {FormField} from '../../../core/models/form/formField.model';
import {FormTemplateModel} from '../../../core/models/form/formTemplate.model';
import {ObjectMapper} from 'jackson-js';
import {v4 as uuidv4} from 'uuid';
import {TaskType} from 'src/app/core/models/order/tasktype/tasktype.model';
import {selectedTaskTypeSelect, TaskTypeState} from "../../../core/models/order/tasktype/store";
import {
    saveTaskTypeAction,
    taskTypeSelectedAction
} from "../../../core/models/order/tasktype/store/taskype.actions";
import {DocumentManagementApiService} from "../../../core/services/document-management-api.service";
import {UpSpotFileModel} from "../../../core/models/upSpotFile.model";
import {DocumentMetaDataModel} from "../../../core/models/file/documentMetaData-model";
import {TranslateService} from "@ngx-translate/core";
import {ToastService} from "../../../core/services/Toast/toast-service";

@Component({
    selector: 'app-task-type-edit',
    templateUrl: './task-type-edit.component.html'
})
export class TaskTypeEditComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() resetSelectedAfterSave = false;
    @Input() companyId = null;
    @ViewChild('description') description: ElementRef;

    taskTypeForm: FormGroup;
    selectedTaskType$: Observable<TaskType> = this.taskTypeStore.select(selectedTaskTypeSelect);
    selectedTaskTypeSub: Subscription;
    selectedTaskType: TaskType;
    selectedColor: string;

    types = {
        string: 'Text',
        number: 'Zahl'
    };

    formTemplates: FormTemplateModel[] = [];
    save = false;

    files: DocumentMetaDataModel[] = [];

    constructor(
        private taskTypeStore: Store<TaskTypeState>,
        private documentService: DocumentManagementApiService,
        private toastrService: ToastService,
        private translate: TranslateService,) {
    }

    ngOnInit(): void {
        this.selectedTaskType = null;
        this.taskTypeForm = new FormGroup({
            id: new FormControl(),
            description: new FormControl(null, Validators.required),
            initials: new FormControl(null, Validators.required),
            color: new FormControl(null, Validators.required)
        });

        this.selectedTaskTypeSub = this.selectedTaskType$.subscribe(
            (taskType: TaskType) => {
                if( taskType) {
                    this.selectTaskType(taskType);
                    taskType.documentIds?.forEach(id => {
                        if (id) {
                            this.documentService.getFileInfoById(id).subscribe(file => {
                                this.files.push(file);
                            });
                        }
                    });
                }
            }
        );
    }

    saveTaskType(): void {
        const taskType = this.taskTypeForm.value;
        this.taskTypeStore.dispatch(saveTaskTypeAction({payload: taskType, companyId: this.companyId}));

        if (this.resetSelectedAfterSave) {
            this.save = true;
        }
        this.resetForm();
    }

    selectTaskType(taskType: TaskType): void {
        if (taskType && this.taskTypeForm) {
            if (this.save) {
                this.taskTypeStore.dispatch(taskTypeSelectedAction({payload: null}));
                this.save = false;
            }
            this.taskTypeForm.patchValue(taskType);
            this.selectedTaskType = taskType;
            this.formTemplates = [];
            if (taskType.formTemplate) {
                this.formTemplates = JSON.parse(taskType.formTemplate);
            }

        } else {
            this.resetForm();
        }
    }

    resetForm(): void {
        this.taskTypeForm?.reset();
        this.selectedTaskType = null;
    }

    colorChanged(selectedColor: string): void {
        this.selectedColor = selectedColor;
        this.taskTypeForm.patchValue({
            color: selectedColor
        });
    }

    ngOnDestroy(): void {
        this.selectedTaskTypeSub.unsubscribe();
        this.files = null;
        this.selectedTaskType = null;
    }

    addForm(): void {
        this.formTemplates.push(new FormTemplateModel(uuidv4(), this.formTemplates.length + 1, null, null, []));
    }

    addFormField(index: number): void {
        this.formTemplates[index].formFields.push(
            new FormField(
                this.formTemplates[index].formFields.length + 1, null, null, null, null, null, null));
    }

    up(formIndex: number, index: number): void {
        this.array_move(formIndex, index, index + 1);
    }

    down(formIndex: number, index: number): void {
        this.array_move(formIndex, index, index - 1);
    }

    delete(formIndex: number, index: number): void {
        this.formTemplates[formIndex].formFields.splice(index, 1);
    }

    saveForm(): void {
        const objectMapper = new ObjectMapper();
        const taskType: TaskType = objectMapper.parse<TaskType>(JSON.stringify(this.taskTypeForm.value), {mainCreator: () => [TaskType]});
        this.formTemplates.forEach(formTemplate => {
            formTemplate.formFields.forEach(formField => {
                if (!formField.key) {
                    formField.key = uuidv4();
                }
            });
        });
        taskType.formTemplate = JSON.stringify(this.formTemplates);
        this.taskTypeStore.dispatch(saveTaskTypeAction({payload: taskType, companyId: this.companyId}));
    }

    array_move(formIndex: number, oldIndex: number, newIndex: number): void {
        if (newIndex >= this.formTemplates[formIndex].formFields.length) {
            let k = newIndex - this.formTemplates[formIndex].formFields.length + 1;
            while (k--) {
                this.formTemplates[formIndex].formFields.push(undefined);
            }
        }
        this.formTemplates[formIndex].formFields.splice(newIndex, 0, this.formTemplates[formIndex].formFields.splice(oldIndex, 1)[0]);
    }

    ngAfterViewInit(): void {
        if (this.description) {
            this.description.nativeElement.focus();
        }
    }

    addDocument(filesEvent: Event, markAsTemplate: boolean): void {
        let files = (<HTMLInputElement>filesEvent.target).files;
        for (let i = 0; i < files.length; i++) {
            const upSpotFile = new UpSpotFileModel(files[i], null);
            const reader = new FileReader();
            reader.readAsDataURL(upSpotFile.file);
            const idxDot = upSpotFile.file.name.lastIndexOf('.') + 1;
            const extFile = upSpotFile.file.name.substr(idxDot, upSpotFile.file.name.length).toLowerCase();
            if (extFile === 'pdf') {
                this.documentService.addFileToTaskType(upSpotFile, markAsTemplate).subscribe(saved => {
                    if (saved) {
                        const documentIds = [...this.selectedTaskType.documentIds];
                        if (saved && saved.id) {
                            documentIds.push(saved.id);
                            const taskTypeToSave = {...this.selectedTaskType, documentIds};
                            this.taskTypeStore.dispatch(saveTaskTypeAction({
                                payload: taskTypeToSave,
                                companyId: this.companyId
                            }));
                        }
                    }
                });
            } else {
                this.toastrService.error(this.translate.instant('labels.onlyDocuments'));
            }
        }
    }

    public openFile(document: DocumentMetaDataModel): void {
        this.documentService.openFile(document);
    }
}
