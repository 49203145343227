import {Injectable} from '@angular/core';
import {UserManagementApiService} from '../../../services/user-management-api.service';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {registerNewTenantAction, tenantRegisteredAction} from './tenant.actions';
import {map, mergeMap} from 'rxjs/operators';
import {Tenant} from '../tenant.model';
import {Router} from '@angular/router';
import {UserService} from "../../../services/user.service";

@Injectable()
export class TenantEffects {
    constructor(
        private httpApiService: UserManagementApiService,
        private userService: UserService,
        private actions$: Actions) {
    }

    registerTenant$ = createEffect(() => this.actions$.pipe(
        ofType(registerNewTenantAction),
        mergeMap(() => this.httpApiService.registerNewTenant()
            .pipe(
                map((tenant: Tenant) => {
                    this.userService.refreshToken().then( result => {
                        window.location.reload();
                    });
                    return tenantRegisteredAction({payload: tenant});
                })
            ))
    ));
}
