import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Order} from "../../../../core/models/order/order.model";
import {Task} from "../../../../core/models/order/task.model";

@Component({
    selector: 'app-sidebar-order-list',
    templateUrl: './sidebar-order-list.component.html',
    styleUrls: ['./sidebar-order-list.component.scss']
})
export class SidebarOrderListComponent implements OnInit, OnChanges {

    @Input() orders: Order[];
    @Output() task = new EventEmitter<Task>();

    ordersFiltered: Order[];
    searchTerm: string;

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.ordersFiltered = this.orders;
    }

    ngOnInit(): void {
    }

    objectToJson(object: any): string {
        return JSON.stringify(object);
    }

    updateFilter(event): void {
        let val = event.target.value.toLowerCase();
        this.searchTerm = val;
        if (val.length > 0) {
            this.ordersFiltered = [];
            this.orders.forEach(order => {
                const tasks = [];
                order.tasks?.forEach(task => {
                    if (task.description?.toLowerCase().includes(val.toLowerCase()) || task.taskType?.description?.toLowerCase().includes(val.toLowerCase()) || order.externalId?.id?.toLowerCase().includes(val.toLowerCase())) {
                        tasks.push(task);
                    }
                });
                const newOrder = {...order, tasks};
                this.ordersFiltered.push(newOrder);
            });
        } else {
            this.ordersFiltered = this.orders;
        }
    }

    planTask(task: Task): void {
        this.task.emit(task);
    }
}
