import {Action, createReducer, on} from '@ngrx/store';
import * as OrderActions from './order.actions';
import {Order} from '../order.model';
import {Task} from '../task.model';
import {DocumentMetaDataModel} from '../../file/documentMetaData-model';
import {OrderStateEnum} from '../orderstate.enum';
import {Customer} from "../../customer/customer.model";

export interface ReducerOrderState {
    selectedOrder: Order;
    filesOfSelectedOrder: DocumentMetaDataModel[];
    uploadedImage: DocumentMetaDataModel;
    ordersByPeriod: Customer[];
    orders: Customer[];
}

export const initialOrderState: ReducerOrderState = {
    selectedOrder: null,
    uploadedImage: null,
    filesOfSelectedOrder: [],
    ordersByPeriod: [],
    orders: []
};

const orderReducer = createReducer(
    initialOrderState,
    on(OrderActions.orderSelectedAction, (state, {payload}) => ({...state, selectedOrder: payload})),
    on(OrderActions.orderSavedAction, (state, {payload}) => ({
        ...state,
        selectedOrder: payload,
        orders: changeFoundOrder(state.orders, payload)
    })),
    on(OrderActions.allOrdersByPeriodSelectedAction, (state, {payload}) => ({...state, ordersByPeriod: payload})),
    on(OrderActions.allOrdersSelectedAction, (state, {payload}) => ({
        ...state,
        orders: payload
    })),
    on(OrderActions.taskChangedAction, (state, {task, orderId}) => ({
        ...state,
    })),
    on(OrderActions.imageAddedToOrderAction, (state, {files}) => ({...state, filesOfSelectedOrder: files})),
    on(OrderActions.imageUploadedAction, (state, {file}) => ({...state, uploadedImage: file})),
);

function changeFoundOrder(customers: Customer[], savedOrder: Order) {
    const newCustomers = [...customers];
    customers.forEach(customer => {
        const orders = [...customer.orders];
        const index1 = customer.orders?.findIndex(order => order.id === savedOrder.id);
        if (index1 > -1) {
            orders.splice(index1, 1);
            orders.push(savedOrder);
        } else {
            if (customer.id === savedOrder.customerId) {
                orders.push(Object.assign({}, savedOrder));
            }
        }
        const customerIndex = newCustomers.findIndex(cust => cust.id === customer.id);
        newCustomers.splice(customerIndex, 1);
        const newCustomer = {...customer, orders};
        newCustomers.push(newCustomer);
    });
    return newCustomers;
}


export function reducer(state: ReducerOrderState | undefined, action: Action): ReducerOrderState {
    return orderReducer(state, action);
}
