import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';
import {DatevErrorsDetail} from './datevErrorDetail.model';

export class DatevMappingErrorsModel {

    @JsonProperty() @JsonClassType({type: () => [String]})
    ident: string;
    @JsonProperty() @JsonClassType({type: () => [String]})
    attributeValue: string;
    @JsonProperty() @JsonClassType({type: () => [String]})
    companyId: string;
    @JsonProperty() @JsonClassType({type: () => [String]})
    description: string;

    constructor(
        ident: string,
        attributeValue: string,
        companyId: string,
        description: string
    ) {
        this.ident = ident;
        this.attributeValue = attributeValue;
        this.companyId = companyId;
        this.description = description;
    }

    public static fromService(error: any): DatevMappingErrorsModel {
        const objectMapper = new ObjectMapper();
        return objectMapper.parse<DatevMappingErrorsModel>(JSON.stringify({
            ident: error.ident,
            attributeValue: error.attributeValue,
            companyId: error.companyId,
            description: error.description
        }), {mainCreator: () => [DatevMappingErrorsModel]});
    }

    public static fromServiceArray(errorArray: any): DatevMappingErrorsModel[] {
        const resultErrors = [];
        if (errorArray) {
            for (const error of errorArray) {
                resultErrors.push(DatevMappingErrorsModel.fromService(error));
            }
        }
        return resultErrors;
    }
}
