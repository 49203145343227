import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Injectable} from '@angular/core';
import {UserService} from "../services/user.service";
import {filter, switchMap, tap} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class AppAuthGuard implements CanActivate {
  constructor(private userService: UserService,
              protected readonly router: Router,) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    const requiredRoles = route.data.roles;
    // Allow the user to proceed if no additional roles are required to access the route.
    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      return true;
    }
    return requiredRoles.some((role) => this.userService.roles.includes(role));
  }
}
