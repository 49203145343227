import {Component, OnInit, EventEmitter, Output, ViewChild, ElementRef, Inject} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { MenuItem } from './menu.model';
import {UserService} from "../../core/services/user.service";
import {CompanyState} from "../../core/models/company/store";
import {Store} from "@ngrx/store";
import MetisMenu from "metismenujs";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  mm: any;
  menu: any;
  toggle: any = true;
  menuItems: MenuItem[] = [];
  @ViewChild('sideMenu') sideMenu!: ElementRef;
  @Output() mobileMenuButtonClicked = new EventEmitter();

  appVersion: string = this.version;
  constructor(private router: Router, public translate: TranslateService,
              private companyStore: Store<CompanyState>,
              private userService: UserService,
              @Inject('VERSION') private version: string
  ) {
    translate.setDefaultLang('de');
  }

  ngOnInit(): void {
    this.addHeaderToMenu();
    if (this.userService.isAdmin()) {
      this.addDashBoardToMenu();
      this.addCalendarToMenu();
      // this.addOrdersToMenu();
      this.addCustomersToMenu();
      this.addCompanyToMenu();
      this.addCompaniesEditToMenu();
      this.addEmployeeEditToMenu();
      this.addCustomerToMenu();
      this.addBasicDataToMenu();
      this.addDatevToMenu();
    } else if (this.userService.isEmployee() ) {
      this.addPersonCalendarToMenu();
    }
  }

  /***
   * Activate droup down set
   */
  ngAfterViewInit() {
    this.initActiveMenu();
  }

  closeMenu() {
    document.body.classList.toggle('vertical-sidebar-enable');
  }

  removeActivation(items: any) {
    items.forEach((item: any) => {
      if (item.classList.contains("menu-link")) {
        if (!item.classList.contains("active")) {
          item.setAttribute("aria-expanded", false);
        }
        (item.nextElementSibling) ? item.nextElementSibling.classList.remove("show") : null;
      }
      if (item.classList.contains("nav-link")) {
        if (item.nextElementSibling) {
          item.nextElementSibling.classList.remove("show");
        }
        item.setAttribute("aria-expanded", false);
      }
      item.classList.remove("active");
    });
  }

  toggleSubItem(event: any) {
    if (event.target && event.target.nextElementSibling)
      event.target.nextElementSibling.classList.toggle("show");
  };

  toggleItem(event: any) {
    let isCurrentMenuId = event.target.closest('a.nav-link');
    let isMenu = isCurrentMenuId.nextElementSibling as any;
    if (isMenu.classList.contains("show")) {
      isMenu.classList.remove("show");
      isCurrentMenuId.setAttribute("aria-expanded", "false");
    } else {
      let dropDowns = Array.from(document.querySelectorAll('#navbar-nav .show'));
      dropDowns.forEach((node: any) => {
        node.classList.remove('show');
      });
      (isMenu) ? isMenu.classList.add('show') : null;
      const ul = document.getElementById("navbar-nav");
      if (ul) {
        const iconItems = Array.from(ul.getElementsByTagName("a"));
        let activeIconItems = iconItems.filter((x: any) => x.classList.contains("active"));
        activeIconItems.forEach((item: any) => {
          item.setAttribute('aria-expanded', "false")
          item.classList.remove("active");
        });
      }
      isCurrentMenuId.setAttribute("aria-expanded", "true");
      if (isCurrentMenuId) {
        this.activateParentDropdown(isCurrentMenuId);
      }
    }
  }

  // remove active items of two-column-menu
  activateParentDropdown(item: any) { // navbar-nav menu add active
    item.classList.add("active");
    let parentCollapseDiv = item.closest(".collapse.menu-dropdown");
    if (parentCollapseDiv) {
      //   // to set aria expand true remaining
      parentCollapseDiv.classList.add("show");
      parentCollapseDiv.parentElement.children[0].classList.add("active");
      parentCollapseDiv.parentElement.children[0].setAttribute("aria-expanded", "true");
      if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
        parentCollapseDiv.parentElement.closest(".collapse").classList.add("show");
        if (parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling)
          parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.classList.add("active");
      }
      return false;
    }
    return false;
  }

  updateActive(event: any) {
    const ul = document.getElementById("navbar-nav");
    if (ul) {
      const items = Array.from(ul.querySelectorAll("a.nav-link"));
      this.removeActivation(items);
    }
    this.activateParentDropdown(event.target);
  }

  initActiveMenu() {
    const pathName = window.location.pathname;
    const ul = document.getElementById("navbar-nav");
    if (ul) {
      const items = Array.from(ul.querySelectorAll("a.nav-link"));
      let activeItems = items.filter((x: any) => x.classList.contains("active"));
      this.removeActivation(activeItems);

      let matchingMenuItem = items.find((x: any) => {
        return x.pathname === pathName;
      });
      if (matchingMenuItem) {
        this.activateParentDropdown(matchingMenuItem);
      }
    }
  } 

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    const sidebarsize = document.documentElement.getAttribute("data-sidebar-size");
    if (sidebarsize == 'sm-hover-active') {
      document.documentElement.setAttribute("data-sidebar-size", 'sm-hover')
    } else {
      document.documentElement.setAttribute("data-sidebar-size", 'sm-hover-active')
    }
  }

  /**
   * SidebarHide modal
   * @param content modal content
   */
   SidebarHide() {
    document.body.classList.remove('vertical-sidebar-enable');
  }

  addHeaderToMenu(): void {
    this.menuItems.push(
        {
          label: 'menu.navigation',
          isTitle: true
        });
  }

  addDashBoardToMenu(): void {
    this.menuItems.push({
      label: 'menu.dashboard',
      icon: 'ri-home-3-line',
      link: '/dashboard'
    });
  }

  addOrdersToMenu(): void {
    this.menuItems.push(         {
      label: 'menu.orders',
      icon: 'ri-book-open-line',
      link: '/orders',
      badge: {
        variant: 'primary',
      },
      subItems: [
        {
          label: 'menu.createOrder',
          link: '/order-overview'
        },
        //       {
//          label: 'Auftragsliste',
//          link: '/order-list'
//        }
      ]
    });
  }

  addDatevToMenu(): void {
    this.menuItems.push(         {
      label: 'menu.datev',
      icon: 'ri-download-line',
      link: '/datev',
      badge: {
        variant: 'primary',
      },
      subItems: [
        {
          label: 'menu.datevTransformation',
          link: '/datev/transformation'
        },
      ]
    });
  }

  addCalendarToMenu(): void {
    this.menuItems.push(          {
      label: 'menu.calendar',
      icon: 'ri-calendar-line',
      link: '/calendar',
      badge: {
        variant: 'primary',
      }
    });
  }

  addCustomersToMenu(): void {
    this.menuItems.push(
        {
          label: 'menu.customers',
          icon: 'ri-open-arm-line',
          link: '/company',
          badge: {
            variant: 'primary',
          },
          subItems: [
                      {
                        label: this.translate.instant('menu.customersList'),
                        link: '/customer-list'
                      }
          ]
        }
    );
  }

  addCompanyToMenu(): void {
    this.menuItems.push(
        {
          label: 'menu.my_company',
          icon: 'ri-store-2-line',
          link: '/company',
          badge: {
            variant: 'primary',
          },
          subItems: [
            /*          {
                        label: this.translate.instant('menu.companyChoose'),
                        link: '/company-choose'
                      }*/
          ]
        }
    );
  }

  addBasicDataToMenu(): void {
    this.menuItems.forEach((item: MenuItem, index) => {
      if (item.label === 'menu.my_company') {
        item.subItems.push(    {
          label: 'menu.basicData',
          link: '/company-basicdata'
        });
      }
    });
  }

  addPersonCalendarToMenu(): void {
    this.menuItems.push(          {
      label: 'menu.personCalendar',
      icon: 'calendar',
      link: '/person-calendar',
      badge: {
        variant: 'primary',
      }
    });
  }

  addCustomerToMenu(): void {
    this.menuItems.forEach((item: MenuItem, index) => {
      if (item.label === 'menu.orders') {
        item.subItems.push(    {
          label: 'menu.customer',
          link: '/customer-list'
        });
      }
    });
  }

  addEmployeeEditToMenu(): void {
    this.menuItems.forEach((item: MenuItem, index) => {
      if (item.label === 'menu.my_company') {
        item.subItems.push(    {
          label: 'menu.employee',
          link: '/company-employee-overview'
        });
      }
    });
  }

  addCompaniesEditToMenu(): void {
    this.menuItems.forEach((item: MenuItem, index) => {
      if (item.label === 'menu.my_company') {
        item.subItems.push(    {
          label: 'menu.companies',
          link: '/company-overview'
        });
      }
    });
  }

}
