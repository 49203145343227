import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, mergeMap, switchMap} from 'rxjs/operators';
import {
  contactTypeSavedAction, contactTypesForAllCompaniesLoadedAction,
  contactTypesLoadedAction,
  loadAllContactTypesAction,
  loadAllContactTypesForAllCompaniesAction,
  saveContactTypeAction
} from './contacttype.actions';
import {OrderManagementApiService} from '../../../services/order-management-api.service';
import {ContactType} from '../contactType.model';

@Injectable()
export class ContacttypeEffects {
  constructor(
    private httpApiService: OrderManagementApiService,
    private actions$: Actions) {}

  loadContactTypes$ = createEffect(() => this.actions$.pipe(
    ofType(loadAllContactTypesAction),
    mergeMap(() => this.httpApiService.getAllContactTypes()
      .pipe(
        map(contactTypes => contactTypesLoadedAction({payload: contactTypes}))
      ))
  ));

  loadContactTypesForAllCompanies$ = createEffect(() => this.actions$.pipe(
    ofType(loadAllContactTypesForAllCompaniesAction),
    mergeMap(() => this.httpApiService.getAllContactTypesWithoutCompany()
      .pipe(
        map(contactTypes => contactTypesForAllCompaniesLoadedAction({payload: contactTypes}))
      ))
  ));

  saveContactType$ = createEffect(() => this.actions$.pipe(
    ofType(saveContactTypeAction),
    switchMap(({payload, companyId}) => this.httpApiService.addContactType(payload, companyId)
      .pipe(
        map(( contactType: ContactType) => {
          return contactTypeSavedAction({payload: contactType});
          }
        ))
    )));
}
