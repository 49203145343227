import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UpSpotFileModel} from '../../../../core/models/upSpotFile.model';
import {addImageToOrderAction} from '../../../../core/models/order/store/order.actions';
import {Store} from '@ngrx/store';
import {filesOfSelectedOrderSelect, OrderState} from '../../../../core/models/order/store';
import {Customer} from '../../../../core/models/customer/customer.model';
import {Order} from '../../../../core/models/order/order.model';
import {Observable, Subscription} from 'rxjs';
import {DocumentMetaDataModel} from '../../../../core/models/file/documentMetaData-model';
import {DocumentManagementApiService} from '../../../../core/services/document-management-api.service';
import {DomSanitizer} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import * as BlobUtil from "blob-util";
import {Lightbox} from "ngx-lightbox";
import {ToastService} from "../../../../core/services/Toast/toast-service";

@Component({
    selector: 'app-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() order: Order;
    @Input() customer: Customer;

    image: any;

    filesOfSelectedOrder$: Observable<DocumentMetaDataModel[]> = this.orderStore.select(filesOfSelectedOrderSelect);
    filesOfSelectedOrderSub: Subscription;
    filesOfSelectedOrder: DocumentMetaDataModel[] = [];
    imagesOfSelectedOrder: DocumentMetaDataModel[] = [];

    constructor(
        private orderStore: Store<OrderState>,
        private documentManagementApi: DocumentManagementApiService,
        private sanitizer: DomSanitizer,
        private toastr: ToastService,
        private transLateService: TranslateService,
        private lightbox: Lightbox,
    ) {
    }

    ngOnDestroy(): void {
        this.filesOfSelectedOrderSub.unsubscribe();
        this.filesOfSelectedOrder = [];
        this.imagesOfSelectedOrder = [];
    }

    ngOnInit(): void {
        this.filesOfSelectedOrder = [];
        this.imagesOfSelectedOrder = [];
    }


    addImage(filesEvent: Event): void {
        let files = (<HTMLInputElement>filesEvent.target).files;
        for (let i = 0; i < files.length; i++) {
            const upSpotFile = new UpSpotFileModel(files[i], null);
            const reader = new FileReader();
            reader.readAsDataURL(upSpotFile.file);
            if (UpSpotFileModel.isImage(upSpotFile.file.name)) {
                this.orderStore.dispatch(addImageToOrderAction({
                    order: this.order,
                    customer: this.customer,
                    file: upSpotFile,
                    markAsTemplate: false
                }));
            } else {
                this.toastr.error(this.transLateService.instant('labels.onlyImages'));
            }
        }
    }

    addDocument(filesEvent: Event): void {
        let files = (<HTMLInputElement>filesEvent.target).files;
        for (let i = 0; i < files.length; i++) {
            const upSpotFile = new UpSpotFileModel(files[i], null);
            const reader = new FileReader();
            reader.readAsDataURL(upSpotFile.file);
            const idxDot = upSpotFile.file.name.lastIndexOf('.') + 1;
            const extFile = upSpotFile.file.name.substr(idxDot, upSpotFile.file.name.length).toLowerCase();
            if (extFile === 'pdf') {
                this.orderStore.dispatch(addImageToOrderAction({
                    order: this.order,
                    customer: this.customer,
                    file: upSpotFile,
                    markAsTemplate: false
                }));
            } else {
                this.toastr.error(this.transLateService.instant('labels.onlyDocuments'));
            }
        }
    }

    public openImage(document: DocumentMetaDataModel): void {
        if (!document.loaded) {
            this.documentManagementApi.getFileByDocument(document).subscribe(file => {
                BlobUtil.blobToBase64String(file)
                    .then((base64String) => {
                        const imageUrl = 'data:' + document.previewFileInfo?.mimeType + ';base64,' + base64String;
                        this.image = imageUrl;
                        document.originalFileUrl = this.image;
                        document.loaded = true;
                        this.openLightBox(document);
                    });
            });
        } else {
            this.openLightBox(document);
        }
    }

    openLightBox(document: DocumentMetaDataModel): void {
        this.lightbox.open([{src: document.originalFileUrl, caption: document.fileName, thumb: document.previewFileUrl}]);
    }

    public openFile(document: DocumentMetaDataModel): void {
        this.documentManagementApi.openFile(document);
    }

    ngAfterViewInit(): void {
        this.filesOfSelectedOrderSub = this.filesOfSelectedOrder$.subscribe(
            filesOfSelectedOrder => {
                this.filesOfSelectedOrder = [];
                this.imagesOfSelectedOrder = [];
                if (filesOfSelectedOrder) {
                    filesOfSelectedOrder.forEach(model => {
                        if (UpSpotFileModel.isImage(model.fileName)) {
                            this.imagesOfSelectedOrder.push(JSON.parse(JSON.stringify(model)));
                        } else if (UpSpotFileModel.isPDF(model.fileName)) {
                            if (!model.templateInfo?.templateDocumentId) {
                                this.filesOfSelectedOrder.push(JSON.parse(JSON.stringify(model)));
                            }
                        }
                    });
                }
            });
    }

}
