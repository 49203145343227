import {createAction, props} from '@ngrx/store';
import {ContactType} from '../contactType.model';

export const loadAllContactTypesAction = createAction('[CONTACTTYPE] loadAllContactTypesAction');
export const contactTypesLoadedAction = createAction('[CONTACTTYPE] contactTypesLoadedAction', props<{payload: ContactType[]}>());
export const loadAllContactTypesForAllCompaniesAction = createAction('[CONTACTTYPE] loadAllContactTypesForAllCompaniesAction');
export const contactTypesForAllCompaniesLoadedAction = createAction('[CONTACTTYPE] contactTypesForAllCompaniesLoadedAction', props<{payload: ContactType[]}>());
export const contactTypeSelectedAction = createAction('[CONTACTTYPE] CompanySelected', props<{payload: ContactType}>());
export const saveContactTypeAction = createAction( '[CONTACTTYPE] save', props<{payload: ContactType, companyId: string}>());
export const contactTypeSavedAction = createAction( '[CONTACTTYPE] saved', props<{payload: ContactType}>());
