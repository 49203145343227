<!-- Start Breadcrumbs -->
<app-breadcrumbs title="menu.basicData" [breadcrumbItems]="breadCrumbItems"></app-breadcrumbs>
<!-- End Breadcrumbs -->

<div class="col-md-12 mb-3 card">
    <div class="card-header">{{ 'labels.orderTypes' | translate }}</div>
    <app-ordertype></app-ordertype>
</div>

<div class="col-md-12 mb-3 card">
    <div class="card-header">{{ 'labels.taskTypes' | translate }}</div>
    <app-taskType></app-taskType>
</div>
<div class="col-md-12 mb-3 card">
    <div class="card-header">{{ 'labels.contactTypes' | translate }}</div>
    <app-contacttype></app-contacttype>
</div>
<div class="col-md-12 mb-3 card">
    <div class="card-header">{{ 'labels.addressTypes' | translate }}</div>
    <app-addresstype></app-addresstype>
</div>
