<ng-container>
    <div class="modal-header bg-soft-info p-3">
        <h5 class="modal-title" id="exampleModalLabel">{{'labels.importDataEdit' | translate}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal"
                (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <div class="px-3 mx-n3 mb-2">
        <div class="modal-body">
            <div class="row">
                <div class="col" [perfectScrollbar] style="max-height: 500px;">
                    <ng-container *ngIf="datevImport.recordType === 'ORDER'; else customer">
                        <ng-container>
                            <!-- Fehler darstellen -->
                            <div class="callout callout-warning"
                                 *ngIf="datevImport?.orderRecord.errors.length > 0 || datevImport?.orderRecord.mappingErrors.length > 0">
                                <h4 class="">Fehler</h4>
                                <hr>
                                <ng-container *ngFor="let error of datevImport.orderRecord?.errors">
                                    <div class="fs-16">{{error.actionMessageCode|translate}}</div>
                                    <div *ngFor="let detail of error.details">
                                        <span>{{'datevTransformationComponent.' + detail.attribute|translate}}</span>: {{detail.errorMessageCode|translate}}
                                    </div>
                                </ng-container>
                                <ng-container *ngFor="let error of datevImport.orderRecord?.mappingErrors">
                                    <div class="fs-16">Mappingfehler</div>
                                </ng-container>
                            </div>
                            <ng-container>
                                <div class="callout callout-warning"
                                     *ngIf="datevImport?.orderRecord.operationAddress.errors.length > 0 || datevImport?.orderRecord.operationAddress.mappingErrors.length > 0; else operationAddressHeader">
                                    <h4 class="">Fehler</h4>
                                    <hr>
                                    <ng-container
                                            *ngFor="let error of datevImport?.orderRecord.operationAddress.errors">
                                        <div class="fs-16">{{error.actionMessageCode|translate}}</div>
                                        <div *ngFor="let detail of error.details">
                                            <span>{{'datevTransformationComponent.' + detail.attribute|translate}}</span>: {{detail.errorMessageCode|translate}}
                                        </div>
                                    </ng-container>
                                    <ng-container
                                            *ngFor="let error of datevImport?.orderRecord.operationAddress.mappingErrors">
                                        <div class="fs-16">Mappingfehler</div>
                                    </ng-container>
                                </div>
                                <ng-template #operationAddressHeader>
                                    <h4>Ausführungsort</h4>
                                    <hr>
                                </ng-template>
                            </ng-container>


                            <!-- Kunden darstellen -->
                            <div class="row">
                                <div class="col-sm-4">
                                    <div class="mb-3">
                                        <label for="orderOperationCompanyName" class="form-label">Firmenname</label>
                                        <input type="text" class="form-control" id="orderOperationCompanyName"
                                               placeholder="Firmenname"
                                               [(ngModel)]="datevImport.orderRecord.operationAddress.companyName">
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="mb-3">
                                        <label for="orderOperationFirstName" class="form-label">Vorname</label>
                                        <input type="text" class="form-control" id="orderOperationFirstName"
                                               placeholder="Vorname"
                                               [(ngModel)]="datevImport.orderRecord.operationAddress.firstName">
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="mb-3">
                                        <label for="orderOperationName" class="form-label">Nachname</label>
                                        <input type="text" class="form-control" id="orderOperationName"
                                               placeholder="Nachname"
                                               [(ngModel)]="datevImport.orderRecord.operationAddress.name">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="mb-3">
                                        <label for="orderOperationStreet" class="form-label">Straße</label>
                                        <input type="text" class="form-control" id="orderOperationStreet"
                                               placeholder="Straße"
                                               [(ngModel)]="datevImport.orderRecord.operationAddress.street">
                                    </div>
                                </div>
                                <div class="col-sm-2">
                                    <div class="mb-3">
                                        <label for="orderOperationZipcode" class="form-label">Postleitzahl</label>
                                        <input type="text" class="form-control" id="orderOperationZipcode"
                                               placeholder="Postleitzahl"
                                               [(ngModel)]="datevImport.orderRecord.operationAddress.zipcode">
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="mb-3">
                                        <label for="orderOperationCity" class="form-label">Stadt</label>
                                        <input type="text" class="form-control" id="orderOperationCity"
                                               placeholder="Stadt"
                                               [(ngModel)]="datevImport.orderRecord.operationAddress.city">
                                    </div>
                                </div>
                            </div>
                            <h4>Auftrag</h4>
                            <hr>
                            <!-- Auftrag darstellen -->
                            <ng-container>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="mb-3">
                                            <label for="orderCustomerId" class="form-label">Kundennummer</label>
                                            <input type="text" class="form-control" id="orderCustomerId"
                                                   placeholder="Kundennummer"
                                                   [(ngModel)]="datevImport.orderRecord.customerId">
                                        </div>
                                    </div>

                                    <div class="col-sm-6">
                                        <div class="mb-3">
                                            <label for="orderId" class="form-label">Auftragsnummer</label>
                                            <input type="text" class="form-control" id="orderId"
                                                   placeholder="Auftragsnummer"
                                                   [(ngModel)]="datevImport.orderRecord.customerId">
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="orderDescription" class="form-label">Beschreibung</label>
                                    <textarea class="form-control" id="orderDescription"
                                              placeholder="Beschreibung" rows="3"
                                              [(ngModel)]="datevImport.orderRecord.description"></textarea>
                                </div>
                            </ng-container>

                            <h4>Aufgaben</h4>
                            <hr>
                            <!-- Tasks darstellen -->
                            <ng-container *ngFor="let task of datevImport.orderRecord.tasks; let i = index">
                                <div class="callout callout-warning"
                                     *ngIf="task.errors.length > 0 || task.mappingErrors.length > 0">
                                    <h4 class="">Fehler</h4>
                                    <hr>
                                    <ng-container *ngFor="let error of task.errors">
                                        <div class="fs-16">{{error.actionMessageCode|translate}}</div>
                                        <div *ngFor="let detail of error.details">
                                            <span>{{'datevTransformationComponent.' + detail.attribute|translate}}</span>: {{detail.errorMessageCode|translate}}
                                        </div>
                                    </ng-container>
                                    <ng-container *ngFor="let error of task.mappingErrors">
                                        <div class="fs-16">Mappingfehler</div>
                                    </ng-container>
                                </div>

                                <hr class="text-muted" *ngIf="i > 0">
                                <div>
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label for="taskTypeId" class="form-label">Id</label>
                                                <input type="text" class="form-control" id="taskTypeId" placeholder="Id"
                                                       [(ngModel)]="task.typeId">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="mb-3">
                                                <label for="taskDescription" class="form-label">Beschreibung</label>
                                                <textarea class="form-control" id="taskDescription"
                                                          placeholder="Beschreibung" rows="3"
                                                          [(ngModel)]="task.description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <ng-template #customer>
                        <ng-container>
                            <!-- Fehler darstellen -->
                            <div class="callout callout-warning"
                                 *ngIf="datevImport.customerRecord?.errors?.length > 0 || datevImport.customerRecord?.mappingErrors?.length > 0">
                                <h4 class="">Fehler</h4>
                                <hr>
                                <ng-container *ngFor="let error of datevImport.customerRecord?.errors">
                                    <div class="fs-16">{{error.actionMessageCode|translate}}</div>
                                    <div *ngFor="let detail of error.details">
                                        <span>{{'datevTransformationComponent.' + detail.attribute|translate}}</span>: {{detail.errorMessageCode|translate}}
                                    </div>
                                </ng-container>
                                <ng-container *ngFor="let error of datevImport.customerRecord?.mappingErrors">
                                    <div class="fs-16">Mappingfehler</div>
                                </ng-container>
                            </div>
                            <ng-container>
                                <div class="callout callout-warning"
                                     *ngIf="datevImport?.customerRecord?.mainAddress?.errors?.length > 0 || datevImport?.customerRecord?.mainAddress?.mappingErrors?.length > 0; else operationAddressHeader">
                                    <h4 class="">Fehler</h4>
                                    <hr>
                                    <ng-container
                                            *ngFor="let error of datevImport?.customerRecord?.mainAddress?.errors">
                                        <div class="fs-16">{{error.actionMessageCode|translate}}</div>
                                        <div *ngFor="let detail of error.details">
                                            <span>{{'datevTransformationComponent.' + detail.attribute|translate}}</span>: {{detail.errorMessageCode|translate}}
                                        </div>
                                    </ng-container>
                                    <ng-container
                                            *ngFor="let error of datevImport?.customerRecord?.mainAddress?.mappingErrors">
                                        <div class="fs-16">Mappingfehler</div>
                                    </ng-container>
                                </div>
                                <ng-template #operationAddressHeader>
                                    <h4>Ausführungsort</h4>
                                    <hr>
                                </ng-template>
                            </ng-container>


                            <!-- Kunden darstellen -->
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="mb-3">
                                        <label for="customerId" class="form-label">Kundennummer</label>
                                        <input type="text" class="form-control" id="customerId"
                                               placeholder="Kundennummer"
                                               [(ngModel)]="datevImport.customerRecord.id">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="mb-3">
                                        <label for="companyId" class="form-label">Firmennummer</label>
                                        <input type="text" class="form-control" id="companyId"
                                               placeholder="Firmennummer"
                                               [(ngModel)]="datevImport.customerRecord.datevCompanyId">
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="datevImport.customerRecord.mainAddress">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <div class="mb-3">
                                            <label for="customerMainAddressCompanyName"
                                                   class="form-label">Firmenname</label>
                                            <input type="text" class="form-control" id="customerMainAddressCompanyName"
                                                   placeholder="Firmenname"
                                                   [(ngModel)]="datevImport.customerRecord.mainAddress.companyName">
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="mb-3">
                                            <label for="customerMainAddressFirstName" class="form-label">Vorname</label>
                                            <input type="text" class="form-control" id="customerMainAddressFirstName"
                                                   placeholder="Vorname"
                                                   [(ngModel)]="datevImport.customerRecord.mainAddress.firstName">
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="mb-3">
                                            <label for="customerMainAddressName" class="form-label">Nachname</label>
                                            <input type="text" class="form-control" id="customerMainAddressName"
                                                   placeholder="Nachname"
                                                   [(ngModel)]="datevImport.customerRecord.mainAddress.name">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="mb-3">
                                            <label for="customerMainAddressStreet" class="form-label">Straße</label>
                                            <input type="text" class="form-control" id="customerMainAddressStreet"
                                                   placeholder="Straße"
                                                   [(ngModel)]="datevImport.customerRecord.mainAddress.street">
                                        </div>
                                    </div>
                                    <div class="col-sm-2">
                                        <div class="mb-3">
                                            <label for="customerMainAddressZipcode"
                                                   class="form-label">Postleitzahl</label>
                                            <input type="text" class="form-control" id="customerMainAddressZipcode"
                                                   placeholder="Postleitzahl"
                                                   [(ngModel)]="datevImport.customerRecord.mainAddress.zipcode">
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="mb-3">
                                            <label for="customerMainAddressCity" class="form-label">Stadt</label>
                                            <input type="text" class="form-control" id="customerMainAddressCity"
                                                   placeholder="Stadt"
                                                   [(ngModel)]="datevImport.customerRecord.mainAddress.city">
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <h4>Kontakte</h4>
                            <hr>
                            <!-- Contacts darstellen -->
                            <ng-container *ngFor="let contact of datevImport.customerRecord?.contacts; let i = index">
                                <div class="callout callout-warning"
                                     *ngIf="contact.errors.length > 0 || contact.mappingErrors.length > 0">
                                    <h4 class="">Fehler</h4>
                                    <hr>
                                    <ng-container *ngFor="let error of contact.errors">
                                        <div class="fs-16">{{error.actionMessageCode|translate}}</div>
                                        <div *ngFor="let detail of error.details">
                                            <span>{{'datevTransformationComponent.' + detail.attribute|translate}}</span>: {{detail.errorMessageCode|translate}}
                                        </div>
                                    </ng-container>
                                    <ng-container *ngFor="let error of contact.mappingErrors">
                                        <div class="fs-16">Mappingfehler</div>
                                    </ng-container>
                                </div>

                                <hr class="text-muted" *ngIf="i > 0">
                                <div>
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <div class="mb-3">
                                                <label for="contactType" class="form-label">Typ</label>
                                                <input type="text" class="form-control" id="contactType"
                                                       placeholder="Id"
                                                       [(ngModel)]="contact.type">
                                            </div>
                                        </div>
                                        <div class="col-sm-8">
                                            <div class="mb-3">
                                                <label for="contactContent" class="form-label">Wert</label>
                                                <input type="text" class="form-control" id="contactContent"
                                                       placeholder="Id"
                                                       [(ngModel)]="contact.content">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-template>
                </div><!--end col-->
            </div><!-- row -->
        </div>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary me-2 mb-2 mb-md-0" id="saveDatevImport"
                (click)="save()">{{ 'labels.save' | translate }}</button>
    </div>
</ng-container>



