import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, mergeMap} from 'rxjs/operators';
import {
    customersOverviewLoadedAction,
    findCustomerOverviewAction,
    loadCustomerOverviewAction
} from './customerOverview.actions';
import {OrderManagementApiService} from '../../../../services/order-management-api.service';

@Injectable()
export class CustomerOverviewEffects {
  constructor(
    private httpApiService: OrderManagementApiService,
    private actions$: Actions) {}

  loadCustomersOverview$ = createEffect(() => this.actions$.pipe(
    ofType(loadCustomerOverviewAction),
    mergeMap(({page, size, companyId, customerId}) => this.httpApiService.getAllCustomersOverview(page, size, companyId, customerId)
      .pipe(
        map(pageResult => {
          return customersOverviewLoadedAction({payload: pageResult});
        })
      ))
  ));

    findCustomersOverview$ = createEffect(() => this.actions$.pipe(
        ofType(findCustomerOverviewAction),
        mergeMap(({page, size, companyId, search}) => this.httpApiService.findCustomersOverview(page, size, companyId, search)
            .pipe(
                map(pageResult => {
                    return customersOverviewLoadedAction({payload: pageResult});
                })
            ))
    ));
}
