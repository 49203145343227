import {Component, Injectable, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Company} from '../../../core/models/company/company.model';
import {Observable, Subscription} from 'rxjs';
import {allCompaniesSelect, CompanyState, selectedCompanySelect} from '../../../core/models/company/store';
import {Store} from '@ngrx/store';
import {
  companySelectedAction,
  loadAllCompaniesAction,
  saveCompanyAction
} from '../../../core/models/company/store/company.actions';
import {User} from '../../../core/models/user/user.model';
import {loggedInUserSelect, UserState} from '../../../core/models/user/store';
import {saveUserAction} from '../../../core/models/user/store/user.actions';
import {UserManagementApiService} from '../../../core/services/user-management-api.service';
import {CustomEmailValidator} from '../../../core/validator/customEmail.validator';
import {ObjectMapper} from 'jackson-js';

@Component({
  selector: 'app-company-edit',
  templateUrl: './company-edit.component.html'
})
@Injectable()
export class CompanyEditComponent implements OnInit, OnDestroy {
  companyForm: FormGroup;
  selectedCompany$: Observable<Company> = this.companyStore.select(selectedCompanySelect);
  selectedCompanySub: Subscription;
  selectedCompany: Company;

  companies$: Observable<Company[]> = this.companyStore.select(allCompaniesSelect);
  companiesSub: Subscription;
  companies: Company[];

  loggedInUser$: Observable<User> = this.userStore.select(loggedInUserSelect);
  loggedInUserSub: Subscription;
  loggedInUser: User;
  defaultCompany: string;
  saveDefaultCompany = false;

  constructor(
    private companyStore: Store<CompanyState>,
    private userStore: Store<UserState>,
    private httpApiAService: UserManagementApiService,
    private emailValidator: CustomEmailValidator) {
    this.companyForm = new FormGroup( {
        tenantId: new FormControl(),
        companyId: new FormControl(),
        address: new FormGroup( {
            companyName: new FormControl(null, Validators.required),
            street: new FormControl( null, Validators.required),
            zipcode: new FormControl( null, Validators.required),
            city: new FormControl( null, Validators.required)
          }
        ),
        phoneNumber: new FormControl(),
        emailAddress: new FormControl(null, [Validators.required, Validators.email], [this.emailValidator.existingEmailValidator(this.getDefaultEmail.bind(this))]),
        isDefault: new FormControl('')
      }
    );
  }

  getDefaultEmail(): string {
    return this.selectedCompany?.emailAddress;
  }

  ngOnInit(): void {
    this.loggedInUserSub = this.loggedInUser$.subscribe(
      ( user: User) => {
        this.defaultCompany = user?.defaultCompany;
        this.loggedInUser = user;
        //this.companyStore.dispatch(loadAllCompaniesAction());
      }
    );

    this.selectedCompanySub = this.selectedCompany$.subscribe(
      (company: Company) => {
        this.selectCompany(company);
        if (this.saveDefaultCompany){
          this.saveDefaultCompany = false;
          this.userStore.dispatch(saveUserAction({ payload: {...this.loggedInUser, defaultCompany: company.companyId}}));
        }
      }
    );


    this.companiesSub = this.companies$.subscribe(
      (companies: Company[]) => {
        this.companies = companies;
        if (!this.defaultCompany && companies.length === 0) {
          this.companyForm.patchValue({isDefault: true});
        }
      }
    );
  }

  selectCompany(company: Company): void {
    this.selectedCompany = null;
    if (company) {
      this.selectedCompany = company;
      this.companyForm.patchValue(company);
      if (this.defaultCompany) {
        if (this.defaultCompany === company.companyId) {
          this.companyForm.patchValue({isDefault: true});
        } else {
          this.companyForm.patchValue({isDefault: false});
        }
      } else {
        this.companyForm.patchValue({isDefault: false});
      }
    }
  }

  saveCompany(): void {
    const objectMapper = new ObjectMapper();
    const newCompany: Company = objectMapper.parse<Company>(JSON.stringify(this.companyForm.value), {mainCreator: () => [Company]});
    this.companyStore.dispatch(saveCompanyAction({payload: newCompany}));

    if ( newCompany.isDefault ) {
      this.saveDefaultCompany = true;
    }
  }

  resetForm(): void {
    this.companyForm.reset();
    this.selectedCompany$ = null;
  }

  ngOnDestroy(): void {
    this.selectedCompanySub.unsubscribe();
    this.loggedInUserSub.unsubscribe();
    this.companiesSub.unsubscribe();

    this.companyStore.dispatch(companySelectedAction({payload: null}));
  }

}
