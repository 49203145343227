import {createAction, props} from '@ngrx/store';
import {OrderType} from '../ordertype.model';

export const loadAllOrderTypesAction = createAction('[ORDERTYPE] loadAllOrderTypesAction');
export const orderTypesLoadedAction = createAction('[ORDERTYPE] orderTypesLoadedAction', props<{payload: OrderType[]}>());
export const loadAllOrderTypesForAllCompaniesAction = createAction('[ORDERTYPE] loadAllOrderTypesForAllCompaniesAction');
export const orderTypesForAllCompaniesLoadedAction = createAction('[ORDERTYPE] orderTypesForAllCompaniesLoadedAction', props<{payload: OrderType[]}>());
export const orderTypeSelectedAction = createAction('[ORDERTYPE] orderTypeSelectedAction', props<{payload: OrderType}>());
export const saveOrderTypeAction = createAction( '[ORDERTYPE] saveOrderTypeAction', props<{payload: OrderType, companyId: string}>());
export const orderTypeSavedAction = createAction( '[ORDERTYPE] orderTypeSavedAction', props<{payload: OrderType}>());
