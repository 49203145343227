import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';
import {EmployeeVacationValidFromModel} from "./employee-vacation-valid-from.model";

export class EmployeeVacationEntitlementModel {
    @JsonProperty()
    @JsonClassType({type: () => [String]})
    employeeId: string;

    @JsonProperty()
    @JsonClassType({type: () => [String]})
    vacationEntitlementId: string;

    @JsonProperty()
    @JsonClassType({type: () => [Number]})
    annualVacationDays: number;

    @JsonProperty() @JsonClassType({type: () => [EmployeeVacationValidFromModel]})
    validFrom: EmployeeVacationValidFromModel;

    constructor(employeeId: string, vacationEntitlementId: string, annualVacationDays: number) {
        this.employeeId = employeeId;
        this.vacationEntitlementId = vacationEntitlementId;
        this.annualVacationDays = annualVacationDays;
    }

    public static fromService(entitlement: any): EmployeeVacationEntitlementModel {
        const objectMapper = new ObjectMapper();
        const returnAuthor = objectMapper.parse<EmployeeVacationEntitlementModel>(JSON.stringify({
                employeeId: entitlement.employeeId,
                vacationEntitlementId: entitlement.vacationEntitlementId,
                annualVacationDays: entitlement.annualVacationDays.rawData,
                validFrom: EmployeeVacationValidFromModel.fromService(entitlement.validFrom)
            }), {mainCreator: () => [EmployeeVacationEntitlementModel]}
        );
        return returnAuthor;
    }

    public static toService(entitlement: EmployeeVacationEntitlementModel): any {
        return {
            employeeId: entitlement.employeeId,
            vacationEntitlementId: entitlement.vacationEntitlementId,
            validFrom: EmployeeVacationValidFromModel.toService(entitlement.validFrom),
            annualVacationDays: entitlement.annualVacationDays
        }
    }

    public static fromServiceArray(entitlements: any): EmployeeVacationEntitlementModel[] {
        const resultEntitlements = [];
        if (entitlements) {
            for (const entitlement of entitlements) {
                resultEntitlements.push(EmployeeVacationEntitlementModel.fromService(entitlement));
            }
        }
        return resultEntitlements;
    }

}
