<form [formGroup]="addressTypeForm" (ngSubmit)="saveAddressType()">
    <div class="modal-body">
        <div class="row g-3">
            <div class="mb-3 col-12">
                <input type="hidden" id="addressTypeId" formControlName="id">
                <div>
                    <small class="text-uppercase text-muted">{{ 'labels.description' | translate }}*</small>
                    <input type="text" class="form-control" id="addressTypeDescription" formControlName="description"
                           #description>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="hstack gap-2 justify-content-end">
            <small class="text-muted form-check-label" *ngIf="!selectedAddressType?.id">
                <input type="checkbox" class="form-check-input" name="createNew"
                       (click)="resetSelectedAfterSave = !resetSelectedAfterSave">
                <em class="input-frame"></em>
                {{ 'labels.addMore' | translate }}
            </small>
            <button type="submit" class="btn btn-success"
                    [disabled]="!addressTypeForm.valid" id="saveAddressType">{{ 'labels.save' | translate }}</button>
        </div>
    </div>
</form>

