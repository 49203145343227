import {Action, createReducer, on} from '@ngrx/store';
import {BatchModel} from "../batch.model";
import * as BatchActions from "./batch.actions";

export interface ReducerBatchState {
  batchStatus: BatchModel;
}

export const initialBatchState: ReducerBatchState = {
  batchStatus: new BatchModel(null, null, null, null)
};

const batchReducer = createReducer(
    initialBatchState,
    on(BatchActions.datevBatchLoadedAction, (state, {batch}) => ({...state, batchStatus: batch}))
);

export function reducer(state: ReducerBatchState | undefined, action: Action): ReducerBatchState {
  return batchReducer(state, action);
}
