import {JsonClassType, JsonProperty} from 'jackson-js';

export class FormField {

  @JsonProperty() @JsonClassType({type: () => [Number]})
  sort: number;
  @JsonProperty() @JsonClassType({type: () => [String]})
  formFieldType: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  key: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  value: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  description: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  pattern: string;
  @JsonProperty() @JsonClassType({type: () => [Boolean]})
  required: boolean;

  constructor(
    sort: number,
    formFieldType: string,
    key: string,
    value: string,
    description: string,
    pattern: string,
    required: boolean
  ) {
    this.sort = sort;
    this.formFieldType = formFieldType;
    this.key = key;
    this.value = value;
    this.description = description;
    this.pattern = pattern;
    this.required = required;
  }
}
