import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {OrderManagementApiService} from '../../../../services/order-management-api.service';
import {
  loadAllOrderTypesAction,
  loadAllOrderTypesForAllCompaniesAction,
  orderTypeSavedAction, orderTypesForAllCompaniesLoadedAction,
  orderTypesLoadedAction,
  saveOrderTypeAction
} from './ordertype.actions';
import {OrderType} from '../ordertype.model';
import {ContactType} from "../../../contacttype/contactType.model";
import {Observable, of} from "rxjs";
import {
  contactTypesForAllCompaniesLoadedAction,
  loadAllContactTypesForAllCompaniesAction
} from "../../../contacttype/store/contacttype.actions";

@Injectable()
export class OrderTypeEffects {
  constructor(
    private httpApiService: OrderManagementApiService,
    private actions$: Actions) {}

  loadOrderTypes$ = createEffect(() => this.actions$.pipe(
    ofType(loadAllOrderTypesAction),
    mergeMap(() => this.httpApiService.getAllOrderTypes()
      .pipe(
        map(orderTypes => orderTypesLoadedAction({payload: orderTypes}))
      ))
  ));

  loadOrderTypesForAllCompanies$ = createEffect(() => this.actions$.pipe(
    ofType(loadAllOrderTypesForAllCompaniesAction),
    mergeMap(() => this.httpApiService.getAllOrderTypesWithoutCompany()
      .pipe(
        map(orderTypes => orderTypesForAllCompaniesLoadedAction({payload: orderTypes}))
      ))
  ));

  saveOrderType$ = createEffect(() => this.actions$.pipe(
    ofType(saveOrderTypeAction),
    switchMap(({payload, companyId}) => this.httpApiService.addOrderType(payload, companyId)
      .pipe(
        map(( orderType: OrderType) => {
          return orderTypeSavedAction({payload: orderType});
          }
        ))
    )));
}
