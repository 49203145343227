<header id="page-topbar">
    <div class="layout-width">
        <div class="navbar-header">
            <div class="d-flex">
                <!-- LOGO -->
                <div class="navbar-brand-box horizontal-logo">
                    <a href="" class="logo logo-dark">
                        <span class="logo-sm">
                            <img src="assets/images/logo-sm.png" alt="" height="22">
                        </span>
                        <span class="logo-lg">
                            <img src="assets/images/logo-dark.png" alt="" height="17">
                        </span>
                    </a>

                    <a href="" class="logo logo-light">
                        <span class="logo-sm">
                            <img src="assets/images/logo-sm.png" alt="" height="22">
                        </span>
                        <span class="logo-lg">
                            <img src="assets/images/logo-light.png" alt="" height="17">
                        </span>
                    </a>
                </div>

                <button type="button" class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                        id="topnav-hamburger-icon" (click)="toggleMobileMenu($event)">
                    <span class="hamburger-icon">
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                </button>

                <form class="app-search d-none d-md-block" id="searchForm">
                    <ng-template #rt let-r="result" let-t="term">
                        <div *ngIf="r.description != null">{{r.description}}</div>
                    </ng-template>
                    <div class="position-relative">
                        <input
                                id="navbarForm"
                                type="text"
                                class="form-control"
                                name="globalSearch"
                                [ngbTypeahead]="globalSearch"
                                [(ngModel)]="searchModel"
                                (selectItem)="selectedGlobalSearchAction($event)"
                                [inputFormatter]="GlobalSearchformatter"
                                [resultTemplate]="rt"
                                placeholder="Suche.."/>
                        <span class="mdi mdi-magnify search-widget-icon"></span>
                        <span class="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                              id="search-close-options"></span>
                    </div>
                </form>
            </div>

            <div class="d-flex align-items-center">

                <div class="dropdown d-md-none topbar-head-dropdown header-item" ngbDropdown>
                    <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                            id="page-header-search-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false" ngbDropdownToggle>
                        <i class="bx bx-search fs-22"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                         aria-labelledby="page-header-search-dropdown" ngbDropdownMenu>
                        <form class="p-3">
                            <div class="mb-3 m-0">
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Search ..."
                                           aria-label="Recipient's username">
                                    <button class="btn btn-primary" type="submit"><i
                                            class="mdi mdi-magnify"></i></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div class="dropdown ms-1 topbar-head-dropdown header-item" ngbDropdown>
                    <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                        <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" alt="Header Language" height="20"
                             class="rounded">
                        <img *ngIf="flagvalue === undefined" src="{{valueset}}" alt="Header Language" height="20"
                             class="rounded">
                    </button>
                    <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>

                        <!-- item-->
                        <a href="javascript:void(0);" class="dropdown-item notify-item language py-2" data-lang="eng"
                           *ngFor="let item of listLang" (click)="setLanguage(item.text, item.lang, item.flag)"
                           [ngClass]="{'active': cookieValue === item.lang}">
                            <img src="{{item.flag}}" alt="user-image" class="me-2 rounded" height="18"> <span
                                class="align-middle">{{item.text}}</span>
                        </a>
                    </div>
                </div>
                <ng-container *ngIf="userService.isAdmin()">
                    <div class="dropdown topbar-head-dropdown ms-1 header-item" ngbDropdown>
                        <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                            <i class='bx bx-category-alt fs-22'></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end">
                            <div class="p-3 border-top-0 border-start-0 border-end-0 border-dashed border">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h6 class="m-0 fw-semibold fs-15"> Schnellauswahl </h6>
                                    </div>
                                </div>
                            </div>

                            <div class="p-2">
                                <div class="row g-0">
                                    <div class="col">
                                        <a class="dropdown-icon-item" (click)="navigateNewPerson()">
                                            <i class="ri-user-add-line icon-xl"></i>
                                            <span>Mitarbeiter anlegen</span>
                                        </a>
                                    </div>
                                    <div class="col">
                                        <a class="dropdown-icon-item" (click)="navigateNewCustomer()">
                                            <i class="ri-user-follow-line icon-xl"></i>
                                            <span>Kunden anlegen</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="ms-1 header-item d-none d-sm-flex">
                    <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                            data-toggle="fullscreen" (click)="fullscreen()">
                        <i class='bx bx-fullscreen fs-22'></i>
                    </button>
                </div>

                <div class="ms-1 header-item d-none d-sm-flex">
                    <button type="button"
                            class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode">
                        <i class='bx bx-moon fs-22' (click)="changeMode('dark')"></i>
                        <i class='bx bx-sun fs-22' (click)="changeMode('light')"></i>
                    </button>
                </div>

                <div class="dropdown topbar-head-dropdown ms-1 header-item" ngbDropdown #messageMenu autoClose="true">
                    <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                            id="page-header-notifications-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false" ngbDropdownToggle>
                        <i class='bx bx-bell fs-22'></i>
                        <span *ngIf="messages?.unreadMessages > 0"
                              class="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">{{ messages?.unreadMessages }}
                            <span
                                    class="visually-hidden">ungelesene Nachrichten</span></span>
                    </button>
                    <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                         aria-labelledby="page-header-notifications-dropdown" ngbDropdownMenu>

                        <div class="dropdown-head bg-primary bg-pattern rounded-top">
                            <div class="p-3">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h6 class="m-0 fs-16 fw-semibold text-white"> Nachrichten </h6>
                                    </div>
                                    <div class="col-auto dropdown-tabs" *ngIf="messages?.unreadMessages > 0">
                                        <span class="badge badge-soft-light fs-13"> {{ messages?.unreadMessages }}
                                            neue</span>
                                    </div>
                                </div>
                            </div>

                            <div class="px-2 pt-2">
                                <ul ngbNav #nav="ngbNav" [activeId]="1"
                                    class="nav nav-tabs dropdown-tabs nav-tabs-custom" id="notificationItemsTab">
                                    <li [ngbNavItem]="1">
                                        <a ngbNavLink>
                                            Alle ({{messages?.messagePage?.content?.length}})
                                        </a>
                                        <ng-template ngbNavContent>
                                            <div *ngIf="messages?.messagePage?.content?.length == 0; else elseMessages">
                                                <div class="tab-pane p-4" id="alerts-tab" role="tabpanel"
                                                     aria-labelledby="alerts-tab">
                                                    <div class="w-25 w-sm-50 pt-3 mx-auto">
                                                        <img src="assets/images/svg/bell.svg" class="img-fluid"
                                                             alt="user-pic">
                                                    </div>
                                                    <div class="text-center pb-5 mt-2">
                                                        <h6 class="fs-18 fw-semibold lh-base">Hey! Aktuell sind keine
                                                            Nachrichten für dich verfügbar! </h6>
                                                    </div>
                                                </div>
                                                <div class="my-3 text-center">
                                                    <button routerLink="/messages"
                                                            type="button"
                                                            class="btn btn-soft-success waves-effect waves-light"
                                                            ngbDropdownToggle>
                                                        zeige alle Benachrichtigungen <i
                                                            class="ri-arrow-right-line align-middle"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <ng-template #elseMessages>
                                                <div class="tab-pane fade show active py-2 ps-2"
                                                     id="all-messages-tab"
                                                     role="tabpanel">
                                                    <div style="max-height: 300px;">
                                                        <div class="pe-2" style="max-height: 250px;" [perfectScrollbar]>
                                                            <ng-container *ngIf="messages?.messagePage">
                                                                <div
                                                                        *ngFor="let message of messages?.messagePage?.content">
                                                                    <div class="text-reset notification-item d-block dropdown-item position-relative"
                                                                         (click)="loadMessage(message)">
                                                                        <div class="d-flex">
                                                                            <div class="avatar-xs me-3">
                                                                <span class="avatar-title bg-soft-info text-info rounded-circle fs-16">
                                                                    <i class="bx bx-badge-check"></i>
                                                                </span>
                                                                            </div>
                                                                            <div class="flex-1">
                                                                                <a class="stretched-link">
                                                                                    <h6 class="mt-0 mb-2 lh-base">
                                                                                        <ng-container
                                                                                                *ngIf="message.messageType === 'MESSAGE_TASK_COMMENT_CREATED'; else displayValue">
                                                                                            {{'notification.' + message.messageType | translate}}
                                                                                            <br>{{message?.message}}
                                                                                        </ng-container>
                                                                                        <ng-template
                                                                                            #displayValue>{{message?.displayValue}}</ng-template>
                                                                                    </h6>
                                                                                </a>
                                                                                <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                                                    <span><i
                                                                                            class="mdi mdi-clock-outline"></i>{{message?.sender?.displayValue ?? message?.sender.id }}
                                                                                        - {{message.createdDate | date: 'dd.MM.yyyy HH:mm:ss'}}</span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                        <div class="my-3 text-center">
                                                            <button routerLink="/messages"
                                                                    type="button"
                                                                    class="btn btn-soft-success waves-effect waves-light"
                                                                    ngbDropdownToggle>
                                                                zeige alle Benachrichtigungen <i
                                                                    class="ri-arrow-right-line align-middle"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </ng-template>
                                        </ng-template>
                                    </li>
                                </ul>
                            </div>

                        </div>

                        <div class="tab-content" id="notificationItemsTabContent">
                            <div class="tab-content text-muted">
                                <div [ngbNavOutlet]="nav"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- User Dropdown -->
                <div class="dropdown ms-sm-3 header-item topbar-user" ngbDropdown>
                    <button type="button" class="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                        <span class="d-flex align-items-center">
                            <div class="avatar-xs">
                            <div class="avatar-title rounded-circle userprofile"
                                 [ngStyle]="{'background-color': (loggedInUser | async )?.color}">{{(loggedInUser | async)?.initials}}</div>
                                </div>
                            <span class="text-start ms-xl-2">
                                <span class="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{{(loggedInUser | async)?.firstName}} {{(loggedInUser | async)?.lastName}}</span>
                                <span class="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">{{(loggedInUser | async)?.emailAddress}}</span>
                                <span class="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">{{(activeCompany | async)?.address?.companyName}}</span>
                            </span>
                        </span>
                    </button>
                    <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                        <!-- item-->
                        <h6 class="dropdown-header">Willkommen {{(loggedInUser | async)?.firstName}}!</h6>
                        <a class="dropdown-item" (click)="navigateToFaq()"><i
                                class="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i> <span
                                class="align-middle">Hilfe</span></a>
                        <div class="dropdown-divider"></div>
                        <ng-container *ngFor="let company of companies | async">
                            <a class="dropdown-item cursor-pointer" (click)="selectCompany(company)"
                               [ngClass]=" { 'fw-bold': company.companyId === (activeCompany | async)?.companyId}"><i
                                    class="mdi mdi-swap-horizontal text-muted fs-16 align-middle me-1"></i> <span
                                    class="align-middle">{{company.address?.companyName}}</span></a>
                        </ng-container>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" (click)="logout()"><i
                                class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span
                                class="align-middle" data-key="t-logout">Ausloggen</span></a>
                    </div>
                </div>
            </div>
        </div>
        <!--TODO-->
        <ng-container *ngIf="statusMessages$">
            <ng-container *ngFor="let message of statusMessages$|async">
                <div class="text-center alert alert-warning border-0 rounded-0 m-0 d-flex align-items-center"
                     role="alert"
                     *ngIf="!message.messageRead">
                    <i class="feather alert-triangle icon-xl me-2"></i>
                    <div class="flex-grow-1 text-truncate">
                        {{message.message}}
                    </div>
                    <div class="flex-grow-1 text-truncate">
                        <button class="btn btn-outline-primary" (click)="markRead(message.documentId)">Als gelesen
                            markieren
                        </button>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</header>

<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
