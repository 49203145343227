<ng-container *ngIf="order && selectedCustomer">
    <div class="row">
        <div class="col-lg-12">
            <div class="card mt-n4 mx-n4">
                <div class="bg-soft-warning">
                    <div class="card-body pb-0 px-4">
                        <div class="row mb-3">
                            <div class="col-md">
                                <div class="row align-items-center g-3">
                                    <div class="col-md-auto">
                                        <div class="avatar-md">
                                            <div class="avatar-title bg-white rounded-circle">
                                                <img src="assets/images/brands/slack.png" alt="" class="avatar-xs">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md">
                                        <div>
                                            <h4 class="fw-bold">{{selectedCustomer.mainAddress | fullName}}</h4>
                                            <div class="hstack gap-3 flex-wrap">
                                                <div>
                                                    <i [ngClass]="{'ri-building-line align-bottom me-1': selectedCustomer.isCompany,
                                                     'ri-user-3-line': !selectedCustomer.isCompany}"></i> {{order.orderType?.description}}
                                                </div>
                                                <ng-container *ngIf="order.externalId">
                                                    <div class="vr"></div>
                                                    <div>{{'labels.externalOrderId' | translate}} : <span
                                                            class="fw-medium">{{order.externalId.id}}</span>
                                                    </div>
                                                </ng-container>
                                                <div class="vr"></div>
                                                <div>{{'labels.startdate' | translate}} : <span
                                                        class="fw-medium">{{order.startDate | date: 'dd.MM.yyyy HH:mm'}}</span>
                                                </div>
                                                <div class="vr"></div>
                                                <div>{{'labels.enddate' | translate}} : <span
                                                        class="fw-medium">{{order.endDate | date: 'dd.MM.yyyy HH:mm'}}</span>
                                                </div>
                                                <div class="vr"></div>
                                                <div>{{'labels.desiredDate' | translate}} : <span
                                                        class="fw-medium">{{order.desiredDate | date: 'dd.MM.yyyy'}}</span>
                                                </div>
                                                <div class="vr"></div>
                                                <div class="badge rounded-pill bg-info fs-12">{{order.orderState | orderStatus | translate}}</div>
                                            </div>
                                            <div class="mt-1">{{order.operationAddress | fullAddress}}</div>
                                            <div class="mt-1">{{selectedCustomer.remark}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-auto" *ngIf="editable === true">
                                <div class="hstack gap-1 flex-wrap">
                                    <button type="button" class="btn py-0 fs-16 favourite-btn active"
                                            (click)="editOrder()">
                                        <i class=" ri-pencil-line icon-xs text-muted"></i>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <ul ngbNav #customNav="ngbNav" [activeId]="1" class="nav nav-tabs-custom border-bottom-0"
                            role="tablist">
                            <li [ngbNavItem]="1" class="nav-item">
                                <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                                    {{'labels.overview' | translate}}
                                </a>
                                <ng-template ngbNavContent>
                                    <div class="row">
                                        <div class="col-xl-9 col-lg-8">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="text-muted">
                                                        <h6 class="mb-3 fw-semibold text-uppercase">{{'labels.description' | translate}}</h6>
                                                        <p>{{order.description}}</p>

                                                        <div class="pt-3 border-top border-top-dashed mt-4">
                                                            <div class="row">

                                                                <div class="col-lg-3 col-sm-6">
                                                                    <div>
                                                                        <p class="mb-2 text-uppercase fw-medium">{{'labels.startdate' | translate}}
                                                                            :</p>
                                                                        <h5 class="fs-15 mb-0">{{order.startDate | date: 'dd.MM.yyyy HH:mm'}}</h5>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-3 col-sm-6">
                                                                    <div>
                                                                        <p class="mb-2 text-uppercase fw-medium">{{'labels.enddate' | translate}}
                                                                            :</p>
                                                                        <h5 class="fs-15 mb-0">{{order.endDate | date: 'dd.MM.yyyy HH:mm'}}</h5>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-3 col-sm-6">
                                                                    <div>
                                                                        <p class="mb-2 text-uppercase fw-medium">
                                                                            {{'labels.desiredDate' | translate}} :</p>
                                                                        <div class="fs-15 mb-0">{{order.desiredDate | date: 'dd.MM.yyyy HH:mm'}}</div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-3 col-sm-6">
                                                                    <div>
                                                                        <p class="mb-2 text-uppercase fw-medium">{{'labels.status' | translate}}
                                                                            :</p>
                                                                        <div class="badge bg-warning fs-12">{{order.orderState | orderStatus | translate}}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div class="pt-3 border-top border-top-dashed mt-4"
                                                             *ngIf="files && files.length > 0">
                                                            <h6 class="mb-3 fw-semibold text-uppercase">{{'labels.documents' | translate}}</h6>
                                                            <div class="row g-3">
                                                                <div class="col-xxl-4 col-lg-6" *ngFor="let file of files">
                                                                    <div class="border rounded border-dashed p-2">
                                                                        <div class="d-flex align-items-center">
                                                                            <div class="flex-shrink-0 me-3">
                                                                                <div class="avatar-sm">
                                                                                    <div class="avatar-title bg-light text-secondary rounded fs-24">
                                                                                        <i class="ri-folder-zip-line"></i>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="flex-grow-1 overflow-hidden">
                                                                                <h5 class="fs-13 mb-1"><a
                                                                                        href="javascript: void(0);"
                                                                                        class="text-body text-truncate d-block">{{file.fileName}}</a></h5>
                                                                            </div>
                                                                            <div class="flex-shrink-0 ms-2">
                                                                                <div class="d-flex gap-1">
                                                                                    <button type="button"
                                                                                            class="btn btn-icon text-muted btn-sm fs-18" (click)="openFile(file)">
                                                                                        <i class="ri-download-2-line"></i>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- end row -->
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- end card body -->
                                            </div>
                                            <!-- end card -->
                                            <app-chatcomponent [messages]="orderMessages"
                                                               [order]="order"></app-chatcomponent>
                                            <!-- end card -->
                                        </div>
                                        <!-- end col -->
                                        <div class="col-xl-3 col-lg-4">
                                            <app-contactoptioncomponent [customer]="selectedCustomer"></app-contactoptioncomponent>
                                            <app-contactpersons [order]="order" [customer]="selectedCustomer"
                                                                [selectable]="true"></app-contactpersons>

                                            <div class="card" *ngIf="employees && employees.length > 0">
                                                <div class="card-header align-items-center d-flex border-bottom-dashed">
                                                    <h4 class="card-title mb-0 flex-grow-1">{{'labels.assignedEmployeesToOrder' | translate}}</h4>
                                                </div>

                                                <div class="card-body">
                                                    <div class="mx-n3 px-3" [perfectScrollbar]>
                                                        <div class="vstack gap-3">
                                                            <div class="d-flex align-items-center"
                                                                 *ngFor="let employee of employees">
                                                                <div class="avatar-xs flex-shrink-0 me-3">
                                                                    <div class="avatar-title rounded-circle"
                                                                         [ngStyle]="{'background-color': employee.color }">{{employee.initials}}</div>
                                                                </div>
                                                                <div class="flex-grow-1">
                                                                    <h5 class="fs-13 mb-0"><a
                                                                            class="text-body d-block">{{employee.firstName + ' ' + employee.lastName}}</a>
                                                                    </h5>
                                                                </div>
                                                                <div class="flex-shrink-0">
                                                                    <h5 class="fs-13 mb-0"><a
                                                                            class="text-body d-block">{{employee.emailAddress}}</a>
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                            <!-- end member item -->
                                                        </div>
                                                        <!-- end list -->
                                                    </div>
                                                </div>
                                                <!-- end card body -->
                                            </div>
                                            <!-- end card -->
                                            <app-documents *ngIf="order?.id" [order]="order"
                                                           [customer]="selectedCustomer"></app-documents>
                                            <!-- end card -->
                                        </div>
                                        <!-- end col -->
                                    </div>
                                    <!-- end row -->
                                </ng-template>
                            </li>
                            <li [ngbNavItem]="2" class="nav-item" *ngIf="templates && templates.length > 0">
                                <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                                    {{'labels.forms' | translate}}
                                </a>
                                <ng-template ngbNavContent>
                                    <!-- end row -->
                                    <app-formtemplates [(templates)]="templates"></app-formtemplates>
                                    <!-- end team list -->

                                </ng-template>
                            </li>
                            <li [ngbNavItem]="3" class="nav-item">
                                <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                                    {{'labels.tasks' | translate}}
                                </a>
                                <ng-template ngbNavContent>
                                    <!-- end row -->
                                    <app-task [order]="order" [selectedCustomerId]="order.customerId"
                                                  [canAddNewTasks]="editable"></app-task>
                                    <!-- end team list -->

                                </ng-template>
                            </li>
                        </ul>
                    </div>
                    <!-- end card body -->
                </div>
            </div>
            <!-- end card -->
        </div>
        <!-- end col -->
    </div>
    <!-- end row -->
    <div class="row">
        <div class="col-lg-12">
            <div class="tab-content text-muted">
                <div [ngbNavOutlet]="customNav"></div>
            </div>
        </div>
        <!-- end col -->
    </div>
    <!-- end row -->

</ng-container>
