import {Action, createReducer, on} from '@ngrx/store';
import * as MessageActions from './messages.actions';
import {MessageModel} from '../message.model';

export interface ReducerMessagesState {
  messages: MessageModel
  messagesForNavbar: MessageModel
}

export const initialMessageState: ReducerMessagesState = {
  messages: null,
  messagesForNavbar: null
};

const messagesReducer = createReducer(
  initialMessageState,
  on(MessageActions.messagesLoadedAction, (state, {payload}) => ({
    ...state,
    messages: payload,
    messagesForNavbar: payload
  })),
  on(MessageActions.messagesForNavbarLoadedAction, (state, {payload}) => ({...state, messagesForNavbar: payload})),
  on(MessageActions.messageAsReadMarkedAction, (state, {payload}) => ({
    ...state,
    messages: handleMessageOfPayload(payload, state.messages),
    messagesForNavbar: handleMessageOfPayload(payload, state.messages)
  })),
  on(MessageActions.messageAsUnReadMarkedAction, (state, {payload}) => ({
    ...state,
    messages: handleMessageOfPayload(payload, state.messages),
    messagesForNavbar: handleMessageOfPayload(payload, state.messages)
  })),
  on(MessageActions.messageAsDeletedMarkedAction, (state, {payload}) => ({
    ...state,
    messages: handleMessageOfPayload(payload, state.messages),
    messagesForNavbar: handleMessageOfPayload(payload, state.messages)
  })),
);

function handleMessageOfPayload(messageModel: MessageModel, state: MessageModel): MessageModel {
  const message = messageModel.messagePage.content[0];
  const unreadCount = messageModel.messageSummary;
  if (message) {
    const index = state.messagePage.content.findIndex(document => document.id === message.id);
    let newState = {...state};
    if (index >= 0) {
      let documents = [...state.messagePage.content];
      documents.splice(index, 1);
      documents.push(message);
      const messagePage = {...state.messagePage, content: documents};
      newState = {...state, messagePage}//, unreadMessages: unreadCount}
    }
    return newState;
  } else {
    return state;
  }
}

export function reducer(state: ReducerMessagesState | undefined, action: Action): ReducerMessagesState {
  return messagesReducer(state, action);
}
