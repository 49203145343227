import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, mergeMap} from 'rxjs/operators';
import {loadOrderStateOverviewAction, orderStateOverviewLoadedAction} from './orderStateOverview.actions';
import {OrderManagementApiService} from '../../../../services/order-management-api.service';

@Injectable()
export class OrderStateOverviewEffects {
  constructor(
    private httpApiService: OrderManagementApiService,
    private actions$: Actions) {}

  loadOrderStateOverview$ = createEffect(() => this.actions$.pipe(
    ofType(loadOrderStateOverviewAction),
    mergeMap(({page, size, orderState}) => this.httpApiService.getOrderStateOverview(page, size)
      .pipe(
        map(pageResult => {
          return orderStateOverviewLoadedAction({payload: pageResult});
        })
      ))
  ));
}
