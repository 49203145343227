import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';
import {DatevAddressModel} from "./datevAddress.model";
import {DatevTaskModel} from "./datevTask.model";

export class DatevErrorsDetail {

  @JsonProperty() @JsonClassType({type: () => [String]})
  attribute: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  errorMessageCode: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  errorText: string;

  constructor(
    attribute: string,
    errorMessageCode: string,
    errorText: string
  ){
    this.attribute = attribute;
    this.errorMessageCode = errorMessageCode;
    this.errorText = errorText;
  }

  public static fromService(error: any): DatevErrorsDetail {
    const objectMapper = new ObjectMapper();
    return objectMapper.parse<DatevErrorsDetail>(JSON.stringify({
      attribute: error.attribute,
      errorMessageCode: error.errorMessageCode,
      errorText: error.errorText
    }), {mainCreator: () => [DatevErrorsDetail]});
  }

  public static fromServiceArray(errorArray: any): DatevErrorsDetail[] {
    const resultErrors = [];
    if (errorArray) {
      for (const error of errorArray) {
        resultErrors.push(DatevErrorsDetail.fromService(error));
      }
    }
    return resultErrors;
  }
}
