import {createAction, props} from '@ngrx/store';
import {Company} from '../company.model';

export const loadAllCompaniesAction = createAction('[COMPANY] loadAllCompaniesAction');
export const companiesLoadedAction = createAction('[COMPANY] companiesLoadedAction', props<{payload: Company[]}>());
export const companySelectedAction = createAction('[COMPANY] companySelectedAction', props<{payload: Company}>());
export const saveCompanyAction = createAction( '[COMPANY] saveCompanyAction', props<{payload: Company}>());
export const companySavedAction = createAction( '[COMPANY] companySavedAction', props<{payload: Company}>());
export const setActiveCompanyAction = createAction( '[COMPANY] setActiveCompanyAction ', props<{payload: string}>());
export const activeCompanySetAction = createAction( '[COMPANY] activeCompanySetAction', props<{payload: Company}>());
