<!-- ========== App Menu ========== -->
<div class="app-menu navbar-menu">
    <!-- LOGO -->
    <div class="navbar-brand-box">
        <!-- Light Logo-->
        <div class="logo logo-light">
        <span class="logo-lg">
        <a routerLink="/" class="logo logo-light">
            Planung<span>1.0</span>
        </a>
        </span>
            <span class="logo-sm">
        <a routerLink="/" class="logo logo-light">
            P<span>1.0</span>
        </a>
        </span>
        </div>
        <button type="button" class="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
                id="vertical-hover" (click)="toggleMobileMenu($event)">
            <i class="ri-record-circle-line"></i>
        </button>
    </div>

    <div id="scrollbar">
        <div class="container-fluid">

            <div id="two-column-menu">
            </div>
            <div class="sidebar-menu-scroll" style="max-height: 100vh" [perfectScrollbar]>
                <ul class="metismenu list-unstyled navbar-nav" id="navbar-nav">

                    <ng-container *ngFor="let item of menuItems">
                        <li class="menu-title" *ngIf="item.isTitle"><span
                                data-key="t-menu">{{ item.label | translate }}</span></li>

                        <li class="nav-item" *ngIf="!item.isTitle && !item.isLayout">
                            <a *ngIf="hasItems(item)" href="javascript:void(0);" class="is-parent nav-link menu-link"
                               [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}"
                               data-bs-toggle="collapse" aria-expanded="false" (click)="toggleItem($event)">
                                <i class="{{ item.icon }} icon nav-icon" class=""></i>
                                <span class=""> {{ item.label | translate }}</span>
                                <span class="badge badge-pill {{item.badge.variant}}"
                                      *ngIf="item.badge">{{item.badge.text
                                    | translate}}</span>
                            </a>

                            <a [routerLink]="item.link" *ngIf="!hasItems(item)"
                               class="side-nav-link-ref nav-link menu-link" routerLinkActive="active">
                                <i class="bx {{ item.icon }} icon nav-icon" *ngIf="item.icon" class=""></i>
                                <span class=""> {{ item.label | translate }}</span>
                                <span class="badge {{item.badge.variant}} float-end"
                                      *ngIf="item.badge">{{item.badge.text |
                                    translate}}</span>
                            </a>

                            <div class="collapse menu-dropdown" *ngIf="hasItems(item)">
                                <ul *ngIf="hasItems(item)" class="nav nav-sm flex-column" aria-expanded="false">
                                    <li *ngFor="let subitem of item.subItems" class="nav-item">
                                        <a [routerLink]="subitem.link" *ngIf="!hasItems(subitem)" class="nav-link"
                                           [attr.data-parent]="subitem.parentId" routerLinkActive="active"
                                           (click)="updateActive($event)">
                                            {{ subitem.label | translate}}
                                        </a>
                                        <a *ngIf="hasItems(subitem)" class="nav-link" href="javascript:void(0);"
                                           [attr.data-parent]="subitem.parentId" data-bs-toggle="collapse"
                                           aria-expanded="false" (click)="toggleSubItem($event)">
                                            {{ subitem.label | translate}}
                                        </a>
                                        <div class="collapse menu-dropdown" *ngIf="hasItems(subitem)">
                                            <ul *ngIf="hasItems(subitem)" class="nav nav-sm flex-column"
                                                aria-expanded="false">
                                                <li *ngFor="let subSubitem of subitem.subItems" class="nav-item">
                                                    <a [attr.data-parent]="subSubitem.parentId"
                                                       [routerLink]="subSubitem.link" routerLinkActive="active"
                                                       class="nav-link" (click)="updateActive($event)">
                                                        {{ subSubitem.label | translate }}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ng-container>
                </ul>
            </div>

            <div id="version">
                <div>{{appVersion}}</div>
            </div>
        </div>
        <!-- Sidebar -->
    </div>

</div>
<!-- Left Sidebar End -->
<!-- Vertical Overlay-->
<div class="vertical-overlay" (click)="SidebarHide()"></div>
