import {Action, createReducer, on} from '@ngrx/store';
import * as StatusMessageActions from './faq.actions';
import {FaqCategoryModel} from '../faqCategory.model';

export interface ReducerFaqState {
    faqs: FaqCategoryModel[]
}

export const initialStatusMessageState: ReducerFaqState = {
    faqs: null,
};

const faqReducer = createReducer(
    initialStatusMessageState,
    on(StatusMessageActions.FAQsLoadedAction, (state, {payload}) => ({
        ...state,
        faqs: payload
    })),
);

export function reducer(state: ReducerFaqState | undefined, action: Action): ReducerFaqState {
    return faqReducer(state, action);
}
