import {createSelector} from '@ngrx/store';
import {ReducerContactTypeState} from './contacttype.reducer';

export interface ContactTypeState {
  contactType: ReducerContactTypeState;
}

export const selectContactTypeState = (state: ContactTypeState) => state.contactType;

export const selectedContactTypeSelect = createSelector(
  selectContactTypeState,
  (state) => state.selectedContactType
);

export const allContactTypesSelect = createSelector(
  selectContactTypeState,
  (state) => state.contactTypes
);

export const allContactTypesForAllCompaniesSelect = createSelector(
  selectContactTypeState,
  (state) => state.contactTypesAllCompanies
);

export const allContactTypesForSelectBoxSelect = createSelector(
  selectContactTypeState,
  (state) => state.contactTypesForSelectBox
);
