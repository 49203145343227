import {ContactPerson} from '../contactperson.model';
import {ChatMessage} from '../chatMessage.model';
import {OrderType} from './ordertype/ordertype.model';
import {Address} from '../address.model';
import {FormTemplateModel} from '../form/formTemplate.model';
import {Task} from './task.model';
import {User} from '../user/user.model';
import {OrderStateEnum} from './orderstate.enum';
import {ContactType} from '../contacttype/contactType.model';
import {TaskStateEnum} from './taskStateEnum';
import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';
import {TaskType} from "./tasktype/tasktype.model";
import {Customer} from "../customer/customer.model";
import {AddressType} from "../addresstype/addressType.model";
import {ExternalIdModel} from "../customer/externalId.model";

export class Order {
    @JsonProperty() @JsonClassType({type: () => [String]})
    id: string;
    @JsonProperty() @JsonClassType({type: () => [ExternalIdModel]})
    externalId: ExternalIdModel;
    @JsonProperty() @JsonClassType({type: () => [OrderType]})
    orderType: OrderType;
    @JsonProperty() @JsonClassType({type: () => [Array, [ContactPerson]]})
    contactPersons: ContactPerson[] = [];
    @JsonProperty() @JsonClassType({type: () => [Date]})
    startDate: Date;
    @JsonProperty() @JsonClassType({type: () => [Date]})
    endDate: Date;
    @JsonProperty() @JsonClassType({type: () => [Date]})
    desiredDate: Date;
    @JsonProperty() @JsonClassType({type: () => [Array, [File]]})
    files: File[] = [];
    @JsonProperty() @JsonClassType({type: () => [Array, [ChatMessage]]})
    chatMessages: ChatMessage[] = [];
    @JsonProperty() @JsonClassType({type: () => [String]})
    description: string;
    @JsonProperty() @JsonClassType({type: () => [Address]})
    operationAddress: Address;
    @JsonProperty() @JsonClassType({type: () => [String]})
    orderState: OrderStateEnum;
    @JsonProperty() @JsonClassType({type: () => [Array, [FormTemplateModel]]})
    formTemplate: FormTemplateModel[];
    @JsonProperty() @JsonClassType({type: () => [Array, [Task]]})
    tasks: Task[] = [];
    @JsonProperty() @JsonClassType({type: () => [String]})
    customerId: string;
    @JsonProperty() @JsonClassType({type: () => [Boolean]})
    differentOperationAddress: string;
    @JsonProperty() @JsonClassType({type: () => [String]})
    companyId: string;
    @JsonProperty() @JsonClassType({type: () => [Customer]})
    customer: Customer;

    constructor(
        id: string,
        externalId: ExternalIdModel,
        @JsonClassType({type: () => [OrderType]}) orderType: OrderType,
        startDate: Date,
        endDate: Date,
        desiredDate: Date,
        description: string,
        @JsonClassType({type: () => [Address]}) operationAddress: Address,
        orderState: OrderStateEnum,
        customerId: string = null,
        customer: Customer = null
    ) {
        this.id = id;
        this.externalId = externalId;
        this.orderType = orderType;
        this.startDate = startDate;
        this.endDate = endDate;
        this.desiredDate = desiredDate;
        this.description = description;
        this.operationAddress = operationAddress;
        this.orderState = orderState;
        this.customerId = customerId;
    }

    public static fromServiceArray(ordersArray: any, contactTypes: ContactType[], users: User[], taskTypes: TaskType[], customerId: string, orderType: OrderType, addressTypes: AddressType[]): Order[] {
        const resultOrders = [];
        if (ordersArray) {
            for (const order of ordersArray) {
                resultOrders.push(Order.fromService(order, users, customerId, orderType, contactTypes, taskTypes, addressTypes));
            }
        }
        return resultOrders;
    }

    public static fromService(order: any, users: User[], customerId: string, orderType: OrderType, contactTypes: ContactType[], taskTypes: TaskType[], addressTypes: AddressType[]): Order {
        let startDate = new Date(order.startDateTime);
        let endDate = new Date(order.endDateTime);
        let desiredDate = new Date(order.desiredDateTime);
        if (endDate.toDateString() === new Date(0).toDateString()) {
            endDate = startDate;
        }
        if (startDate.toDateString() === new Date(0).toDateString()) {
            startDate = null;
        }
        if (endDate.toDateString() === new Date(0).toDateString()) {
            endDate = null;
        }
        if (desiredDate.toDateString() === new Date(0).toDateString()) {
            desiredDate = null;
        }
        const companyId = customerId.substring(customerId.indexOf('_') + 1, customerId.length);
        const objectMapper = new ObjectMapper();
        const returnOrder = objectMapper.parse<Order>(JSON.stringify({
                id: order.id,
                externalId: ExternalIdModel.fromService(order.externalId),
                orderType,
                contactPersons: ContactPerson.fromServiceArray(order.contactPeople, contactTypes),
                startDate,
                endDate,
                desiredDate,
                files: null,
                chatMessages: ChatMessage.fromServiceArray(order.comments, users),
                description: order.description,
                operationAddress: Address.fromService(order.operationAddress, addressTypes),
                orderState: null,
                formTemplate: FormTemplateModel.fromService(order.formTemplate),
                tasks: Task.fromServiceArray(order.subOrder, users, taskTypes),
                customerId,
                companyId
            }), {mainCreator: () => [Order]}
        );
        returnOrder.orderState = Order.getOrderState(returnOrder.tasks);
        return returnOrder;
    }

    public static toService(order: Order): any {
        let startDate = null;
        let endDate = null;
        order.tasks?.forEach(task => {
            if (!startDate || task.startDate?.getTime() < startDate.getTime()) {
                startDate = task.startDate;
            }
            if (!endDate || task.endDate?.getTime() > endDate.getTime()) {
                endDate = task.endDate;
            }
        });
        return {
            id: order.id,
            externalId: ExternalIdModel.toService(order.externalId),
            description: order.description,
            desiredDateTime: order.desiredDate?.toISOString(),
            startDateTime: startDate?.toISOString(),
            endDateTime: endDate?.toISOString(),
            contactPeople: ContactPerson.toServiceArray(order.contactPersons),
            orderTypeId: order.orderType?.id,
            operationAddress: Address.toService(order.operationAddress, true),
            formTemplate: FormTemplateModel.toService(order.formTemplate),
            comments: ChatMessage.toServiceArray(order.chatMessages),
            subOrder: Task.toServiceArray(order.tasks)
        };
    }

    public static getOrderState(tasks: Task[]): OrderStateEnum {
        let orderState = OrderStateEnum.HEAD_ONLY;
        const taskStates = [];
        tasks.forEach(task => {
            if ((taskStates.length === 0 || taskStates.findIndex(state => state === task.state) === -1) && task.state !== null) {
                taskStates.push(task.state);
            }
        });

        if (taskStates.length === 0) {
            return orderState;
        }

        if (Order.hasAtLeastOneOf(TaskStateEnum[TaskStateEnum.UNSCHEDULED], taskStates)) {
            orderState = OrderStateEnum.NEW;
        }

        if (Order.hasAtLeastOneOf(TaskStateEnum[TaskStateEnum.SCHEDULED], taskStates)) {
            orderState = OrderStateEnum.PARTIALLY_SCHEDULED;
        }

        if (Order.hasAtLeastOneOf(TaskStateEnum[TaskStateEnum.PAUSED], taskStates)) {
            orderState = OrderStateEnum.IN_PROGRESS;
        }

        if (Order.hasAllOf(TaskStateEnum[TaskStateEnum.IN_PROGRESS], taskStates)) {
            orderState = OrderStateEnum.IN_PROGRESS;
        }

        if (Order.hasAllOf(TaskStateEnum[TaskStateEnum.SCHEDULED], taskStates)) {
            return OrderStateEnum.SCHEDULED;
        }

        if (Order.hasAllOf(TaskStateEnum[TaskStateEnum.FINISHED], taskStates)) {
            return OrderStateEnum.FINISHED;
        }

        return orderState;
    }

    public static hasAtLeastOneOf(taskState: string, allTaskStates: string[]): boolean {
        return allTaskStates.some(state => state === taskState);
    }

    public static hasAllOf(taskState: string, allTaskStates: string[]): boolean {
        return allTaskStates.every(state => state === taskState);
    }

    public static deleteUserIdFromTask(order: Order, taskId: string, userId: string): Order {
        const taskIndex = order.tasks.findIndex(taskFind => taskFind.id === taskId);
        if (taskIndex > -1) {
            const tasks = [...order.tasks];
            const task = {...tasks[taskIndex]};
            const userIndex = task.userIds?.indexOf(userId);
            if (userIndex > -1) {
                const userIds = [...task.userIds];
                userIds.splice(userIndex, 1);
                if (userIds.length === 0) {
                    task.state = TaskStateEnum.UNSCHEDULED;
                }
                const taskFinal = {...task, userIds};
                tasks.splice(taskIndex, 1);
                tasks.push(taskFinal);
                const newOrder = {...order, tasks: tasks};
                return newOrder;
            }
        }
        return null;
    }

    public static isForCalendar(order: Order): boolean {
        if (order.orderState === OrderStateEnum.SCHEDULED ||
            order.orderState === OrderStateEnum.IN_PROGRESS ||
            order.orderState === OrderStateEnum.PARTIALLY_SCHEDULED) {
            return true;
        } else {
            return false;
        }
    }

    public static isForPlanning(order: Order): boolean {
        if (order.orderState === OrderStateEnum.PARTIALLY_SCHEDULED || order.orderState === OrderStateEnum.NEW) {
            return true;
        } else {
            return false;
        }
    }
}
