import {JsonClassType, JsonProperty, ObjectMapper} from "jackson-js";
import {DatevAddressModel} from "./datevAddress.model";
import {DatevErrors} from "./datevErrors.model";
import {DatevMappingErrorsModel} from "./datevMappingErrors.model";
export class DatevTaskModel {

    @JsonProperty() @JsonClassType({type: () => [String]})
    id: string;
    @JsonProperty() @JsonClassType({type: () => [String]})
    typeDescription: string;
    @JsonProperty() @JsonClassType({type: () => [String]})
    typeId: string;
    @JsonProperty() @JsonClassType({type: () => [String]})
    description: string;
    @JsonProperty() @JsonClassType({type: () => [Array, [DatevErrors]]})
    errors: DatevErrors[] = [];
    @JsonProperty() @JsonClassType({type: () => [Array, [DatevMappingErrorsModel]]})
    mappingErrors: DatevMappingErrorsModel[] = [];

    constructor(
        id: string,
        typeDescription: string,
        typeId: string,
        description: string,
        errors: DatevErrors[],
        mappingErrors: DatevMappingErrorsModel[]
    ){
        this.id = id;
        this.typeDescription = typeDescription;
        this.typeId = typeId;
        this.description = description;
        this.errors = errors;
        this.mappingErrors = mappingErrors;
    }

    public static fromService(task: any): DatevTaskModel {
        const objectMapper = new ObjectMapper();
        return objectMapper.parse<DatevTaskModel>(JSON.stringify({
            id: task.id,
            typeDescription: task.typeDescription,
            typeId: task.typeId,
            customerRecord: task.bla,
            description: task.description,
            errors: DatevErrors.fromServiceArray(task.errors),
            mappingErrors: DatevMappingErrorsModel.fromServiceArray(task.mappingErrors)
        }), {mainCreator: () => [DatevTaskModel]});
    }

    public static fromServiceArray(taskArray: any): DatevTaskModel[] {
        const resultTasks = [];
        if (taskArray) {
            for (const task of taskArray) {
                resultTasks.push(DatevTaskModel.fromService(task));
            }
        }
        return resultTasks;
    }

    public static toServiceArray(tasksArray: DatevTaskModel[]): any {
        const tasks = [];
        if (tasksArray) {
            for (const task of tasksArray) {
                tasks.push(DatevTaskModel.toService(task));
            }
        }
        return tasks;
    }

    public static toService(task: DatevTaskModel): any {
        return {
            id: task.id,
            typeDescription: task.typeDescription,
            typeId: task.typeId,
            description: task.description
        };
    }
}
