
export class FilterService {

  filterDataArray(val: any, dataArray: any, tempDataArray: any): any {
    const value = val.target.value.toLowerCase();
    // get the key names of each column in the dataset
    const keys = Object.keys(tempDataArray[0]);
    // assign filtered matches to the active datatable
    let result = tempDataArray.filter(item => {
      // iterate through each row's column data
      for (let i = 0; i < keys.length; i++) {

        if ( item[keys[i]] === 'Array' ) {
          console.log('array');
        }
        // check for a match
        if (
          (item[keys[i]] &&
            item[keys[i]]
              .toString()
              .toLowerCase()
              .indexOf(value) !== -1) ||
          !value
        ) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    });

    if (value === '') {
      result = tempDataArray;
    }

    return result;
  }
}
