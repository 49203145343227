import {FileInfoModel} from './fileInfo.model';
import {TemplateInfo} from "./templateInfo.model";

export class DocumentMetaDataModel {
  constructor(
    public id: string,
    public customerId: string,
    public orderId: string,
    public taskId: string,
    public uploadedByUserId: string,
    public uploadTime: Date,
    public fileName: string,
    public originalFileInfo: FileInfoModel,
    public previewFileInfo: FileInfoModel,
    public previewFileUrl: string,
    public originalFileUrl: string,
    public loaded: boolean,
    public isImage: boolean,
    public isPDF: boolean,
    public templateInfo: TemplateInfo
  ){}
}
