<div class="card">
    <div class="card-header align-items-center d-flex">
        <h4 class="card-title mb-0 flex-grow-1">{{description}}</h4>
    </div><!-- end card header -->
    <div class="card-body">
        <div class="table-responsive table-card">
            <table class="table table-borderless table-nowrap align-middle mb-0">
                <thead class="table-light text-muted">
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Start</th>
                    <th scope="col">Ende</th>
                    <th scope="col">Status</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let order of orders" (click)="openOrder(order)" class="cursor-pointer">
                    <td class="d-flex">
                        <h6 class="mb-0">{{order.externalId?.id}}</h6>
                    </td>
                    <td>
                        <h6 class="mb-0">{{order.operationAddress | fullName}}</h6>
                    </td>
                    <td>
                        <h6 class="mb-0">{{order.endDate | date: 'dd.MM.yyyy'}}</h6>
                    </td>
                    <td>
                        <h6 class="mb-0">{{order.endDate | date: 'dd.MM.yyyy'}}</h6>
                    </td>
                    <td>
                        <h6 class="mb-0"><span class="badge"
                                               [ngClass]="
                (order.orderState.valueOf() === 0 ) ? 'bg-danger' :
                (order.orderState.valueOf() === 1 ) ? 'bg-success' :
                (order.orderState.valueOf() === 2 ) ? 'bg-warning' :
                (order.orderState.valueOf() === 3 ) ? 'bg-info' :
                (order.orderState.valueOf() === 4 ) ? 'bg-dark' :
                (order.orderState.valueOf() === 5 ) ? 'bg-primary' : ''
                ">{{order.orderState | orderStatus | translate}}</span></h6>
                    </td>
                </tr><!-- end tr -->
                </tbody><!-- end tbody -->
            </table><!-- end table -->
        </div>
    </div>
</div>
