import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';
import {MessageStatus} from "./messageStatus";

export class MessageSummaryOverall {
  @JsonProperty() @JsonClassType({type: () => [MessageStatus]})
  overall: MessageStatus

  constructor(summary: MessageStatus) {
    this.overall = summary;
  }

  public static fromService(messageSummary: any): MessageSummaryOverall {
    const objectMapper = new ObjectMapper();
    const summary = objectMapper.parse<MessageSummaryOverall>( JSON.stringify( {
      overall: MessageStatus.fromService(messageSummary)}), {mainCreator: () => [MessageSummaryOverall]}
    );
    return summary;
  }
}
