import { Pipe, PipeTransform } from '@angular/core';
import {DatevImportOverview} from "../models/datev/datevImportOverview.model";
@Pipe({
  name: 'datevRecordCountByState'
})
export class DatevRecordCountByStatePipe implements PipeTransform {
  transform(datevOverview: DatevImportOverview, state: string): string {
      if (datevOverview && datevOverview.states) {
          const foundState = datevOverview.states.find(importState => importState.state === state);
          if (foundState) {
              return foundState.records + '';
          } else {
              return '0';
          }
      } else {
          return '0';
      }
  }
}
