import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
    NgbAccordionModule,
    NgbDatepickerModule, NgbNavModule, NgbPaginationModule, NgbTooltipModule, NgbTypeaheadModule
} from '@ng-bootstrap/ng-bootstrap';

import {FlatpickrModule} from 'angularx-flatpickr';
import {CountToModule} from 'angular-count-to';
import {NgApexchartsModule} from 'ng-apexcharts';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';

import {FullCalendarModule} from '@fullcalendar/angular'; // for FullCalendar!
// Load Icons

// Pages Routing
import {PagesRoutingModule} from "./pages-routing.module";
import {SharedModule} from "../shared/shared.module";
import {DashboardtileComponent} from "./dashboard/dashboardtile/dashboardtile.component";
import {DashboardComponent} from "./dashboard/dashboard.component";
import {TranslateModule} from "@ngx-translate/core";
import {OrderStatusPipe} from "../core/pipe/orderStatus.pipe";
import {DashboardStatComponent} from "./dashboard/projects-stat/dashboard-stat.component";
import {OrdersPerStatusComponent} from "./dashboard/orders-per-status/orders-per-status.component";
import {OrdersPerEmployeeComponent} from "./dashboard/orders-per-employee/orders-per-employee.component";
import {WelcomeComponent} from "./welcome/welcome/welcome.component";
import {CompanyOverviewComponent} from "./company/company-overview/company-overview.component";
import {CompanyListComponent} from "./company/list/company-list.component";
import {CompanyEditModalDialogComponent} from "./company/company-edit-modal-dialog/company-edit-modal-dialog.component";
import {CompanyEditComponent} from "./company/edit/company-edit.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {
    CompanyEmployeesOverviewComponent
} from "./company/company-employees-overview/company-employees-overview.component";
import {PersonEditComponent} from "./employee/edit/person-edit.component";
import {PersonListComponent} from "./employee/list/person-list.component";
import {PersonEditModalDialogComponent} from "./employee/person-edit-modal-dialog/person-edit-modal-dialog.component";
import {ColorPickerModule} from "ngx-color-picker";
import {NgSelectModule} from "@ng-select/ng-select";
import {TaskListPersonComponent} from "./employee/task-list-person/task-list-person.component";
import {TaskStatusPipe} from "../core/pipe/taskStatus.pipe";
import {OrderStatusToNumberPipe} from "../core/pipe/orderStatusToNumber.pipe";
import {TaskToNumberStatusPipe} from "../core/pipe/taskStatusToNumber.pipe";
import {WeekDayPipe} from "../core/pipe/weekDay.pipe";
import {
    CompanyBasicdataOverviewComponent
} from "./company/company-basicdata-overview/company-basicdata-overview.component";
import {AddresstypeComponent} from "./addresstype/addresstype.component";
import {AddresstypeListComponent} from "./addresstype/list/addresstype-list.component";
import {AddresstypeEditComponent} from "./addresstype/edit/addresstype-edit.component";
import {
    AddresstypeEditModalDialogComponent
} from "./addresstype/addresstype-edit-modal-dialog/addresstype-edit-modal-dialog.component";
import {ContacttypeComponent} from "./contacttype/contacttype.component";
import {ContactTypeListComponent} from "./contacttype/list/contact-type-list.component";
import {ContacttypeEditComponent} from "./contacttype/edit/contacttype-edit.component";
import {
    ContacttypeEditModalDialogComponent
} from "./contacttype/contacttype-edit-modal-dialog/contacttype-edit-modal-dialog.component";
import {OrdertypeComponent} from "./ordertype/ordertype.component";
import {
    OrdertypeEditModalDialogComponent
} from "./ordertype/ordertype-edit-modal-dialog/ordertype-edit-modal-dialog.component";
import {OrderTypeListComponent} from "./ordertype/list/order-type-list.component";
import {OrdertypeEditComponent} from "./ordertype/edit/ordertype-edit.component";
import {TaskTypeComponent} from "./taskType/taskType.component";
import {
    TaskTypeEditModalDialogComponent
} from "./taskType/tasktype-edit-modal-dialog/task-type-edit-modal-dialog.component";
import {TaskTypeListComponent} from "./taskType/list/task-type-list.component";
import {TaskTypeEditComponent} from "./taskType/edit/task-type-edit.component";
import {AngularSplitModule} from "angular-split";
import {SignaturePadModule} from "angular2-signaturepad";
import {CalendarComponent} from "./calendar/calendar.component";
import {DatevTransformationComponent} from "./datev/transformation/datevTransformation.component";
import {CustomerListComponent} from './customer/customer-list/customer-list.component';
import {CustomerEditComponent} from "./customer/edit/customer-edit.component";
import {OrderEditComponent} from "./orders/edit/order-edit.component";
import {FormtemplatesComponent} from "./common/formtemplatecomponent/formtemplates/formtemplates.component";
import {
    TaskEditModalDialogComponent
} from "./orders/task-edit-modal-dialog/task-edit-modal-dialog.component";
import {TaskComponent} from "./common/subordercomponent/task/task.component";
import {CustomerViewComponent} from "./common/customer-view/customer-view.component";
import {ContactpersonsComponent} from "./common/contactpersoncomponent/contactpersons/contactpersons.component";
import {OrderStatusComponent} from "./common/order-status/order-status.component";
import {TaskStatusComponent} from "./common/task-status/task-status.component";
import {DocumentsComponent} from "./common/documentcomponent/documents/documents.component";
import {TaskCardComponent} from "./common/cards/task-card/task-card.component";
import {PlusCardComponent} from "./common/cards/plus-card/plus-card/plus-card.component";
import {
    CustomerContactpersonCardComponent
} from "./common/cards/customer-contactperson-card/customer-contactperson-card.component";
import {ChatcomponentComponent} from "./common/chatcomponent/chatcomponent.component";
import {TaskEditComponent} from "./orders/task-edit/task-edit.component";
import {OrderEditModalComponent} from './orders/order-edit-modal/order-edit-modal.component';
import {MessageComponent} from "./message/message.component";
import {ConfigurationComponent} from "./message/configuration/configuration.component";
import {PerfectScrollbarModule} from "ngx-perfect-scrollbar";
import { CustomerEditModalDialogComponent } from './customer/customer-edit-modal-dialog/customer-edit-modal-dialog.component';
import {FullNamePipe} from "../core/pipe/fullName.pipe";
import {DatevRecordCountByStatePipe} from "../core/pipe/datevRecordCountByType.pipe";
import {PersonTaskListComponent} from "./employee/person-task-list/person-task-list.component";
import {TaskEditMobileComponent} from "./orders/task-edit-mobile/task-edit-mobile.component";
import {EditOrderMobileComponent} from "./orders/edit-mobile/edit-order-mobile.component";
import { DatevErrorComponent } from './datev/datev-error/datev-error.component';
import { DatevOverviewStateComponent } from './datev/datev-overview-state/datev-overview-state.component';
import { DatevErrorListComponent } from './datev/datev-error-list/datev-error-list.component';
import { FaqsComponent } from './faqs/faqs.component';
import {FullAddressPipe} from "../core/pipe/fullAddress.pipe";
import {PickerModule} from "@ctrl/ngx-emoji-mart";
import {SimplebarAngularModule} from "simplebar-angular";
import {
    SidebarOrderListComponent
} from "../layouts/rightsidebar/orderlist/sidebar-order-list/sidebar-order-list.component";
import { ContactoptioncomponentComponent } from './common/contactoptioncomponent/contactoptioncomponent.component';
import { VacationEntitlementsEditComponent } from './employee/vacation-entitlements-edit/vacation-entitlements-edit.component';
import { VacationListComponent } from './employee/vacation-list/vacation-list.component';
import { VacationEditComponent } from './employee/vacation-edit/vacation-edit.component';

@NgModule({
    declarations: [
        DashboardtileComponent,
        DashboardStatComponent,
        OrdersPerStatusComponent,
        OrdersPerEmployeeComponent,
        DashboardComponent,
        WelcomeComponent,
        CompanyOverviewComponent,
        OrderStatusPipe,
        FullNamePipe,
        FullAddressPipe,
        DatevRecordCountByStatePipe,
        TaskStatusPipe,
        OrderStatusToNumberPipe,
        TaskToNumberStatusPipe,
        WeekDayPipe,
        CompanyListComponent,
        CompanyEditModalDialogComponent,
        CompanyEditComponent,
        CompanyEmployeesOverviewComponent,
        PersonEditComponent,
        PersonListComponent,
        PersonEditModalDialogComponent,
        TaskListPersonComponent,
        CompanyBasicdataOverviewComponent,
        AddresstypeComponent,
        AddresstypeListComponent,
        AddresstypeEditComponent,
        AddresstypeEditModalDialogComponent,
        ContacttypeComponent,
        ContactTypeListComponent,
        ContacttypeEditComponent,
        ContacttypeEditModalDialogComponent,
        OrdertypeComponent,
        OrdertypeEditModalDialogComponent,
        OrderTypeListComponent,
        OrdertypeEditComponent,
        TaskTypeComponent,
        TaskTypeEditModalDialogComponent,
        TaskTypeListComponent,
        TaskTypeEditComponent,
        CalendarComponent,
        DatevTransformationComponent,
        CustomerListComponent,
        CustomerEditComponent,
        OrderEditComponent,
        FormtemplatesComponent,
        TaskEditModalDialogComponent,
        TaskComponent,
        CustomerViewComponent,
        ContactpersonsComponent,
        OrderStatusComponent,
        TaskStatusComponent,
        DocumentsComponent,
        TaskCardComponent,
        PlusCardComponent,
        CustomerContactpersonCardComponent,
        ChatcomponentComponent,
        TaskEditComponent,
        OrderEditModalComponent,
        MessageComponent,
        ConfigurationComponent,
        CustomerEditModalDialogComponent,
        PersonTaskListComponent,
        TaskEditMobileComponent,
        EditOrderMobileComponent,
        DatevErrorComponent,
        DatevOverviewStateComponent,
        DatevErrorListComponent,
        FaqsComponent,
        SidebarOrderListComponent,
        ContactoptioncomponentComponent,
        VacationEntitlementsEditComponent,
        VacationListComponent,
        VacationEditComponent
    ],
    imports: [
        FullCalendarModule,
        PerfectScrollbarModule,
        CommonModule,
        CountToModule,
        NgApexchartsModule,
        NgbDropdownModule,
        PagesRoutingModule,
        SharedModule,
        TranslateModule,
        NgbTypeaheadModule,
        ReactiveFormsModule,
        FormsModule,
        ColorPickerModule,
        NgSelectModule,
        NgbDatepickerModule,
        AngularSplitModule,
        FullCalendarModule,
        NgbNavModule,
        NgbTooltipModule,
        NgbAccordionModule,
        SignaturePadModule,
        NgbPaginationModule,
        PickerModule,
        SimplebarAngularModule,
        FlatpickrModule,
    ],
    providers: [],
    exports: [
        TaskEditComponent,
        FullAddressPipe
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PagesModule {
}
