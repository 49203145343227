import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';

export class TaskType {
  @JsonProperty() @JsonClassType({type: () => [String]})
  id: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  description: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  initials: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  color: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  formTemplate: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  rgbaColor: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  companyId: string;
  @JsonProperty() @JsonClassType({type: () => [Array, [String]]})
  documentIds: string[];

  constructor(
    id: string,
    description: string,
    initials: string,
    color: string,
    formTemplate: string,
    rgbaColor: string,
    companyId: string,
    documentIds: string[]
  ){
    this.id = id;
    this.description = description;
    this.initials = initials;
    this.color = color;
    this.formTemplate = formTemplate;
    this.rgbaColor = rgbaColor;
    this.companyId = companyId;
    this.documentIds = documentIds;
  }


  public static fromServiceArray(taskTypeArray: any): TaskType[] {
    const taskTypes: TaskType[] = [];
    for (const taskType of taskTypeArray) {
      taskTypes.push(TaskType.fromService(taskType));
    }
    return taskTypes;
  }

  public static fromService(taskType: any): TaskType {
    const objectMapper = new ObjectMapper();
    let documentIds = [];
    if (taskType.documentIds) {
      documentIds = taskType.documentIds
    }
    return objectMapper.parse<TaskType>( JSON.stringify( {
      id: taskType.id,
      description: taskType.description,
      initials: taskType.initials,
      color: taskType.color,
      formTemplate: taskType.formTemplate,
      rgbaColor: TaskType.hexColor(taskType.color),
      companyId: taskType.companyId,
      documentIds: documentIds
  }), {mainCreator: () => [TaskType]});
  }

  public static toService(taskType: TaskType, companyId: string): any {
    return {
      id: taskType.id,
      description: taskType.description,
      initials: taskType.initials,
      color: taskType.color,
      formTemplate: taskType.formTemplate,
      companyId,
      documentIds: taskType.documentIds
    };
  }

  public static hexColor(color: string): string {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(color)) {
      c = color.substring(1).split('');
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      // tslint:disable-next-line:no-bitwise
      return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0.1)';
    }
    return null;
  }
}
