import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {merge, Observable, OperatorFunction, Subject, Subscription} from "rxjs";
import {NgbActiveModal, NgbModal, NgbTypeahead} from "@ng-bootstrap/ng-bootstrap";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Order} from "../../../core/models/order/order.model";
import {orderTypeSelectedAction} from "../../../core/models/order/ordertype/store/ordertype.actions";
import {Address} from "../../../core/models/address.model";
import { saveOrderAction} from "../../../core/models/order/store/order.actions";
import {ObjectMapper} from "jackson-js";
import {OrderType} from "../../../core/models/order/ordertype/ordertype.model";
import {debounceTime, distinctUntilChanged, filter, map} from "rxjs/operators";
import {DatePipe} from "@angular/common";
import {Store} from "@ngrx/store";
import {CustomerOverviewState} from "../../../core/models/customer/customerOverview/store";
import {OrderState, selectedOrderSelect} from "../../../core/models/order/store";
import {UserState} from "../../../core/models/user/store";
import {allOrderTypesForSelectBoxSelect, OrderTypeState} from "../../../core/models/order/ordertype/store";
import {CompanyState} from "../../../core/models/company/store";
import {CustomerState, selectedCustomerSelect} from "../../../core/models/customer/store";
import {Customer} from "../../../core/models/customer/customer.model";

@Component({
    selector: 'app-order-edit-modal',
    templateUrl: './order-edit-modal.component.html',
    styleUrls: ['./order-edit-modal.component.scss']
})
export class OrderEditModalComponent implements OnInit, OnDestroy {
    orderForm: FormGroup;

    @ViewChild('instanceOrderType') instanceOrderType: NgbTypeahead;
    @ViewChild('createNewOrderType') createNewOrderType: any;

    differentOperationAddress = false;

    focusOrderType$ = new Subject<string>();
    clickOrderType$ = new Subject<string>();

    selectedOrder$: Observable<Order> = this.orderStore.select(selectedOrderSelect);
    selectedOrderSub: Subscription;
    selectedOrder: Order;

    selectedCustomer$: Observable<Customer> = this.customerStore.select(selectedCustomerSelect);
    selectedCustomerSub: Subscription;
    selectedCustomer: Customer;

    orderTypes$: Observable<OrderType[]> = this.orderTypeStore.select(allOrderTypesForSelectBoxSelect);
    orderTypesSub: Subscription;
    orderTypes;

    constructor(
        private modalService: NgbModal,
        private datePipe: DatePipe,
        private customerOverviewStore: Store<CustomerOverviewState>,
        private orderStore: Store<OrderState>,
        private userStore: Store<UserState>,
        private orderTypeStore: Store<OrderTypeState>,
        private companyStore: Store<CompanyState>,
        private customerStore: Store<CustomerState>,
        public activeModal: NgbActiveModal,
    ) {
        this.orderForm = new FormGroup({
            id: new FormControl(),
            customerId: new FormControl(null, Validators.required),
            orderType: new FormControl(null),
            externalId: new FormControl(),
            description: new FormControl(null, Validators.required),
            differentOperationAddress: new FormControl(),
            operationAddress: new FormGroup({
                name: new FormControl(),
                companyName: new FormControl(),
                city: new FormControl(),
                zipcode: new FormControl(),
                street: new FormControl(),
            }),
            startDate: new FormControl(new Date()),
            endDate: new FormControl(new Date()),
            desiredDate: new FormControl(new Date())
        });
        this.orderForm.get('externalId')?.disable();
    }

    ngOnDestroy(): void {
        this.selectedCustomerSub.unsubscribe();
        this.selectedOrderSub.unsubscribe();
        this.orderTypesSub.unsubscribe();
        this.orderTypes = null;
    }

    ngOnInit(): void {
        this.selectedOrderSub = this.selectedOrder$.subscribe(
            (order: Order) => {
                this.selectOrder(order);
            }
        );

        this.orderTypesSub = this.orderTypes$.subscribe(
            (orderTypes: OrderType[]) => {
                if (orderTypes) {
                    this.orderTypes = orderTypes;
                }
            }
        );

        this.selectedCustomerSub = this.selectedCustomer$.subscribe(
            (customer: Customer) => {
                this.selectNewCustomer(customer);
            }
        );
    }

    selectOrder(order: Order): void {
        if (order && this.orderForm) {
            this.selectedOrder = order;
            this.orderForm.patchValue(order);
            if ( this.selectedOrder.orderType) {
                const orderType = this.orderTypes.find(type => type.id === this.selectedOrder.orderType.id);
                if (orderType) {
                    this.orderTypeStore.dispatch(orderTypeSelectedAction({payload: orderType}));
                }
            }
            this.differentOperationAddress = false;
            if (this.selectedCustomer && order.operationAddress) {
                this.differentOperationAddress = !Address.isSameAddress(this.selectedCustomer.mainAddress, order.operationAddress);
            }
            this.orderForm.get('differentOperationAddress').patchValue(this.differentOperationAddress);
        }
    }

    resetForm(): void {
        this.orderForm?.reset();
        this.instanceOrderType?.writeValue(null);
    }

    saveOrder(): void {
        const objectMapper = new ObjectMapper();
        const orderFormValue: Order = this.orderForm.value;
        const order: Order = objectMapper.parse<Order>(JSON.stringify(orderFormValue), {mainCreator: () => [Order]});
        const newOrder = {...order, chatMessages: this.selectedOrder.chatMessages, tasks: this.selectedOrder.tasks, contactPersons: this.selectedOrder.contactPersons};
        if (!this.differentOperationAddress) {
            newOrder.operationAddress = this.selectedCustomer.mainAddress;
        }
        this.orderStore.dispatch(saveOrderAction({payload: newOrder, customerId: this.selectedCustomer.id}));
        this.modalService.dismissAll();
    }

    selectOrderType(item): void {
        if (item) {
            const orderType: OrderType = item.item;
            if (orderType.id === '-1') {
                this.openModalDialog(this.createNewOrderType);
            } else {
                this.orderTypeStore.dispatch(orderTypeSelectedAction({payload: orderType}));
            }
        }
    }

    openModalDialog(content): void {
        this.modalService.open(content, {size: 'md', backdrop: 'static', keyboard: false});
    }

    selectNewOrderType(orderType: OrderType): void {
        if (orderType && this.orderForm && this.instanceOrderType) {
            const orderTypeForm: FormGroup = this.orderForm.controls.orderType as FormGroup;
            orderTypeForm.patchValue(orderType);
            this.instanceOrderType.writeValue(orderType);
        }
    }

    selectNewCustomer(customer: Customer): void {
        if (customer && this.orderForm) {
            this.selectedCustomer = customer;
            this.orderForm.patchValue({customerId: customer.id});
        }
    }

    clickIsDifferentOperationAddress(): void {
        this.differentOperationAddress = !this.differentOperationAddress;
    }

    Orderformatter = (x: OrderType) => x.description;
    searchOrderType: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const clicksWithClosedPopup$ = this.clickOrderType$.pipe(filter(() => !this.instanceOrderType.isPopupOpen()));
        const inputFocus$ = this.focusOrderType$;

        return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
            map(term => (term === '' ? this.orderTypes
                : this.orderTypes.filter(v => v.description?.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
        );
    }


}
