<div class="card-body px-4">
  <div class="row align-items-center team-row" (click)="onMyClick()">
    <div class="col team-settings">
      <div class="row align-items-center">
        <div class="col text-end dropdown" ngbDropdown>
          <a class="arrow-none" data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
            <i class="ri-more-fill fs-17"></i>
          </a>
          <ul class="dropdown-menu" ngbDropdownMenu>
            <li><a class="dropdown-item"><i class="ri-eye-fill text-muted me-2 align-bottom"></i>{{'labels.editTask' | translate}}</a></li>
          </ul>
        </div>

      </div>
    </div>
    <div class="col-lg-5 col">
      <div class="team-profile-img">
        <div class="col-2  avatar-lg img-thumbnail rounded-circle">
          <div class="avatar-title rounded-circle">
            {{ task.taskType?.description.charAt(0) }}
          </div>
        </div>
        <div class="team-content text-truncate">
          <a class="d-block"><h5
                  class="fs-16 mb-1">{{task.sortOrder + 1}} - {{task.description}}</h5></a>
          <p class="text-muted mb-0">{{task.taskType?.description}}</p>
        </div>
      </div>
    </div>
    <div class="col-lg-2 col">
      <div class="row text-muted text-center">
        <div class="col-12 border-end border-end-dashed">
          <h5 class="mb-1">{{task.state?.toString() | taskStatus | translate}}</h5>
          <p class="text-muted mb-0">{{'labels.status' | translate}}</p>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col">
      <div class="row text-muted text-center">
        <div class="col-6 border-end border-end-dashed">
          <h5 class="mb-1">{{task.startDate | date: 'dd.MM.yyyy HH:mm'}}</h5>
          <p class="text-muted mb-0">{{'labels.startdate' | translate}}</p>
        </div>
        <div class="col-6 border-end border-end-dashed">
          <h5 class="mb-1">{{task.endDate | date: 'dd.MM.yyyy HH:mm'}}</h5>
          <p class="text-muted mb-0">{{'labels.enddate' | translate}}</p>
        </div>
      </div>
    </div>
    <div class="col-1">
      <div class="row text-muted text-center">
        <div class="avatar-group">
          <div class="avatar-group-item" *ngFor="let taskUser of task.userIds">
            <ng-container *ngFor="let user of users$ |async">
              <ng-container *ngIf="user.userId === taskUser">

                <a href="javascript: void(0);" class="d-block" ngbTooltip="{{user.firstName + ' ' + user.lastName}}"
                   placement="top">
                  <div class="avatar-xxs">
                                                <span class="avatar-title rounded-circle" [ngStyle]="{'background-color': user?.color}">
                                                    {{ user.firstName?.charAt(0) }}{{ user.lastName?.charAt(0) }}
                                                </span>
                  </div>
                </a>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
