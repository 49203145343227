import {JsonClassType, JsonProperty, ObjectMapper} from "jackson-js";

export class HostIdentModel {

    @JsonProperty() @JsonClassType({type: () => [String]})
    virtualHostName: string;

    constructor(virtualHostName: string) {
        this.virtualHostName = virtualHostName;
    }


    public static fromService(hostIdentModel: any): HostIdentModel {
        const objectMapper = new ObjectMapper();
        return objectMapper.parse<HostIdentModel>(JSON.stringify({
            virtualHostName: hostIdentModel['virtual-host-name']
        }), {mainCreator: () => [HostIdentModel]});
    }
}
