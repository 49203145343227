import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Customer} from '../../../core/models/customer/customer.model';
import {Observable, Subscription, timer} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {OrderType} from '../../../core/models/order/ordertype/ordertype.model';
import {User} from '../../../core/models/user/user.model';
import {ChatMessage} from '../../../core/models/chatMessage.model';
import {DatePipe} from '@angular/common';
import {Store} from '@ngrx/store';
import {
  orderSelectedAction,
  selectOrderAction
} from '../../../core/models/order/store/order.actions';
import {filesOfSelectedOrderSelect, OrderState, selectedOrderSelect} from '../../../core/models/order/store';
import {Order} from '../../../core/models/order/order.model';
import {allUsersSelect, UserState} from '../../../core/models/user/store';
import {
  OrderTypeState,
  selectedOrderTypeSelect
} from '../../../core/models/order/ordertype/store';
import {orderTypeSelectedAction} from '../../../core/models/order/ordertype/store/ordertype.actions';
import {activeCompanySelect, CompanyState} from '../../../core/models/company/store';
import {Company} from '../../../core/models/company/company.model';
import {CustomerState, selectedCustomerSelect} from '../../../core/models/customer/store';
import {customerSelectedAction, selectCustomerAction} from '../../../core/models/customer/store/customer.actions';
import {ContactTypeState} from '../../../core/models/contacttype/store';
import {ActivatedRoute} from '@angular/router';
import {FormTemplateModel} from '../../../core/models/form/formTemplate.model';
import {OrderEditModalComponent} from "../order-edit-modal/order-edit-modal.component";
import {DocumentMetaDataModel} from "../../../core/models/file/documentMetaData-model";
import {DocumentManagementApiService} from "../../../core/services/document-management-api.service";

@Component({
  selector: 'app-order-edit',
  templateUrl: './order-edit.component.html',
  styleUrls: ['./order-edit.component.scss']
})
export class OrderEditComponent implements OnInit, OnDestroy, AfterViewInit {
  createNew = false;

  order$: Observable<Order> = this.orderStore.select(selectedOrderSelect);
  orderSub: Subscription;
  order: Order;

  employees: User[];

  selectedOrderType$: Observable<OrderType> = this.orderTypeStore.select(selectedOrderTypeSelect);
  selectedOrderTypeSub: Subscription;
  selectedOrderType: OrderType;

  selectedOrder$: Observable<Order> = this.orderStore.select(selectedOrderSelect);
  selectedOrderSub: Subscription;
  selectedOrder: Order;

  filesOfSelectedOrder$: Observable<DocumentMetaDataModel[]> = this.orderStore.select(filesOfSelectedOrderSelect);
  filesOfSelectedOrderSub: Subscription;
  filesOfSelectedOrder: DocumentMetaDataModel[];

  activeCompany$: Observable<Company> = this.companyStore.select(activeCompanySelect);
  activeCompanySub: Subscription;
  activeCompany: Company;

  selectedCustomer$: Observable<Customer> = this.customerStore.select(selectedCustomerSelect);
  selectedCustomerSub: Subscription;
  selectedCustomer: Customer;

  orderMessages: ChatMessage[] = [];

  users$: Observable<User[]> = this.userStore.select(allUsersSelect);
  usersSub: Subscription;
  users: User[];

  geocoder;
  templates: FormTemplateModel[] = [];
  @ViewChild('FileSelectInputDialog') FileSelectInputDialog: ElementRef;

  @ViewChild('createNewOrderType') createNewOrderType: any;

  differentOperationAddress = false;

  editable: boolean = true;

  files: DocumentMetaDataModel[] = [];

  constructor(private modalService: NgbModal,
              private datePipe: DatePipe,
              private orderStore: Store<OrderState>,
              private userStore: Store<UserState>,
              private orderTypeStore: Store<OrderTypeState>,
              private companyStore: Store<CompanyState>,
              private customerStore: Store<CustomerState>,
              private contactTypeStore: Store<ContactTypeState>,
              private route: ActivatedRoute,
              private documentService: DocumentManagementApiService
              ) {

    this.usersSub = this.users$.subscribe(users => {
      if (users) {
        this.users = users;
      }
    });

    this.route.params.subscribe(params => {
      const customerId = params['customerId'];
      const orderId = params['orderId'];
      this.editable = !params['editable'] ? true : false;
      if (customerId && orderId) {
          this.customerStore.dispatch(selectCustomerAction({customerId: params['customerId']}));
          if (orderId !== '0') {
            this.orderStore.dispatch(selectOrderAction({customerId: params['customerId'], orderId: params['orderId']}));
          } else {
            this.createNew = true;
            this.orderStore.dispatch(orderSelectedAction({payload: new Order(null, null, null, null, null, null, null, null, null, null, null)}));
          }
      }
    });
  }

  ngAfterViewInit(): void {
    if ( this.createNew) {
      this.editOrder();
    }
  }

  ngOnDestroy(): void {
    this.orderSub.unsubscribe();
    this.activeCompanySub.unsubscribe();
    this.selectedCustomerSub.unsubscribe();
    this.selectedOrderTypeSub.unsubscribe();
    this.selectedOrderSub.unsubscribe();
    this.usersSub.unsubscribe();

    this.orderStore.dispatch(orderSelectedAction({payload: null}));
    this.customerStore.dispatch(customerSelectedAction({payload: null}));
    this.orderTypeStore.dispatch(orderTypeSelectedAction({payload: null}));
  }

  ngOnInit(): void {
    this.selectedOrderSub = this.selectedOrder$.subscribe(
        (order: Order) => {
          this.orderMessages = [];
          this.templates = [];
          if (order) {
            this.selectedOrder = order;
            this.loadFiles();
            this.fillTemplate();
            this.orderMessages = [...order.chatMessages];
            order.tasks?.forEach(task => {
              this.employees = this.users.filter(user => task.userIds?.includes(user.userId));
            });
          }
        }
    );

    this.orderSub = this.order$.subscribe(
        (order: Order) => {
          if (order) {
            this.order = order;
          }
        }
    );

    this.activeCompanySub = this.activeCompany$.subscribe(
        (company: Company) => {
          this.activeCompany = company;
        }
    );

    this.selectedCustomerSub = this.selectedCustomer$.subscribe(
        (customer: Customer) => {
          this.selectNewCustomer(customer);
        }
    );

    this.selectedOrderTypeSub = this.selectedOrderType$.subscribe(
        (orderType: OrderType) => {
          this.selectedOrderType = orderType;
        }
    );
  }

  loadFiles(): void {
    this.files = [];
    this.selectedOrder.orderType?.documentIds?.forEach(id => {
        this.loadAndAddFileToArray(id);
    });

    this.selectedOrder?.tasks?.forEach(task => {
       task.taskType?.documentIds?.forEach(id => {
           this.loadAndAddFileToArray(id);
       });
    });

    this.filesOfSelectedOrderSub = this.filesOfSelectedOrder$.subscribe( files => {
      if (files) {
        files.forEach((file, index) => {
          if (file.templateInfo?.isChild && file.templateInfo?.type === 'LATEST_CHILD') {
            // prüfen ob schon ein parent existiert
            const baseIndexes = [];
            this.files.forEach((baseFile, index2) => {
              if (file.templateInfo.templateDocumentId === baseFile.templateInfo.templateDocumentId){
                baseIndexes.push(index2);
              }
            });
            if (baseIndexes.length > 0) {
              baseIndexes.forEach(index => {
                this.files.splice(index, 1);
              });
            }
            this.files.push(file);
          }
        });
      }
    });
  }

    private loadAndAddFileToArray(id: string) {
        this.documentService.getFileInfoById(id).subscribe(fileInfo => {
          if (this.files.findIndex(file => file.id === fileInfo.id) === -1) {
            // ist file ein Master?
            if (fileInfo.templateInfo.isRoot || fileInfo.templateInfo.isChild) {
              if (this.files.findIndex(file => file.templateInfo.templateDocumentId === fileInfo.templateInfo.templateDocumentId && file.templateInfo.isChild) === -1) {
                this.files.push(fileInfo);
              }
            } else {
              this.files.push(fileInfo);
            }
          }
        });
    }

    fillTemplate(): void {
    this.templates = [];
    let emptyTemplates = this.getEmptyTemplatesFromOrderType(this.selectedOrder);
    emptyTemplates = emptyTemplates.concat(this.getEmptyTemplatesFromTaskTypes(this.selectedOrder));
    let templatesFromOrder = this.getTemplatesFromOrder(this.selectedOrder);
    templatesFromOrder = templatesFromOrder.concat(this.getTemplatesFromTasks(this.selectedOrder));

    templatesFromOrder.forEach( orderTemplate => {
      const index = emptyTemplates.findIndex(emptyTemplate => emptyTemplate.id === orderTemplate.id);
      if (index > -1) {
        emptyTemplates[index] = orderTemplate;
      } else {
        emptyTemplates.push(orderTemplate);
      }
    });
    this.templates = emptyTemplates;
  }

  private getTemplatesFromOrder(order: Order): any[] {
    let templatesFromOrder = [];
    if (order && order.formTemplate && order.formTemplate.length > 0) {
      templatesFromOrder = templatesFromOrder.concat(JSON.parse(JSON.stringify(this.selectedOrder.formTemplate)));
    }
    return templatesFromOrder;
  }

  private getTemplatesFromTasks(order: Order): any[] {
    let templatesFromOrder = [];
    order.tasks?.forEach(task => {
      if (task.formTemplate && task.formTemplate.length > 0) {
        templatesFromOrder = templatesFromOrder.concat(JSON.parse(JSON.stringify(task.formTemplate)));
      }
    });
    return templatesFromOrder;
  }

  private getEmptyTemplatesFromOrderType(order: Order): any[] {
    let emptyTemplates = [];
    if (order.orderType && order.orderType.formTemplate) {
      emptyTemplates = emptyTemplates.concat(JSON.parse(this.selectedOrderType.formTemplate));
    }
    return emptyTemplates;
  }

  private getEmptyTemplatesFromTaskTypes(order: Order): any[] {
    let emptyTemplates = [];
    order.tasks.forEach(task => {
      if (task.taskType?.formTemplate) {
        emptyTemplates = emptyTemplates.concat(JSON.parse(task.taskType?.formTemplate));
      }
    });
    return emptyTemplates;
  }

  editOrder() {
    this.modalService.open(OrderEditModalComponent, {size: 'lg', animation: true, centered: true, backdrop: 'static', keyboard: false});
  }

  selectNewCustomer(customer: Customer): void {
    if (customer) {
      this.selectedCustomer = customer;
    }
  }

  public openFile(document: DocumentMetaDataModel): void {
    this.documentService.openFile(document);
  }

}
