import { Component, OnInit } from '@angular/core';
import {AddressType} from '../../../core/models/addresstype/addressType.model';
import {addressTypeSelectedAction} from 'src/app/core/models/addresstype/store/addresstype.actions';
import {Observable, Subscription} from 'rxjs';
import {AddressTypeState, allAddressTypesSelect} from '../../../core/models/addresstype/store';
import {Store} from '@ngrx/store';
import {
  AddresstypeEditModalDialogComponent
} from "../addresstype-edit-modal-dialog/addresstype-edit-modal-dialog.component";
import {NgbModalRef} from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
import {Company} from "../../../core/models/company/company.model";
import {activeCompanySelect, CompanyState} from "../../../core/models/company/store";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-addresstype-list',
  templateUrl: './addresstype-list.component.html',
  styleUrls: ['./addresstype-list.component.scss']
})
export class AddresstypeListComponent implements OnInit {
  addressTypes$: Observable<AddressType[]> = this.addressTypeStore.select(allAddressTypesSelect);
  addressTypesSubscription: Subscription;

  addressTypes: AddressType[];
  addressTypesFiltered: AddressType[];

  loadingIndicator = true;

  addressTypeModal: NgbModalRef;

  activeCompany$: Observable<Company> = this.companyStore.select(activeCompanySelect);
  activeCompanySubscription: Subscription;
  activeCompany: Company;

  searchTerm: string;

  constructor(private addressTypeStore: Store<AddressTypeState>,
              private modalService: NgbModal,
              private companyStore: Store<CompanyState>,) { }

  ngOnInit(): void {
    this.activeCompanySubscription = this.activeCompany$.subscribe(company => {
      if (company) {
        this.activeCompany = company;
      }
    });
    this.addressTypesSubscription = this.addressTypes$.subscribe(addressTypes => {
      this.addressTypes = addressTypes;
      this.addressTypesFiltered = addressTypes;
    });
  }

  onSelect(selected): void {
    const addressType: AddressType = selected;
    this.addressTypeStore.dispatch(addressTypeSelectedAction({payload: addressType}));
    this.openDialog();
  }

  updateFilter(event): void {
    let val = event.target.value.toLowerCase();
    this.searchTerm = val;
    if (val.length > 0) {
      this.addressTypesFiltered = this.addressTypes.filter(type => type.description.toLowerCase().includes(val.toLowerCase()));
    } else {
      this.addressTypesFiltered = this.addressTypes;
    }
  }

  newAddressType(): void {
    this.addressTypeStore.dispatch(addressTypeSelectedAction({payload: null}));
    this.openDialog();
  }

  openDialog(): void {
    this.addressTypeModal = this.modalService.open(AddresstypeEditModalDialogComponent, {size: 'lg', animation: true, centered: true, backdrop: 'static', keyboard: false});
    this.addressTypeModal.componentInstance.companyId = this.activeCompany.companyId;
  }

  ngOnDestroy(): void {
    this.activeCompanySubscription.unsubscribe();
    this.addressTypesSubscription.unsubscribe();
  }
}
