import {Injectable} from '@angular/core';
import {UserManagementApiService} from '../../../services/user-management-api.service';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, mergeMap, switchMap} from 'rxjs/operators';
import {
  activeCompanySetAction,
  companiesLoadedAction,
  companySavedAction,
  loadAllCompaniesAction,
  saveCompanyAction,
  setActiveCompanyAction
} from './company.actions';
import {Company} from '../company.model';
import {ClearStatesService} from '../../../services/clearstates.service';
import {ToastService} from "../../../services/Toast/toast-service";

@Injectable()
export class CompanyEffects {
  constructor(
    private httpApiService: UserManagementApiService,
    private notificationService: ToastService,
    private actions$: Actions,
    private clearStatesService: ClearStatesService) {}

  loadCompanies$ = createEffect(() => this.actions$.pipe(
    ofType(loadAllCompaniesAction),
    mergeMap(() => this.httpApiService.loadCompanies()
      .pipe(
        map(companies => (companiesLoadedAction({payload: companies})))
      ))
  ));

  saveCompany$ = createEffect(() => this.actions$.pipe(
    ofType(saveCompanyAction),
    switchMap(({payload}) => this.httpApiService.saveCompany(payload)
      .pipe(
        map(( company: Company) => {
          return companySavedAction({payload: company});
        }),
      )
    )));

  activeCompany$ = createEffect(() => this.actions$.pipe(
    ofType(setActiveCompanyAction),
    switchMap(({payload}) => this.httpApiService.loadCompanyById(payload)
      .pipe(
        map(( company: Company) => {
          return activeCompanySetAction({payload: company});
        })
      )
    )));
}
