import {createAction, props} from '@ngrx/store';
import {MessageModel} from '../message.model';
import {MessageDocument} from "../messageDocument.model";

export const loadMessagesAction = createAction('[MESSAGES] loadMessagesAction', props<{page: number, size: number}>());
export const messagesLoadedAction = createAction('[MESSAGES] MessagesLoadedAction', props<{payload: MessageModel}>());
export const loadMessagesForNavbarAction = createAction('[MESSAGES] loadMessagesForNavbarAction', props<{page: number, size: number}>());
export const messagesForNavbarLoadedAction = createAction('[MESSAGES] messagesForNavbarLoadedAction', props<{payload: MessageModel}>());
export const markMessageAsReadAction = createAction('[MESSAGES] markMessageAsReadAction', props<{payload: MessageDocument}>());
export const messageAsReadMarkedAction = createAction('[MESSAGES] messageAsReadMarkedAction', props<{payload: MessageModel}>());
export const markMessageAsUnReadAction = createAction('[MESSAGES] markMessageAsUnReadAction', props<{payload: MessageDocument}>());
export const messageAsUnReadMarkedAction = createAction('[MESSAGES] messageAsUnReadMarkedAction', props<{payload: MessageModel}>());
export const markMessageAsDeletedAction = createAction('[MESSAGES] markMessageAsDeletedAction', props<{payload: MessageDocument}>());
export const messageAsDeletedMarkedAction = createAction('[MESSAGES] messageAsDeletedMarkedAction', props<{payload: MessageModel}>());
