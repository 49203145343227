import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';
import {MessageConfiguration} from './messageConfiguration.model';

export class MessageConfigurations {
  @JsonProperty() @JsonClassType({type: () => [String]})
  userId: string;
  @JsonProperty() @JsonClassType({type: () => [Array, [MessageConfiguration]]})
  configuration: MessageConfiguration[] = [];
  @JsonProperty() @JsonClassType({type: () => [Array, [String]]})
  forwardTypes: string[] = [];

  constructor(
    userId: string,
    configuration: MessageConfiguration[],
    forwardTypes: string[]
  ) {
    this.userId = userId;
    this.configuration = configuration;
    this.forwardTypes = forwardTypes;
  }

  public static fromService(messageConfigurations: any): MessageConfigurations {
    const objectMapper = new ObjectMapper();
    const configurations = objectMapper.parse<MessageConfigurations>( JSON.stringify( {
      userId: messageConfigurations.userId,
      configuration: MessageConfiguration.fromServiceArray(messageConfigurations.configuration),
      forwardTypes: messageConfigurations.forwardTypes}), {mainCreator: () => [MessageConfigurations]}
    );
    return configurations;
  }

  public static toService(messageConfigurations: MessageConfigurations): any {
    return {
      userId: messageConfigurations.userId,
      configuration: MessageConfiguration.toServiceArray(messageConfigurations.configuration),
      forwardTypes: messageConfigurations.forwardTypes
    };
  }
}
