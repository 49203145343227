import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';

export class DatevImportOverviewStates {
  @JsonProperty() @JsonClassType({type: () => [String]})
  state: string;
  @JsonProperty() @JsonClassType({type: () => [Number]})
  records: number;
  @JsonProperty() @JsonClassType({type: () => [String]})
  icon: string;
  @JsonProperty() @JsonClassType({type: () => [Boolean]})
  ignoreInView: boolean;

  constructor(
    state: string,
    records: number
  ) {
    this.state = state;
    this.records = records;

    if (state === 'STARTING' || state === 'SKIPPED') {
      this.ignoreInView = true;
    } else if (state === 'WAITING') {
      this.icon = 'ri-stack-line';
    } else if (state === 'RUNNING') {
      this.icon = 'ri-play-line';
    } else if (state === 'ERRONEOUS') {
      this.icon = 'ri-error-warning-line';
    } else if (state === 'MAPPING_ISSUES') {
      this.icon = 'ri-file-forbid-line';
    } else if (state === 'FINISHED') {
      this.icon = 'ri-check-double-line';
    }
  }

  public static fromService(data: any): DatevImportOverviewStates[] {
    let states: DatevImportOverviewStates[] = [];
    if ( data) {
      const objectMapper = new ObjectMapper();
      data.forEach( state => {
        states.push(objectMapper.parse<DatevImportOverviewStates>( JSON.stringify( {
          state: state.state,
          records: state.records
        }), {mainCreator: () => [DatevImportOverviewStates]}));
      });
    }
    return states;
  }
}
