import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {CustomerState, selectedCustomerSelect} from '../../../core/models/customer/store';
import {OrderState, selectedOrderSelect} from '../../../core/models/order/store';
import {selectCustomerAction} from '../../../core/models/customer/store/customer.actions';
import {saveOrderAction, selectOrderAction} from '../../../core/models/order/store/order.actions';
import {Observable, Subscription} from 'rxjs';
import {Order} from '../../../core/models/order/order.model';
import {Customer} from '../../../core/models/customer/customer.model';
import {ChatMessage} from '../../../core/models/chatMessage.model';
import {Task} from '../../../core/models/order/task.model';
import {TaskStateEnum} from '../../../core/models/order/taskStateEnum';
import {FormControl, FormGroup} from '@angular/forms';
import {FormTemplateModel} from "../../../core/models/form/formTemplate.model";
import {
  allTaskTypesForAllCompaniesSelect,
  TaskTypeState
} from "../../../core/models/order/tasktype/store";
import {TaskType} from "../../../core/models/order/tasktype/tasktype.model";
import {DocumentMetaDataModel} from "../../../core/models/file/documentMetaData-model";
import {DocumentManagementApiService} from "../../../core/services/document-management-api.service";

@Component({
  selector: 'app-task-edit-mobile',
  templateUrl: './task-edit-mobile.component.html',
  styleUrls: ['./task-edit-mobile.component.scss']
})
export class TaskEditMobileComponent implements OnInit {

  taskForm: FormGroup;

  customerId: string;
  orderId: string;
  taskId: string;
  task: Task;

  order$: Observable<Order> = this.orderStore.select(selectedOrderSelect);
  orderSub: Subscription;
  order: Order;

  taskType$: Observable<TaskType[]> = this.taskTypeStore.select(allTaskTypesForAllCompaniesSelect);
  taskTypeSub: Subscription;

  selectedCustomer$: Observable<Customer> = this.customerStore.select(selectedCustomerSelect);
  selectedCustomerSub: Subscription;
  selectedCustomer: Customer;
  taskMessages: ChatMessage[] = [];

  allowedTaskStates = [];

  templates: FormTemplateModel[] = [];

  files: DocumentMetaDataModel[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private customerStore: Store<CustomerState>,
    private orderStore: Store<OrderState>,
    private taskTypeStore: Store<TaskTypeState>,
    private documentService: DocumentManagementApiService
  ) {

    this.route.params.subscribe(params => {
      this.customerId = params.customerId;
      this.orderId = params.orderId;
      this.taskId = params.taskId;
    });

    this.taskForm = new FormGroup({
      id: new FormControl(),
      taskTypeId: new FormControl({value: '', disabled: true}),
      taskTypeDescription: new FormControl({value: '', disabled: true}),
      description: new FormControl({value: '', disabled: true}),
      startDate: new FormControl({value: new Date(), disabled: true}),
      endDate: new FormControl({value: new Date(), disabled: true}),
      state: new FormControl(),
      userIds: new FormControl({value: '', disabled: true})
    });

  }

  ngOnInit(): void {
    this.taskTypeSub = this.taskType$.subscribe(()=> {
      if (this.customerId && this.orderId) {
        this.customerStore.dispatch(selectCustomerAction({customerId: this.customerId}));
        this.orderStore.dispatch(selectOrderAction({customerId: this.customerId, orderId: this.orderId}));
      }
      this.orderSub = this.order$.subscribe(
        (order: Order) => {
          if (order) {
            this.order = order;
            this.task = this.order.tasks.find(task => task.id === this.taskId);

            if (this.task) {
              this.files = [];
              this.taskMessages = [...this.task.chatMessages];
              this.allowedTaskStates = [];
              this.allowedTaskStates.push(this.task.state);
              this.task?.taskType?.documentIds?.forEach(id => {
                this.loadAndAddFileToArray(id);
              });
              this.task.allowedStateTransitions.forEach(state => {
                if (state.toString() !== TaskStateEnum[TaskStateEnum.UNSCHEDULED].toString()) {
                  this.allowedTaskStates.push(state);
                }
              });

              this.taskForm.patchValue(this.task);
              this.taskForm.patchValue({taskTypeId: this.task.taskType?.description});

              this.templates = [];
              const taskTypeTemplates = JSON.parse(this.task.taskType?.formTemplate);
              const taskTemplates = JSON.parse(JSON.stringify(this.task?.formTemplate));
              const orderTemplates = this.order.formTemplate;

              if (taskTypeTemplates?.length > 0) {
                this.templates = taskTypeTemplates;

                if (taskTemplates?.length > 0) {
                  taskTemplates.forEach( taskTemplate => {
                    const index = this.templates.findIndex( template => template.id === taskTemplate.id)
                    if (index > -1) {
                      this.templates[index] = taskTemplate;
                    }
                  });
                }

                if (orderTemplates?.length > 0) {
                  orderTemplates.forEach( orderTemplate => {
                    const index = this.templates.findIndex( template => template.id === orderTemplate.id)
                    if (index > -1) {
                      this.templates[index] = orderTemplate;
                    }
                  });
                }
              }
            }
          }
        }
      );
    });


    this.selectedCustomerSub = this.selectedCustomer$.subscribe(
      (customer: Customer) => {
        this.selectNewCustomer(customer);
      }
    );
  }

  selectNewCustomer(customer: Customer): void {
    if (customer) {
      this.selectedCustomer = customer;
    }
  }

  navigateToOrder(): void {
    this.router.navigate(['order-edit-mobile/' + this.selectedCustomer.id + '/' + this.order.id + '/false']);
  }

  saveTask(): void {
    const taskOfForm = this.taskForm?.value;
    const description = this.task.description;
    const startDate = this.task.startDate;
    const endDate = this.task.endDate;
    const userIds = this.task.userIds;
    const state = taskOfForm.state;
    const newTask: Task = {
      ...this.task,
      state,
      userIds,
      description,
      startDate,
      endDate,
      taskType: this.task.taskType
    };

    const newTasks: Task[] = [...this.order.tasks];
    const index = newTasks.findIndex(x => x.id === newTask.id);
    if (index !== -1) {
      newTasks[index] = newTask;
    } else {
      newTasks.push(newTask);
    }
    let formTemplateToSave = [];
    if (this.order.formTemplate && this.order.formTemplate.length > 0) {
      formTemplateToSave = [...this.order.formTemplate];
      this.templates.forEach(template => {
        const index = formTemplateToSave.findIndex(orderTemplate => orderTemplate.id === template.id);
        if (index > -1) {
          formTemplateToSave[index] = template;
        } else {
          formTemplateToSave.push(template);
        }
      });
    } else {
      formTemplateToSave = this.templates;
    }
    const newOrder = {...this.order, tasks: newTasks, formTemplate: formTemplateToSave};
    this.orderStore.dispatch(saveOrderAction({payload: newOrder, customerId: this.selectedCustomer.id}));
  }

  openMap() {
    const url: string = 'maps.google.com/?q=' + this.order?.operationAddress?.street + ' ' + this.order?.operationAddress?.zipcode + '  ' + this.order?.operationAddress?.city
    if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window['MSStream']) {
      window.open('maps://' + url, '_blank');
    } else {
      window.open('https://' + url, '_blank');
    }
  }

  public openFile(document: DocumentMetaDataModel): void {
    this.documentService.openFile(document);
  }

  private loadAndAddFileToArray(id: string) {
    this.documentService.getFileInfoById(id).subscribe(fileInfo => {
      if (this.files.findIndex(file => file.id === fileInfo.id) === -1) {
        this.files.push(fileInfo);
      }
    })
  }
}
