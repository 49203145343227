import {createSelector} from '@ngrx/store';
import {ReducerFaqState} from './faq.reducer';

export interface FaqState {
  faqs: ReducerFaqState;
}

export const faqState = (state: FaqState) => state.faqs;

export const faqsSelect = createSelector(
    faqState,
  (state) => state.faqs
);

