import {createAction, props} from '@ngrx/store';
import {EmployeeVacationEntitlementModel} from "../employee-vacation-entitlement.model";
import {EmployeeVacationModel} from "../employee-vacation.model";
import {Page} from "../../page/page.model";
import {HolidayResultModel} from "../holiday-result.model";

export const loadVacationEntitlementsAction = createAction('[EMPLOYEEVACATION] loadVacationEntitlements', props<{employeeId: string}>());
export const vacationEntitlementsLoadedAction = createAction('[EMPLOYEEVACATION] vacationEntitlementsLoaded', props<{entitlements: EmployeeVacationEntitlementModel[]}>());
export const addVacationEntitlementAction = createAction('[EMPLOYEEVACATION] addVacationEntitlementAction', props<{entitlement: EmployeeVacationEntitlementModel}>());
export const vacationEntitlementAddedAction = createAction('[EMPLOYEEVACATION] vacationEntitlementAddedAction', props<{entitlement: EmployeeVacationEntitlementModel}>());
export const changeVacationEntitlementAction = createAction('[EMPLOYEEVACATION] changeVacationEntitlementAction', props<{entitlement: EmployeeVacationEntitlementModel}>());
export const vacationEntitlementChangedAction = createAction('[EMPLOYEEVACATION] vacationEntitlementChangedAction', props<{entitlement: EmployeeVacationEntitlementModel}>());
export const loadVacationAction = createAction('[EMPLOYEEVACATION] loadVacationAction', props<{employeeIds: string[], year: number}>());
export const vacationLoadedAction = createAction('[EMPLOYEEVACATION] vacationLoadedAction', props<{vacation: EmployeeVacationModel[]}>());
export const addVacationAction = createAction('[EMPLOYEEVACATION] addVacationAction', props<{vacation: EmployeeVacationModel}>());
export const vacationAddedAction = createAction('[EMPLOYEEVACATION] vacationAddedAction', props<{vacation: EmployeeVacationModel}>());
export const loadHolidaysAction = createAction('[EMPLOYEEVACATION] loadHolidaysAction', props<{year: number, startMonth: number, endMonth: number, page: number, size: number}>());
export const holidaysLoadedAction = createAction('[EMPLOYEEVACATION] holidaysLoadedAction', props<{holidays: Page<HolidayResultModel>}>());

