<div *ngIf="copyTemplates.length > 0">
    <form [formGroup]="templatesForm" (ngSubmit)="saveForm()">
        <div formArrayName="templates">
            <div class="row">
                <ng-container *ngFor="let template of formTemplates().controls; let templateIndex = index;">
                    <div class="col-12 col-xl-6">
                        <ngb-accordion #acc="ngbAccordion">
                            <ngb-panel id="toggle-{{templateIndex}}">
                                <ng-template ngbPanelTitle>
                                    <a class="d-flex align-items-center text-start m-3" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="leadDiscovered1">
                                        <div class="flex-grow-1 ms-3">
                                            <h6 class="fs-14 mb-1">{{template.get('name').value}}</h6>
                                        </div>
                                    </a>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <div class="card-body">
                                        <h6 class="fs-14 mb-1">{{template.get('description').value}}</h6>
                                        <div [formGroupName]="templateIndex">
                                            <div class="row mb-3">
                                                <input type="hidden" id="id" formControlName="id">
                                                <input type="hidden" id="sort" formControlName="sort">
                                                <input type="hidden" id="name" formControlName="name">
                                            </div>
                                            <div formArrayName="formFields">
                                                <div *ngFor="let field of formFields(templateIndex).controls; let fieldIndex = index">
                                                    <div [formGroupName]="fieldIndex">
                                                        <div class="mb-3 overflow-hidden">
                                                            <div>
                                                                <small
                                                                        class="text-uppercase text-muted">{{field.get('description').value}} {{field.get('required').value ? '*' : ''}}</small>
                                                                <textarea
                                                                        *ngIf="field.get('formFieldType').value === 'textarea'; else signature"
                                                                        class="form-control"
                                                                        id="formValueTextArea{{templateIndex}}{{fieldIndex}}"
                                                                        rows="6"
                                                                        [required]="field.get('required').value"
                                                                        formControlName="value"></textarea>
                                                                <ng-template #signature>
                                                                    <ng-container
                                                                            *ngIf="field.get('formFieldType').value === 'signature'; else textOrNumber">
                                                                        <input type="hidden" formControlName="value">
                                                                        <signature-pad *ngIf="!field.get('value').value"
                                                                                       #signaturePad
                                                                                       [options]="signaturePadOptions"
                                                                                       id="signatureCanvas{{templateIndex}}{{fieldIndex}}"></signature-pad>
                                                                        <img *ngIf="field.get('value').value"
                                                                             [src]="field.get('value').value">
                                                                        <div class="d-flex">
                                                                            <div class="ms-auto">
                                                                                <button *ngIf="field.get('value').value"
                                                                                        class="btn btn-outline-dark"
                                                                                        (click)="deleteSignature(templateIndex, fieldIndex)">
                                                                                    Unterschrift löschen
                                                                                </button>
                                                                                <button *ngIf="!field.get('value').value"
                                                                                        class="btn btn-outline-primary"
                                                                                        (click)="saveSignature(signaturePad.toDataURL(), templateIndex, fieldIndex)">
                                                                                    Unterschrift übernehmen
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </ng-container>
                                                                </ng-template>
                                                                <ng-template #textOrNumber>
                                                                    <input type="{{field.get('formFieldType').value}}"
                                                                           class="form-control"
                                                                           id="formValue{{templateIndex}}{{fieldIndex}}"
                                                                           [required]="field.get('required').value"
                                                                           formControlName="value">
                                                                </ng-template>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer hstack gap-2">
                                        <button type="submit" class="btn btn-outline-primary"
                                                [disabled]="!template.valid">{{ 'labels.save' | translate}}</button>
                                    </div>
                                </ng-template>
                            </ngb-panel>
                        </ngb-accordion>
                    </div>

                </ng-container>
            </div>
        </div>
    </form>
</div>
