import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html'
})
export class ErrorPageComponent implements OnInit, OnDestroy {

  type: any;
  title: any;
  desc: any;
  private sub: Subscription;

  constructor(private route: ActivatedRoute, private translateService: TranslateService) { }

  ngOnInit(): void {
    this.type = this.route.snapshot.paramMap.get('type');

    this.sub = this.route.data.subscribe( param => {
      if (param.type) {
        this.type = param.type;
      }
      if (param.title) {
        this.title = param.title;
      }
      if (param.desc) {
        this.desc = param.desc;
      }
    });

    switch ( this.type) {
      case 404:
        this.title = this.translateService.instant('errorpagecomponent.404_header');
        this.desc = this.translateService.instant('errorpagecomponent.404_body');
        break;
      case '500':
        this.title = this.translateService.instant('errorpagecomponent.500_header');
        this.desc = this.translateService.instant('errorpagecomponent.500_body');
        break;
      default:
        this.type = this.translateService.instant('errorpagecomponent.default_type');
        this.title = this.translateService.instant('errorpagecomponent.default_header');
        this.desc = this.translateService.instant('errorpagecomponent.default_body');
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

}
