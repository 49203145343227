import {Component, Input, OnInit} from '@angular/core';
import {Order} from '../../../../core/models/order/order.model';
import {ContactPerson} from '../../../../core/models/contactperson.model';
import {CheckableCardModel} from '../../../../core/models/checkableCard.model';
import {Customer} from '../../../../core/models/customer/customer.model';
import {saveOrderAction} from '../../../../core/models/order/store/order.actions';
import {Store} from '@ngrx/store';
import {OrderState} from '../../../../core/models/order/store';

@Component({
  selector: 'app-contactpersons',
  templateUrl: './contactpersons.component.html',
  styleUrls: ['./contactpersons.component.scss']
})
export class ContactpersonsComponent implements OnInit {

  @Input() order: Order;
  @Input() customer: Customer;
  @Input() selectable = false;
  constructor(
    private orderStore: Store<OrderState>
  ) { }

  ngOnInit(): void {
  }

  existsContactPersonAtOrder(contactPerson: ContactPerson): boolean {
    return this.order?.contactPersons?.findIndex(x => x.contactPersonId === contactPerson.contactPersonId) >= 0;
  }

  addContactPerson(personIndex: number, checked: boolean): void {
    if (this.customer.contactPersons) {
      const contactPerson: ContactPerson = this.customer.contactPersons[personIndex];
      const index = this.customer.contactPersons.findIndex(x => x.contactPersonId === contactPerson.contactPersonId);
      const contactPersons = [...this.order.contactPersons];
      if (index >= 0 && !checked) {
        contactPersons.splice(index, 1);
      } else if (checked) {
        contactPersons.push(contactPerson);
      }
      const newOrder = {...this.order, contactPersons};
      this.orderStore.dispatch(saveOrderAction({payload: newOrder, customerId: this.customer.id}));
    }
  }
}
