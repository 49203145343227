import {createAction, props} from '@ngrx/store';
import {TaskType} from "../tasktype.model";

export const loadAllTaskTypesAction = createAction('[TASKTYPE] loadAllTaskTypesAction');
export const taskTypesLoadedAction = createAction('[TASKTYPE] taskTypesLoadedAction', props<{payload: TaskType[]}>());
export const taskTypeSelectedAction = createAction('[TASKTYPE] taskTypeSelectedAction', props<{payload: TaskType}>());
export const loadAllTaskTypesForAllCompaniesAction = createAction('[TASKTYPE] loadAllTaskTypesForAllCompaniesAction');
export const taskTypesForAllCompaniesLoadedAction = createAction('[TASKTYPE] taskTypesForAllCompaniesLoadedAction', props<{payload: TaskType[]}>());
export const saveTaskTypeAction = createAction( '[TASKTYPE] saveTaskTypeAction', props<{payload: TaskType, companyId: string}>());
export const taskTypeSavedAction = createAction( '[TASKTYPE] taskTypeSavedAction', props<{payload: TaskType}>());
