<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                Copyright © {{year}} <a href="https://www.upspot.de" target="_blank" rel="noopener">up|spot</a>. All rights reserved
            </div>
            <div class="col-sm-6">
                <div class="text-sm-end d-none d-sm-block">
                    Programmiert mit <i class="mdi mdi-heart-outline"></i>
                </div>
            </div>
        </div>
    </div>
</footer>
