<div class="modal-body">
    <h5>{{ 'labels.savedVacations' | translate }}</h5>
    <ng-container *ngFor="let vacation of employeeVacation">
        <div class="row mb-3">
            <div class="col-12 col-md-6">
                <input class="form-control" [(ngModel)]="vacation.period.startDate"
                       name="dp" ngbDatepicker id="vacationStartDate"
                       placeholder="dd.mm.yyyy"
                       autocomplete="off" disabled>
            </div>
            <div class="col-12 col-md-6">
                <input class="form-control" [(ngModel)]="vacation.period.endDate"
                       name="dp" ngbDatepicker id="vacationEndDate"
                       autocomplete="off" disabled>
            </div>
        </div>
    </ng-container>
    <hr>
</div>

