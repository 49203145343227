import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {StatusMessageModel} from "../models/statusMessage/statusMessage.model";
import {FaqCategoryModel} from "../models/faq/faqCategory.model";
import {ToastService} from "./Toast/toast-service";

@Injectable()
export class UiManagementApiService {
    private REST_API_SERVER = this.uiManagement;

    constructor(
        private httpClient: HttpClient,
        private toastrService: ToastService,
        private translate: TranslateService,
        @Inject('UI_MANAGEMENT') private uiManagement: string) {
    }

    loadStatusMessages(): Observable<any> {
        const url = this.REST_API_SERVER + '/ui-service/status'
        return this.httpClient.get<any>(url).pipe(
            catchError(error => {
                console.log('Fehler beim laden der Statusmeldungen von ' + url);
                return of([]);
            }),
            map(
                (data: StatusMessageModel[]) => {
                    if (data.length > 0) {
                        return StatusMessageModel.fromServiceArray(data)
                    } else {
                        return null;
                    }
                }
            )
        );
    }

    markStatusMessageAsRead(documentId: string): Observable<any> {
        const url = this.REST_API_SERVER + '/ui-service/status/' + documentId + '/mark-read'
        return this.httpClient.put<any>(url, null).pipe(
            catchError(error => {
                console.log('Fehler beim laden der Statusmeldungen von ' + url);
                return of([]);
            }),
            map(
                () => {
                    return true
                }
            )
        );
    }

    loadFAQs(): Observable<any> {
        const url = this.REST_API_SERVER + '/ui-service/faq'
        return this.httpClient.get<any>(url).pipe(
            catchError(error => {
                console.log('Fehler beim laden der FAQs ' + url);
                return of([]);
            }),
            map(
                (data: FaqCategoryModel[]) => {
                    if (data.length > 0) {
                        return FaqCategoryModel.fromServiceArray(data)
                    } else {
                        return null;
                    }
                }
            )
        );
    }
}
