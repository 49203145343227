import {Component, OnInit} from '@angular/core';
import {faqsSelect, faqState, FaqState} from "../../core/models/faq/store";
import {Store} from "@ngrx/store";
import {loadFAQsAction} from "../../core/models/faq/store/faq.actions";
import {Observable} from "rxjs";
import {FaqCategoryModel} from "../../core/models/faq/faqCategory.model";

@Component({
    selector: 'app-faqs',
    templateUrl: './faqs.component.html',
    styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {

    faqs$: Observable<FaqCategoryModel[]> = this.faqStore.select(faqsSelect);
    constructor(
        public faqStore: Store<FaqState>
    ) {
        faqStore.dispatch(loadFAQsAction());
    }

    ngOnInit(): void {
    }

}
