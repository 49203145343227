import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, mergeMap} from 'rxjs/operators';
import {datevBatchLoadedAction, datevBatchStarted, loadDatevBachAction, startDatevBatch} from './batch.actions';
import {BatchManagementApiService} from "../../../services/batch-management-api.service";

@Injectable()
export class BatchEffects {
  constructor(
    private httpApiService: BatchManagementApiService,
    private actions$: Actions) {
  }

  loadDatevBatch$ = createEffect(() => this.actions$.pipe(
    ofType(loadDatevBachAction),
    mergeMap(() => this.httpApiService.loadDatevBatch()
      .pipe(
        map(batch => datevBatchLoadedAction({batch}))
      ))
  ));

    startDatevBatch$ = createEffect(() => this.actions$.pipe(
        ofType(startDatevBatch),
        mergeMap(() => this.httpApiService.startDatev()
            .pipe(
                map(batch => datevBatchStarted())
            ))
    ));
}
