import {JsonClassType, JsonProperty, ObjectMapper} from "jackson-js";
import {Address} from "../address.model";
import {RecordType} from "./datevImportDto.model";
import {DatevUsageTypeModel} from "./datevUsageType.model";
import {ContactType} from "../contacttype/contactType.model";
import {User} from "../user/user.model";
import {TaskType} from "../order/tasktype/tasktype.model";
import {OrderType} from "../order/ordertype/ordertype.model";
import {AddressType} from "../addresstype/addressType.model";
import {DatevErrors} from "./datevErrors.model";
import {DatevMappingErrorsModel} from "./datevMappingErrors.model";

export class DatevAddressModel {

    @JsonProperty() @JsonClassType({type: () => [String]})
    id: string;
    @JsonProperty() @JsonClassType({type: () => [String]})
    companyName: string;
    @JsonProperty() @JsonClassType({type: () => [String]})
    firstName: string;
    @JsonProperty() @JsonClassType({type: () => [String]})
    name: string;
    @JsonProperty() @JsonClassType({type: () => [String]})
    street: string;
    @JsonProperty() @JsonClassType({type: () => [String]})
    zipcode: string;
    @JsonProperty() @JsonClassType({type: () => [String]})
    city: string;
    @JsonProperty() @JsonClassType({type: () => [DatevUsageTypeModel]})
    usageType: DatevUsageTypeModel;
    @JsonProperty() @JsonClassType({type: () => [Array, [DatevErrors]]})
    errors: DatevErrors[] = [];
    @JsonProperty() @JsonClassType({type: () => [Array, [DatevMappingErrorsModel]]})
    mappingErrors: DatevMappingErrorsModel[] = [];

    constructor(
        id: string,
        companyName: string,
        firstName: string,
        name: string,
        street: string,
        zipcode: string,
        city: string,
        usageType: DatevUsageTypeModel,
        errors: DatevErrors[],
        mappingError: DatevMappingErrorsModel[]
    ){
        this.id = id;
        this.companyName = companyName;
        this.firstName = firstName;
        this.name = name;
        this.street = street;
        this.zipcode = zipcode;
        this.city = city;
        this.usageType = usageType;
        this.errors = errors;
        this.mappingErrors = mappingError;
    }

    public static fromService(address: any): DatevAddressModel {
        if (address) {
            const objectMapper = new ObjectMapper();
            return objectMapper.parse<DatevAddressModel>(JSON.stringify({
                id: address.id,
                companyName: address.companyName,
                firstName: address.firstName,
                name: address.name,
                street: address.street,
                zipcode: address.zipcode,
                city: address.city,
                usageType: address.usageType,
                errors: DatevErrors.fromServiceArray(address.errors),
                mappingErrors: DatevMappingErrorsModel.fromServiceArray(address.mappingErrors)
            }), {mainCreator: () => [DatevAddressModel]});
        } else {
            return null;
        }
    }

    public static fromServiceArray(addressArray: any): DatevAddressModel[] {
        const resultAddresses = [];
        if (addressArray) {
            for (const address of addressArray) {
                resultAddresses.push(DatevAddressModel.fromService(address));
            }
        }
        return resultAddresses;
    }

    public static toService(address: DatevAddressModel): any {
        return {
            id: address.id,
            companyName: address.companyName,
            firstName: address.firstName,
            name: address.name,
            street: address.street,
            zipcode: address.zipcode,
            city: address.city,
            usageType: address.usageType
        };
    }
}
