import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import {Order} from '../../core/models/order/order.model';
import {Observable, Subscription} from 'rxjs';
import {allOrdersSelect, OrderState} from '../../core/models/order/store';
import {Store} from '@ngrx/store';
import {Router} from '@angular/router';
import {allUsersSelect, UserState} from '../../core/models/user/store';
import {User} from '../../core/models/user/user.model';
import {activeCompanySelect, CompanyState} from '../../core/models/company/store';
import {Company} from '../../core/models/company/company.model';
import {StatisticManagementApiService} from '../../core/services/statistic-management-api.service';
import {DashboardPeriod} from './dashboardtile/dashboardtile.component';
import {OrderStateEnum} from '../../core/models/order/orderstate.enum';
import {RxStompService} from "../../core/services/stomp/rx-stomp.service";
import {Customer} from "../../core/models/customer/customer.model";
import {selectAllOrdersAction} from "../../core/models/order/store/order.actions";
import {ToastService} from "../../core/services/Toast/toast-service";
import {EmployeeVacationState, holidaysSelect} from "../../core/models/employee-vacation/store";
import {HolidayModel} from "../../core/models/employee-vacation/holiday.model";
import {Page} from "../../core/models/page/page.model";
import {HolidayResultModel} from "../../core/models/employee-vacation/holiday-result.model";
import {loadHolidaysAction} from "../../core/models/employee-vacation/store/employee-vacation.actions";
import {
    findCustomerOverviewAction,
    loadCustomerOverviewAction
} from "../../core/models/customer/customerOverview/store/customerOverview.actions";

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    preserveWhitespaces: true
})
export class DashboardComponent implements OnInit, OnDestroy {
    breadCrumbItems!: Array<{}>;

    public newCustomersData = [];
    public newCustomersDataDescription = [];

    public newOrdersData = [];
    public newOrdersDataDescription = [];

    public ordersData = [];
    public ordersDataDescription = [];
    public ordersDataColors = [];

    public ordersStateData = [];
    public ordersStateDataDescription = [];
    public ordersStateColors = [];

    ordersForPlanning: Order[] = [];
    ordersForCalendar: Order[] = [];

    ordersPerState: {
        HEAD_ONLY: number,
        NEW: number,
        PARTIALLY_SCHEDULED: number,
        SCHEDULED: number,
        IN_PROGRESS: number,
        FINISHED: number
    };
    ordersForPlanningLoaded = false;
    orderForCalenderLoaded = false;
    usersLoaded = false;
    activeCompanyLoaded = false;
    newCustomersLoaded = false;

    orders$: Observable<Customer[]> = this.orderStore.select(allOrdersSelect);
    ordersSub: Subscription;

    users$: Observable<User[]> = this.userStore.select(allUsersSelect);
    usersSubscription: Subscription;
    users: User[];
    tasksPerUser: any = [];

    activeCompany$: Observable<Company> = this.companyStore.select(activeCompanySelect);
    activeCompanySubscription: Subscription;
    activeCompany: Company;

    holidays$: Observable<Page<HolidayResultModel>> = this.employeeVacationStore.select(holidaysSelect);
    holidaysSubscription: Subscription;
    holidays: Page<HolidayResultModel>;


    holidayPage = 1;

    constructor(
        private calendar: NgbCalendar,
        private orderStore: Store<OrderState>,
        private router: Router,
        private userStore: Store<UserState>,
        private companyStore: Store<CompanyState>,
        private statisticApi: StatisticManagementApiService,
        private rxStompService: RxStompService,
        private employeeVacationStore: Store<EmployeeVacationState>
    ) {
        this.ordersForPlanningLoaded = false;
        this.orderForCalenderLoaded = false;
        this.usersLoaded = false;
        this.activeCompanyLoaded = false;

        this.employeeVacationStore.dispatch(loadHolidaysAction({
            year: 2023,
            startMonth: 1,
            endMonth: 12,
            page: 0,
            size: 5
        }));

        this.ordersSub = this.orders$.subscribe(
            (customers: Customer[]) => {
                if (customers) {
                    let orders = [];
                    customers.forEach(customer => {
                        orders = orders.concat(customer.orders);
                    });
                    orders.forEach(order => {
                        if (Order.isForCalendar(order)) {
                            this.ordersForCalendar.push(order);
                        }
                        if (Order.isForPlanning(order)) {
                            this.ordersForPlanning.push(order);
                        }
                    });
                    this.orderForCalenderLoaded = true;
                    this.ordersForPlanningLoaded = true;
                    this.initCharts();
                }
            }
        );

        this.activeCompanySubscription = this.activeCompany$.subscribe((company: Company) => {
            if (company) {
                this.activeCompany = company;
                this.activeCompanyLoaded = true;

                this.initCharts();

                this.usersSubscription = this.users$.subscribe(
                    (users: User[]) => {
                        if (users) {
                            this.users = users;
                            this.usersLoaded = true;
                            this.initCharts();
                        }
                    }
                );
            }
        });

        this.holidaysSubscription = this.holidays$.subscribe(result => {
            if (result) {
                this.holidays = result;
            }
        });
    }

    onSendMessage() {
        const message = `Message generated at ${new Date()}`;
        this.rxStompService.publish({destination: '/topic/demo', body: message});
    }

    openOrder(order: Order): void {
        this.router.navigate(['/order-overview/' + order.customerId + '/' + order.id]);
    }

    ngOnInit(): void {
        this.breadCrumbItems = [
            {label: 'menu.navigation'},
            {label: 'menu.dashboard', active: true}
        ];
    }

    ngOnDestroy(): void {
        this.ordersSub?.unsubscribe();
        this.activeCompanySubscription?.unsubscribe();
        this.usersSubscription?.unsubscribe();
    }

    initCharts(): void {
        if (this.ordersForPlanningLoaded && this.orderForCalenderLoaded && this.usersLoaded && this.activeCompanyLoaded) {
            this.ordersPerState = {
                HEAD_ONLY: this.ordersForPlanning.filter(x => x.orderState === 0).length,
                NEW: this.ordersForPlanning.filter(x => x.orderState === 1).length,
                PARTIALLY_SCHEDULED: this.ordersForPlanning.filter(x => x.orderState === 2).length,
                SCHEDULED: this.ordersForCalendar.filter(x => x.orderState === 3).length,
                IN_PROGRESS: this.ordersForCalendar.filter(x => x.orderState === 4).length,
                FINISHED: this.ordersForCalendar.filter(x => x.orderState === 5).length,
            };

            this.tasksPerUser = [];
            this.users.forEach(user => {
                if (user.companies.findIndex(company => company.companyId === this.activeCompany.companyId) > -1) {
                    let count = 0;
                    this.ordersForCalendar.map(order => {
                        order.tasks.map(task => {
                            const taskCount = task.userIds ? task.userIds?.filter(x => x === user.userId).length : 0;
                            count = count + taskCount;
                        });
                    });

                    const object = {
                        user,
                        count
                    };

                    this.tasksPerUser.push(object);
                }
            });
            let plannedOrders = 0;
            this.ordersForCalendar.forEach(order => {
                if (order.orderState === OrderStateEnum.SCHEDULED || order.orderState === OrderStateEnum.IN_PROGRESS) {
                    plannedOrders += 1;
                }
            });
            this.ordersData = [plannedOrders, this.ordersForPlanning.length];
            this.ordersDataDescription = ['taskStatus.planned', 'taskStatus.unplanned'];
            this.ordersDataColors = ['#f77eb9', '#7ee5e5'];

            this.ordersStateData = [
                this.ordersPerState.NEW, this.ordersPerState.PARTIALLY_SCHEDULED,
                this.ordersPerState.SCHEDULED, this.ordersPerState.IN_PROGRESS,
                this.ordersPerState.FINISHED, this.ordersPerState.HEAD_ONLY];
            this.ordersStateDataDescription = ['orderStatus.newOrder', 'orderStatus.partlyPlanned', 'orderStatus.planned', 'orderStatus.working', 'orderStatus.done', 'orderStatus.head_only'];
            this.ordersStateColors = ['#10b759', '#fbbc06', '#7ee5e5', '#f77eb9', '#727cf5', '#f36'];
        }
    }

    newCustomerPeriodChanged(period: DashboardPeriod): void {
        const startDate = this.getStartDateByPeriod(period);
        let endDate = new Date();
        endDate.setDate(endDate.getDate() + 1);
        this.newCustomersData = [];
        this.newCustomersDataDescription = [];
        /* Wenn es 30 Tage sind dann soll nach Tag gruppiert werden, ansonsten nach Woche */
        if (period === 1) {
            this.statisticApi.getNewCustomersByDateRangeGroupByDayWeekYearAndMonth(startDate, endDate).subscribe(newCustomers => {
                const newDataArray = [];
                const newDataDescriptionArray = [];
                newCustomers.forEach(data => {
                    newDataArray.push(data.count);
                    newDataDescriptionArray.push(data.day + '.' + data.month + '.' + data.year);
                });
                this.newCustomersData = newDataArray;
                this.newCustomersDataDescription = newDataDescriptionArray;
            });
        } else {
            this.statisticApi.getNewCustomersByDateRangeGroupByWeekYearAndMonth(startDate, endDate).subscribe(newCustomers => {
                const newDataArray = [];
                const newDataDescriptionArray = [];

                newCustomers.forEach(data => {
                    newDataArray.push(data.count);
                    newDataDescriptionArray.push(data.week + '/' + data.year);
                });

                this.newCustomersData = newDataArray;
                this.newCustomersDataDescription = newDataDescriptionArray;
            });
        }
    }

    newOrdersPeriodChanged(period: DashboardPeriod): void {
        const startDate = this.getStartDateByPeriod(period);
        let endDate = new Date();
        endDate.setDate(endDate.getDate() + 1);
        this.newOrdersData = [];
        this.newOrdersDataDescription = [];
        /* Wenn es 30 Tage sind dann soll nach Tag gruppiert werden, ansonsten nach Woche */
        if (period === 1) {
            this.statisticApi.getNewOrdersByDateRangeGroupByDayWeekYearAndMonth(startDate, endDate).subscribe(newOrders => {
                const newDataArray = [];
                const newDataDescriptionArray = [];
                newOrders.forEach(data => {
                    newDataArray.push(data.count);
                    newDataDescriptionArray.push(data.day + '.' + data.month + '.' + data.year);
                });
                this.newOrdersData = newDataArray;
                this.newOrdersDataDescription = newDataDescriptionArray;
            });
        } else {
            this.statisticApi.getNewOrdersByDateRangeGroupByWeekYearAndMonth(startDate, endDate).subscribe(newOrders => {
                const newDataArray = [];
                const newDataDescriptionArray = [];

                newOrders.forEach(data => {
                    newDataArray.push(data.count);
                    newDataDescriptionArray.push(data.week + '/' + data.year);
                });

                this.newOrdersData = newDataArray;
                this.newOrdersDataDescription = newDataDescriptionArray;
            });
        }
    }

    ordersPeriodChanged(period: DashboardPeriod): void {
        const startDate = this.getStartDateByPeriod(period);
        const endDate = new Date();
        this.orderStore.dispatch(selectAllOrdersAction({startDate, endDate, filterActiveCompany: false}));
    }

    getStartDateByPeriod(period: DashboardPeriod): Date {
        const newStartDate = new Date();
        let minusDays = 0;
        switch (period) {
            case 1: {
                minusDays = 30;
                break;
            }
            case 2: {
                minusDays = 60;
                break;
            }
            case 3: {
                minusDays = 90;
                break;
            }
        }
        newStartDate.setDate(newStartDate.getDate() - minusDays);
        return newStartDate;
    }
    changeHolidayPage(event: number): void {
        if (event !== this.holidayPage) {
            this.holidayPage = event;
            this.employeeVacationStore.dispatch(loadHolidaysAction({
                page: this.holidayPage - 1,
                size: 5,
                year: 2023,
                startMonth: 1,
                endMonth: 12
            }));
        }
    }
}
