import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';
import {IdentDescription} from '../identDescription.model';

export class OrderReference {
  @JsonProperty()
  @JsonClassType({type: () => [String]})
  companyId: string;

  @JsonProperty()
  @JsonClassType({type: () => [IdentDescription]})
  customer: IdentDescription;

  @JsonProperty()
  @JsonClassType({type: () => [IdentDescription]})
  order: IdentDescription;

  @JsonProperty()
  @JsonClassType({type: () => [IdentDescription]})
  task: IdentDescription;

  constructor( companyId: string, customer: IdentDescription, order: IdentDescription, task: IdentDescription) {
    this.companyId = companyId;
    this.customer = customer;
    this.order = order;
    this.task = task;
  }

  public static fromService(orderReference: any): OrderReference {
    const objectMapper = new ObjectMapper();
    const reference = objectMapper.parse<OrderReference>( JSON.stringify( {
      companyId: orderReference.companyId,
      customer: IdentDescription.fromService(orderReference.customer),
      order: IdentDescription.fromService(orderReference.order),
      task: IdentDescription.fromService(orderReference.task)}), {mainCreator: () => [OrderReference]}
    );
    return reference;
  }
}
