import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';
import {Page} from '../page/page.model';
import {MessageDocument} from './messageDocument.model';
import {MessageSummary} from "./messageSummary.model";

export class MessageModel {
  @JsonProperty()
  @JsonClassType({type: () => [MessageSummary]})
  messageSummary: MessageSummary;

  @JsonProperty()
  @JsonClassType({type: () => [Page]})
  messagePage: Page<MessageDocument>;

  @JsonProperty()
  @JsonClassType({type: () => [Number]})
  unreadMessages: number;

  constructor(messageSummary: MessageSummary, messagePage: Page<MessageDocument>) {
    this.messageSummary = messageSummary;
    this.messagePage = messagePage;
  }

  public static fromService(messageModel: any): MessageModel {
    if (messageModel && !Array.isArray(messageModel)) {
      const found = messageModel?.summary?.overall?.find(summary => summary.status === 'UNREAD')?.count;

      const objectMapper = new ObjectMapper();
      const returnMessageConfiguration = objectMapper.parse<MessageModel>(JSON.stringify({
            messageSummary: MessageSummary.fromService(messageModel.summary),
            messagePage: MessageDocument.getPageFromService(messageModel.messages),
            unreadMessages: found ? found : 0
          }), {mainCreator: () => [MessageModel]}
      );
      return returnMessageConfiguration;
    } else {
      return null;
    }
  }
}
