export class UpSpotFileModel {
  constructor(
    public file: File,
    public imagePath: string
  ) {}

  public static isImage(fileName: string): boolean {
    const idxDot = fileName.lastIndexOf('.') + 1;
    const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (extFile === 'jpg' || extFile === 'jpeg' || extFile === 'png') {
      return true;
    } else {
      return false;
    }
  }

  public static isPDF(fileName: string): boolean {
    const idxDot = fileName.lastIndexOf('.') + 1;
    const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (extFile === 'pdf') {
      return true;
    } else {
      return false;
    }
  }
}
