import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';
import {EmployeeVacationPeriodModel} from "./employee-vacation-period.model";
import {EmployeeVacationValidFromModel} from "./employee-vacation-valid-from.model";

export class HolidayModel {
    @JsonProperty()
    @JsonClassType({type: () => [String]})
    name: string;

    @JsonProperty()
    @JsonClassType({type: () => [String]})
    type: string;

    @JsonProperty()
    @JsonClassType({type: () => [String]})
    counties: string;


    constructor(name: string, type: string, counties: string) {
        this.name = name;
        this.type = type;
        this.counties = counties;
    }

    public static fromService(holiday: any): HolidayModel {
        const objectMapper = new ObjectMapper();
        const returnHoliday = objectMapper.parse<HolidayModel>(JSON.stringify({
                name: holiday.name,
                type: holiday.type,
                counties: holiday.counties
            }), {mainCreator: () => [HolidayModel]}
        );
        return returnHoliday;
    }


    public static fromServiceArray(holidays: any): HolidayModel[] {
        const resultHolidays = [];
        if (holidays) {
            for (const holiday of holidays) {
                resultHolidays.push(HolidayModel.fromService(holiday));
            }
        }
        return resultHolidays;
    }
}
