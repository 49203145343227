import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ContactType} from '../../../core/models/contacttype/contactType.model';
import {ContactTypeState, selectedContactTypeSelect} from '../../../core/models/contacttype/store';
import {Store} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import {contactTypeSelectedAction, saveContactTypeAction} from '../../../core/models/contacttype/store/contacttype.actions';
import {ObjectMapper} from 'jackson-js';

@Component({
  selector: 'app-contacttype-edit',
  templateUrl: './contacttype-edit.component.html'
})
export class ContacttypeEditComponent implements OnInit, OnDestroy, AfterViewInit{
  contactTypeForm: FormGroup;
  selectedContactType$: Observable<ContactType> = this.contactTypeStore.select(selectedContactTypeSelect);
  selectedContactTypeSub: Subscription;
  selectedContactType: ContactType;

  @Input() autoFocus = false;
  @ViewChild('description') description: ElementRef;
  @Input() resetSelectedAfterSave = false;
  @Input() companyId = null;
  @Output() saved = new EventEmitter<boolean>();

  save = false;
  constructor(
    private contactTypeStore: Store<ContactTypeState>
  ) {
    this.contactTypeForm = new FormGroup( {
      id: new FormControl(),
      description: new FormControl(null, Validators.required)
    });
  }

  ngOnInit(): void {
    this.selectedContactTypeSub = this.selectedContactType$.subscribe(
        (contactType: ContactType) => this.selectContactType(contactType)
    );
  }

  saveContactType(): void {
    const objectMapper = new ObjectMapper();
    const contactType = objectMapper.parse<ContactType>(JSON.stringify(this.contactTypeForm.value), {mainCreator: () => [ContactType]});
    this.contactTypeStore.dispatch(saveContactTypeAction({payload: contactType, companyId: this.companyId}));

    this.saved.emit(true);

    if ( this.resetSelectedAfterSave) {
      this.save = true;
    }
    this.resetForm();
  }

  selectContactType(contactType: ContactType): void {
    if (contactType) {
      if (this.save) {
        this.contactTypeStore.dispatch(contactTypeSelectedAction({payload: null}));
        this.save = false;
      }
      this.contactTypeForm.patchValue(contactType);
      this.selectedContactType = contactType;
    } else {
      this.resetForm();
    }
  }

  resetForm(): void {
    this.contactTypeForm?.reset();
    this.selectedContactType = null;
  }

  ngOnDestroy(): void {
    this.selectedContactTypeSub.unsubscribe();
    //this.contactTypeStore.dispatch(contactTypeSelectedAction({payload: null}));
  }

  ngAfterViewInit(): void {
    if (this.description && this.autoFocus) {
      this.description.nativeElement.focus();
    }
  }
}
