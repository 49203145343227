import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormTemplateModel} from '../../../../core/models/form/formTemplate.model';
import {SignaturePad} from 'angular2-signaturepad';
import {Task} from "../../../../core/models/order/task.model";
import {Order} from "../../../../core/models/order/order.model";
import {saveOrderAction} from "../../../../core/models/order/store/order.actions";
import {OrderState} from "../../../../core/models/order/store";
import {Store} from "@ngrx/store";
import {FormArray, FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-formtemplates',
  templateUrl: './formtemplates.component.html',
  styleUrls: ['./formtemplates.component.scss']
})
export class FormtemplatesComponent implements OnInit {
  @Input() templates: FormTemplateModel[] = [];
  copyTemplates: FormTemplateModel[] = [];
  @Output() templatesChange = new EventEmitter<FormTemplateModel[]>();
  @Input() order: Order;
  @Input() task: Task;

  templatesForm: FormGroup;

  public signaturePadOptions: Object = {
    minWidth: 2,
    canvasWidth: 800,
    canvasHeight: 100
  };

  @ViewChild('signaturePad') signaturePad: SignaturePad;

  constructor(
    private orderStore: Store<OrderState>
  ) {
    this.templatesForm = new FormGroup({
      templates: new FormArray([]),
    });
  }

  formTemplates(): FormArray {
    return this.templatesForm.get('templates') as FormArray;
  }

  newTemplate(): FormGroup {
    return new FormGroup( {
      id: new FormControl(),
      sort: new FormControl(),
      name: new FormControl(),
      description: new FormControl(),
      formFields: new FormArray([])
    });
  }

  addTemplate() {
    this.formTemplates().push(this.newTemplate());
  }

  formFields(templateIndex: number): FormArray {
    return this.formTemplates().at(templateIndex).get('formFields') as FormArray;
  }

  newFormField(): FormGroup {
    return new FormGroup( {
      sort: new FormControl(),
      formFieldType: new FormControl(),
      key: new FormControl(),
      value: new FormControl(),
      description: new FormControl(),
      pattern: new FormControl(),
      required: new FormControl(),
    });
  }

  addFormField(templateIndex: number): void {
    this.formFields(templateIndex).push(this.newFormField());
  }

  setFormFieldValue(templateIndex: number, formFieldIndex: number, value): void {
    this.formFields(templateIndex).at(formFieldIndex).patchValue({value});
  }


  ngOnInit(): void {
    this.copyTemplates = JSON.parse(JSON.stringify(this.templates));
    this.copyTemplates?.forEach((template, index) => {
      this.addTemplate();
      template.formFields.forEach(() => {
        this.addFormField(index);
      })
    });
    const patchValue = { templates: this.copyTemplates };
    this.templatesForm.patchValue(patchValue);
  }

  saveSignature(data, formIndex: number, inputIndex: number): void {
    this.setFormFieldValue(formIndex, inputIndex, data);
  }

  deleteSignature(formIndex: number, inputIndex: number): void {
    this.setFormFieldValue(formIndex, inputIndex, null);
  }

  saveForm(): void {
    // copyTemplates wieder in die Objekte einpflegen..
    const orderTemplate = JSON.parse(JSON.stringify(this.order.formTemplate)) ?? [];
    this.copyTemplates = this.templatesForm?.value?.templates;
    this.copyTemplates.forEach( template => {
      const orderTypeIndex = orderTemplate?.findIndex(orderTemplate => orderTemplate.id === template.id) ?? -1;
      if (orderTypeIndex > -1) {
        orderTemplate.splice(orderTypeIndex, 1);
      }
      orderTemplate.push(template);
    });

    const newOrder = {...this.order, formTemplate: orderTemplate};
    this.orderStore.dispatch(saveOrderAction({payload: newOrder, customerId: this.order.customerId}));
  }

}
