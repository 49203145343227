import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {ChartType} from 'ng-apexcharts/lib/model/apex-types';
import {activeCompanySelect, CompanyState} from "../../../core/models/company/store";
import {Store} from "@ngrx/store";
import {Observable, Subscription} from "rxjs";
import {Company} from "../../../core/models/company/company.model";

export declare type DashboardPeriod = 1 | 2 | 3;

@Component({
  selector: 'app-dashboardtile',
  templateUrl: './dashboardtile.component.html',
  styleUrls: ['./dashboardtile.component.scss']
})
export class DashboardtileComponent implements OnInit, OnChanges, OnDestroy {

  @Input() chartType: ChartType;
  @Input() chartHeight: number;
  @Input() chartData: any[];
  @Input() chartDateDescription: any[];
  @Input() colors: any[];
  @Input() headline: string;
  @Input() showTrend = false;
  @Input() showCount = false;
  @Output() dashboardPeriod = new EventEmitter<DashboardPeriod>();

  totalCount = 0;
  actualDashboardPeriod: DashboardPeriod = 1;

  activeCompany$: Observable<Company> = this.companyState.select(activeCompanySelect);
  activeCompanySub: Subscription;
  activeCompany: Company;

  constructor(
    private companyState: Store<CompanyState>
  ) {
    this.activeCompanySub = this.activeCompany$.subscribe( company => {
      this.activeCompany = company;
      this.changeDashboardPeriod(1);
    })
  }

  ngOnDestroy(): void {
        this.activeCompanySub.unsubscribe();
    }

  ngOnInit(): void {
    this.changeDashboardPeriod(1);
  }

  changeDashboardPeriod(period: DashboardPeriod): void {
    this.actualDashboardPeriod = period;
    this.dashboardPeriod.emit(period);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.chartData?.currentValue) {
      let newCount = 0;
      changes.chartData.currentValue.forEach((data) => {
        newCount += data;
      });
      this.totalCount = newCount;
    }
  }
}
