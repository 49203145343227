export class SearchResult {
  constructor(
    public customerId: string,
    public valueId: string,
    public valueFoundAt: string,
    public description: string,
    public hits: number
  ) {}

  public static fromServiceArray(searchResultArray: any): SearchResult[] {
    const searchResults: SearchResult[] = [];
    for (const searchResult of searchResultArray.values) {
      searchResults.push(SearchResult.fromService(searchResult, searchResultArray.hits));
    }
    if (searchResults.length < searchResultArray.hits) {
      const message = 'Weitere ' + (searchResultArray.hits - searchResults.length ) + ' Suchergebnisse';
      searchResults.push(new SearchResult(null, null, null, message, null ));
    }
    return searchResults;
  }

  public static fromService(searchResult: any, hits: number): SearchResult {
    return new SearchResult(
      searchResult.customerId,
      searchResult.valueId,
      searchResult.valueFoundAt,
      searchResult.description,
      hits
    );
  }
}
