<div class="row mt-4" *ngIf="messageConfig && user">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <div class="p-3 border-bottom">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="d-flex align-items-end mb-2 mb-lg-0">
                <i class="feather icon-settings icon-xl me-2"></i>
                <h4 class="me-1">{{ 'labels.messageSettings'| translate}}</h4>
              </div>
            </div>
          </div>
        </div>
          <table class="table table-hover">
            <thead>
            <tr>
              <th>Typ</th>
              <th>Nachricht</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let config of messageConfig.configuration">
              <td>{{ 'notification.' + config.messageType | translate}}</td>
              <td>
                <select #selectElem id="type" type="text" class="form-control"
                        (change)="change(selectElem.value, config)">
                  <option *ngFor="let forwardType of messageConfig.forwardTypes" [value]="forwardType"
                          [selected]="forwardType === config.forwardType">
                    {{ 'notification.' + forwardType | translate}}
                  </option>
                </select>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
  </div>
</div>

