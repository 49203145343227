import {Action, createReducer, on} from '@ngrx/store';
import * as EmployeeVacationActions from './employee-vacation.actions';
import {EmployeeVacationEntitlementModel} from "../employee-vacation-entitlement.model";
import {EmployeeVacationModel} from "../employee-vacation.model";
import {HolidayResultModel} from "../holiday-result.model";
import {Page} from "../../page/page.model";

export interface EmployeeVacationReducer {
    entitlements: EmployeeVacationEntitlementModel[];
    vacations: EmployeeVacationModel[];
    holidays: Page<HolidayResultModel>
}

export const initialState: EmployeeVacationReducer = {
    entitlements: [],
    vacations: [],
    holidays: null
};

const employeeVacationReducer = createReducer(
    initialState,
    on(EmployeeVacationActions.vacationEntitlementsLoadedAction, (state, {entitlements}) => ({
        ...state,
        entitlements: entitlements
    })),
    on(EmployeeVacationActions.changeVacationEntitlementAction, (state, {entitlement}) => ({
        ...state,
        entitlements: changeEntitlement(state.entitlements, entitlement)
    })),
    on(EmployeeVacationActions.vacationEntitlementAddedAction, (state, {entitlement}) => ({
        ...state,
        entitlements: addEntitlement(state.entitlements, entitlement)
    })),
    on(EmployeeVacationActions.vacationLoadedAction, (state, {vacation}) => ({
        ...state,
        vacations: vacation
    })),
    on(EmployeeVacationActions.vacationAddedAction, (state, {vacation}) => ({
        ...state,
        vacations: addVacation(state.vacations, vacation)
    })),
    on(EmployeeVacationActions.holidaysLoadedAction, (state, {holidays}) => ({
        ...state,
        holidays: holidays
    }))
);

function addVacation(vacations: EmployeeVacationModel[], vacation: EmployeeVacationModel): EmployeeVacationModel[] {
    const result = [...vacations];
    result.push(vacation);
    return result;
}

function addEntitlement(entitlements: EmployeeVacationEntitlementModel[], entitlement: EmployeeVacationEntitlementModel): EmployeeVacationEntitlementModel[] {
    const resultEntitlements = [...entitlements];
    resultEntitlements.push(entitlement);
    return resultEntitlements;
}

function changeEntitlement(entitlements: EmployeeVacationEntitlementModel[], entitlement: EmployeeVacationEntitlementModel): EmployeeVacationEntitlementModel[] {
    const resultEntitlements = [...entitlements];
    const index = entitlements.findIndex(_entitlement => _entitlement.vacationEntitlementId === entitlement.vacationEntitlementId);
    if (index > -1) {
        resultEntitlements.splice(index);
        resultEntitlements.push(entitlement)
    }
    return resultEntitlements;
}

export function reducer(state: EmployeeVacationReducer | undefined, action: Action): EmployeeVacationReducer {
    return employeeVacationReducer(state, action);
}
