import {createSelector} from '@ngrx/store';
import {ReducerCustomerState} from './customer.reducer';

export interface CustomerState {
  customer: ReducerCustomerState;
}

export const selectCustomerState = (state: CustomerState) => state.customer;

export const selectedCustomerSelect = createSelector(
  selectCustomerState,
  (state) => state.selectedCustomer
);

export const selectedCustomerHistorySelect = createSelector(
    selectCustomerState,
    (state) => state.customerHistory
);
