import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';

export class MessageConfiguration {
  @JsonProperty() @JsonClassType({type: () => [String]})
  messageType: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  forwardType: string;

  constructor(
    messageType: string,
    forwardType: string
  ) {
    this.messageType = messageType;
    this.forwardType = forwardType;
  }

  public static fromServiceArray(messageConfigurationsArray: any): MessageConfiguration[] {
    const configurations: MessageConfiguration[] = [];
    if (messageConfigurationsArray) {
      for (const config of messageConfigurationsArray) {
        configurations.push(MessageConfiguration.fromService(config));
      }
    }
    return configurations;
  }

  public static fromService(messageConfiguration: any): MessageConfiguration {
    const objectMapper = new ObjectMapper();
    const returnMessageConfiguration = objectMapper.parse<MessageConfiguration>( JSON.stringify( {
      messageType: messageConfiguration.messageType,
      forwardType: messageConfiguration.forwardType}), {mainCreator: () => [MessageConfiguration]}
    );
    return returnMessageConfiguration;
  }

  public static toServiceArray(messageConfigurationArray: MessageConfiguration[]): any {
    const configurations = [];
    if (messageConfigurationArray) {
      for (const config of messageConfigurationArray) {
        configurations.push(MessageConfiguration.toService(config));
      }
    }
    return configurations;
  }

  public static toService(messageConfiguration: MessageConfiguration): any {
    return {
      messageType: messageConfiguration.messageType,
      forwardType: messageConfiguration.forwardType
    };
  }
}
