import {JsonClassType, JsonProperty, ObjectMapper} from "jackson-js";
import {Address} from "../address.model";
import {DatevCustomerModel} from "./datevCustomer.model";
import {DatevOrderModel} from "./datevOrder.model";
import {DatevTaskModel} from "./datevTask.model";
import {DatevErrors} from "./datevErrors.model";

export class DatevImportDtoModel {

    @JsonProperty() @JsonClassType({type: () => [String]})
    id: string;
    @JsonProperty() @JsonClassType({type: () => [String]})
    recordType: string;
    @JsonProperty() @JsonClassType({type: () => [DatevCustomerModel]})
    customerRecord: DatevCustomerModel;
    @JsonProperty() @JsonClassType({type: () => [DatevOrderModel]})
    orderRecord: DatevOrderModel;
    @JsonProperty() @JsonClassType({type: () => [String]})
    importState: string;

    constructor(
        id: string,
        recordType: string,
        @JsonClassType({type: () => [DatevCustomerModel]}) customerRecord: DatevCustomerModel,
        @JsonClassType({type: () => [DatevOrderModel]}) orderRecord: DatevOrderModel,
        importState: string
    ){
        this.id = id;
        this.recordType = recordType;
        this.customerRecord = customerRecord;
        this.orderRecord = orderRecord;
        this.importState = importState;
    }

    public deepCopy(): DatevImportDtoModel {
        if ( this.recordType === 'ORDER') {
            const tasks = [...this.orderRecord.tasks];
            const operationAddress = {...this.orderRecord.operationAddress};
            const orderRecord = {...this.orderRecord, operationAddress, tasks};
            return {...this, orderRecord}
        } else if (this.recordType === 'CUSTOMER') {
            const mainAddress = {...this.customerRecord.mainAddress};
            const contacts = [...this.customerRecord.contacts];
            const customerRecord = {...this.customerRecord, mainAddress, contacts};
            return {...this, customerRecord}
        } else {
            return this;
        }
    }

    public static fromService(importDto: any): DatevImportDtoModel {
        const objectMapper = new ObjectMapper();
        return objectMapper.parse<DatevImportDtoModel>(JSON.stringify({
            id: importDto.id,
            recordType: importDto.recordType,
            customerRecord: DatevCustomerModel.fromService(importDto.customerRecord),
            orderRecord: DatevOrderModel.fromService(importDto.orderRecord),
            importState: importDto.importState
        }), {mainCreator: () => [DatevImportDtoModel]});
    }
}

export enum RecordType {
    CUSTOMER,
    ORDER
}
