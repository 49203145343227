import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {ChatMessage} from '../../../core/models/chatMessage.model';
import {Observable, Subscription} from 'rxjs';
import {User} from '../../../core/models/user/user.model';
import {loggedInUserSelect, UserState} from '../../../core/models/user/store';
import {Store} from '@ngrx/store';
import {filesOfSelectedOrderSelect, OrderState, uploadedImageSelect} from "../../../core/models/order/store";
import {
    addImageToOrderAction,
    imageUploadedAction,
    saveOrderAction
} from "../../../core/models/order/store/order.actions";
import {Task} from "../../../core/models/order/task.model";
import {Order} from "../../../core/models/order/order.model";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Lightbox} from "ngx-lightbox";
import {UpSpotFileModel} from "../../../core/models/upSpotFile.model";
import {Customer} from "../../../core/models/customer/customer.model";
import {DocumentMetaDataModel} from "../../../core/models/file/documentMetaData-model";
import {DocumentManagementApiService} from "../../../core/services/document-management-api.service";
import * as BlobUtil from 'blob-util';

@Component({
    selector: 'app-chatcomponent',
    templateUrl: './chatcomponent.component.html',
    styleUrls: ['./chatcomponent.component.scss']
})
export class ChatcomponentComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

    @Input() messages: ChatMessage[] = [];
    @Input() order: Order;
    @Input() task: Task;
    @Input() header: string;
    @Output() savedEvent = new EventEmitter<void>();

    @ViewChild('scrollRef') scrollRef: any;

    loggedInUser$: Observable<User> = this.userStore.select(loggedInUserSelect);
    loggedInUserSub: Subscription;
    loggedInUser: User;

    uploadedImage$: Observable<DocumentMetaDataModel> = this.orderStore.select(uploadedImageSelect);
    uploadedImageSub: Subscription;
    uploadedImage: DocumentMetaDataModel;

    orderDocuments$: Observable<DocumentMetaDataModel[]> = this.orderStore.select(filesOfSelectedOrderSelect);
    orderDocumentsSub: Subscription;
    orderDocuments: DocumentMetaDataModel[];

    isReplyMessage = false;

    showEmojiPicker = false;

    emoji = '';

    formData!: UntypedFormGroup;

    constructor(
        private userStore: Store<UserState>,
        private orderStore: Store<OrderState>,
        public formBuilder: UntypedFormBuilder,
        private lightbox: Lightbox,
        private documentManagementApi: DocumentManagementApiService,
    ) {
        this.loggedInUserSub = this.loggedInUser$.subscribe(
            user => {
                this.loggedInUser = user;
            }
        );

        this.uploadedImageSub = this.uploadedImage$.subscribe(image => {
            if (image) {
                this.uploadedImage = image;
                this.addImageToOrder();
            }
        });
    }

    private addImagesToMessages(documents: DocumentMetaDataModel[]) {
        this.orderDocuments = documents;
        const messagesCopy = [...this.messages];
        this.messages.forEach(message => {
            if (message.imageId) {
                const document = this.orderDocuments.find(document => document.id === message.imageId);
                if (document) {
                    let image: any = {
                        src: null,
                        caption: document.fileName,
                        thumb: document.previewFileUrl
                    };
                    const copyMessage = new ChatMessage(message.id, message.user, message.message, message.replyMessage, message.replyUser, message.messageDateTime, message.imageId, message.sort);
                    copyMessage.image = image;
                    const index = messagesCopy.findIndex(findMessage => findMessage.id === copyMessage.id);
                    if (index > -1) {
                        messagesCopy.splice(index, 1);
                        messagesCopy.push(copyMessage)
                    }
                    this.messages.sort((a, b) => {
                        return a.messageDateTime.getTime() - b.messageDateTime.getTime()
                    });
                }
            }
        });
        this.messages = messagesCopy;
        this.messages.sort((a, b) => {
            return a.messageDateTime.getTime() - b.messageDateTime.getTime()
        });
        this.onListScroll();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.orderDocuments) {
            this.addImagesToMessages(this.orderDocuments);
        }
    }

    ngAfterViewInit(): void {
        this.orderDocumentsSub = this.orderDocuments$.subscribe(documents => {
            if (documents) {
                this.addImagesToMessages(documents);
            }
        });
        this.scrollRef.SimpleBar.getScrollElement().scrollTop = 300;
        this.onListScroll();
    }

    ngOnInit(): void {
        if (this.messages) {
            this.messages = [...this.messages];
        } else {
            this.messages = [];
        }

        this.formData = this.formBuilder.group({
            message: ['', [Validators.required]],
        });
    }

    ngOnDestroy(): void {
        this.loggedInUserSub.unsubscribe();
        this.uploadedImageSub.unsubscribe();
        this.orderDocumentsSub.unsubscribe();

        this.orderStore.dispatch(imageUploadedAction({file: null}));
    }

    addComment(chatMessage: any): void {
        const message = new ChatMessage(null, null, chatMessage.value, null, null, null, null, null);
        if (this.isReplyMessage == true) {
            var chatReplyUser = (document.querySelector(".replyCard .replymessage-block .flex-grow-1 .conversation-name") as HTMLAreaElement).innerHTML;
            var chatReplyMessage = (document.querySelector(".replyCard .replymessage-block .flex-grow-1 .mb-0") as HTMLAreaElement).innerText;

            message.replyMessage = chatReplyMessage;
            message.replyUser = chatReplyUser;
        }

        this.saveMessage(message);
    }

    private saveMessage(message: ChatMessage) {
        const comment = new ChatMessage(null, this.loggedInUser, message.message, message.replyMessage, message.replyUser, new Date(), message.imageId, this.messages.length);
        comment.image = message.image;

        this.messages.push(comment);
        if (this.task) {
            const chatMessages = [...this.task.chatMessages];
            chatMessages.push(comment);
            const taskToSave = {...this.task, chatMessages};
            const tasks = [...this.order.tasks];
            const taskIndex = tasks.findIndex(sub => sub.id === this.task.id);
            tasks.splice(taskIndex, 1);
            tasks.push(taskToSave);
            const orderToSave = {...this.order, tasks};
            this.orderStore.dispatch(saveOrderAction({payload: orderToSave, customerId: this.order.customerId}));
        } else {
            const orderChatMessages = [...this.order.chatMessages];
            orderChatMessages.push(comment);
            const orderToSave = {...this.order, chatMessages: orderChatMessages};
            this.orderStore.dispatch(saveOrderAction({payload: orderToSave, customerId: this.order.customerId}));
        }
        // Set Form Data Reset
        this.formData = this.formBuilder.group({
            message: null,
        });
        this.isReplyMessage = false;
        document.querySelector('.replyCard')?.classList.remove('show');
        this.emoji = '';
        this.onListScroll();
    }

    addImageToOrder(): void {

        if (this.uploadedImage && this.uploadedImage.originalFileInfo.mimeType !== 'application/pdf') {
            const message = new ChatMessage(null, null, null, null, null, null, null, null,);
            message.imageId = this.uploadedImage.id;
            this.saveMessage(message);
        }
    }

    replyMessage(event: any, align: any) {
        this.isReplyMessage = true;
        document.querySelector('.replyCard')?.classList.add('show');
        var copyText = event.target.closest('.chat-list').querySelector('.ctext-content').innerHTML;
        (document.querySelector(".replyCard .replymessage-block .flex-grow-1 .mb-0") as HTMLAreaElement).innerHTML = copyText;
        var msgOwnerName: any = event.target.closest(".chat-list").classList.contains("right") == true ? 'You' : document.querySelector('.username')?.innerHTML;
        (document.querySelector(".replyCard .replymessage-block .flex-grow-1 .conversation-name") as HTMLAreaElement).innerHTML = msgOwnerName;
    }

    copyMessage(event: any) {
        navigator.clipboard.writeText(event.target.closest('.chat-list').querySelector('.ctext-content').innerHTML);
        (document.getElementById("copyClipBoard") as HTMLElement).style.display = "block";
        setTimeout(() => {
            (document.getElementById("copyClipBoard") as HTMLElement).style.display = "none";
        }, 1000);
    }

    closeReplay() {
        document.querySelector('.replyCard')?.classList.remove('show');
    }

    toggleEmojiPicker() {
        this.showEmojiPicker = !this.showEmojiPicker;
    }

    addEmoji(event: any) {
        const {emoji} = this;
        const text = `${emoji}${event.emoji.native}`;
        this.emoji = text;
        this.showEmojiPicker = false;
    }

    onFocus() {
        this.showEmojiPicker = false;
    }

    onBlur() {
    }

    get form() {
        return this.formData.controls;
    }

    onListScroll() {
        if (this.scrollRef !== undefined) {
            setTimeout(() => {
                if (this.scrollRef && this.scrollRef.SimpleBar) {
                    this.scrollRef.SimpleBar.getScrollElement().scrollTop = this.scrollRef?.SimpleBar?.getScrollElement().scrollHeight;
                }
            }, 500);
        }
    }

    open(message: ChatMessage): void {
        this.documentManagementApi.getFileById(message.imageId).subscribe(file => {
            const document = this.orderDocuments.find(document => document.id === message.imageId)
            BlobUtil.blobToBase64String(file)
                .then((base64String) => {
                    const imageUrl = 'data:' + document.previewFileInfo?.mimeType + ';base64,' + base64String;
                    message.image = {
                        src: imageUrl,
                        caption: document.fileName,
                        thumb: document.previewFileUrl
                    };
                    // open lightbox
                    this.lightbox.open([message.image], 0, {});
                })
        });
    }

    close(): void {
        // close lightbox programmatically
        this.lightbox.close();
    }

    addImage(filesEvent: Event) {
        let files = (<HTMLInputElement>filesEvent.target).files;
        for (let i = 0; i < files.length; i++) {
            const upSpotFile = new UpSpotFileModel(files[i], null);
            const reader = new FileReader();
            reader.readAsDataURL(upSpotFile.file);
            const customer: Customer = new Customer(this.order.customerId, null, null, null, null, null);
            if (UpSpotFileModel.isImage(upSpotFile.file.name)) {
                this.orderStore.dispatch(addImageToOrderAction({
                    order: this.order,
                    customer: customer,
                    file: upSpotFile,
                    markAsTemplate: false
                }));
            } else {
                //this.toastr.error(this.transLateService.instant('labels.onlyImages'));
            }
        }
    }

    printMEssages() {
        console.log(this.messages);
    }
}
