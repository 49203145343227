<form [formGroup]="companyForm" (ngSubmit)="saveCompany()">
    <div class="modal-body">
        <div class="row g-3">
            <div class="mb-3 col-12">
                <input type="hidden" id="tenantId" formControlName="tenantId">
                <input type="hidden" id="companyId" formControlName="companyId">
            </div>
        </div>

        <div class="row">
            <div class="mb-3 col-12" formGroupName="address">
                <div>
                    <small class="text-uppercase text-muted">{{ 'labels.company' | translate }}*</small>
                    <input type="text" class="form-control" id="companyName" formControlName="companyName"
                           [class.invalid-Input-Formfeedback]="companyForm?.get('address')['controls'].companyName.errors?.required && companyForm?.get('address')['controls'].companyName.touched">
                    <div class="invalid-Formfeedback"
                         *ngIf="companyForm?.get('address')['controls'].companyName.errors?.required && companyForm?.get('address')['controls'].companyName.touched">
                        <small>{{ 'labels.requiredField' | translate}}</small>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12 col-md-12">
                        <small class="text-uppercase text-muted">{{ 'labels.street' | translate }}*</small>
                        <input type="text" class="form-control" id="street" formControlName="street"
                               [class.invalid-Input-Formfeedback]="companyForm?.get('address')['controls'].street.errors?.required && companyForm?.get('address')['controls'].street.touched">
                        <div class="invalid-Formfeedback"
                             *ngIf="companyForm?.get('address')['controls'].street.errors?.required && companyForm?.get('address')['controls'].street.touched">
                            <small>{{ 'labels.requiredField' | translate}}</small>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12 col-md-6">
                        <small class="text-uppercase text-muted">{{ 'labels.zipcode' | translate }}*</small>
                        <input type="text" class="form-control" id="zipcode" formControlName="zipcode"
                               [class.invalid-Input-Formfeedback]="companyForm?.get('address')['controls'].zipcode.errors?.required && companyForm?.get('address')['controls'].zipcode.touched">
                        <div class="invalid-Formfeedback"
                             *ngIf="companyForm?.get('address')['controls'].zipcode.errors?.required && companyForm?.get('address')['controls'].zipcode.touched">
                            <small>{{ 'labels.requiredField' | translate}}</small>
                        </div>
                    </div>

                    <div class="col-12 col-md-6">
                        <small class="text-uppercase text-muted">{{ 'labels.city' | translate }}*</small>
                        <input type="text" class="form-control" id="city" formControlName="city"
                               [class.invalid-Input-Formfeedback]="companyForm?.get('address')['controls'].city.errors?.required && companyForm?.get('address')['controls'].city.touched">
                        <div class="invalid-Formfeedback"
                             *ngIf="companyForm?.get('address')['controls'].city.errors?.required && companyForm?.get('address')['controls'].city.touched">
                            <small>{{ 'labels.requiredField' | translate}}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-12 col-md-6">
                <small class="text-uppercase text-muted">{{ 'labels.phone' | translate }}</small>
                <input type="text" class="form-control" id="phone" formControlName="phoneNumber">
            </div>

            <div class="col-12 col-md-6">
                <small class="text-uppercase text-muted">{{ 'labels.email' | translate }}</small>
                <input type="text" class="form-control" id="email" formControlName="emailAddress"
                       [class.invalid-Input-Formfeedback]="companyForm?.controls?.emailAddress?.errors?.required && companyForm?.controls?.emailAddress?.touched">
                <div class="invalid-Formfeedback"
                     *ngIf="companyForm?.controls?.emailAddress?.errors?.eMailAddressExists">
                    <small>{{ 'labels.emailExistsError' | translate }}</small>
                </div>
                <div class="invalid-Formfeedback"
                     *ngIf="companyForm?.controls?.emailAddress?.errors?.required && companyForm?.controls?.emailAddress?.touched">
                    <small>{{ 'labels.requiredField' | translate}}</small>
                </div>
            </div>
        </div>
        <div class="mt-3 form-check form-check-flat form-check-primary">
            <small class="text-muted form-check-label">
                <input type="checkbox" class="form-check-input" name="isDefault" formControlName="isDefault">
                <em class="input-frame"></em>
                {{ 'labels.defaultCompanyQuestion' | translate }}
            </small>
        </div>
    </div>
    <div class="modal-footer">
        <div class="hstack gap-2 justify-content-end">
            <button type="button" class="btn btn-light" [disabled]="!companyForm.touched"
                    (click)="resetForm()">{{ 'labels.clear' | translate }}</button>
            <button type="submit" class="btn btn-success"
                    [disabled]="!companyForm.valid">{{ 'labels.save' | translate }}</button>
        </div>
    </div>
</form>
