import {HttpClient} from '@angular/common/http';
import {Observable, of, Subscription} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Inject, Injectable, OnDestroy} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Store} from '@ngrx/store';
import {DatevMapping} from '../models/datev/datevMapping.model';
import {ObjectMapper} from 'jackson-js';
import {DatevImportOverview} from '../models/datev/datevImportOverview.model';
import {allCompaniesSelect, CompanyState} from '../models/company/store';
import {Company} from '../models/company/company.model';
import {DatevImportDtoModel} from "../models/datev/datevImportDto.model";
import {Page} from "../models/page/page.model";
import {DatevOrderModel} from "../models/datev/datevOrder.model";
import {DatevCustomerModel} from "../models/datev/datevCustomer.model";
import {ToastService} from "./Toast/toast-service";

@Injectable()
export class DatevManagementApiService implements OnDestroy{
  companies$: Observable<Company[]> = this.companyStore.select(allCompaniesSelect);
  companiesSubscription: Subscription;

  private REST_API_SERVER = this.orderManagementUrl;
  cache = {};

  constructor(
    private httpClient: HttpClient,
    private toastrService: ToastService,
    private translate: TranslateService,
    private companyStore: Store<CompanyState>,
    @Inject('ORDER_MANAGEMENT') private orderManagementUrl: string) {
    this.companiesSubscription = this.companies$.subscribe( companies => {

    });
  }

  public getAllMappings(): Observable<DatevMapping[]> {
    const url: string = this.REST_API_SERVER + '/import/datev/mapping';
    return this.httpClient.get<any>(url).pipe(
      catchError(error => {
        this.toastrService.error(this.translate.instant('datevManagementService.error_loadMappings'));
        return of([]);
      }),
      map(
        (data: DatevMapping[]) => {
          if (data.length > 0) {
            const objectMapper = new ObjectMapper();
            const result = objectMapper.parse<DatevMapping[]>( JSON.stringify(data), {mainCreator: () => [DatevMapping]});
            return data; // DatevCompanyId.fromServiceArray(data);
          } else {
            return null;
          }
        }
      )
    );
  }

  public addDatevMappings(datevMappings: DatevMapping[]): Observable<DatevMapping[]> {
    const url: string = this.REST_API_SERVER + '/import/datev/mapping';
    return this.httpClient.post<DatevMapping[]>(url, datevMappings).pipe(
      catchError(error => {
        this.toastrService.error(this.translate.instant('datevManagementService.error_saveMappings'));
        return of([]);
      }),
      map((item: any) => {
        this.toastrService.success(this.translate.instant('datevManagementService.mappings_successfully_saved'));
        return item;
      })
    );
  }

  public importOverview(): Observable<DatevImportOverview> {
    const url: string = this.REST_API_SERVER + '/import/datev/overview/import';
    return this.httpClient.get<DatevImportOverview>(url).pipe(
      catchError(error => {
        this.toastrService.error(this.translate.instant('datevManagementService.error_loadOverview'));
        return of(null);
      }),
      map(
        data => {
          if (data) {
            return DatevImportOverview.fromService(data);
          } else {
            return null;
          }
        }
      )
    );
  }

  public importRecords(): Observable<number> {
    const url: string = this.REST_API_SERVER + '/import/datev/queue';
    return this.httpClient.post<number>( url, null).pipe(
      catchError(error => {
        this.toastrService.error(this.translate.instant('datevManagementService.error_importRecords'));
        return of(0);
      }),
      map((item: number) => {
        this.toastrService.success(this.translate.instant('datevManagementService.importRecords_successfully'));
        return item;
      })
    );
  }

  public getImportData(page: number, size: number): Observable<Page<DatevImportDtoModel>> {
      const url: string = this.REST_API_SERVER + '/import/datev/queue?importState=ERRONEOUS'+
      '&page=' + page +
      '&size=' + size;
       return this.httpClient.get<any>(url).pipe(
          catchError(error => {
              this.toastrService.error(this.translate.instant('datevManagementService.error_loadMappings'));
              return of();
          }),
          map(
              (dataArray: any) => {
                  const importDataArray: DatevImportDtoModel[] = [];
                  for (const data of dataArray.content) {
                      importDataArray.push(DatevImportDtoModel.fromService(data));
                  }
                  const resultPage = new Page<DatevImportDtoModel>(
                      dataArray.totalPages,
                      dataArray.totalElements,
                      dataArray.numberOfElements,
                      dataArray.pageSize,
                      dataArray.pageNumber,
                      importDataArray);
                  return resultPage;
              }
          )
      );
  }

    public saveImportData(importDto: DatevImportDtoModel): Observable<string> {
      let url = this.REST_API_SERVER + '/import/datev/queue/' +
          importDto?.id;
      let body = {};
      if (importDto.recordType === 'ORDER') {
          url =  url + '/order';
          body = DatevOrderModel.toService(importDto.orderRecord);
      } else if ( importDto.recordType === 'CUSTOMER') {
          body = DatevCustomerModel.toService(importDto.customerRecord);
          url =  url + '/customer';
      }

        return this.httpClient.put<string>(url,body).pipe(
            catchError(error => {
                this.toastrService.error(this.translate.instant('datevManagementService.error_loadMappings'));
                return of();
            }),
            map(
                (data: any) => {
                    return data;
                }
            )
        );
    }

  ngOnDestroy(): void {
    this.companiesSubscription.unsubscribe();
  }
}
