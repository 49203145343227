import {Injectable} from '@angular/core';
import {OrderManagementApiService} from '../../../services/order-management-api.service';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, mergeMap, switchMap} from 'rxjs/operators';
import {
    addImageToOrderAction,
    allOrdersByPeriodSelectedAction, allOrdersSelectedAction,
    changeTaskAction,
    imageAddedToOrderAction,
    loadImagesOfOrderAction,
    orderSavedAction,
    orderSelectedAction,
    saveOrderAction, selectAllOrdersAction,
    selectAllOrdersByPeriodAction,
    selectOrderAction,
    taskChangedAction
} from './order.actions';
import {Order} from '../order.model';
import {Task} from '../task.model';
import {DocumentManagementApiService} from '../../../services/document-management-api.service';
import {DocumentMetaDataModel} from '../../file/documentMetaData-model';
import {Page} from '../../page/page.model';
import {OrderState} from './index';
import {Store} from '@ngrx/store';
import {Customer} from "../../customer/customer.model";

@Injectable()
export class OrderEffects {
  constructor(
    private httpApiService: OrderManagementApiService,
    private documentHttpApiService: DocumentManagementApiService,
    private actions$: Actions,
    private orderStore: Store<OrderState>) {
  }

  loadOrder$ = createEffect(() => this.actions$.pipe(
    ofType(selectOrderAction),
    switchMap(({customerId, orderId}) => this.httpApiService.getOrderById(customerId, orderId)),
    switchMap((order: Order) => [
      orderSelectedAction({payload: order}),
      loadImagesOfOrderAction({orderId: order.id, customerId: order.customerId})
    ])
  ));

  selectAllOrdersAction$ = createEffect(() => this.actions$.pipe(
    ofType(selectAllOrdersAction),
    switchMap(({startDate, endDate}) => this.httpApiService.getOrders(startDate, endDate)
      .pipe(
        map((customers: Customer[]) => {
          return allOrdersSelectedAction({payload: customers});
        }),
      )
    )));


  loadAllOrdersByPeriod$ = createEffect(() => this.actions$.pipe(
    ofType(selectAllOrdersByPeriodAction),
    switchMap(({start, end, userId}) => this.httpApiService.getOrdersForPeriodAndUserIds(start, end, userId)
      .pipe(
        map((customer: Customer[]) => {
          return allOrdersByPeriodSelectedAction({payload: customer});
        }),
      )
    )));


  saveOrder$ = createEffect(() => this.actions$.pipe(
    ofType(saveOrderAction),
    switchMap(({payload, customerId}) => this.httpApiService.saveOrder(payload, customerId)
      .pipe(
        map((order: Order) => {
          return orderSavedAction({payload: order});
        })
      )
    )));

  saveTask$ = createEffect(() => this.actions$.pipe(
    ofType(changeTaskAction),
    switchMap(({task, orderId, customerId}) => this.httpApiService.updateTask(task, orderId, customerId)
      .pipe(
        map((newTask: Task) => {
          return taskChangedAction({task: newTask, orderId});
        })
      )
    )));

  addImageAtOrder$ = createEffect(() => this.actions$.pipe(
    ofType(addImageToOrderAction),
    switchMap(({order, customer, file, markAsTemplate}) => this.documentHttpApiService.addImageToOrder(order, customer, file, markAsTemplate)
      .pipe(
        map((filesOfSelectedOrder: DocumentMetaDataModel[]) => {
          return imageAddedToOrderAction({files: filesOfSelectedOrder});
        })
      )
    )));

  loadImagesOfOrderAction$ = createEffect(() => this.actions$.pipe(
    ofType(loadImagesOfOrderAction),
    switchMap(({customerId, orderId}) => this.documentHttpApiService.getThumbnailImagesForOrder(orderId, customerId, 0, 9999)
      .pipe(
        map((page: Page<DocumentMetaDataModel>) => {
          return imageAddedToOrderAction({files: page.content});
        })
      )
    )));
}
