import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {Customer} from '../../../core/models/customer/customer.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ContactType} from '../../../core/models/contacttype/contactType.model';
import {
    CustomerState,
    selectedCustomerHistorySelect,
    selectedCustomerSelect
} from '../../../core/models/customer/store';
import {Store} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import {
    customerSelectedAction,
    loadCustomerHistory,
    saveCustomerAction
} from '../../../core/models/customer/store/customer.actions';
import {
    allContactTypesForSelectBoxSelect,
    ContactTypeState,
    selectedContactTypeSelect
} from '../../../core/models/contacttype/store';
import {Company} from '../../../core/models/company/company.model';
import {activeCompanySelect, CompanyState} from '../../../core/models/company/store';
import {CustomerOverviewState} from '../../../core/models/customer/customerOverview/store';
import {ActivatedRoute, Router} from '@angular/router';
import {OrderState} from '../../../core/models/order/store';
import {selectOrderAction} from '../../../core/models/order/store/order.actions';
import {CustomerOrder} from '../../../core/models/customer/customerOrder.model';
import {
    AddressTypeState,
    allAddressTypesForSelectBoxSelect,
    selectedAddressTypeSelect
} from '../../../core/models/addresstype/store';
import {AddressType} from '../../../core/models/addresstype/addressType.model';
import {Address} from '../../../core/models/address.model';
import {ContactPerson} from '../../../core/models/contactperson.model';
import {Contact} from '../../../core/models/contact.model';
import {CheckableCardModel} from '../../../core/models/checkableCard.model';
import {TranslateService} from '@ngx-translate/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import {ObjectMapper} from 'jackson-js';
import {contactTypeSelectedAction} from "../../../core/models/contacttype/store/contacttype.actions";
import {CustomerHistoryModel} from "../../../core/models/customer/customerHistory.model";

@Component({
    selector: 'app-customer-edit',
    templateUrl: './customer-edit.component.html',
    styleUrls: ['./customer-edit.component.scss']
})
export class CustomerEditComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() crateNew;
    customerForm: FormGroup;

    customerAddress: FormGroup;
    customerAddresses: Address[] = [];

    contactPerson: FormGroup;
    contactPersons: ContactPerson[] = [];

    contact: FormGroup;
    contacts: Contact[] = [];

    selectedCustomer$: Observable<Customer> = this.customerStore.select(selectedCustomerSelect);
    selectedCustomerSub: Subscription;
    selectedCustomer: Customer;
    initials: string;

    selectedContactType$: Observable<ContactType> = this.contactTypeStore.select(selectedContactTypeSelect);
    selectedContactTypeSub: Subscription;

    selectedAddressType$: Observable<AddressType> = this.addressTypeStore.select(selectedAddressTypeSelect);
    selectedAddressTypeSub: Subscription;

    selectedCompany$: Observable<Company> = this.companyStore.select(activeCompanySelect);
    selectedCompanySub: Subscription;
    selectedCompany: Company;

    selectedCustomerHistory$: Observable<CustomerHistoryModel[]> = this.customerStore.select(selectedCustomerHistorySelect);
    selectedCustomerHistorySub: Subscription;
    selectedCustomerHistory: CustomerHistoryModel[];

    isCompany: boolean;

    @ViewChild('createNewContactType') createNewContactType: any;
    contactTypeModal: NgbModalRef;

    @ViewChild('createNewAddressType') createNewAddressType: any;
    addressTypeModal: NgbModalRef;

    @ViewChild('addNewAddressModal') addNewAddressModal: any;
    addressModal: NgbModalRef;

    @ViewChild('addNewContactPersonModal') addNewContactPersonModal: any;
    contactPersonModal: NgbModalRef;

    @ViewChild('addNewContactModal') addNewContactModal: any;
    contactModal: NgbModalRef;

    @ViewChild('changeMainAddressModal') changeMainAddressModal: any;
    mainAddressModal: NgbModalRef;

    @ViewChild('historyModal') _historyModal: any;
    historyModal: NgbModalRef;

    contactTypes: Observable<ContactType[]> = this.contactTypeStore.select(allContactTypesForSelectBoxSelect);
    contactTypesSub: Subscription;
    contactTypesArray: ContactType[] = [];
    contactType: ContactType;

    addressTypes: Observable<AddressType[]> = this.addressTypeStore.select(allAddressTypesForSelectBoxSelect);
    addressTypeSub: Subscription;
    addressTypeArray: AddressType[];
    addressType: AddressType;

    constructor(
        private customerStore: Store<CustomerState>,
        private customerOverviewStore: Store<CustomerOverviewState>,
        private contactTypeStore: Store<ContactTypeState>,
        private addressTypeStore: Store<AddressTypeState>,
        private companyStore: Store<CompanyState>,
        private modalService: NgbModal,
        private orderState: Store<OrderState>,
        private router: Router,
        private route: ActivatedRoute,
        private translate: TranslateService) {
    }

    ngAfterViewInit(): void {
        if (this.crateNew && this.crateNew === '1') {
            this.changeMainAddress();
        }
    }

    ngOnDestroy(): void {
        this.selectedCustomerSub.unsubscribe();
        this.selectedContactTypeSub.unsubscribe();
        this.selectedAddressTypeSub.unsubscribe();
        this.selectedCompanySub.unsubscribe();
        this.selectedCompanySub.unsubscribe();
        this.contactTypesSub.unsubscribe();
        this.addressTypeSub.unsubscribe();

        this.customerStore.dispatch(customerSelectedAction({payload: null}));
    }

    ngOnInit(): void {
        this.selectedCompanySub = this.selectedCompany$.subscribe(
            (company: Company) => this.selectedCompany = company
        );

        this.contactTypesSub = this.contactTypes.subscribe(
            (contactTypes: ContactType[]) => this.contactTypesArray = contactTypes
        );

        this.addressTypeSub = this.addressTypes.subscribe(
            (addressTypes: AddressType[]) => this.addressTypeArray = addressTypes
        );

        this.customerAddress = new FormGroup({
                id: new FormControl(),
                addressType: new FormGroup({
                    id: new FormControl(null, Validators.required),
                    description: new FormControl()
                }),
                firstName: new FormControl(),
                name: new FormControl(),
                companyName: new FormControl(),
                street: new FormControl(null, Validators.required),
                zipcode: new FormControl(null, Validators.required),
                city: new FormControl(null, Validators.required)
            }
        );

        this.contactPerson = new FormGroup({
                contactPersonId: new FormControl(),
                name: new FormControl(),
                firstName: new FormControl(),
                personRole: new FormControl(),
                contactPersonContacts: new FormArray([]),
            }
        );

        this.contact = new FormGroup({
            id: new FormControl(),
            value: new FormControl(null, Validators.required),
            contactType: new FormGroup({
                id: new FormControl(null, Validators.required),
                description: new FormControl()
            })
        });

        this.customerForm = new FormGroup({
            id: new FormControl(),
            externalId: new FormControl(),
            isCompany: new FormControl(),
            mainAddress: new FormGroup({
                firstName: new FormControl(),
                name: new FormControl(),
                companyName: new FormControl(),
                street: new FormControl(),
                zipcode: new FormControl(),
                city: new FormControl()
            }),
            remark: new FormControl()
        });

        this.selectedCustomerSub = this.selectedCustomer$.subscribe(
            (customer: Customer) => {
                if (customer) {
                    this.selectCustomer(customer);
                }
            }
        );

        this.selectedCustomerHistorySub = this.selectedCustomerHistory$.subscribe(history => {
            if (history) {
                this.selectedCustomerHistory = history;
            }
        })

        this.selectedContactTypeSub = this.selectedContactType$.subscribe(
            (contactType: ContactType) => this.selectNewContactType(contactType)
        );

        this.selectedAddressTypeSub = this.selectedAddressType$.subscribe(
            (addressType: AddressType) => this.selectNewAddressType(addressType)
        );
    }

    selectNewContactType(contactType: ContactType): void {
        if (contactType) {
            this.contactType = contactType;
            this.closeModalView(this.contactTypeModal);
            if (this.contactModal) {
                this.contact.get('contactType').patchValue(contactType);
            } else if (this.contactPersonModal) {
                const contactPersonContact = this.contactPerson.get('contactPersonContacts') as FormArray;
                const index = contactPersonContact.value.findIndex(data => data?.contactType?.id === '-1');
                if (index !== -1) {
                    contactPersonContact?.controls[index]?.get('contactType')?.patchValue(contactType);
                }
            }
        }
    }

    selectNewAddressType(addressType: AddressType): void {
        if (addressType) {
            this.addressType = addressType;
            this.closeModalView(this.addressTypeModal);
            if (this.addressModal) {
                this.customerAddress.get('addressType').patchValue(addressType);
            }
        }
    }

    openModalDialog(content): NgbModalRef {
        return this.modalService.open(content, {size: 'lg', animation: true, centered: true, backdrop: 'static', keyboard: false});
    }

    saveCustomer(): void {
        const objectMapper = new ObjectMapper();
        const newCustomer: Customer = objectMapper.parse<Customer>(JSON.stringify(this.customerForm.value), {mainCreator: () => [Customer]});

        const saveCustomer = {
            ...this.selectedCustomer,
            mainAddress: newCustomer.mainAddress,
            externalId: newCustomer.externalId,
            isCompany: newCustomer.isCompany,
            remark: newCustomer.remark
        };
        saveCustomer.companyId = this.selectedCompany.companyId;
        this.customerStore.dispatch(saveCustomerAction({payload: saveCustomer}));
        this.modalService.dismissAll();
    }

    private selectCustomer(customer: Customer): void {
        if (customer) {
            if (customer.mainAddress.companyName !== null) {
                this.isCompany = true;
            } else {
                this.isCompany = false;
            }
            this.resetForm();
            this.selectedCustomer = customer;

            const firstName = this.selectedCustomer.mainAddress.firstName
            const lastName = this.selectedCustomer.mainAddress.name
            const company = this.selectedCustomer.mainAddress.companyName

            if (company && company.length > 1) {
                this.initials = company.substring(0, 2).toUpperCase()
            } else if (firstName != null && firstName !== '' && lastName != null && lastName !== '') {
                this.initials = (firstName.substring(0, 1) + lastName.substring(0, 1)).toUpperCase();
            } else {
                this.initials = ''
            }


            this.contactPersons = [...this.selectedCustomer.contactPersons];
            this.customerAddresses = [...this.selectedCustomer.customerAddresses];
            this.contacts = [...this.selectedCustomer.contacts];

            this.customerStore.dispatch(loadCustomerHistory({customerId: customer.id}));
        }
    }

    resetForm(): void {
        this.selectedCustomer = null;
        const contactPersonContacts = this.contactPerson?.controls.contactPersonContacts as FormArray;
        contactPersonContacts?.clear();
        this.contactPerson?.reset();
        this.customerAddress?.reset();
    }

    addContactPersonContact(): void {
        const contactPersonContact = this.contactPerson.get('contactPersonContacts') as FormArray;
        contactPersonContact.push(new FormGroup({
            id: new FormControl(),
            value: new FormControl(null, Validators.required),
            contactType: new FormGroup({
                id: new FormControl(null, Validators.required),
                description: new FormControl()
            })
        }));
    }

    clickIsCompany(): void {
        this.isCompany = !this.isCompany;
    }

    selectedContactType(value: any): void {
        const contactType = value.target.value.substr(3, value.target.value.length);
        if (contactType === '-1') {
            this.contactTypeModal = this.openModalDialog(this.createNewContactType);
        }
    }

    selectedAddressType(value: any): void {
        const addressType = value.target.value.substr(3, value.target.value.length);
        if (addressType === '-1') {
            this.addressTypeModal = this.openModalDialog(this.createNewAddressType);
        }
    }

    onSelect({selected}): void {
        const order: CustomerOrder = selected[0];
        this.orderState.dispatch(selectOrderAction({customerId: this.selectedCustomer.id, orderId: order.id}));
        this.router.navigate(['/order-overview']);
    }

    addNewAddress(): void {
        this.customerAddress.reset();
        this.addressModal = this.openModalDialog(this.addNewAddressModal);
    }

    saveAddress(): void {
        const customerAddress: Address = this.customerAddress.value;
        if (!customerAddress.addressType.description) {
            for (const addressType of this.addressTypeArray) {
                if (customerAddress.addressType.id === addressType.id) {
                    customerAddress.addressType = addressType;
                    break;
                }
            }
        }

        if (customerAddress.id) {
            const index = this.customerAddresses.findIndex(x => x.id === customerAddress.id);
            this.customerAddresses[index] = customerAddress;
        } else {
            this.customerAddresses.push(this.customerAddress.value);
        }
        const saveCustomer = {...this.selectedCustomer, customerAddresses: this.customerAddresses};
        this.customerStore.dispatch(saveCustomerAction({payload: saveCustomer}));

        this.customerAddress.reset();
        this.closeModalView(this.addressModal);
    }

    selectAddress(index: number): void {
        this.customerAddress.reset();
        this.customerAddress.patchValue(this.customerAddresses[index]);
        this.addressModal = this.openModalDialog(this.addNewAddressModal);
    }

    resetAndCloseDialogCustomerAddress(): void {
        this.customerAddress.reset();
        this.closeModalView(this.addressModal);
    }

    addNewContact(): void {
        this.contact.reset();
        this.contactModal = this.openModalDialog(this.addNewContactModal);
    }

    saveContact(): void {
        const contact: Contact = this.contact.value;
        if (!contact.contactType?.description) {
            for (const contactType of this.contactTypesArray) {
                if (contactType.id === contact.contactType.id) {
                    contact.contactType = contactType;
                    break;
                }
            }
        }
        if (contact.id) {
            const index = this.contacts.findIndex(x => x.id === contact.id);
            this.contacts[index] = contact;
        } else {
            this.contacts.push(this.contact.value);
        }
        const saveCustomer = {...this.selectedCustomer, contacts: this.contacts};
        this.customerStore.dispatch(saveCustomerAction({payload: saveCustomer}));
        this.contact.reset();
        this.closeModalView(this.contactModal);
    }

    selectContact(index: number): void {
        const contact = this.contacts[index];
        this.contact.reset();
        this.contact.patchValue(contact);
        this.contactModal = this.openModalDialog(this.addNewContactModal);
    }

    resetAndCloseDialogContact(): void {
        const contactPersonContacts = this.contactPerson.controls.contactPersonContacts as FormArray;
        this.contactTypeStore.dispatch(contactTypeSelectedAction({payload: null}));
        contactPersonContacts?.clear();
        this.contactPerson.reset();
        this.contact.reset();
        this.closeModalView(this.contactModal);
    }

    addNewContactPerson(): void {
        this.contactPerson.reset();
        this.contactPersonModal = this.openModalDialog(this.addNewContactPersonModal);
    }

    saveContactPerson(): void {
        const contactPerson: ContactPerson = this.contactPerson.value;
        for (const contact of contactPerson.contactPersonContacts) {
            if (!contact.contactType?.description) {
                for (const contactType of this.contactTypesArray) {
                    if (contactType.id === contact.contactType.id) {
                        contact.contactType = contactType;
                        break;
                    }
                }
            }
        }
        if (contactPerson.contactPersonId) {
            const index = this.contactPersons.findIndex(x => x.contactPersonId === contactPerson.contactPersonId);
            this.contactPersons[index] = contactPerson;
        } else {
            this.contactPersons.push(this.contactPerson.value);
        }
        const saveCustomer = {...this.selectedCustomer, contactPersons: this.contactPersons};
        this.customerStore.dispatch(saveCustomerAction({payload: saveCustomer}));
        this.contactPerson.reset();
        this.closeModalView(this.contactModal);
    }

    selectContactPerson(index: number): void {
        const contactPerson = this.contactPersons[index];
        if (contactPerson.contactPersonContacts) {
            for (const contact of contactPerson.contactPersonContacts) {
                this.addContactPersonContact();
            }
        }

        this.contactPerson.reset();
        this.contactPerson.patchValue(this.contactPersons[index]);
        this.contactPersonModal = this.openModalDialog(this.addNewContactPersonModal);
    }

    resetAndCloseDialogContactPerson(): void {
        const contactPersonContacts = this.contactPerson.controls.contactPersonContacts as FormArray;
        contactPersonContacts?.clear();
        this.contactPerson.reset();

        this.closeModalView(this.contactPersonModal);
    }

    resetAndCloseDialogHistory(): void {
        this.closeModalView(this.historyModal);
    }

    changeMainAddress(): void {
        this.customerForm?.patchValue(this.selectedCustomer);
        this.mainAddressModal = this.openModalDialog(this.changeMainAddressModal);
    }

    resetAndCloseDialogMainAddress(): void {
        const contactPersonContacts = this.contactPerson.controls.contactPersonContacts as FormArray;
        contactPersonContacts?.clear();
        this.contactPerson.reset();
        this.closeModalView(this.mainAddressModal);
    }

    navigateToNewOrder(index: number): void {
        if (index !== null && index >= 0) {
            this.router.navigate(['/order-overview/' + this.selectedCustomer?.id + '/' + this.selectedCustomer?.orders[index]?.id]);
        } else {
            this.router.navigate(['/order-overview/' + this.selectedCustomer?.id + '/0']);
        }
    }

    openHistory(index: number): void {
        this.historyModal = this.openModalDialog(this._historyModal);
    }

    getNewCustomerText(): string {
        return this.translate.instant('labels.addNewCustomer');
    }

    getNewAddressText(): string {
        return this.translate.instant('labels.addNewAddress');
    }

    getNewContactPersonText(): string {
        return this.translate.instant('labels.addNewContactPerson');
    }

    getNewContactText(): string {
        return this.translate.instant('labels.addNewContact');
    }

    getNewOrderText(): string {
        return this.translate.instant('labels.addNewOrder');
    }

    closeModalView(modal: NgbModalRef): void {
        modal?.close();
        modal = null;
    }

}
