import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';

export class StatusMessageAuthorModel {
  @JsonProperty()
  @JsonClassType({type: () => [String]})
  userId: string;

  constructor(userId) {
    this.userId = userId;
  }

  public static fromService(author: any): StatusMessageAuthorModel {
      const objectMapper = new ObjectMapper();
      const returnAuthor = objectMapper.parse<StatusMessageAuthorModel>(JSON.stringify({
            userId: author['user-id']
          }), {mainCreator: () => [StatusMessageAuthorModel]}
      );
      return returnAuthor;
    }

}
