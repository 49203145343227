import {createSelector} from '@ngrx/store';
import {ReducerOrderTypeState} from './ordertype.reducer';

export interface OrderTypeState {
  orderType: ReducerOrderTypeState;
}

export const selectOrderTypeState = (state: OrderTypeState) => state.orderType;

export const selectedOrderTypeSelect = createSelector(
    selectOrderTypeState,
  (state) => state.selectedOrderType
);

export const allOrderTypesSelect = createSelector(
    selectOrderTypeState,
  (state) => state.orderTypes
);

export const allOrderTypesForSelectBoxSelect = createSelector(
    selectOrderTypeState,
  (state) => state.orderTypesForSelectBox
);

export const allOrderTypesForAllCompaniesSelect = createSelector(
    selectOrderTypeState,
  (state) => state.orderTypesAllCompanies
);
