import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, mergeMap, switchMap} from 'rxjs/operators';
import {OrderManagementApiService} from '../../../../services/order-management-api.service';
import {TaskType} from '../tasktype.model';
import {
  loadAllTaskTypesAction, loadAllTaskTypesForAllCompaniesAction,
  saveTaskTypeAction,
  taskTypeSavedAction, taskTypesForAllCompaniesLoadedAction,
  taskTypesLoadedAction
} from "./taskype.actions";

@Injectable()
export class TasktypeEffects {
  constructor(
    private httpApiService: OrderManagementApiService,
    private actions$: Actions) {}

  loadTaskTypes$ = createEffect(() => this.actions$.pipe(
    ofType(loadAllTaskTypesAction),
    mergeMap(() => this.httpApiService.getAllTaskTypes()
      .pipe(
        map(taskTypes => taskTypesLoadedAction({payload: taskTypes}))
      ))
  ));

  loadTaskTypesForAllCompanies$ = createEffect(() => this.actions$.pipe(
    ofType(loadAllTaskTypesForAllCompaniesAction),
    mergeMap(() => this.httpApiService.getAllTaskTypesWithoutCompany()
      .pipe(
        map(taskTypes => taskTypesForAllCompaniesLoadedAction({payload: taskTypes}))
      ))
  ));

  saveTaskType$ = createEffect(() => this.actions$.pipe(
    ofType(saveTaskTypeAction),
    switchMap(({payload, companyId}) => this.httpApiService.addTaskType(payload, companyId)
      .pipe(
        map(( taskType: TaskType) => {
          return taskTypeSavedAction({payload: taskType});
          }
        ))
    )));
}
