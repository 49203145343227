<!-- Start Breadcrumbs -->
<app-breadcrumbs title="menu.calendar" [breadcrumbItems]="breadCrumbItems"></app-breadcrumbs>
<!-- End Breadcrumbs -->

<div id="upspot-calender" class="user-select-none">
    <div class="form-check form-check-flat form-check-primary">
        <div>
            <label class="form-check-label">
                <input type="checkbox" class="form-check-input" name="showWeekends" #showWeekends
                       (change)="changeWeekendStatus(showWeekends.checked)">
                <em class="input-frame"></em>
                <small class="text-uppercase text-muted">{{ 'labels.showWeekends' | translate }}</small>
            </label>
        </div>
        <div>
            <label class="form-check-label">
                <input type="checkbox" class="form-check-input" name="showMinutes" #showMinutes
                       (change)="changeMinutesView(showMinutes.checked)">
                <em class="input-frame"></em>
                <small class="text-uppercase text-muted">Minuten Anzeigen?</small>
            </label>
        </div>
    </div>
    <full-calendar class="h-100"
                   [options]='calendarOptions'
                   #calendar></full-calendar>
</div>

<ng-template class="offcanvas offcanvas-end border-0" tabindex="-1" id="theme-settings-offcanvas"  #rightBar let-offcanvas>
    <div class="d-flex align-items-center bg-primary bg-gradient p-3 offcanvas-header">
        <h5 class="m-0 me-2 text-white">{{sideBarHeader}}</h5>

        <button type="button" class="btn-close btn-close-white ms-auto " data-bs-dismiss="offcanvas"
                aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
    </div>
    <div class="offcanvas-body p-0">
        <div class="h-100" [perfectScrollbar]>
            <div class="p-4">
                <ng-container *ngIf="!sidebarOrders">
                    <app-task-edit [order]="sideBarOrder" [showOpenOrder]="true" [canAddNewTasks]="true"
                                   [selectedCustomerId]="sideBarCustomerId"
                                   [selectedTask]="sideBarTask"
                                   [canChangeStatus]="true"
                                    (orderOpened)="orderOpened($event)"></app-task-edit>
                </ng-container>
                <div id="orderContainer">
                    <ng-container *ngIf="sidebarOrders">
                        <app-sidebar-order-list [orders]="sidebarOrders"
                                                (task)="planTask($event)"></app-sidebar-order-list>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</ng-template>
