<div class="card">
    <div class="card-header align-items-center d-flex border-bottom-dashed">
        <h4 class="card-title mb-0 flex-grow-1">{{'labels.images' | translate }}</h4>
        <div class="flex-shrink-0">
            <input #ImagesSelectedDialog type="file" multiple (change)="addImage($event)" hidden="true"
                   accept="image/png, image/jpeg, image/jpg">
            <button type="button" class="btn btn-soft-info btn-sm"
                    (click)="ImagesSelectedDialog.click()"><i
                    class="ri-upload-2-fill me-1 align-bottom"></i> Upload
            </button>
        </div>
    </div>

    <div class="card-body">
        <div class="vstack gap-2">
            <div class="border rounded border-dashed p-2" *ngFor="let file of imagesOfSelectedOrder; let i = index">
                <div class="d-flex align-items-center">
                    <div class="flex-shrink-0 me-3 previewImage">
                        <img [src]="file.previewFileUrl">
                    </div>
                    <div class="flex-grow-1 overflow-hidden">
                        <h5 class="fs-13 mb-1"><a
                                class="text-body text-truncate d-block">{{ file.fileName }}</a>
                        </h5>
                        <div class="small">{{file.uploadedByUserId}} {{ file.uploadTime | date: 'dd.MM.yyyy HH:mm:ss'}}</div>
                    </div>
                    <div class="flex-shrink-0 ms-2">
                        <div class="d-flex gap-1">
                            <button type="button"
                                    (click)="openImage(file)"
                                    class="btn btn-icon text-muted btn-sm fs-18">
                                <i class="ri-download-2-line"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- end card body -->
</div>

<div class="card">
    <div class="card-header align-items-center d-flex border-bottom-dashed">
        <h4 class="card-title mb-0 flex-grow-1">{{'labels.documents' | translate }}</h4>
        <div class="flex-shrink-0">
            <input #FileSelectInputDialog type="file" multiple (change)="addDocument($event)"
                   accept="application/pdf" hidden="true">
            <button type="button" (click)="FileSelectInputDialog.click()" class="btn btn-soft-info btn-sm">
                <i class="ri-upload-2-fill me-1 align-bottom"></i> Upload
            </button>
        </div>
    </div>

    <div class="card-body">
        <div class="vstack gap-2">
            <div class="border rounded border-dashed p-2" *ngFor="let file of filesOfSelectedOrder; let i = index">
                <div class="d-flex align-items-center">
                    <div class="flex-shrink-0 me-3">
                        <div class="avatar-sm">
                            <div class="avatar-title bg-light text-secondary rounded fs-24">
                                <i class="ri-file-ppt-line"></i>
                            </div>
                        </div>
                    </div>
                    <div class="flex-grow-1 overflow-hidden">
                        <h5 class="fs-13 mb-1"><a
                                class="text-body text-truncate d-block">{{ file.fileName }}</a>
                        </h5>
                        <div class="small">{{file.uploadedByUserId}} {{ file.uploadTime | date: 'dd.MM.yyyy HH:mm:ss'}}</div>
                    </div>
                    <div class="flex-shrink-0 ms-2">
                        <div class="d-flex gap-1">
                            <button type="button"
                                    class="btn btn-icon text-muted btn-sm fs-18" (click)="openFile(file)">
                                <i class="ri-download-2-line"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- end card body -->
</div>
