<div class="email-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
    <div class="email-menu-sidebar" id="menusidebar">
        <div class="p-4 d-flex flex-column h-100">
            <div class="pb-4 border-bottom border-bottom-dashed">
                <h4>{{'labels.messageCenter' | translate}}</h4>
            </div>

            <ngx-simplebar class="mx-n4 px-4 email-menu-sidebar-scroll">
                <div>
                    <h5 class="fs-12 text-uppercase text-muted mt-4">Filter</h5>

                    <div class="mail-list mt-1">
                        <ng-container *ngFor="let byType of messages?.messageSummary?.byMessageType">
                            <a href="javascript:void(0);" (click)="filterMessages(byType.type)"><span
                                    class="ri-checkbox-blank-circle-line me-2 text-info"></span><span
                                    class="mail-list-link"
                                    data-type="label">{{'notification.' + byType.type | translate}}</span></a>
                        </ng-container>
                        <div class="border-bottom border-bottom-dashed"></div>
                        <a href="javascript:void(0);" (click)="filterMessages(null)">
                            <span class="ri-checkbox-blank-circle-line me-2 text-info"></span>
                            <span class="mail-list-link" data-type="label">Alle Nachrichten</span>
                        </a>
                    </div>
                </div>

            </ngx-simplebar>
        </div>
    </div>
    <!-- end email-menu-sidebar -->

    <div class="email-content">
        <div class="p-4 pb-0">
            <div class="border-bottom border-bottom-dashed">
                <div class="row mt-n2 mb-3 mb-sm-0">
                    <div class="col col-sm-auto order-1 d-block d-lg-none">
                        <button type="button" class="btn btn-soft-success btn-icon btn-sm fs-16 email-menu-btn">
                            <i class="ri-menu-2-fill align-bottom"></i>
                        </button>
                    </div>
                    <div class="col-sm order-3 order-sm-2">
                        <div class="hstack gap-sm-1 align-items-center flex-wrap email-topbar-link">
                            <div class="form-check fs-14 m-0" (click)="checkMessages()">
                                <input class="form-check-input" type="checkbox" value="" id="checkall">
                                <label class="form-check-label" for="checkall" #check></label>
                            </div>

                            <div id="email-topbar-actions" style="display:block;">
                                <div class="hstack gap-sm-1 align-items-center flex-wrap">
                                    <button type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-16"
                                            ngbTooltip="Gelesen" placement="bottom" (click)="markAsRead()">
                                        <i class="ri-mail-open-line align-bottom"></i>
                                    </button>
                                    <button type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-16"
                                            ngbTooltip="Ungelesen" placement="bottom" (click)="markAsUnread()">
                                        <i class=" ri-mail-unread-line align-bottom"></i>
                                    </button>
                                    <button type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-16"
                                            ngbTooltip="Löschen" placement="bottom" (click)="markAsDelete()">
                                        <i class="ri-delete-bin-5-fill align-bottom"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-auto order-2 order-sm-3">
                        <div class="d-flex gap-sm-1 email-topbar-link">
                            <button type="button" class="btn btn-ghost-secondary btn-icon btn-sm fs-16"
                                    (click)="reloadMessages()">
                                <i class="ri-refresh-line align-bottom"></i>
                            </button>
                            <div>
                            <span class="me-2">Seite {{actualPage + 1 }}
                                von  {{messages?.messagePage.totalPages == 0 ? 1 : messages?.messagePage.totalPages}} </span>
                                <div class="btn-group">
                                    <button class="btn btn-outline-secondary btn-icon" [disabled]="actualPage<=0"
                                            type="button" (click)="changePageSize(-1)">
                                        <i class="ri-arrow-left-s-line text-info feather-icon-align"></i>
                                    </button>
                                    <button class="btn btn-outline-secondary btn-icon"
                                            [disabled]="messages?.messagePage.totalPages <= 1 || (actualPage+1) == messages?.messagePage.totalPages"
                                            type="button" (click)="changePageSize(1)">
                                        <i class="ri-arrow-right-s-line text-info feather-icon-align"></i>
                                    </button>

                                    <button class="btn btn-outline-secondary btn-icon"
                                            type="button" (click)="openSettings(configsModal)">
                                        <i class="ri-settings-3-line text-info feather-icon-align"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row align-items-end mt-3">
                    <div class="col">
                        <div id="mail-filter-navlist">
                            <ul class="nav nav-tabs nav-tabs-custom nav-success gap-1 text-center border-bottom-0"
                                role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link fw-semibold active" href="javascript:void(0)">
                                        <i class="ri-inbox-fill align-bottom d-inline-block"></i>
                                        <span class="ms-1 d-none d-sm-inline-block">{{'labels.messages' | translate}}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-auto">
                        <div class="text-muted mb-2">Seite {{actualPage + 1 }}
                            von  {{filteredMessages?.messagePage.totalPages == 0 ? 1 : filteredMessages?.messagePage.totalPages}}</div>
                    </div>
                </div>
            </div>

            <ngx-simplebar class="message-list-content mx-n4 px-4 message-list-scroll">
                <div>

                    <div class="row" *ngFor="let message of filteredMessages?.messagePage?.content; let i = index;"
                        [ngClass]="{ 'fw-bold': message.status === 'UNREAD' }">
                        <div class="col">
                            <input type="checkbox" class="form-check-input" id="chk-{{i}}" [checked]="allChecked"
                                   (click)="markMessage(message, $event.target)">
                            <label for="chk-{{i}}" class="toggle"></label>
                            <a class="ms-3"
                               (click)="handleMessage(message)">{{'notification.' + message.messageType | translate}}</a>
                        </div>
                        <div class="col-6">
                            <span class="">
                                <ng-container
                                        *ngIf="message.messageType === 'MESSAGE_TASK_COMMENT_CREATED'; else displayValue">
                                                                                            {{message?.message}}
                                </ng-container>
                                <ng-template #displayValue>{{message?.displayValue}}</ng-template>
                            </span>
                        </div>
                        <div class="col-3">
                            <div class="">
                                {{message?.sender?.displayValue ?? message?.sender.id }}
                                - {{message.createdDate | date: 'dd.MM.yyyy HH:mm'}}
                            </div>
                        </div>
                    </div>
                </div>
            </ngx-simplebar>
        </div>
    </div>
    <!-- end email-content -->
</div>
<!-- end email wrapper -->

<ng-template #configsModal let-modal>
    <app-configuration></app-configuration>
</ng-template>
