<div class="card" *ngIf="customer?.contactPersons?.length > 0">
    <div class="card-header align-items-center d-flex border-bottom-dashed">
        <h4 class="card-title mb-0 flex-grow-1">{{'labels.addContactPersonToOrder' | translate }}</h4>
    </div>
    <div class="card-body">
        <div class="table-card">
            <table class="table mb-0">
                <tbody>
                <ng-container *ngIf="selectable; else elseBlock">
                    <tr [ngClass]="{'text-muted': !checkBox.checked}" *ngFor="let contactPerson of customer?.contactPersons; let i = index">
                        <td class="fw-medium">{{contactPerson.firstName}} {{contactPerson.name}}</td>
                        <td>{{contactPerson.personRole}}</td>
                        <td>
                            <div class="form-check form-switch" ngbTooltip="{{'labels.shouldAddContactPersonToOrder' | translate }}" (click)="addContactPerson(i, checkBox.checked)">
                                <input class="form-check-input" type="checkbox" [checked]="existsContactPersonAtOrder(contactPerson)" role="switch" #checkBox/>
                            </div>
                        </td>
                    </tr>
                </ng-container>
                <ng-template #elseBlock>
                    <tr class="" *ngFor="let contactPerson of order?.contactPersons; let i = index"
                        (click)="addContactPerson(i, checkBox.checked)">
                        <td class="fw-medium">{{contactPerson.firstName}} {{contactPerson.name}}</td>
                        <td>{{contactPerson.personRole}}</td>
                        <td>
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" role="switch" checked #checkBox/>
                            </div>
                        </td>
                    </tr>
                </ng-template>
                </tbody>
            </table><!--end table-->
        </div>
    </div>
</div>
