<ul ngbNav #customNav="ngbNav" [activeId]="1" class="nav nav-tabs-custom border-bottom-0"
    role="tablist">
    <li [ngbNavItem]="1" class="nav-item">
        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
            {{'labels.taskType' | translate}}
        </a>
        <ng-template ngbNavContent>
            <form [formGroup]="taskTypeForm" (ngSubmit)="saveTaskType()">
                <div class="modal-body">
                    <div class="row g-3">
                        <div class="mb-3 col-12">
                            <input type="hidden" id="taskTypeId" formControlName="id" #taskTypeId>
                            <div>
                                <small class="text-uppercase text-muted">{{ 'labels.description' | translate }}*</small>
                                <input type="text" class="form-control" id="taskTypeDescription"
                                       formControlName="description"
                                       [class.invalid-Input-Formfeedback]="taskTypeForm.controls.description.errors?.required && taskTypeForm.controls.description.touched"
                                       #description>
                                <div class="invalid-Formfeedback"
                                     *ngIf="taskTypeForm.controls.description.errors?.required && taskTypeForm.controls.description.touched">
                                    <small>{{ 'labels.requiredField' | translate}}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <small class="text-uppercase text-muted">{{ 'labels.initials' | translate }}*</small>
                            <input type="text" class="form-control" id="initials" formControlName="initials"
                                   [class.invalid-Input-Formfeedback]="taskTypeForm.controls.initials.errors?.required && taskTypeForm.controls.initials.touched">
                            <div class="invalid-Formfeedback"
                                 *ngIf="taskTypeForm.controls.initials.errors?.required && taskTypeForm.controls.initials.touched">
                                <small>{{ 'labels.requiredField' | translate}}</small>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <small class="text-uppercase text-muted">{{ 'labels.color' | translate }}*</small>
                            <input
                                    [colorPicker]="taskTypeForm.get('color').value"
                                    [value]="taskTypeForm.get('color').value"
                                    (colorPickerChange)="taskTypeForm.get('color').setValue($event)"
                                    [style.background]="taskTypeForm.get('color').value"
                                    class="form-control colorPicker"
                                    formControlName="color"
                                    cpAlphaChannel="disabled"
                                    id="color"
                                    [class.invalid-Input-Formfeedback]="taskTypeForm.controls.color.errors?.required && taskTypeForm.controls.color.touched">
                            <div class="invalid-Formfeedback"
                                 *ngIf="taskTypeForm.controls.color.errors?.required && taskTypeForm.controls.color.touched">
                                <small>{{ 'labels.requiredField' | translate}}</small>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="hstack gap-2 justify-content-end">
                        <small class="text-muted form-check-label" *ngIf="!taskTypeId.value">
                            <input type="checkbox" class="form-check-input" name="createNew"
                                   (click)="resetSelectedAfterSave = !resetSelectedAfterSave">
                            <em class="input-frame"></em>
                            {{ 'labels.addMore' | translate }}
                        </small>
                        <button type="submit" class="btn btn-outline-primary me-2 mb-2 mb-md-0"
                                [disabled]="!taskTypeForm.valid"
                                id="saveOrderType">{{ 'labels.save' | translate }}</button>
                    </div>
                </div>
            </form>
        </ng-template>
    </li>
    <li [ngbNavItem]="2" class="nav-item" *ngIf="taskTypeForm.controls.id.value">
        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
            {{'labels.forms' | translate}}
        </a>
        <ng-template ngbNavContent>
            <div class="modal-body">
                <div class="row mb-3">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="card-title">{{ 'labels.forms' | translate }}</div>
                                <div class="float-end">
                                    <button type="button" class="btn btn-primary mb-3"
                                            (click)="addForm()">{{ 'labels.addForm' | translate }}</button>
                                </div>
                                <div class="clearfix"></div>
                                <hr>
                                <div *ngFor="let formTemplate of formTemplates; let formIndex = index">
                                    <div class="float-end">
                                        <button type="button" class="btn btn-primary mb-3"
                                                (click)="addFormField(formIndex)">{{ 'labels.addField' | translate }}</button>
                                    </div>
                                    <div class="clearfix"></div>
                                    <div class="row">
                                        <div class="col-12 mb-3">
                                            <small class="text-uppercase text-muted">{{ 'labels.formName' | translate }}</small>
                                            <input type="text" class="form-control" id="formName{{formIndex}}"
                                                   [(ngModel)]="formTemplate.name">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 mb-3">
                                            <small class="text-uppercase text-muted">{{ 'labels.description' | translate }}</small>
                                            <input type="text" class="form-control" id="formDescription{{formIndex}}"
                                                   [(ngModel)]="formTemplate.description">
                                        </div>
                                    </div>
                                    <div *ngFor="let formField of formTemplate.formFields; let i = index">
                                        <div class="row">
                                            <div class="mb-3 col-12 col-md-2">
                                                <small class="text-uppercase text-muted">Typ</small>
                                                <select type="text" class="form-control"
                                                        id="formFieldType{{formIndex}}-{{i}}"
                                                        [(ngModel)]="formField.formFieldType">
                                                    <option value="text">{{ 'labels.text' | translate }}</option>
                                                    <option value="textarea">{{ 'labels.multiText' | translate }}</option>
                                                    <option value="number">{{ 'labels.number' | translate }}</option>
                                                    <option value="signature">{{ 'labels.signature' | translate }}</option>
                                                </select>
                                            </div>
                                            <div class="mb-3 col-12 col-md-3">
                                                <small class="text-uppercase text-muted">{{ 'labels.description' | translate }}</small>
                                                <input type="text" class="form-control"
                                                       id="formFieldLabel{{formIndex}}-{{i}}"
                                                       [(ngModel)]="formField.description">
                                            </div>
                                            <div class="mb-3 col-12 col-md-2 form-inline">
                                                <small class="text-muted form-check-label">
                                                    <input type="checkbox" class="form-check-input"
                                                           name="formFieldRequired{{formIndex}}-{{i}}"
                                                           [(ngModel)]="formField.required">
                                                    <em class="input-frame"></em>
                                                    {{ 'labels.requiredField' | translate }}
                                                </small>
                                            </div>
                                            <div class="mb-3 col-12 col-md-2">
                                                <button type="button" class="btn btn-primary me-3 align-middle"
                                                        (click)="delete(formIndex, i)"><i
                                                        class="fa fa-trash-o"></i></button>
                                                <button type="button" class="btn btn-primary me-3"
                                                        (click)="down(formIndex, i)"
                                                        *ngIf="i > 0"><i
                                                        class="fa fa-arrow-up"></i></button>
                                                <button type="button" class="btn btn-primary" (click)="up(formIndex, i)"
                                                        *ngIf="i < formTemplate.formFields.length -1"><i
                                                        class="fa fa-arrow-down"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                </div>
                                <div class="mt-3">
                                    <button type="button" class="btn btn-primary me-2 mb-2 mb-md-0"
                                            (click)="saveForm()">{{ 'labels.saveFormFields' | translate }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </ng-template>
    </li>

    <li [ngbNavItem]="3" class="nav-item" *ngIf="taskTypeForm.controls.id.value">
        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
            {{'labels.documents' | translate}}
        </a>
        <ng-template ngbNavContent>
            <div class="modal-body">
                <div class="row-mb-3">
                    <div class="col-12 mb-3">
                        <small class="text-muted form-check-label">
                            <input type="checkbox" class="form-check-input" name="createNew" #templateCheckBox>
                            <em class="input-frame"></em>
                            {{ 'labels.markAsTemplate' | translate }}
                        </small>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-12 mb-3">
                        <input #FileSelectInputDialog type="file" multiple (change)="addDocument($event, templateCheckBox.checked)"
                               accept="application/pdf" hidden="true">
                        <button type="button" (click)="FileSelectInputDialog.click()" class="btn btn-soft-info btn-sm">
                            <i class="ri-upload-2-fill me-1 align-bottom"></i> Upload
                        </button>
                    </div>
                </div>

                <div class="vstack gap-2">
                    <div class="border rounded border-dashed p-2" *ngFor="let file of files; let i = index">
                        <div class="d-flex align-items-center">
                            <div class="flex-shrink-0 me-3">
                                <div class="avatar-sm">
                                    <div class="avatar-title bg-light text-secondary rounded fs-24">
                                        <i class="ri-file-ppt-line"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-grow-1 overflow-hidden">
                                <h5 class="fs-13 mb-1"><a
                                        class="text-body text-truncate d-block">{{ file.fileName }}</a>
                                </h5>
                                <div class="small">{{file.uploadedByUserId}} {{ file.uploadTime | date: 'dd.MM.yyyy HH:mm:ss'}}</div>
                            </div>
                            <div class="flex-shrink-0 ms-2">
                                <div class="d-flex gap-1">
                                    <button type="button"
                                            class="btn btn-icon text-muted btn-sm fs-18" (click)="openFile(file)">
                                        <i class="ri-download-2-line"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </li>
</ul>

<div class="row">
    <div class="col-lg-12">
        <div class="tab-content text-muted">
            <div [ngbNavOutlet]="customNav"></div>
        </div>
    </div>
    <!-- end col -->
</div>
