import {createAction, props} from '@ngrx/store';
import {AddressType} from '../addressType.model';

export const loadAllAddressTypesAction = createAction('[ADDRESSTYPE] loadAllAddressTypesAction');
export const addressTypesLoadedAction = createAction('[ADDRESSTYPE] addressTypesLoadedAction', props<{payload: AddressType[]}>());
export const loadAllAddressTypesForAlLCompaniesAction = createAction('[ADDRESSTYPE] loadAllAddressTypesForAlLCompaniesAction');
export const addressTypesForAllCompaniesLoadedAction = createAction('[ADDRESSTYPE] addressTypesForAllCompaniesLoadedAction', props<{payload: AddressType[]}>());
export const addressTypeSelectedAction = createAction('[ADDRESSTYPE] addressTypeSelectedAction', props<{payload: AddressType}>());
export const saveAddressTypeAction = createAction( '[ADDRESSTYPE] saveAddressTypeAction', props<{payload: AddressType, companyId: string}>());
export const addressTypeSavedAction = createAction( '[ADDRESSTYPE] addressTypeSavedAction', props<{payload: AddressType}>());
