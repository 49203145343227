import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';
import {EmployeeVacationPeriodModel} from "./employee-vacation-period.model";
import {EmployeeVacationValidFromModel} from "./employee-vacation-valid-from.model";
import {HolidayModel} from "./holiday.model";

export class HolidayResultModel {
    @JsonProperty()
    @JsonClassType({type: () => [String]})
    date: Date;

    @JsonProperty()
    @JsonClassType({type: () => [HolidayModel]})
    holiday: HolidayModel;

    @JsonProperty()
    @JsonClassType({type: () => [Number]})
    week: number;


    constructor(date: Date, holiday: HolidayModel, week: number) {
        this.date = date;
        this.holiday = holiday;
        this.week = week;
    }

    public static fromService(holiday: any): HolidayResultModel {
        const objectMapper = new ObjectMapper();
        const returnHoliday = objectMapper.parse<HolidayResultModel>(JSON.stringify({
                date: new Date(holiday.date),
                holiday: HolidayModel.fromService(holiday.holiday),
                week: holiday.week
            }), {mainCreator: () => [HolidayResultModel]}
        );
        return returnHoliday;
    }


    public static fromServiceArray(holidays: any): HolidayResultModel[] {
        const resultHolidays = [];
        if (holidays) {
            for (const holiday of holidays) {
                resultHolidays.push(HolidayResultModel.fromService(holiday));
            }
        }
        return resultHolidays;
    }
}
