import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, switchMap} from 'rxjs/operators';
import {
    addVacationAction,
    addVacationEntitlementAction,
    changeVacationEntitlementAction, holidaysLoadedAction, loadHolidaysAction,
    loadVacationAction,
    loadVacationEntitlementsAction,
    vacationAddedAction,
    vacationEntitlementAddedAction,
    vacationEntitlementChangedAction,
    vacationEntitlementsLoadedAction,
    vacationLoadedAction
} from "./employee-vacation.actions";
import {EmployeeVacationApiService} from "../../../services/employee-vacation-api.service";
import {EmployeeVacationEntitlementModel} from "../employee-vacation-entitlement.model";
import {EmployeeVacationModel} from "../employee-vacation.model";
import {HolidayResultModel} from "../holiday-result.model";
import {Page} from "../../page/page.model";

@Injectable()
export class EmployeeVacationEffects {
    constructor(
        private httpApiService: EmployeeVacationApiService,
        private actions$: Actions) {
    }

    loadEntitlements$ = createEffect(() => this.actions$.pipe(
        ofType(loadVacationEntitlementsAction),
        switchMap(({employeeId}) => this.httpApiService.getEntitlements(employeeId)
            .pipe(
                map((entitlements: EmployeeVacationEntitlementModel[]) => {
                    return vacationEntitlementsLoadedAction({entitlements: entitlements});
                }),
            )
        )));

    addEntitlement$ = createEffect(() => this.actions$.pipe(
        ofType(addVacationEntitlementAction),
        switchMap(({entitlement}) => this.httpApiService.addEntitlement(entitlement)
            .pipe(
                map((entitlement: EmployeeVacationEntitlementModel) => {
                    return vacationEntitlementAddedAction({entitlement: entitlement});
                }),
            )
        )));

    changeEntitlement$ = createEffect(() => this.actions$.pipe(
        ofType(changeVacationEntitlementAction),
        switchMap(({entitlement}) => this.httpApiService.changeEntitlement(entitlement)
            .pipe(
                map((entitlement: EmployeeVacationEntitlementModel) => {
                    return vacationEntitlementChangedAction({entitlement: entitlement});
                }),
            )
        )));

    loadVacation$ = createEffect(() => this.actions$.pipe(
        ofType(loadVacationAction),
        switchMap(({employeeIds, year}) => this.httpApiService.getVacation(employeeIds, year)
            .pipe(
                map((vacation: EmployeeVacationModel[]) => {
                    return vacationLoadedAction({vacation});
                }),
            )
        )));

    addVacation$ = createEffect(() => this.actions$.pipe(
        ofType(addVacationAction),
        switchMap(({vacation}) => this.httpApiService.addVacation(vacation)
            .pipe(
                map((vacation: EmployeeVacationModel) => {
                    return vacationAddedAction({vacation});
                }),
            )
        )));

    loadHolidays$ = createEffect(() => this.actions$.pipe(
        ofType(loadHolidaysAction),
        switchMap(({year, startMonth, endMonth, page, size}) => this.httpApiService.getHolidays(year, startMonth, endMonth, page, size)
            .pipe(
                map((holidays: Page<HolidayResultModel>) => {
                    return holidaysLoadedAction({holidays});
                }),
            )
        )));
}
