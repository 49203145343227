import {Action, createReducer, on} from '@ngrx/store';
import * as CustomerActions from './customer.actions';
import {Customer} from '../customer.model';
import {CustomerHistoryModel} from "../customerHistory.model";

export interface ReducerCustomerState {
  selectedCustomer: Customer;
  customerHistory: CustomerHistoryModel[]
}

export const initialCustomerState: ReducerCustomerState = {
  selectedCustomer: null,
  customerHistory: null
};

const userReducer = createReducer(
  initialCustomerState,
  on(CustomerActions.customerSelectedAction, (state, {payload}) => ({...state, selectedCustomer: payload})),
  on(CustomerActions.customerSavedAction, (state, {payload}) => ({...state, selectedCustomer: payload})),
  on(CustomerActions.customerHistoryLoaded, (state, {history}) => ({...state, customerHistory: history}))
);

export function reducer(state: ReducerCustomerState | undefined, action: Action): ReducerCustomerState {
  return userReducer(state, action);
}
