import {Action, createReducer, on} from '@ngrx/store';
import * as StatusMessageActions from './status-messages.actions';
import {StatusMessageModel} from '../statusMessage.model';

export interface ReducerStatusMessagesState {
    statusMessages: StatusMessageModel[]
}

export const initialStatusMessageState: ReducerStatusMessagesState = {
    statusMessages: null,
};

const statusMessagesReducer = createReducer(
    initialStatusMessageState,
    on(StatusMessageActions.statusMessagesLoadedAction, (state, {payload}) => ({
        ...state,
        statusMessages: payload
    })),
    on(StatusMessageActions.markedStatusMessageAsReadAction, (state, {documentId}) => ({
        ...state,
        statusMessages: markMessageAsRead(state.statusMessages, documentId)
    })),
);

function markMessageAsRead(messages: StatusMessageModel[], documentId: string): StatusMessageModel[] {
    const resultMessages = [...messages];

    const index = messages.findIndex(message => message.documentId === documentId);
    if (index > -1) {
        const newMessage = {...resultMessages[index]};
        newMessage.messageRead = true;
        resultMessages.splice(index, 1);
        resultMessages.push(newMessage);
    }

    return resultMessages;
}

export function reducer(state: ReducerStatusMessagesState | undefined, action: Action): ReducerStatusMessagesState {
    return statusMessagesReducer(state, action);
}
