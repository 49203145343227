<div>
    <ul ngbNav #customNav="ngbNav" [activeId]="1" class="nav nav-tabs-custom border-bottom-0"
        role="tablist">
        <li [ngbNavItem]="1" class="nav-item">
            <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                {{'labels.overview' | translate}}
            </a>
            <ng-template ngbNavContent>
                <form [formGroup]="taskForm" (ngSubmit)="saveTask()">
                    <div class="modal-body">
                        <div class="row">
                            <div class="row">
                                <div class="mb-3 col-12">
                                    <ng-template #rtTaskType let-resultTemplateTaskType="result" let-t="term">
                                        <div>{{resultTemplateTaskType.description}}</div>
                                    </ng-template>

                                    <small class="text-uppercase text-muted">{{ 'labels.taskType' | translate }}</small>
                                    <div class="input-group">
                                        <input
                                                id="taskType"
                                                type="text"
                                                class="form-control"
                                                formControlName="taskType"
                                                [ngbTypeahead]="searchTaskType"
                                                (focus)="focusTaskType$.next($any($event).target.value)"
                                                (click)="clickTaskType$.next($any($event).target.value)"
                                                [inputFormatter]="TaskTypeformatter"
                                                [resultTemplate]="rtTaskType"
                                                (selectItem)="selectTaskType($event)"
                                                #instanceTaskType="ngbTypeahead"
                                        />
                                        <span class="input-group-text"><em class="feather icon-search"></em></span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12">
                                    <input type="hidden" id="taskId" formControlName="id">
                                    <input type="hidden" id="taskIndex" formControlName="index">
                                    <div>
                                        <small class="text-uppercase text-muted">{{ 'labels.description' | translate }}</small>
                                        <textarea class="form-control" id="taskDescription"
                                                  formControlName="description"
                                                  rows="6"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12">
                                    <small class="text-uppercase text-muted">{{ 'labels.startdate' | translate }}</small>
                                    <input class="form-control flatpickr-input"
                                           type="text"
                                           autocomplete="off"
                                           placeholder="dd.MM.yyyy HH:mm"
                                           mwlFlatpickr
                                           [allowInput]="true"
                                           [convertModelValue]="true"
                                           [enableTime]="true"
                                           dateFormat="d.m.Y H:i"
                                           locale="de"
                                           formControlName="startDate"
                                           id="taskStartDate"
                                           (flatpickrClose)="startDateChanged($event)"
                                            >
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12">
                                    <small class="text-uppercase text-muted">{{ 'labels.enddate' | translate }}</small>
                                    <input class="form-control flatpickr-input"
                                           type="text"
                                           autocomplete="off"
                                           placeholder="dd.MM.yyyy HH:mm"
                                           mwlFlatpickr
                                           [allowInput]="true"
                                           [convertModelValue]="true"
                                           [enableTime]="true"
                                           dateFormat="d.m.Y H:i"
                                           locale="de"
                                           formControlName="endDate"
                                           id="taskEndDate">
                                </div>
                            </div>
                            <div class="row mb-3" *ngIf="!canAddNewTasks || canChangeStatus">
                                <div class="col-12">
                                    <small class="text-uppercase text-muted">{{ 'labels.status' | translate }}</small>
                                    <select type="text" class="form-control" formControlName="state">
                                        <option *ngFor="let state of allowedTaskStates" [value]="state">
                                            {{ state.toString() | taskStatus | translate }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12">
                                    <ng-container *ngIf="canAddNewTasks; else elseBlock  ">
                                        <small class="text-uppercase text-muted">{{ 'labels.employee' | translate }}</small>
                                        <ng-select
                                                [items]="usersForTag"
                                                [multiple]=true
                                                bindLabel="display"
                                                bindValue="value"
                                                [hideSelected]="true"
                                                formControlName="userIds">

                                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                                <span class="ng-value-label">{{item.display}}</span>
                                                <span class="ng-value-icon right" (click)="clear(item)"
                                                      aria-hidden="true">×</span>
                                            </ng-template>

                                            <ng-template ng-option-tmp let-item="item">
                                                {{item.display}}
                                            </ng-template>

                                        </ng-select>
                                    </ng-container>
                                    <ng-template #elseBlock>
                                        <input type="hidden" id="userIds" formControlName="userIds">
                                    </ng-template>
                                </div>
                            </div>


                        </div>

                    </div>
                    <div class="modal-footer">
                        <div class="hstack gap-2 justify-content-end">
                            <button *ngIf="showOpenOrder" type="button" class="btn btn-soft-success"
                                    (click)="loadOrder()">{{ 'labels.openOrder' | translate}}</button>
                            <button type="submit" class="btn btn-success"
                                    >{{ 'labels.save' | translate}}</button>
                        </div>
                    </div>
                </form>

            </ng-template>
        </li>
        <li [ngbNavItem]="2" class="nav-item">
            <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                {{'labels.documents' | translate}}
            </a>
            <ng-template ngbNavContent>
                <app-documents *ngIf="order?.id" [order]="order"
                               [customer]="customer"></app-documents>
            </ng-template>
        </li>
        <li [ngbNavItem]="3" class="nav-item">
            <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                {{'labels.taskMessages' | translate}}
            </a>
            <ng-template ngbNavContent>
                <app-chatcomponent *ngIf="selectedTask?.id" [messages]="selectedTask?.chatMessages"
                                   [order]="order" [task]="selectedTask" [header]="'labels.taskMessages' | translate"></app-chatcomponent>
            </ng-template>
        </li>
        <li [ngbNavItem]="4" class="nav-item">
            <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                {{'labels.orderMessages' | translate}}
            </a>
            <ng-template ngbNavContent>
                <app-chatcomponent [messages]="order.chatMessages"
                                   [order]="order" [header]="'labels.orderMessages' | translate"></app-chatcomponent>

            </ng-template>
        </li>

    </ul>
</div>

<div [ngbNavOutlet]="customNav"></div>
