<ng-container>
  <div class="modal-header bg-soft-info p-3">
    <h5 class="modal-title" id="exampleModalLabel">{{'labels.task' | translate}}</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal"
            (click)="activeModal.dismiss('Cross click')"></button>
  </div>
    <app-task-edit [order]="order" [showOpenOrder]="showOpenOrder" [canAddNewTasks]="canAddNewTasks"
                       [selectedCustomerId]="selectedCustomerId" [selectedTask]="selectedTask"
                       (saved)="activeModal.dismiss()"></app-task-edit>
</ng-container>
