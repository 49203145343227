import {Task} from '../../core/models/order/task.model';
import {Order} from '../../core/models/order/order.model';
import {TranslateService} from '@ngx-translate/core';
import {TaskStateEnum, TaskStateLabel} from '../../core/models/order/taskStateEnum';
import {CustomerOverview} from '../../core/models/customer/customerOverview/customerOverview.model';
import {Address} from '../../core/models/address.model';
import {DatePipe} from "@angular/common";
import {formatDate} from "@fullcalendar/core";

let eventGuid = 0;


export function createEventId(): string {
  return String(eventGuid++);
}

export function resourceLabelClassNames(arg): any {
  if (arg.resource?.extendedProps?.externCompany) {
    return ['isFromExternCompany'];
  } else if (arg.resource?.id === '-1') {
    return [''];//['planningBackground'];
  } else {
    return [''];
  }
}

export function titleFormat(datePipe: DatePipe, arg): any {
  const startKw = datePipe.transform(new Date(arg.start.year + '-' + (arg.start.month+1) + '-' + arg.start.day), 'w', )
  const endKw = datePipe.transform(new Date(arg.end.year + '-' + (arg.end.month+1) + '-' + arg.end.day), 'w')
  const startMonth = datePipe.transform(new Date(arg.start.year + '-' + (arg.start.month+1) + '-' + arg.start.day), 'MMM')
  const endMonth = datePipe.transform(new Date(arg.end.year + '-' + (arg.end.month+1) + '-' + arg.end.day), 'MMM')
  const startYear = arg.start.year;
  const endYear = arg.end.year;

  let result = '';
  if (startKw === endKw) {
    result = 'KW ' + startKw;
  } else {
    result = 'KW ' + startKw + ' - ' + endKw;
  }

  result = result + ' | ';

  if( startMonth === endMonth) {
    result = result + startMonth;
  } else {
    result = result + startMonth + ' - ' + endMonth;
  }
  result = result + ' ' + endYear;
  return result;
}

export function resourceLaneClassNames(arg): any {
  if (arg.resource?.extendedProps?.externCompany) {
    return ['isFromExternCompany'];
  } else if (arg.resource?.id === '-1') {
    return [''];//['planningBackground'];
  } else {
    return [''];
  }
}

export function resourceLabelContent(resource): any {
  return {
    html: '<div class="">' + resource.fieldValue + '<br>' +
      (resource?.resource?.extendedProps?.companyString ? resource?.resource?.extendedProps?.companyString : '') +
      '</div>'
  };
}

export function eventContent(translate: TranslateService,datePipe: DatePipe, event): any {
  if (event.event.extendedProps.task) {
    if (event.event.start && event.event.end) {
      event.timeText = formatDate(event.event.start, {locale: 'de'}) + ' - ' + formatDate(event.event.end, {locale: 'de'});
    } else if (event.event.start) {
      event.timeText = formatDate(event.event.start, {locale: 'de'})
    }
    const task: Task = event.event.extendedProps.task;
    const order: Order = event.event.extendedProps.order;
    const customer: CustomerOverview = event.event.extendedProps.customer;
    const customerAddress = customer?.mainAddress;
    const operationAddress = order.operationAddress;
    const timeDuration = (event.event.start ? datePipe.transform(event.event.start, 'HH:mm') : '') + '-' + (event.event.end ? datePipe.transform(event.event.end, 'HH:mm') : '');
    let differentOperationAddress = false;
    const firstClassBlock = 'content h-100 ' + (event.event.extendedProps.externOrder === true ? ' externOrder' : '');
    if (!Address.isSameAddress(customerAddress, operationAddress)) {
      differentOperationAddress = true;
    }

    const operationAddressBlock = ('			<div class="orderCardRight text-small">' +
        '				<div class="h12">' + (operationAddress.companyName ? operationAddress.companyName : operationAddress.name ? operationAddress.name : '') + '</div>' +
        '				<div class="h12">' + operationAddress.street + '</div>' +
        '				<div class="h12">' + operationAddress.zipcode + ' ' + operationAddress.city + '</div>' +
        '       <div class="h12 text-muted">AUSFÜHRUNGSORT</div>' +
        '			</div>');

    const customerAddressBlock = customerAddress ? ('			<div class="orderCardLeft text-small">' +
        '				<div class="h12">' + (customerAddress?.companyName ? customerAddress?.companyName : (customerAddress?.firstName ?? '') + ' ' + customerAddress?.name) + '</div>' +
        '				<div class="h12">' + customerAddress?.street + '</div>' +
        '				<div class="h12">' + customerAddress?.zipcode + ' ' + customerAddress?.city + '</div>' +
        '       <div class="h12 text-muted">AUFTRAGGEBER</div>' +
        '			</div>') : '';

    let result = {};
    if (event.view.type === 'upspotMonth') {
      result = {
        html: '' +
            '<div class="' + firstClassBlock + '">' +
            '	<div class="textcontent text-truncate h-100">' +
            '		<div class="orderCardHeader clearfix">' +
            '			<div class="orderCardLeft">' +
            '				<div class="h12">' + task.taskType?.description + '</div>' +
            '			</div>' +
            '		</div>' +
            '		<div class="orderCardFooter">' +
            '			<div class="orderCardLeft text-small">' +
            '				<div class="h12">' + (customerAddress?.companyName != null ? customerAddress?.companyName : customerAddress?.firstName + ' ' + customerAddress?.name) + '</div>' +
            '       <br>' +
            '       <div class="h12 text-muted">AUFTRAGGEBER</div>' +
            '			</div>' +
            '	</div>' +
            '</div>'
      };
    } else if (event.view.type === 'listWeek') {
      result = {
        html: '' +
            '<div class="' + firstClassBlock + '">' +
            '	<div class="textcontent h-100">' +
            '		<div class="orderCardHeader orderCardFlex">' +
            '			<div class="orderCardFlexLeft">' +
            '				<div class="h12">' + event.event.title + '</div>' +
            '			</div>' +
            '			<div class="orderCardFlexCenter">' +
            '				<div class="h12 text-muted">' + timeDuration + '</div>' +
            '			</div>' +
            '			<div class="orderCardFlexRight">' +
            '				<div class="h12 text-muted">' + translate.instant(TaskStateLabel.get(TaskStateEnum[task.state.toString()])) + '</div>' +
            '			</div>' +
            '		</div>' +
            '		<div class="orderCardBody">' +
            (task.sortOrder + 1) + ' - ' + task.description ?? '' +
            '		</div>' +
            '		<div class="orderCardFooter">' +
            customerAddressBlock + (differentOperationAddress ? operationAddressBlock : '') +
            '	</div>' +
            '	</div>' +
            '</div>'
      };
    } else {
      result = {
        html: '' +
            '<div class="' + firstClassBlock + '">' +
            '	<div class="textcontent text-truncate h-100">' +
            '		<div class="orderCardHeader orderCardFlex">' +
            '			<div class="orderCardFlexLeft">' +
            '				<div class="h12">' + (event.event.title && event.event.title !== 'undefined' ? event.event.title : '') + '</div>' +
            '			</div>' +
            '			<div class="orderCardFlexCenter">' +
            '				<div class="h12 text-muted">' + timeDuration + '</div>' +
            '			</div>' +
            '			<div class="orderCardFlexRight">' +
            '				<div class="h12 text-muted">' + translate.instant(TaskStateLabel.get(TaskStateEnum[task.state.toString()])) + '</div>' +
            '			</div>' +
            '		</div>' +
            '		<div class="orderCardBody text-center">' +
            (task.sortOrder + 1) + ' - ' + (task.description ?? '') +
            '		</div>' +
            '		<div class="orderCardFooter">' +
            customerAddressBlock + (differentOperationAddress ? operationAddressBlock : '') +
            '	</div>' +
            '</div>'
      };
    }
    return result;
  } else {
    event.timeText = formatDate(event.event.start, {locale: 'de'}) + ' - ' + formatDate(event.event.end, {locale: 'de'});
    return {  html:
          '<div>' + event.event.title + '</div>' +
          '<br>' +
          '<div>' + event.timeText + '</div>'
    }
  }
}

