import {Injectable} from '@angular/core';
import {UserManagementApiService} from '../../../services/user-management-api.service';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  loadAllUsersAction,
  saveUserAction,
  userLoggedInAction,
  userLoginAction,
  userResendMailAction, userResentMaiLAction,
  userSavedAction,
  usersLoadedAction
} from './user.actions';
import {map, mergeMap, switchMap} from 'rxjs/operators';
import {User} from '../user.model';

@Injectable()
export class UserEffects {
  constructor(
    private httpApiService: UserManagementApiService,
    private actions$: Actions) {}

  loadUsers$ = createEffect(() => this.actions$.pipe(
    ofType(loadAllUsersAction),
    mergeMap(() => this.httpApiService.getAllUsers()
      .pipe(
        map(users => (usersLoadedAction({payload: users})))
      ))
  ));

  saveUser$ = createEffect(() => this.actions$.pipe(
    ofType(saveUserAction),
    switchMap(({payload}) => this.httpApiService.saveUser(payload)
      .pipe(
        map(( user: User) => {
          return userSavedAction({payload: user});
        })
      )
  )));

  logInUser$ = createEffect(() => this.actions$.pipe(
    ofType(userLoginAction),
    switchMap(({payload}) => this.httpApiService.getUserById(payload)
      .pipe(
        map(( user: User) => {
          return userLoggedInAction({payload: user});
        })
      )
    )));

  resendEmail$ = createEffect(() => this.actions$.pipe(
    ofType(userResendMailAction),
    switchMap(({payload}) => this.httpApiService.resendEmails(payload)
      .pipe(
        map(( user: User) => {
          return userResentMaiLAction({payload: user});
        })
      )
    )));
}
