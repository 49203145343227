<div class="modal-body">
    <h5>{{ 'labels.newVacation' | translate }}</h5>
    <form [formGroup]="vacationForm" (ngSubmit)="saveVacation()">
        <div class="modal-body">
            <div class="row g-3">
                <div class="col-12">
                    <input type="hidden" id="employeeId" formControlName="employeeId">
                </div>
            </div>

            <div class="row mb-3 ">
                <div class="col-12 col-md-4">
                    <small class="text-uppercase text-muted">{{ 'labels.vacationStartDate' | translate }}*</small>
                    <input class="form-control flatpickr-input" type="text" formControlName="startDate"
                           autocomplete="off" placeholder="dd.MM.yyyy" mwlFlatpickr [allowInput]="true"
                           [convertModelValue]="true" dateFormat="d.m.Y" locale="de">
                    <div class="invalid-Formfeedback"
                         *ngIf="vacationForm?.controls.startDate.errors?.required && vacationForm?.controls.startDate.touched">
                        <small>{{ 'labels.requiredField' | translate}}</small>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <small class="text-uppercase text-muted">{{ 'labels.vacationEndDate' | translate }}*</small>
                    <input class="form-control flatpickr-input" type="text" formControlName="endDate"
                           autocomplete="off" placeholder="dd.MM.yyyy" mwlFlatpickr [allowInput]="true"
                           [convertModelValue]="true" dateFormat="d.m.Y" locale="de">
                    <div class="invalid-Formfeedback"
                         *ngIf="vacationForm?.controls.endDate.errors?.required && vacationForm?.controls.endDate.touched">
                        <small>{{ 'labels.requiredField' | translate}}</small>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="hstack gap-2 justify-content-end">
                <button type="button" class="btn btn-light"
                        [disabled]="!vacationForm.touched"
                        (click)="resetForm()">{{ 'labels.clear' | translate }}</button>
                <button type="submit" class="btn btn-success"
                        [disabled]="!vacationForm.valid">{{ 'labels.save' | translate }}</button>
            </div>
        </div>
    </form>

</div>

