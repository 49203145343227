import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';

export class Group {
  @JsonProperty() @JsonClassType({type: () => [String]})
  name: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  description: string;

  constructor(
    name: string,
    description: string
  ){
    this.name = name;
    this.description = description;
  }

  public static fromService(group: any): Group {
    let newGroup = null;
    const objectMapper = new ObjectMapper();
    if (group) {
      newGroup = objectMapper.parse<Group>( JSON.stringify( { name: group.value, description: group.description}), {mainCreator: () => [Group]});
    }
    return newGroup;
  }
}
