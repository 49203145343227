export enum TaskStateEnum {
  UNSCHEDULED,
  SCHEDULED,
  IN_PROGRESS,
  PAUSED,
  FINISHED
}

export const TaskStateLabel = new Map<number, string>([
  [TaskStateEnum.UNSCHEDULED, 'taskStatus.unplanned'],
  [TaskStateEnum.SCHEDULED, 'taskStatus.planned'],
  [TaskStateEnum.IN_PROGRESS, 'taskStatus.working'],
  [TaskStateEnum.PAUSED, 'taskStatus.paused'],
  [TaskStateEnum.FINISHED, 'taskStatus.done']
]);
