import {Component, OnDestroy, OnInit} from '@angular/core';
import {Company} from "../../../core/models/company/company.model";
import {CustomerState, selectedCustomerSelect} from "../../../core/models/customer/store";
import {allCustomersOverviewSelect, CustomerOverviewState} from "../../../core/models/customer/customerOverview/store";
import {Store} from "@ngrx/store";
import {activeCompanySelect, CompanyState} from "../../../core/models/company/store";
import {NgbModalRef} from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
import {Observable, Subscription} from "rxjs";
import {CustomerOverview} from "../../../core/models/customer/customerOverview/customerOverview.model";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {customerSelectedAction, selectCustomerAction} from "../../../core/models/customer/store/customer.actions";
import {
    findCustomerOverviewAction,
    loadCustomerOverviewAction
} from "../../../core/models/customer/customerOverview/store/customerOverview.actions";
import {Page} from "../../../core/models/page/page.model";
import {CustomerEditModalDialogComponent} from "../customer-edit-modal-dialog/customer-edit-modal-dialog.component";
import {ActivatedRoute} from "@angular/router";
import {Customer} from "../../../core/models/customer/customer.model";

@Component({
    selector: 'app-customer-list',
    templateUrl: './customer-list.component.html',
    styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent implements OnInit, OnDestroy {
    breadCrumbItems!: Array<{}>;

    searchTerm: string;

    customerModal: NgbModalRef;

    selectedCompany$: Observable<Company> = this.companyStore.select(activeCompanySelect);
    selectedCompany: Company;
    selectedCompanySubscription: Subscription;

    selectedCustomer$: Observable<Customer> = this.customerStore.select(selectedCustomerSelect);
    selectedCustomerSub: Subscription;
    selectedCustomer: Customer;

    customerOverviewPage$: Observable<Page<CustomerOverview>> = this.customerOverviewStore.select(allCustomersOverviewSelect);
    customerOverviewPageSubscription: Subscription;
    customerOverviewPage: Page<CustomerOverview>;

    customerOverviews: CustomerOverview[];
    filteredCustomerOverviews: CustomerOverview[];

    customerId;

    page = 1;

    constructor(
        private customerOverviewStore: Store<CustomerOverviewState>,
        private companyStore: Store<CompanyState>,
        private customerStore: Store<CustomerState>,
        private modalService: NgbModal,
        private route: ActivatedRoute
    ) {
        this.customerId = this.route.snapshot.paramMap.get('customerId');

        this.selectedCompanySubscription = this.selectedCompany$.subscribe(company => {
            if (company) {
                this.selectedCompany = company;
                this.customerOverviewStore.dispatch(loadCustomerOverviewAction({
                    page: this.page - 1,
                    size: 10,
                    companyId: this.selectedCompany.companyId,
                    customerId: this.customerId
                }));
            }
        });

        this.customerOverviewPageSubscription = this.customerOverviewPage$.subscribe(page => {
            if (page) {
                this.page = page.pageNumber + 1;
                this.customerOverviewPage = page;
                this.customerOverviews = page.content;
                this.filteredCustomerOverviews = this.customerOverviews;
            }
        });

        this.selectedCustomerSub = this.selectedCustomer$.subscribe(
            (customer: Customer) => {
                if (customer) {
                    this.selectedCustomer = customer;
                }
            }
        );
    }

    ngOnDestroy(): void {
        this.selectedCompanySubscription.unsubscribe();
        this.customerOverviewPageSubscription.unsubscribe();
        this.selectedCustomerSub.unsubscribe();
    }

    ngOnInit(): void {
        this.breadCrumbItems = [
            {label: 'menu.orders'},
            {label: 'menu.customerList', active: true}
        ];
    }

    onSelect(selected): void {
        this.customerStore.dispatch(selectCustomerAction({customerId: selected.customerId}));
        // this.openDialog();
    }

    updateFilter(event): void {

        let val = event.target.value.toLowerCase();
        this.searchTerm = val;
        if (val.length > 0) {
            this.customerOverviewStore.dispatch(findCustomerOverviewAction({
                page: this.page - 1,
                size: 10,
                companyId: this.selectedCompany.companyId,
                search: val
            }));
        } else {
            this.customerOverviewStore.dispatch(loadCustomerOverviewAction({
                page: this.page - 1,
                size: 10,
                companyId: this.selectedCompany.companyId,
                customerId: this.customerId
            }));
        }
    }

    newCustomer(): void {
        this.customerStore.dispatch(customerSelectedAction({payload: null}));
        this.openDialog();
    }

    openDialog(): void {
        this.customerModal = this.modalService.open(CustomerEditModalDialogComponent, {
            size: 'md',
            animation: true,
            centered: true, backdrop: 'static', keyboard: false
        });
    }

    pageChange(event: number): void {
        if (event !== this.page) {
            this.page = event;
            if (this.searchTerm && this.searchTerm != '') {
                this.customerOverviewStore.dispatch(findCustomerOverviewAction({
                    page: this.page - 1,
                    size: 10,
                    companyId: this.selectedCompany.companyId,
                    search: this.searchTerm
                }));
            } else {
                this.customerOverviewStore.dispatch(loadCustomerOverviewAction({
                    page: this.page - 1,
                    size: 10,
                    companyId: this.selectedCompany.companyId,
                    customerId: null
                }));
            }
        }
    }

}
