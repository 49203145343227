export const environment = {
  production: true,
  USER_MANAGEMENT: '${USER_MANAGEMENT}',
  ORDER_MANAGEMENT: '${ORDER_MANAGEMENT}',
  KEYCLOAK_URL: '${KEYCLOAK_URL}',
  DOCUMENT_MANAGEMENT: '${DOCUMENT_MANAGEMENT}',
  NOTIFICATION_MANAGEMENT: '${NOTIFICATION_MANAGEMENT}',
  KEYCLOAK_REALM: '${KEYCLOAK_REALM}',
  STOMP_URL: '${STOMP_URL}',
  UI_MANAGEMENT: '${UI_MANAGEMENT}',
  EMPLOYEE_MANAGEMENT: '${EMPLOYEE_MANAGEMENT}',
  VERSION: '202302192121'
};
