import {JsonClassType, JsonProperty, ObjectMapper} from "jackson-js";
import {HostIdentModel} from "./hostIdent.model";
import {HostCredentialsModel} from "./hostCredentials.model";

export class VirtualHostDataModel {

    @JsonProperty() @JsonClassType({type: () => [HostIdentModel]})
    virtualHostIdent: HostIdentModel;
    @JsonProperty() @JsonClassType({type: () => [HostCredentialsModel]})
    virtualHostCredentials: HostCredentialsModel;
    @JsonProperty() @JsonClassType({type: () => [String]})
    virtualNotificationQueueName: string;

    constructor(
        @JsonClassType({type: () => [HostIdentModel]}) virtualHostIdent: HostIdentModel,
        @JsonClassType({type: () => [HostCredentialsModel]}) virtualHostCredentials: HostCredentialsModel,
        virtualNotificationQueueName: string) {
        this.virtualHostIdent = virtualHostIdent;
        this.virtualHostCredentials = virtualHostCredentials
        this.virtualNotificationQueueName = virtualNotificationQueueName
    }


    public static fromService(virtualHostDataModel: any): VirtualHostDataModel {
        if (virtualHostDataModel) {
            const objectMapper = new ObjectMapper();
            return objectMapper.parse<VirtualHostDataModel>(JSON.stringify({
                virtualHostIdent: HostIdentModel.fromService(virtualHostDataModel['host-ident']),
                virtualHostCredentials: HostCredentialsModel.fromService(virtualHostDataModel['host-credentials']),
                virtualNotificationQueueName: virtualHostDataModel['notification-queue-name']
            }), {mainCreator: () => [VirtualHostDataModel]});
        } else {
            return null;
        }
    }

}
