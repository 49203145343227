import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {MessageConfigurations} from '../models/messageConfiguration/messageConfigurations.model';
import {Store} from '@ngrx/store';
import {activeCompanySelect, CompanyState} from '../models/company/store';
import {Company} from '../models/company/company.model';
import {MessageModel} from '../models/message/message.model';
import {ToastService} from "./Toast/toast-service";

@Injectable()
export class NotificationService {
  private REST_API_SERVER = this.notificationManagement;

  activeCompany$: Observable<Company> = this.companyStore.select(activeCompanySelect);
  activeCompany: Company;

  constructor(
    private httpClient: HttpClient,
    private toastrService: ToastService,
    private translate: TranslateService,
    private companyStore: Store<CompanyState>,
    @Inject('NOTIFICATION_MANAGEMENT') private notificationManagement: string) {
    this.activeCompany$.subscribe(company => {
      this.activeCompany = company;
    });
  }

  subscribeNotifications(sub: PushSubscription): Observable<any> {
    const body = {
      notificationEndPoint: sub.endpoint,
      publicKey: btoa(String.fromCharCode.apply(null, Array.from(new Uint8Array(sub.getKey('p256dh'))))),
      auth: btoa(String.fromCharCode.apply(null, Array.from(new Uint8Array(sub.getKey('auth')))))
    };
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    };
    return this.httpClient.post(this.REST_API_SERVER + '/notification-api/subscription/subscribe', JSON.stringify(body), options);
  }

  unsubscribeNotifications(): Observable<any> {
    const body = new URLSearchParams();
    return this.httpClient.post(this.REST_API_SERVER + '/notification-api/subscription/unsubscribe', body);
  }

  markAsRead(messageId: string, companyId: string): Observable<any> {
    const url = this.REST_API_SERVER + '/message-box/' + companyId + '/' + messageId + '/read';
    return this.httpClient.post<any>(url, null).pipe(
      catchError(error => {
        this.toastrService.error(this.translate.instant('notification.errorReadSaved'));
        return of([]);
      }),
      map((item: any) => {
        this.toastrService.success(this.translate.instant('notification.readSaved'));
        return MessageModel.fromService(item);
      })
    );
  }

  markAsUnread(messageId: string, companyId: string): Observable<any> {
    const url = this.REST_API_SERVER + '/message-box/' + companyId + '/' + messageId + '/unread';
    return this.httpClient.post<any>(url, null).pipe(
      catchError(error => {
        this.toastrService.error(this.translate.instant('notification.errorUnreadSaved'));
        return of([]);
      }),
      map((item: any) => {
        this.toastrService.success(this.translate.instant('notification.unreadSaved'));
        return MessageModel.fromService(item);
      })
    );
  }

  markAsRemove(messageId: string, companyId: string): Observable<any> {
    const url = this.REST_API_SERVER + '/message-box/' + companyId + '/' + messageId + '/remove';
    return this.httpClient.post<any>(url, null).pipe(
      catchError(error => {
        this.toastrService.error(this.translate.instant('notification.errorDeleteSaved'));
        return of([]);
      }),
      map((item: any) => {
        this.toastrService.success(this.translate.instant('notification.deleteSaved'));
        return MessageModel.fromService(item);
      })
    );
  }

  getMessages(page: number, size: number): Observable<MessageModel> {
    size = size === 0 ? 10 : size;
    const url: string =
      this.REST_API_SERVER +
      '/message-box/' + this.activeCompany.companyId +
      '?page=' + page +
      '&size=' + size +
      '&showRemoved=false';

    return this.httpClient.get<any>(url).pipe(
      catchError(error => {
        this.toastrService.error(this.translate.instant('notification.errorLoadMessages'));
        return of([]);
      }),
      map(
        (data: any) => {
          return MessageModel.fromService(data);
        }
      )
    );
  }

  getConfiguration(userId: string): Observable<MessageConfigurations> {
    if (userId) {
      const url: string =
        this.REST_API_SERVER + '/message-box/configuration';

      return this.httpClient.get<MessageConfigurations>(url).pipe(
        catchError(error => {
          this.toastrService.error(this.translate.instant('notification.errorLoadMessageConfiguration'));
          return of([]);
        }),
        map(
          (data: any) => {
            return MessageConfigurations.fromService(data);
          }
        )
      );
    } else {
      return of(null);
    }
  }

  setConfiguration(configurations: MessageConfigurations): Observable<MessageConfigurations> {
    const url: string =
      this.REST_API_SERVER + '/message-box/configuration';
    const body = MessageConfigurations.toService(configurations);

    return this.httpClient.post<MessageConfigurations>(url, body).pipe(
      catchError(error => {
        this.toastrService.error(this.translate.instant('notification.errorSaveMessageConfiguration'));
        return of([]);
      }),
      map((item: any) => {
        this.toastrService.success(this.translate.instant('notification.messageConfigurationSaved'));
        return MessageConfigurations.fromService(item);
      })
    );
  }
}
