import {ReducerBatchState} from './batch.reducer';
import {createSelector} from "@ngrx/store";

export interface BatchState {
  batch: ReducerBatchState;
}

export const selectBatchState = (state:BatchState ) => state.batch;

export const selectBatchStatus = createSelector(
    selectBatchState,
    (state) => state.batchStatus
);
