import {createAction, props} from '@ngrx/store';
import {Customer} from '../customer.model';
import {CustomerHistoryModel} from "../customerHistory.model";

export const selectCustomerAction = createAction('[CUSTOMER] selectCustomerAction', props<{customerId: string}>());
export const customerSelectedAction = createAction('[CUSTOMER] customerSelectedAction', props<{payload: Customer}>());
export const saveCustomerAction = createAction( '[CUSTOMER] saveCustomerAction', props<{payload: Customer}>());
export const customerSavedAction = createAction( '[CUSTOMER] customerSavedAction', props<{payload: Customer}>());
export const loadCustomerHistory = createAction( '[CUSTOMER] loadCustomerHistory', props<{customerId: string}>());
export const customerHistoryLoaded = createAction( '[CUSTOMER] customerHistoryLoaded', props<{history: CustomerHistoryModel[]}>());
