import {Component, OnDestroy} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Observable, Subscription} from "rxjs";
import {
    EmployeeVacationEntitlementModel
} from "../../../core/models/employee-vacation/employee-vacation-entitlement.model";
import {
    employeeVacationEntitlementsSelect,
    EmployeeVacationState
} from "../../../core/models/employee-vacation/store";
import {Store} from "@ngrx/store";
import {
    addVacationEntitlementAction, changeVacationEntitlementAction,
    loadVacationEntitlementsAction,
    vacationEntitlementsLoadedAction
} from "../../../core/models/employee-vacation/store/employee-vacation.actions";
import {User} from "../../../core/models/user/user.model";
import {selectedUserSelect, UserState} from "../../../core/models/user/store";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-vacation-entitlement-edit',
    templateUrl: './vacation-entitlements-edit.component.html',
    styleUrls: ['./vacation-entitlements-edit.component.scss']
})
export class VacationEntitlementsEditComponent implements OnDestroy {
    entitlementForm: FormGroup;

    selectedUser$: Observable<User> = this.userStore.select(selectedUserSelect);
    selectedUserSub: Subscription;
    selectedUser: User;

    employeeVacationEntitlements$: Observable<EmployeeVacationEntitlementModel[]> = this.employeeVacationStore.select(employeeVacationEntitlementsSelect);
    employeeVacationEntitlementsSub: Subscription;
    employeeVacationEntitlements: EmployeeVacationEntitlementModel[];

    monthList = [
        { Value: 1, Text: this.translate.instant('month.januar') },
        { Value: 2, Text: this.translate.instant('month.februar') },
        { Value: 3, Text: this.translate.instant('month.maerz') },
        { Value: 4, Text: this.translate.instant('month.april') },
        { Value: 5, Text: this.translate.instant('month.mai') },
        { Value: 6, Text: this.translate.instant('month.juni') },
        { Value: 7, Text: this.translate.instant('month.juli') },
        { Value: 8, Text: this.translate.instant('month.august') },
        { Value: 9, Text: this.translate.instant('month.september') },
        { Value: 10, Text: this.translate.instant('month.oktober') },
        { Value: 11, Text: this.translate.instant('month.november') },
        { Value: 12, Text: this.translate.instant('month.dezember') }
    ];
    constructor(
        private employeeVacationStore: Store<EmployeeVacationState>,
        private userStore: Store<UserState>,
        private translate: TranslateService,
    ) {
        this.entitlementForm = new FormGroup({
            employeeId: new FormControl(),
            vacationEntitlementId: new FormControl(),
            validFrom: new FormGroup({
                year: new FormControl(null, Validators.required),
                month: new FormControl(null, Validators.required)
            }),
            annualVacationDays: new FormControl(null, Validators.required)
        });

        this.employeeVacationEntitlementsSub = this.employeeVacationEntitlements$.subscribe(
            (entitlements: EmployeeVacationEntitlementModel[]) => {
                if (entitlements && entitlements.length > 0) {
                    this.employeeVacationEntitlements = entitlements;
                    this.entitlementForm.patchValue(this.employeeVacationEntitlements[this.employeeVacationEntitlements.length - 1]);
                } else if (this.selectedUser){
                    this.entitlementForm.patchValue({employeeId: this.selectedUser.userId});
                }
            });

        this.selectedUserSub = this.selectedUser$.subscribe(
            (user: User) => {
                if (user) {
                    this.selectedUser = user;
                    this.employeeVacationStore.dispatch(loadVacationEntitlementsAction({employeeId: user.userId}));
                }
            }
        );
    }

    saveEntitlement(): void {
        const entitlement: EmployeeVacationEntitlementModel = this.entitlementForm.value;

        if (entitlement.vacationEntitlementId) {
            this.employeeVacationStore.dispatch(changeVacationEntitlementAction({entitlement: entitlement}));
        } else {
            this.employeeVacationStore.dispatch(addVacationEntitlementAction({entitlement: entitlement}));
        }
    }

    resetForm(): void {

    }

    ngOnDestroy(): void {
        this.employeeVacationEntitlementsSub.unsubscribe();
        this.employeeVacationStore.dispatch(vacationEntitlementsLoadedAction({entitlements: null}));
    }

    compare(val1, val2): boolean {
        return val1 && val2 && val1 === val2;
    }

}
