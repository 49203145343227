import {AddressType} from './addresstype/addressType.model';
import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';

export class Address {

  @JsonProperty() @JsonClassType({type: () => [String]})
  id: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  firstName: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  name: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  companyName: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  city: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  zipcode: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  street: string;
  @JsonProperty() @JsonClassType({type: () => [Number]})
  lat: number;
  @JsonProperty() @JsonClassType({type: () => [Number]})
  lng: number;
  @JsonProperty() @JsonClassType({type: () => [AddressType]})
  addressType: AddressType;

  constructor(
    id: string,
    firstName: string,
    name: string,
    companyName: string,
    city: string,
    zipcode: string,
    street: string,
    lat: number,
    lng: number,
    @JsonClassType({type: () => [AddressType]}) addressType: AddressType) {
    this.id = id;
    this.firstName = firstName;
    this.name = name;
    this.companyName = companyName;
    this.city = city;
    this.zipcode = zipcode;
    this.street = street;
    this.lat = lat;
    this.lng = lng;
    this.addressType = addressType;
  }

  public static fromServiceArray(addresses: any, addressTypes: AddressType[]): Address[] {
    const customerAddresses: Address[] = [];
    if (addresses) {
      for (const address of addresses) {
        customerAddresses.push(Address.fromService(address, addressTypes));
      }
    }
    return customerAddresses;
  }

  public static fromService(address: any, addressTypes: AddressType[]): Address {
    if (address) {
      const addressType = addressTypes.find(value => value.id === address.addressTypeId);
      const objectMapper = new ObjectMapper();
      return objectMapper.parse<Address>(JSON.stringify({
        id: (address.id ? address.id : null),
        firstName: address.firstName,
        name: address.name,
        companyName: address.companyName,
        city: address.city,
        zipcode: address.zipcode,
        street: address.street,
        lat: null,
        lng: null,
        addressType
      }), {mainCreator: () => [Address]});
    } else {
      return null;
    }
  }

  public static toService(address: Address, isOperationAddress = false): any {
    return isOperationAddress ? {
      companyName: address.companyName,
      name: address.name,
      street: address.street,
      zipcode: address.zipcode,
      city: address.city
    } : {
      id: address.id,
      companyName: address.companyName,
      name: address.name,
      firstName: address.firstName,
      street: address.street,
      zipcode: address.zipcode,
      city: address.city,
      addressTypeId: address.addressType?.id
    };
  }

  public static toServiceArray(addresses: Address[], isOperationAddress = false): any {
    const customerAddressesArray = [];
    if (addresses) {
      for (const address of addresses) {
        customerAddressesArray.push(Address.toService(address, isOperationAddress));
      }
    }
    return customerAddressesArray;
  }

  public static isSameAddress(mainAddress: Address, operationAddress: Address): boolean {
    return (mainAddress && operationAddress) &&
      (mainAddress.name === operationAddress.name) &&
      (mainAddress.firstName === operationAddress.firstName) &&
      (mainAddress.companyName === operationAddress.companyName) &&
      (mainAddress.zipcode === operationAddress.zipcode) &&
      (mainAddress.city === operationAddress.city) &&
      (mainAddress.street === operationAddress.street);
  }

  public getAddressLine(): string {
    let returnString = this.companyName ?? this.firstName + ' ' + this.name + '<br>';
    returnString = returnString + ' ' + this.street + ' ' + this.zipcode + ' ' + this.city;
    return returnString;
  }
}
