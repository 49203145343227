import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';

export class BatchTimeStamps {
    @JsonProperty() @JsonClassType({type: () => [Date]})
    startDateTime: Date;
    @JsonProperty() @JsonClassType({type: () => [Date]})
    finishDateTime: Date;
    @JsonProperty() @JsonClassType({type: () => [Date]})
    lastUpdateDateTime: Date;

    constructor(
        startDateTime: Date,
        finishDateTime: Date,
        lastUpdateDateTime: Date
    ) {
        this.startDateTime = startDateTime;
        this.finishDateTime = finishDateTime;
        this.lastUpdateDateTime = lastUpdateDateTime;
    }

    public static fromService(batchTimeStamp: any): BatchTimeStamps {
        const objectMapper = new ObjectMapper();
        return objectMapper.parse<BatchTimeStamps>(JSON.stringify({
            startDateTime: batchTimeStamp.startDateTime,
            finishDateTime: batchTimeStamp.finishDateTime,
            lastUpdateDateTime: batchTimeStamp.lastUpdateDateTime
            }
        ), {mainCreator: () => [BatchTimeStamps]});
    }
}
