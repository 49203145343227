import {Component, OnInit, EventEmitter, Output, Inject} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {EventService} from '../../core/services/event.service';

//Logout
import {Router} from '@angular/router';

// Language
import {CookieService} from 'ngx-cookie-service';
import {LanguageService} from '../../core/services/language.service';
import {TranslateService} from '@ngx-translate/core';
import {Store} from "@ngrx/store";
import {OrderState} from "../../core/models/order/store";
import {UserService} from "../../core/services/user.service";
import {messagesForNavbarSelect, MessageState} from "../../core/models/message/store";
import {ClearStatesService} from "../../core/services/clearstates.service";
import {CustomerState} from "../../core/models/customer/store";
import {loadMessagesForNavbarAction, markMessageAsReadAction} from "../../core/models/message/store/messages.actions";
import {interval, Observable, of, OperatorFunction, Subscription} from "rxjs";
import {MessageModel} from "../../core/models/message/message.model";
import {
    activeCompanySelect,
    allCompaniesSelect,
    CompanyState,
    selectedCompanySelect
} from "../../core/models/company/store";
import {Company} from "../../core/models/company/company.model";
import {loggedInUserSelect, UserState} from "../../core/models/user/store";
import {User} from "../../core/models/user/user.model";
import {customerSelectedAction, selectCustomerAction} from "../../core/models/customer/store/customer.actions";
import {orderSelectedAction, selectOrderAction} from "../../core/models/order/store/order.actions";
import {activeCompanySetAction} from "../../core/models/company/store/company.actions";
import {MessageDocument} from "../../core/models/message/messageDocument.model";
import {SearchResult} from "../../core/models/searchResult.model";
import {catchError, switchMap, tap, debounceTime, distinctUntilChanged} from "rxjs/operators";
import {OrderManagementApiService} from "../../core/services/order-management-api.service";
import {statusMessagesSelect, StatusMessageState} from "../../core/models/statusMessage/store";
import {StatusMessageModel} from "../../core/models/statusMessage/statusMessage.model";
import {
    loadStatusMessagesAction,
    markStatusMessageAsReadAction
} from "../../core/models/statusMessage/store/status-messages.actions";

@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

    searchModel: any;
    searching = false;
    searchFailed = false;

    companies: Observable<Company[]> = this.companyStore.select(allCompaniesSelect);

    loggedInUser: Observable<User> = this.userStore.select(loggedInUserSelect);

    messages$: Observable<MessageModel> = this.messageStore.select(messagesForNavbarSelect);
    messages: MessageModel;

    statusMessages$: Observable<StatusMessageModel[]> = this.statusMessageStore.select(statusMessagesSelect);
    timerSubscription: Subscription;

    activeCompany: Observable<Company> = this.companyStore.select(activeCompanySelect);

    element: any;
    mode: string | undefined;
    @Output() mobileMenuButtonClicked = new EventEmitter();

    flagvalue: any;
    valueset: any;
    countryName: any;
    cookieValue: any;

    constructor(
        @Inject(DOCUMENT) private document: any,
        private eventService: EventService,
        public languageService: LanguageService,
        public _cookiesService: CookieService,
        public translate: TranslateService,
        private router: Router,
        private orderStore: Store<OrderState>,
        private customerStore: Store<CustomerState>,
        private clearStatesService: ClearStatesService,
        private messageStore: Store<MessageState>,
        public userService: UserService,
        private companyStore: Store<CompanyState>,
        private userStore: Store<UserState>,
        private statusMessageStore: Store<StatusMessageState>,
        private orderManagementApi: OrderManagementApiService) {
        this.messages$.subscribe(messageModel => {
            if (messageModel) {
                this.messages = messageModel;
            }
        });

        this.statusMessageStore.dispatch(loadStatusMessagesAction());
    }

    ngOnInit(): void {
        this.element = document.documentElement;

        // Cookies wise Language set
        this.cookieValue = this._cookiesService.get('lang');
        const val = this.listLang.filter(x => x.lang === this.cookieValue);
        this.countryName = val.map(element => element.text);
        if (val.length === 0) {
            if (this.flagvalue === undefined) {
                this.valueset = 'assets/images/flags/germany.svg';
            }
        } else {
            this.flagvalue = val.map(element => element.flag);
        }

        this.activeCompany.subscribe( company => {
            if (company) {
                this.messageStore.dispatch(loadMessagesForNavbarAction({page:0, size:6}))

                const source = interval(60000);
                this.timerSubscription = source.subscribe( () => {
                    this.messageStore.dispatch(loadMessagesForNavbarAction({page:0, size:6}))
                });
            }
        });
    }

    /**
     * Toggle the menu bar when having mobile screen
     */
    toggleMobileMenu(event: any) {
        event.preventDefault();
        this.mobileMenuButtonClicked.emit();
    }

    /**
     * Fullscreen method
     */
    fullscreen() {
        document.body.classList.toggle('fullscreen-enable');
        if (
            !document.fullscreenElement && !this.element.mozFullScreenElement &&
            !this.element.webkitFullscreenElement) {
            if (this.element.requestFullscreen) {
                this.element.requestFullscreen();
            } else if (this.element.mozRequestFullScreen) {
                /* Firefox */
                this.element.mozRequestFullScreen();
            } else if (this.element.webkitRequestFullscreen) {
                /* Chrome, Safari and Opera */
                this.element.webkitRequestFullscreen();
            } else if (this.element.msRequestFullscreen) {
                /* IE/Edge */
                this.element.msRequestFullscreen();
            }
        } else {
            if (this.document.exitFullscreen) {
                this.document.exitFullscreen();
            } else if (this.document.mozCancelFullScreen) {
                /* Firefox */
                this.document.mozCancelFullScreen();
            } else if (this.document.webkitExitFullscreen) {
                /* Chrome, Safari and Opera */
                this.document.webkitExitFullscreen();
            } else if (this.document.msExitFullscreen) {
                /* IE/Edge */
                this.document.msExitFullscreen();
            }
        }
    }

    /**
     * Topbar Light-Dark Mode Change
     */
    changeMode(mode: string) {
        this.mode = mode;
        this.eventService.broadcast('changeMode', mode);

        switch (mode) {
            case 'light':
                document.body.setAttribute('data-layout-mode', "light");
                document.body.setAttribute('data-sidebar', "light");
                break;
            case 'dark':
                document.body.setAttribute('data-layout-mode', "dark");
                document.body.setAttribute('data-sidebar', "dark");
                break;
            default:
                document.body.setAttribute('data-layout-mode', "light");
                break;
        }
    }

    /***
     * Language Listing
     */
    listLang = [
        { text: 'Deutsch', flag: 'assets/images/flags/germany.svg', lang: 'de' },
        { text: 'English', flag: 'assets/images/flags/us.svg', lang: 'en' },
    ];

    /***
     * Language Value Set
     */
    setLanguage(text: string, lang: string, flag: string) {
        this.countryName = text;
        this.flagvalue = flag;
        this.cookieValue = lang;
        this.languageService.setLanguage(lang);
    }

    /**
     * Logout the user
     */
    logout() {
        this.clearStatesService.clearStates();
        this.userService.logout();
    }

    selectCompany(company: Company): void {
        this.companyStore.dispatch(activeCompanySetAction({payload: company}));
    }

    navigateToFaq(): void {
        this.router.navigate(['/faq']);
    }


    navigateNewOrder(): void {
        this.orderStore.dispatch(orderSelectedAction({payload: null}));
        this.customerStore.dispatch(customerSelectedAction({payload: null}));
        this.router.navigate(['/order-overview']);
    }

    navigateNewCustomer(): void {
        this.customerStore.dispatch(customerSelectedAction({payload: null}));
        this.router.navigate(['/customer-list', {createNew: 1}]);
    }

    navigateNewPerson(): void {
        this.router.navigate(['/company-employee-overview', {createNew: 1}]);
    }

    loadMessage(message: MessageDocument): void {
        this.messageStore.dispatch(markMessageAsReadAction({payload: message}));
        const orderReference = message.orderReference;
        if (orderReference.customer && orderReference.order && orderReference.task ) {
            // Aufgabe
            this.customerStore.dispatch(selectCustomerAction({customerId: orderReference.customer.id}));
            this.orderStore.dispatch(selectOrderAction({orderId: orderReference.order.id, customerId: orderReference.customer.id}));
            this.router.navigate(['/order-overview']);
        } else if (orderReference.customer && orderReference.order) {
            // Auftrag
            this.customerStore.dispatch(selectCustomerAction({customerId: orderReference.customer.id}));
            this.orderStore.dispatch(selectOrderAction({orderId: orderReference.order.id, customerId: orderReference.customer.id}));
            this.router.navigate(['/order-overview']);
        } else if (orderReference.customer) {
            // Kunde
            this.customerStore.dispatch(selectCustomerAction({customerId: orderReference.customer.id}));
            this.router.navigate(['/customer-list']);
        }
    }

    navigateToMessages(): void {
        this.router.navigate(['/messages']);
    }

    markRead(messageId: string): void {
        this.statusMessageStore.dispatch(markStatusMessageAsReadAction({documentId: messageId}))
    }

    selectedGlobalSearchAction(item): void{
        if (item) {
            item.preventDefault();
            this.searchModel = null;
            const searchResult: SearchResult = item.item;
            if (searchResult.valueFoundAt === 'CUSTOMER') {
                this.customerStore.dispatch(selectCustomerAction({customerId: searchResult.customerId}));
                this.router.navigate(['/customer-list/' + searchResult.customerId]);
            } else if (searchResult.valueFoundAt === 'ORDER') {
                if (this.userService.isEmployee()) {
                    this.router.navigate(['/order-edit-mobile/' + searchResult.customerId + '/' + searchResult.valueId + '/false']);
                } else {
                    this.customerStore.dispatch(selectCustomerAction({customerId: searchResult.customerId}));
                    this.orderStore.dispatch(selectOrderAction({orderId: searchResult.valueId, customerId: searchResult.customerId}));
                    this.router.navigate(['/order-overview']);
                }
            }
        }
    }

    globalSearch: OperatorFunction<string, SearchResult[]> = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            tap(() => this.searching = true),
            switchMap(term =>
                this.orderManagementApi.globalSearch(term).pipe(
                    tap(() => this.searchFailed = false),
                    catchError(() => {
                        this.searchFailed = true;
                        return of([]);
                    }))
            ),
            tap(() => this.searching = false)
        )

    GlobalSearchformatter = (x: SearchResult) => x.description;

}
