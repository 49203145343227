import { Pipe, PipeTransform } from '@angular/core';
import {OrderStateEnum, OrderStateLabel} from '../models/order/orderstate.enum';
@Pipe({
  name: 'orderStatus'
})
export class OrderStatusPipe implements PipeTransform {
  transform(state: OrderStateEnum): string {
    if (state >= 0) {
      return OrderStateLabel.get(state);
    } else {
      return OrderStateLabel.get(OrderStateEnum.NEW);
    }
  }
}
