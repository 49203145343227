import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Order} from '../models/order/order.model';
import {Customer} from '../models/customer/customer.model';
import {catchError, concatMap, map} from 'rxjs/operators';
import {Observable, of, throwError} from 'rxjs';
import {DocumentMetaDataModel} from '../models/file/documentMetaData-model';
import {Page} from '../models/page/page.model';
import {FileInfoModel} from '../models/file/fileInfo.model';
import {UpSpotFileModel} from '../models/upSpotFile.model';
import {Store} from "@ngrx/store";
import {OrderState} from "../models/order/store";
import {imageUploadedAction} from "../models/order/store/order.actions";
import {Company} from "../models/company/company.model";
import {activeCompanySelect, CompanyState} from "../models/company/store";
import {ToastService} from "./Toast/toast-service";

@Injectable()
export class DocumentManagementApiService {
    private REST_API_SERVER = this.documentManagementUrl + '/document-management/';
    activeCompany$: Observable<Company> = this.companyStore.select(activeCompanySelect);
    activeCompany: Company;

    constructor(
        private httpClient: HttpClient,
        private toastrService: ToastService,
        private translate: TranslateService,
        private orderStore: Store<OrderState>,
        @Inject('DOCUMENT_MANAGEMENT') private documentManagementUrl: string,
        private companyStore: Store<CompanyState>,) {
        this.activeCompany$.subscribe(
            company => this.activeCompany = company
        );
    }

    addImageToOrder(order: Order, customer: Customer, file: UpSpotFileModel, markAsTemplate: boolean): Observable<DocumentMetaDataModel[]> {
        const url: string = this.REST_API_SERVER + 'customer/' + customer.id + '/' + order.id;
        const formData: FormData = new FormData();
        formData.append('file', file.file);
        return this.httpClient.post<any>(url, formData).pipe(
            catchError(error => {
                if (error && error.status === 413) {
                    this.toastrService.error(this.translate.instant('orderManagementService.error_save_file_too_large'));
                } else {
                    this.toastrService.error(this.translate.instant('orderManagementService.error_save_file'));
                }
                return of(error);
            }),
            concatMap((item: any) => {
                this.orderStore.dispatch(imageUploadedAction({file: item}));
                return this.getThumbnailImagesForOrder(order.id, order.customerId, 0, 9999).pipe(map(data => {
                    return data.content;
                }));
            }));
    }

    addFileToTaskType(file: UpSpotFileModel, markAsTemplate: boolean): Observable<DocumentMetaDataModel> {
        return this.addFileToCompany(file, markAsTemplate);
    }

    addFileToOrderType(file: UpSpotFileModel): Observable<DocumentMetaDataModel> {
        return this.addFileToCompany(file);
    }

    addFileToCompany(file: UpSpotFileModel, markAsTemplate: boolean = false): Observable<DocumentMetaDataModel> {
        let url: string = this.REST_API_SERVER + 'company/' + this.activeCompany.companyId;
        const formData: FormData = new FormData();
        formData.append('file', file.file);
        if (markAsTemplate) {
            url = url + '?markAsTemplate=true';
        }
        return this.httpClient.post<any>(url, formData).pipe(
            catchError(error => {
                if (error && error.status === 413) {
                    this.toastrService.error(this.translate.instant('orderManagementService.error_save_file_too_large'));
                } else {
                    this.toastrService.error(this.translate.instant('orderManagementService.error_save_file'));
                }
                return of(error);
            }),
            map((item) => {
                const result: DocumentMetaDataModel = item;
                return result;
            }));
    }

    private handleError(error: HttpErrorResponse) {
        if (error.status === 0) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `Backend returned code ${error.status}, body was: `, error.error);
        }
        // Return an observable with a user-facing error message.
        return throwError(() => new Error('Something bad happened; please try again later.'));
    }

    getThumbnailImagesForOrder(orderId: string, customerId: string, page: number, size: number): Observable<Page<DocumentMetaDataModel>> {
        size = size === 0 ? 10 : size;
        const url: string =
            this.REST_API_SERVER + 'customer/' + customerId +
            '/' + orderId +
            '?page=' + page +
            '&size=' + size +
            '&addThumbnail=true';

        return this.httpClient.get<any>(url).pipe(
            catchError(error => {
                this.toastrService.error(this.translate.instant('orderManagementService.error_save_file'));
                console.log(error);
                return of([]);
            }),
            map((item: any) => {
                    const documentMetaDataModelArray = [];
                    for (const metaData of item?.content) {
                        let originalFileInfo = null;
                        let previewFileInfo = null;
                        if (metaData.originalFileInfo) {
                            originalFileInfo = new FileInfoModel(
                                metaData.originalFileInfo.size,
                                metaData.originalFileInfo.relativePath,
                                metaData.originalFileInfo.mimeType,
                                metaData.originalFileInfo.content
                            );
                        }
                        if (UpSpotFileModel.isImage(metaData.fileName)) {
                            if (metaData.previewFileInfo) {
                                previewFileInfo = new FileInfoModel(
                                    metaData.previewFileInfo.size,
                                    metaData.previewFileInfo.relativePath,
                                    metaData.previewFileInfo.mimeType,
                                    metaData.previewFileInfo.content
                                );
                            }
                        } else if (UpSpotFileModel.isPDF(metaData.fileName)) {
                            documentMetaDataModelArray.push(new DocumentMetaDataModel(
                                metaData.id,
                                metaData.customerId,
                                metaData.orderId,
                                metaData.taskId,
                                metaData.uploadedByUserId,
                                new Date(metaData.uploadTime),
                                metaData.fileName,
                                originalFileInfo,
                                previewFileInfo,
                                null,
                                this.REST_API_SERVER + 'files/' + metaData.id,
                                false,
                                false,
                                true,
                                metaData.templateInfo
                            ));
                        }
                        documentMetaDataModelArray.push(new DocumentMetaDataModel(
                            metaData.id,
                            metaData.customerId,
                            metaData.orderId,
                            metaData.taskId,
                            metaData.uploadedByUserId,
                            new Date(metaData.uploadTime),
                            metaData.fileName,
                            originalFileInfo,
                            previewFileInfo,
                            'data:' + previewFileInfo?.mimeType + ';base64,' + previewFileInfo?.content,
                            this.REST_API_SERVER + 'files/' + metaData.id,
                            false,
                            true,
                            false,
                            metaData.templateInfo
                        ));

                    }
                    const resultPage = new Page<DocumentMetaDataModel>(
                        item.totalPages,
                        item.totalElements,
                        item.numberOfElements,
                        item.pageSize,
                        item.pageNumber,
                        documentMetaDataModelArray);
                    return resultPage;
                }
            ));
    }

    getFileByDocument(document: DocumentMetaDataModel): Observable<any> {
        const url: string = this.REST_API_SERVER + 'files/' + document.id;
        return this.httpClient.get(url, {responseType: 'blob'}).pipe(
            catchError(error => {
                console.log(error);
                return [];
            }),
            map((data: Blob) => {
                    return new Blob([data]);
                }
            )
        );
    }

    getFileById(id: string): Observable<any> {
        const url: string = this.REST_API_SERVER + 'files/' + id;
        return this.httpClient.get(url, {responseType: 'blob'}).pipe(
            catchError(error => {
                console.log(error);
                return [];
            }),
            map((data: Blob) => {
                    return new Blob([data]);
                }
            )
        );
    }

    getFileInfoById(documentId: string): Observable<DocumentMetaDataModel> {
        const url: string =
            this.REST_API_SERVER + documentId;

        return this.httpClient.get<any>(url).pipe(
            catchError(error => {
                this.toastrService.error(this.translate.instant('orderManagementService.error_save_file'));
                console.log(error);
                return of([]);
            }),
            map((item: any) => {
                return item;
            }));
    }

    public openFile(document: DocumentMetaDataModel): void {
        this.getFileByDocument(document).subscribe(file => {
            const downloadURL = window.URL.createObjectURL(file);
            const link = window.document.createElement('a');
            link.href = downloadURL;
            link.download = document.fileName;
            link.click();
        });
    }
}
