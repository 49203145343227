import {Action, createReducer, on} from '@ngrx/store';
import * as OrderStateOverviewActions from './orderStateOverview.actions';
import {Page} from '../../../page/page.model';
import {OrderStateOverview} from '../orderStateOverview.model';

export interface ReducerOrderStateOverviewState {
  page: Page<OrderStateOverview>;
}

export const initialOrderStateOverviewState: ReducerOrderStateOverviewState = {
  page: new Page<OrderStateOverview>(0, 0, 0, 0, 0, [])
};

const orderStateOverviewReducer = createReducer(
  initialOrderStateOverviewState,
  on(OrderStateOverviewActions.orderStateOverviewLoadedAction, (state, {payload}) => ({...state, page: payload})),
);

export function reducer(state: ReducerOrderStateOverviewState | undefined, action: Action): ReducerOrderStateOverviewState {
  return orderStateOverviewReducer(state, action);
}
