import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, mergeMap} from 'rxjs/operators';
import {
    loadStatusMessagesAction,
    markedStatusMessageAsReadAction,
    markStatusMessageAsReadAction,
    statusMessagesLoadedAction
} from "./status-messages.actions";
import {UiManagementApiService} from "../../../services/ui-management-api.service";

@Injectable()
export class StatusMessagesEffects {
  constructor(
    private httpApiService: UiManagementApiService,
    private actions$: Actions) {
  }

  loadStatusMessages$ = createEffect(() => this.actions$.pipe(
    ofType(loadStatusMessagesAction),
    mergeMap(() => this.httpApiService.loadStatusMessages()
      .pipe(
        map(messages => (statusMessagesLoadedAction({payload: messages})))
      ))
  ));

    markMessageAsRead$ = createEffect(() => this.actions$.pipe(
        ofType(markStatusMessageAsReadAction),
        mergeMap(({documentId}) => this.httpApiService.markStatusMessageAsRead(documentId)
            .pipe(
                map(() => (markedStatusMessageAsReadAction({documentId})))
            ))
    ));
}
