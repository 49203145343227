import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';
import {DatevImportOverviewStates} from './datevImportOverviewStates.model';

export class DatevImportOverview {
  @JsonProperty() @JsonClassType({type: () => [Date]})
  lastImportTimestamp: Date;
  @JsonProperty() @JsonClassType({type: () => [Array, [DatevImportOverviewStates]]})
  states: DatevImportOverviewStates[];

  constructor(
    lastImportTimestamp: Date,
    @JsonClassType({type: () => [Array, [DatevImportOverviewStates]]})states: DatevImportOverviewStates[]
  ){
    this.lastImportTimestamp = lastImportTimestamp;
    this.states = states;
  }

  public static fromService(data: any): DatevImportOverview {
    let overview: DatevImportOverview;
    if ( data) {
      const objectMapper = new ObjectMapper();
      overview = objectMapper.parse<DatevImportOverview>( JSON.stringify( {
        lastImportTimestamp: data.lastImportTimestamp,
        states: DatevImportOverviewStates.fromService(data.states)
      }), {mainCreator: () => [DatevImportOverview]});
    }
    return overview;
  }
}
