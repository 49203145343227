import {createSelector} from '@ngrx/store';
import {ReducerStatusMessagesState} from './status-messages.reducer';

export interface StatusMessageState {
  statusMessages: ReducerStatusMessagesState;
}

export const statusMessageState = (state: StatusMessageState) => state.statusMessages;

export const statusMessagesSelect = createSelector(
  statusMessageState,
  (state) => state.statusMessages
);

