import {Action, createReducer, on} from '@ngrx/store';
import * as TaskTypeActions from './taskype.actions';
import {TaskType} from '../tasktype.model';

export interface ReducerTaskTypeState {
  taskTypes: TaskType[];
  taskTypesForSelectBox: TaskType[];
  taskTypesAllCompanies: TaskType[];
  selectedTaskType: TaskType;
}

export const initialTaskTypeState: ReducerTaskTypeState = {
  taskTypes: [],
  taskTypesForSelectBox: [],
  taskTypesAllCompanies: [],
  selectedTaskType: null
};

const taskypeReducer = createReducer(
  initialTaskTypeState,
  on(TaskTypeActions.taskTypesLoadedAction, (state, {payload}) => (
    {...state, taskTypes: payload, taskTypesForSelectBox: addCreateNewEntry(payload)})),
  on(TaskTypeActions.taskTypeSavedAction, (state, {payload}) => (
    {...state,
      taskTypes: filterTaskTypes(state.taskTypes, payload),
      selectedTaskType: payload,
      taskTypesForSelectBox: addCreateNewEntry(filterTaskTypes(state.taskTypes, payload)),
      taskTypesAllCompanies: filterTaskTypes(state.taskTypesAllCompanies, payload)})),
  on(TaskTypeActions.taskTypeSelectedAction, (state, {payload}) => ({...state, selectedTaskType: payload})),
  on(TaskTypeActions.taskTypesForAllCompaniesLoadedAction, (state, {payload}) => ({...state, taskTypesAllCompanies: addCreateNewEntry(payload)}))
);

function filterTaskTypes(stateTaskTypes: TaskType[], newTaskType: TaskType): TaskType[] {
  if ( stateTaskTypes) {
    const taskTypes: TaskType[] = stateTaskTypes.filter(x => x.id !== newTaskType.id);
    taskTypes.push(newTaskType);
    return taskTypes;
  } else {
    const taskTypes: TaskType[] = [];
    taskTypes.push(newTaskType);
    return taskTypes;
  }
}

function addCreateNewEntry(stateTaskTypes: TaskType[]): TaskType[] {
  const taskTypes: TaskType[] = Object.assign([], stateTaskTypes);
  taskTypes.splice(0, 0, new TaskType('-1', '+ Neuer Aufgabentyp', null, null, null, null, 'upspot', []));
  return taskTypes;
}

export function reducer(state: ReducerTaskTypeState | undefined, action: Action): ReducerTaskTypeState {
  return taskypeReducer(state, action);
}
