import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {MessageModel} from "../../core/models/message/message.model";
import {messagesSelect, MessageState} from "../../core/models/message/store";
import {Store} from "@ngrx/store";
import {MessageDocument} from "../../core/models/message/messageDocument.model";
import {selectCustomerAction} from "../../core/models/customer/store/customer.actions";
import {selectOrderAction} from "../../core/models/order/store/order.actions";
import {OrderState} from "../../core/models/order/store";
import {CustomerState} from "../../core/models/customer/store";
import {Router} from "@angular/router";
import {
  loadMessagesAction,
  markMessageAsDeletedAction,
  markMessageAsReadAction,
  markMessageAsUnReadAction
} from "../../core/models/message/store/messages.actions";
import {loggedInUserSelect, UserState} from "../../core/models/user/store";
import {User} from "../../core/models/user/user.model";
import {userSelectedAction} from "../../core/models/user/store/user.actions";
import {Company} from "../../core/models/company/company.model";
import {activeCompanySelect, CompanyState} from "../../core/models/company/store";
import Swal from 'sweetalert2';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {
  TaskEditModalDialogComponent
} from "../orders/task-edit-modal-dialog/task-edit-modal-dialog.component";

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit, OnDestroy {

  messages$: Observable<MessageModel> = this.messageStore.select(messagesSelect);
  messagesSubscription: Subscription;
  messages: MessageModel;
  filteredMessages: MessageModel;

  activeCompany$: Observable<Company> = this.companyStore.select(activeCompanySelect);
  activeCompanySub: Subscription;

  messagesToSave: MessageDocument[];

  loggedInUser$: Observable<User> = this.userStore.select(loggedInUserSelect);
  loggedInUserSub: Subscription;
  loggedInUser: User;

  actualPage: number = 0;
  allChecked = false;

  @ViewChild('check') checked: ElementRef;

  constructor(
    private messageStore: Store<MessageState>,
    private orderStore: Store<OrderState>,
    private customerStore: Store<CustomerState>,
    private companyStore: Store<CompanyState>,
    private router: Router,
    private userStore: Store<UserState>,
    private modalService: NgbModal
  ) {
    this.reloadMessages();
    this.loggedInUserSub = this.loggedInUser$.subscribe( user => {
      this.loggedInUser = user;
      this.userStore.dispatch(userSelectedAction({payload: user}));
      this.messagesSubscription = this.messages$.subscribe(messages => {
          if (messages) {
            this.messages = messages;
            this.filteredMessages = this.messages;
          }
        }
      );
    });

    this.activeCompanySub = this.activeCompany$.subscribe( company => {
      this.reloadMessages();
    })

  }

  ngOnDestroy(): void {
    this.messagesSubscription.unsubscribe();
    this.loggedInUserSub.unsubscribe();
    this.userStore.dispatch(userSelectedAction({payload: null}));
    this.activeCompanySub.unsubscribe();
  }

  ngOnInit(): void {
    this.messagesToSave = [];
  }

  handleMessage(message: MessageDocument): void {
    const orderReference = message.orderReference;
    if (orderReference.customer && orderReference.order && orderReference.task ) {
      // Aufgabe
      this.customerStore.dispatch(selectCustomerAction({customerId: orderReference.customer.id}));
      this.orderStore.dispatch(selectOrderAction({orderId: orderReference.order.id, customerId: orderReference.customer.id}));
      this.router.navigate(['/order-overview']);
    } else if (orderReference.customer && orderReference.order) {
      // Auftrag
      this.customerStore.dispatch(selectCustomerAction({customerId: orderReference.customer.id}));
      this.orderStore.dispatch(selectOrderAction({orderId: orderReference.order.id, customerId: orderReference.customer.id}));
      this.router.navigate(['/order-overview']);
    } else if (orderReference.customer) {
      // Kunde
      this.customerStore.dispatch(selectCustomerAction({customerId: orderReference.customer.id}));
      this.router.navigate(['/customer-list']);
    }
  }

  markMessage(message: MessageDocument, target) {
    this.markMessageInList(target.checked, message);
  }

  markMessageInList(add: boolean, message: MessageDocument) {
    if(add) {
      this.messagesToSave.push(message);
    } else {
      const index = this.messagesToSave.findIndex(document => document.id === message.id);
      if (index) {
        this.messagesToSave = this.messagesToSave.splice(index, 1);
      }
    }
  }

  markAsRead(): void {
    this.messagesToSave.forEach( message => {
      this.messageStore.dispatch(markMessageAsReadAction({payload: message}));
    });
    this.messagesToSave = [];
  }

  markAsUnread(): void {
    this.messagesToSave.forEach( message => {
      this.messageStore.dispatch(markMessageAsUnReadAction({payload: message}));
    });
    this.messagesToSave = [];
  }

  markAsDelete(): void {
    this.messagesToSave.forEach( message => {
      this.messageStore.dispatch(markMessageAsDeletedAction({payload: message}));
    });
    this.reloadMessages();
    this.checkMessages();
    this.messagesToSave = [];
  }

  changePageSize(size: number) {
    this.actualPage = this.actualPage + size;
    this.messageStore.dispatch(loadMessagesAction({page: this.actualPage, size: 12}))
  }

  checkMessages(): void {
    this.allChecked = !this.allChecked;
    this.messages.messagePage.content.forEach(message => {
      this.markMessageInList(this.allChecked, message);
    });
  }

  reloadMessages(): void {
    this.messageStore.dispatch(loadMessagesAction({page:0, size:12}));
    this.actualPage = 0;
  }

  filterMessages(messageType: string) {
    if( messageType) {
      // this.messageStore.dispatch(loadMessagesByTypeAction({type: messageType}));
    } else {
      this.reloadMessages();
    }
  }

  openSettings(configsModal): void {
    this.modalService.open(configsModal, {size: 'lg', animation: true, centered: true, backdrop: 'static', keyboard: false})
  }
}
