import {Injectable} from '@angular/core';
import {CompanyState} from '../models/company/store';
import {Store} from '@ngrx/store';
import {CustomerState} from '../models/customer/store';
import {CustomerOverviewState} from '../models/customer/customerOverview/store';
import {ContactTypeState} from '../models/contacttype/store';
import {OrderTypeState} from '../models/order/ordertype/store';
import {OrderState} from '../models/order/store';
import {OrderOverviewState} from '../models/order/orderOverview/store';
import {AddressTypeState} from '../models/addresstype/store';
import {OrderStateOverviewState} from '../models/order/orderStateOverview/store';
import {activeCompanySetAction, companySelectedAction} from '../models/company/store/company.actions';
import {customerSelectedAction} from '../models/customer/store/customer.actions';
import {customersOverviewLoadedAction} from '../models/customer/customerOverview/store/customerOverview.actions';
import {contactTypeSelectedAction, loadAllContactTypesAction} from '../models/contacttype/store/contacttype.actions';
import {loadAllOrderTypesAction, orderTypeSelectedAction} from '../models/order/ordertype/store/ordertype.actions';
import {
  allOrdersByPeriodSelectedAction, allOrdersSelectedAction,
  orderSelectedAction
} from '../models/order/store/order.actions';
import {ordersOverviewLoadedAction} from '../models/order/orderOverview/store/orderOverview.actions';
import {addressTypeSelectedAction, loadAllAddressTypesAction} from '../models/addresstype/store/addresstype.actions';
import {orderStateOverviewLoadedAction} from '../models/order/orderStateOverview/store/orderStateOverview.actions';
import {Observable, of} from 'rxjs';
import {DatevSate} from "../models/datev/store";
import {allDatevMappingsLoadedAction, datevImportOverviewLoadedAction} from "../models/datev/store/datev.actions";
import {MessageState} from "../models/message/store";
import {messagesLoadedAction} from "../models/message/store/messages.actions";
import {TaskTypeState} from "../models/order/tasktype/store";
import {loadAllTaskTypesAction, taskTypeSelectedAction} from "../models/order/tasktype/store/taskype.actions";

@Injectable()
export class ClearStatesService {
  constructor(
    private companyStore: Store<CompanyState>,
    private customerStore: Store<CustomerState>,
    private customerOverviewStore: Store<CustomerOverviewState>,
    private contactTypeStore: Store<ContactTypeState>,
    private orderTypeStore: Store<OrderTypeState>,
    private orderStore: Store<OrderState>,
    private orderOverviewStore: Store<OrderOverviewState>,
    private addressTypeStore: Store<AddressTypeState>,
    private orderStateOverviewStore: Store<OrderStateOverviewState>,
    private datevStore: Store<DatevSate>,
    private messageStore: Store<MessageState>,
    private taskTypeStore: Store<TaskTypeState>
  ) {}

  clearStates(setActiveCompany = true): Observable<any> {
    this.companyStore.dispatch(companySelectedAction({payload: null}));
    if ( setActiveCompany) {
      this.companyStore.dispatch(activeCompanySetAction({payload: null}));
    }

    this.customerStore.dispatch(customerSelectedAction({payload: null}));

    this.customerOverviewStore.dispatch(customersOverviewLoadedAction({payload: null}));

    this.contactTypeStore.dispatch(loadAllContactTypesAction());
    this.contactTypeStore.dispatch(contactTypeSelectedAction({payload: null}));

    this.orderTypeStore.dispatch(loadAllOrderTypesAction());
    this.orderTypeStore.dispatch(orderTypeSelectedAction( {payload: null}));

    this.taskTypeStore.dispatch(loadAllTaskTypesAction());
    this.taskTypeStore.dispatch(taskTypeSelectedAction({payload: null}));

    this.orderStore.dispatch(orderSelectedAction({payload: null}));
    this.orderStore.dispatch(allOrdersByPeriodSelectedAction({payload: null}));
    this.orderStore.dispatch(allOrdersSelectedAction({payload: null}));

    this.orderOverviewStore.dispatch(ordersOverviewLoadedAction({payload: null}));

    this.addressTypeStore.dispatch(loadAllAddressTypesAction());
    this.addressTypeStore.dispatch(addressTypeSelectedAction( {payload: null}));

    this.orderStateOverviewStore.dispatch(orderStateOverviewLoadedAction({payload: null}));

    this.datevStore.dispatch(datevImportOverviewLoadedAction({overview: null}));
    this.datevStore.dispatch(allDatevMappingsLoadedAction({mappings: null}));

    this.messageStore.dispatch(messagesLoadedAction({payload: null}));
    return of();
  }
}
