import { Component } from '@angular/core';

@Component({
  selector: 'app-company-overview',
  templateUrl: './company-overview.component.html'
})
export class CompanyOverviewComponent{
  breadCrumbItems!: Array<{}>;

  ngOnInit() {
    /**
     * BreadCrumb
     */
    this.breadCrumbItems = [
      { label: 'menu.my_company' },
      { label: 'menu.companies', active: true }
    ];
  }
}
