import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';
import {MessageSummary} from "./messageSummary.model";
import {MessageStatus} from "./messageStatus";

export class MessageSummaryByType {
  @JsonProperty() @JsonClassType({type: () => [String]})
  type: string;
  @JsonProperty() @JsonClassType({type: () => [Array, [MessageStatus]]})
  summary: MessageStatus[];

  constructor( type: string, summary: MessageStatus[]) {
    this.type = type;
    this.summary = summary;
  }

  public static fromServiceArray(summaryArray: any): MessageSummaryByType[] {
    const returnSummaryArray: MessageSummaryByType[] = [];
    if (summaryArray) {
      for (const summary of summaryArray) {
        returnSummaryArray.push(MessageSummaryByType.fromService(summary));
      }
    }
    return returnSummaryArray;
  }

  public static fromService(messageSummary: any): MessageSummaryByType {
    const objectMapper = new ObjectMapper();
    const summary = objectMapper.parse<MessageSummaryByType>( JSON.stringify( {
      type: messageSummary.type,
      summary: MessageStatus.fromServiceArray(messageSummary.summary)}), {mainCreator: () => [MessageSummaryByType]}
    );
    return summary;
  }
}
