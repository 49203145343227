import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Task} from "../../../core/models/order/task.model";
import {merge, Observable, OperatorFunction, Subject, Subscription} from "rxjs";
import {User} from "../../../core/models/user/user.model";
import {allUsersSelect, UserState} from "../../../core/models/user/store";
import {Company} from "../../../core/models/company/company.model";
import {allCompaniesSelect, CompanyState} from "../../../core/models/company/store";
import {TaskType} from "../../../core/models/order/tasktype/tasktype.model";
import {
  allTaskTypesForSelectBoxSelect,
  selectedTaskTypeSelect,
  TaskTypeState
} from "../../../core/models/order/tasktype/store";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Store} from "@ngrx/store";
import {OrderState} from "../../../core/models/order/store";
import {DatePipe} from "@angular/common";
import {Order} from "../../../core/models/order/order.model";
import {TaskStateEnum} from "../../../core/models/order/taskStateEnum";
import {taskTypeSelectedAction} from "../../../core/models/order/tasktype/store/taskype.actions";
import {saveOrderAction} from "../../../core/models/order/store/order.actions";
import {
  TaskTypeEditModalDialogComponent
} from "../../taskType/tasktype-edit-modal-dialog/task-type-edit-modal-dialog.component";
import {debounceTime, distinctUntilChanged, map} from "rxjs/operators";
import {Router} from "@angular/router";
import {CustomerState, selectedCustomerSelect} from "../../../core/models/customer/store";
import {selectCustomerAction} from "../../../core/models/customer/store/customer.actions";
import {Customer} from "../../../core/models/customer/customer.model";

@Component({
  selector: 'app-task-edit',
  templateUrl: './task-edit.component.html',
  styleUrls: ['./task-edit.component.scss']
})
export class TaskEditComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  @Output() saved = new EventEmitter<boolean>();
  @Output() orderOpened = new EventEmitter<boolean>();
  @Input() order: Order;
  @Input() selectedCustomerId: string;
  @Input() canAddNewTasks = false;
  @Input() selectedTask: Task;
  @Input() showOpenOrder = false;
  @Input() canChangeStatus = false;

  taskForm: FormGroup;

  allowedTaskStates = [];

  users$: Observable<User[]> = this.userStore.select(allUsersSelect);
  usersSub: Subscription;

  companies$: Observable<Company[]> = this.companyStore.select(allCompaniesSelect);
  companiesSub: Subscription;
  companies: Company[];

  focusTaskType$ = new Subject<string>();
  clickTaskType$ = new Subject<string>();

  taskTypes$: Observable<TaskType[]> = this.taskTypeStore.select(allTaskTypesForSelectBoxSelect);
  taskTypesSub: Subscription;
  taskTypes;

  selectedTaskTypes$: Observable<TaskType> = this.taskTypeStore.select(selectedTaskTypeSelect);
  selectedTaskTypesSub: Subscription;
  selectedTaskTypes;

  customer$: Observable<Customer> = this.customerStore.select(selectedCustomerSelect);
  customerSub: Subscription;
  customer;

  usersForTag = [];

  constructor(
      private router: Router,
      private modalService: NgbModal,
      private orderStore: Store<OrderState>,
      private userStore: Store<UserState>,
      private companyStore: Store<CompanyState>,
      private taskTypeStore: Store<TaskTypeState>,
      private datePipe: DatePipe,
      private customerStore: Store<CustomerState>
  ) {
    this.taskTypesSub = this.taskTypes$.subscribe(types => {
      if (types) {
        this.taskTypes = types;
      }
    });

    this.customerSub = this.customer$.subscribe( customer => {
      if ( customer ) {
        this.customer = customer;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
        this.initView();
        if (this.selectedCustomerId) {
          this.customerStore.dispatch(selectCustomerAction({customerId: this.selectedCustomerId}));
        }
    }

  ngAfterViewInit(): void {
    this.selectedTaskTypesSub = this.selectedTaskTypes$.subscribe(taskType => {
      this.selectedTaskTypes = taskType;
      this.selectNewTaskType(taskType);
    });
  }

  selectNewTaskType(taskType: TaskType): void {
    if (taskType && this.taskForm) {
      const taskTypeForm: FormGroup = this.taskForm.controls.taskType as FormGroup;
      taskTypeForm.patchValue(taskType);
    }
  }

  ngOnDestroy(): void {
    this.selectedTaskTypesSub.unsubscribe();
    this.usersSub.unsubscribe();
    this.taskTypesSub.unsubscribe();
    this.taskTypeStore.dispatch(taskTypeSelectedAction({payload: null}));
    this.selectedTaskTypes = null;
  }

  ngOnInit(): void {
    this.initView();
  }

  initView(): void {
    this.usersSub = this.users$.subscribe(
        (users: User[]) => {
          this.usersForTag = [];
          for (const user of users) {
            let companyString = '';
            let first = true;
            user.companies.forEach(company => {
              const foundCompany = this.companies?.find(comp => comp.companyId === company.companyId);
              if (foundCompany) {
                if (first) {
                  companyString = companyString + foundCompany.address.companyName;
                  first = false;
                } else {
                  companyString = companyString + ', ' + foundCompany.address.companyName;
                }

              }
            });
            const name = user.firstName + ' ' + user.lastName + (companyString ? ' | ' + companyString : '');
            const userForTag = {
              display: name,
              value: user.userId
            };
            this.usersForTag.push(userForTag);
          }
          this.usersForTag.push({display: 'Vorplanung', value: '-1'});
        }
    );

    this.taskForm = new FormGroup({
      id: new FormControl(),
      taskType: new FormControl(null, Validators.required),
      index: new FormControl(),
      description: new FormControl({value: '', disabled: !this.canAddNewTasks}),
      startDate: new FormControl({value: new Date(), disabled: !this.canAddNewTasks}),
      startTime: new FormControl({value: '', disabled: !this.canAddNewTasks}),
      endDate: new FormControl({value: new Date(), disabled: !this.canAddNewTasks}),
      endTime: new FormControl({value: '', disabled: !this.canAddNewTasks}),
      state: new FormControl(),
      userIds: new FormControl({value: '', disabled: !this.canAddNewTasks})
    });

    this.initData();
  }

  initData(): void {
    if (this.selectedTask) {
      this.allowedTaskStates = [];
      this.allowedTaskStates.push(this.selectedTask.state);
      this.selectedTask.allowedStateTransitions.forEach(state => {
        if (state.toString() !== TaskStateEnum[TaskStateEnum.UNSCHEDULED].toString()) {
          this.allowedTaskStates.push(state);
        }
      });

      this.taskForm.patchValue(this.selectedTask);
      const startDateTime: Date = this.selectedTask.startDate;
      const endDateTime: Date = this.selectedTask.endDate;
      const startTime = this.datePipe.transform(startDateTime, 'HH:mm');
      const endTime = this.datePipe.transform(endDateTime, 'HH:mm');

      this.taskForm.patchValue({startTime: startTime, endTime: endTime});

      const userIds = [];
      this.selectedTask.userIds?.forEach(userId => {
        const findUserId = this.usersForTag.find(value => value.value === userId);
        if (findUserId) {
          userIds.push(findUserId);
        }
      });
      this.taskForm.patchValue({userIds: this.selectedTask.userIds});

      this.taskTypeStore.dispatch(taskTypeSelectedAction({payload: this.selectedTask?.taskType}));
    } else {
      this.taskForm.reset();
      this.taskForm.get('startDate').patchValue(new Date());
      this.taskForm.get('endDate').patchValue(new Date());
    }
  }

  saveTask(): void {
    const taskOfForm = this.taskForm?.value;
    let description;
    let startDate: Date;
    let endDate: Date;
    let userIds = [];
    let state;
    if (this.canAddNewTasks) {
      description = taskOfForm.description;
      startDate = taskOfForm.startDate;
      endDate = taskOfForm.endDate;
      userIds = taskOfForm.userIds;
      if (this.selectedTask) {
        state = this.selectedTask?.state;
        if (state === TaskStateEnum[TaskStateEnum.UNSCHEDULED].toString() && userIds?.length > 0) {
          state = TaskStateEnum[TaskStateEnum.SCHEDULED].toString();
        } else if (userIds?.length < 1) {
          state = TaskStateEnum[TaskStateEnum.UNSCHEDULED].toString();
        }
      } else {
        if (userIds?.length > 0) {
          state = TaskStateEnum[TaskStateEnum.SCHEDULED].toString();
        } else {
          state = TaskStateEnum[TaskStateEnum.UNSCHEDULED].toString();
        }
      }
    } else {
      description = this.selectedTask.description;
      startDate = this.selectedTask.startDate;
      endDate = this.selectedTask.endDate;
      userIds = this.selectedTask.userIds;
      state = taskOfForm.state;
    }

    const newTask: Task = {
      ...this.selectedTask,
      taskType: this.selectedTaskTypes,
      state,
      userIds,
      description,
      startDate,
      endDate,
    };
    const tasks: Task[] = [...this.order.tasks];
    const index = tasks.findIndex(x => x.id === newTask.id);
    if (index !== -1) {
      tasks[index] = newTask;
    } else {
      tasks.push(newTask);
    }

    const newOrder = {...this.order, tasks};
    this.orderStore.dispatch(saveOrderAction({payload: newOrder, customerId: this.selectedCustomerId}));
    this.selectedTask = null;
    this.modalService.dismissAll();
  }

  resetForm(): void {
    this.taskForm?.reset();
    this.selectedTask = null;
  }

  openModalDialog(content): void {
    this.modalService.open(content, {size: 'md', backdrop: 'static', keyboard: false});
  }

  loadOrder(): void {
    this.modalService.dismissAll();
    this.orderOpened.emit(true);
    this.router.navigate(['/order-overview/' + this.selectedCustomerId + '/' + this.order.id]);
  }

  startDateChanged(event): void {
    const taskOfForm = this.taskForm?.value;
    let startDate: Date;
    let endDate: Date;
    startDate = taskOfForm.startDate;
    endDate = taskOfForm.endDate;

    if (startDate > endDate) {
      this.taskForm.get('endDate').patchValue(startDate);
    }
    //console.log(event);
  }

  selectTaskType(item): void {
    if (item) {
      const taskType: TaskType = item.item;
      if (taskType.id === '-1') {
        this.taskTypeStore.dispatch(taskTypeSelectedAction({payload: null}));
        this.openModalDialog(TaskTypeEditModalDialogComponent);
      } else {
        this.taskTypeStore.dispatch(taskTypeSelectedAction({payload: taskType}));
      }
    }
  }

  searchTaskType: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    //const clicksWithClosedPopup$ = this.clickTaskType$.pipe(filter(() => true));
    const inputFocus$ = this.focusTaskType$;

    return merge(debouncedText$, inputFocus$).pipe(
      map(term => (term === '' ? this.taskTypes
        : this.taskTypes.filter(v => v.description?.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
    );
  }

  TaskTypeformatter = (x: TaskType) => x.description;

}
