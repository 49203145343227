import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Task} from '../../../../core/models/order/task.model';
import {Observable, Subscription} from "rxjs";
import {User} from "../../../../core/models/user/user.model";
import {allUsersSelect, UserState} from "../../../../core/models/user/store";
import {Store} from "@ngrx/store";

@Component({
  selector: 'app-task-card',
  templateUrl: './task-card.component.html',
  styleUrls: ['./task-card.component.scss']
})
export class TaskCardComponent implements OnInit {
  @Input() task: Task;
  @Input() orderId: string;
  @Input() index: number;
  @Output() clickEvent = new EventEmitter<number>();

  users$: Observable<User[]> = this.userStore.select(allUsersSelect);

  onMyClick(): void {
    this.clickEvent.emit(this.index);
  }

  constructor(
      private userStore: Store<UserState>,
  ) {
  }

  ngOnInit(): void {
  }

}
