import {JsonClassType, JsonProperty, ObjectMapper} from "jackson-js";

export class HostCredentialsModel {

    @JsonProperty() @JsonClassType({type: () => [String]})
    username: string;
    @JsonProperty() @JsonClassType({type: () => [String]})
    password: string;

    constructor(username: string, password: string) {
        this.username = username;
        this.password = password;
    }


    public static fromService(hostCredentialsModel: any): HostCredentialsModel {
        const objectMapper = new ObjectMapper();
        return objectMapper.parse<HostCredentialsModel>(JSON.stringify({
            username: hostCredentialsModel.username,
            password: hostCredentialsModel.password,
        }), {mainCreator: () => [HostCredentialsModel]});
    }
}
