import {Action, createReducer, on} from '@ngrx/store';
import * as OrderOverviewActions from './orderOverview.actions';
import {Page} from '../../../page/page.model';
import {OrderOverview} from '../orderOverview.model';

export interface ReducerOrderOverviewState {
  page: Page<OrderOverview>;
}

export const initialOrderOverviewState: ReducerOrderOverviewState = {
  page: new Page<OrderOverview>(0, 0, 0, 10, 0, [])
};

const orderOverviewReducer = createReducer(
  initialOrderOverviewState,
  on(OrderOverviewActions.ordersOverviewLoadedAction, (state, {payload}) => ({...state, page: payload})),
);

export function reducer(state: ReducerOrderOverviewState | undefined, action: Action): ReducerOrderOverviewState {
  return orderOverviewReducer(state, action);
}
