export enum OrderStateEnum {
  HEAD_ONLY,
  NEW,
  PARTIALLY_SCHEDULED,
  SCHEDULED,
  IN_PROGRESS,
  FINISHED
}

export const OrderStateLabel = new Map<number, string>([
  [OrderStateEnum.NEW, 'orderStatus.newOrder'],
  [OrderStateEnum.HEAD_ONLY, 'orderStatus.head_only'],
  [OrderStateEnum.IN_PROGRESS, 'orderStatus.working'],
  [OrderStateEnum.FINISHED, 'orderStatus.done'],
  [OrderStateEnum.PARTIALLY_SCHEDULED, 'orderStatus.partlyPlanned'],
  [OrderStateEnum.SCHEDULED, 'orderStatus.planned']
]);
