import { Component, OnInit } from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {DatevImportOverview} from "../../../core/models/datev/datevImportOverview.model";
import {datevImportDto, datevImportOverviewSelect, DatevSate} from "../../../core/models/datev/store";
import {Page} from "../../../core/models/page/page.model";
import {DatevImportDtoModel} from "../../../core/models/datev/datevImportDto.model";
import {Store} from "@ngrx/store";
import {loadImportDataAction, setImportDataAction} from "../../../core/models/datev/store/datev.actions";
import {Router} from "@angular/router";
import {NgbModalRef} from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {
  ContacttypeEditModalDialogComponent
} from "../../contacttype/contacttype-edit-modal-dialog/contacttype-edit-modal-dialog.component";
import {DatevErrorComponent} from "../datev-error/datev-error.component";

@Component({
  selector: 'app-datev-error-list',
  templateUrl: './datev-error-list.component.html',
  styleUrls: ['./datev-error-list.component.scss']
})
export class DatevErrorListComponent implements OnInit {
  breadCrumbItems!: Array<{}>;

  datevImportOverview$: Observable<DatevImportOverview> = this.datevStore.select(datevImportOverviewSelect);
  datevImportOverviewSubscription: Subscription;
  datevImportOverview: DatevImportOverview;

  datevImport$: Observable<Page<DatevImportDtoModel>> = this.datevStore.select(datevImportDto)
  datevImportSubscription: Subscription;
  datevImport: Page<DatevImportDtoModel>;

  searchTerm: string;

  importData: DatevImportDtoModel[];
  importDataFiltered: DatevImportDtoModel[];

  page = 1;

  datevError: NgbModalRef;

  constructor(
      private datevStore: Store<DatevSate>,
      private router: Router,
      private modalService: NgbModal,
      ) {
    this.datevImportOverviewSubscription = this.datevImportOverview$.subscribe(overview => {
      if (overview) {
        this.datevImportOverview = overview;
        this.datevStore.dispatch(loadImportDataAction({
          page: this.page - 1,
          size: 10
        }));
      }
    });

    this.datevImportSubscription = this.datevImport$.subscribe(page => {
      if (page && page.content && page.content.length > 0) {
        this.page = page.pageNumber + 1;
        this.datevImport = page;
        this.importData = page.content;
        this.importDataFiltered = this.importData;
      }
    });
  }

  ngOnInit(): void {
    this.breadCrumbItems = [
      {label: 'menu.datev'},
      {label: 'menu.datevError', active: true}
    ];
  }

  ngOnDestroy(): void {
    this.datevImportOverviewSubscription.unsubscribe();
    this.datevImportSubscription.unsubscribe();
  }

  updateFilter(event): void {
    let val = event.target.value.toLowerCase();
    this.searchTerm = val;
    if (val.length > 0) {
      this.importDataFiltered = this.importData.filter(_import => _import.orderRecord?.id.toLowerCase().includes(val.toLowerCase()) || _import.orderRecord?.operationAddress?.firstName.toLowerCase().includes(val.toLowerCase()) || _import.orderRecord?.operationAddress?.name.toLowerCase().includes(val.toLowerCase()));
    } else {
      this.importDataFiltered = this.importData;
    }
  }

  onSelect(selected): void {
    //this.datevStore.dispatch(setImportDataAction({importDto: selected}));
    //this.router.navigate(['datev/error']);

    this.datevError = this.openModalDialog(DatevErrorComponent);
    this.datevError.componentInstance.datevImport = selected;
    this.datevError.closed.subscribe(() => {
      this.datevStore.dispatch(loadImportDataAction({page:0, size: 10}));
    })
  }

  pageChange(event: number): void {
    if (event !== this.page && event > 0) {
      this.page = event;
      if (this.searchTerm && this.searchTerm != '') {
        this.datevStore.dispatch(loadImportDataAction({
          page: this.page - 1,
          size: 10
        }));
      } else {
        this.datevStore.dispatch(loadImportDataAction({
          page: this.page - 1,
          size: 10
        }));
      }
    }
  }

  openModalDialog(content): NgbModalRef {
    return this.modalService.open(content, {size: 'lg', animation: true, centered: true, backdrop: 'static', keyboard: false});
  }
}
