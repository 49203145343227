import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Inject, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ObjectMapper} from 'jackson-js';
import {BatchModel} from "../models/batch/batch.model";
import {ToastService} from "./Toast/toast-service";

@Injectable()
export class BatchManagementApiService{

  private REST_API_SERVER = this.orderManagementUrl;
  cache = {};

  constructor(
    private httpClient: HttpClient,
    private toastrService: ToastService,
    private translate: TranslateService,
    @Inject('ORDER_MANAGEMENT') private orderManagementUrl: string) {
  }

  public loadDatevBatch(): Observable<BatchModel> {
    const url: string = this.REST_API_SERVER + '/import/batch/DATEV_IMPORT';
    return this.httpClient.get<any>(url).pipe(
      catchError(error => {
        this.toastrService.error(this.translate.instant('datevManagementService.error_loadMappings'));
        return of([]);
      }),
      map(
        (data: BatchModel) => {
          if (data) {
            const objectMapper = new ObjectMapper();
            return objectMapper.parse<BatchModel>( JSON.stringify(data), {mainCreator: () => [BatchModel]});
          } else {
            return null;
          }
        }
      )
    );
  }

    public startDatev(): Observable<string> {
        const url: string = this.REST_API_SERVER + '/import/batch/DATEV_IMPORT/start';
        return this.httpClient.post<any>(url, null).pipe(
            catchError(error => {
                this.toastrService.error(this.translate.instant('datevManagementService.error_loadMappings'));
                return of([]);
            }),
            map(
                (data) => {
                    return data
                }
            )
        );
    }
}
