import {Address} from '../address.model';
import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';
import {VirtualHostDataModel} from "./virtualHostData.model";

export class Company {

  @JsonProperty() @JsonClassType({type: () => [String]})
  tenantId: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  companyId: string;
  @JsonProperty() @JsonClassType({type: () => [Address]})
  address: Address;
  @JsonProperty() @JsonClassType({type: () => [String]})
  emailAddress: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  phoneNumber: string;
  @JsonProperty() @JsonClassType({type: () => [Boolean]})
  isDefault: boolean;
  @JsonProperty() @JsonClassType({type: () => [VirtualHostDataModel]})
  virtualHostData: VirtualHostDataModel;

  constructor(
    tenantId: string,
    companyId: string,
    @JsonClassType({type: () => [Address]}) address: Address,
    emailAddress: string,
    phoneNumber: string,
    isDefault: boolean,
    @JsonClassType({type: () => [VirtualHostDataModel]}) virtualHostData: VirtualHostDataModel ) {
    this.tenantId = tenantId;
    this.companyId = companyId;
    this.address = address;
    this.emailAddress = emailAddress;
    this.phoneNumber = phoneNumber;
    this.isDefault = isDefault;
    this.virtualHostData = virtualHostData;
  }


  public static fromService(company: any): Company {
    const objectMapper = new ObjectMapper();
    return objectMapper.parse<Company>(JSON.stringify({
      tenantId: company.tenantId['tenant-id'],
      companyId: company.companyId['company-id'],
      address: new Address(
        null,
        null,
        null,
        company.name.lastName,
        company.address?.city,
        company.address?.zipcode,
        company.address?.street,
        null,
        null,
        null),
      emailAddress: company.emailAddress?.value,
      phoneNumber: company.phoneNumber?.value,
      isDefault: false,
      virtualHostData: VirtualHostDataModel.fromService(company.virtualHostData)
    }), {mainCreator: () => [Company]});
  }

  public static toService(company: Company): any {
    let result: any;
    if (company.companyId && company.phoneNumber) {
      result = {
        tenantId: {'tenant-id': company.tenantId},
        companyId: {'company-id': company.companyId},
        name: {lastName: company.address?.companyName},
        emailAddress: {value: company.emailAddress},
        address: {street: company.address?.street, zipcode: company.address?.zipcode, city: company.address?.city},
        phoneNumber: {value: company.phoneNumber}
      };
    } else if (!company.companyId && !company.phoneNumber) {
      result = {
        tenantId: {'tenant-id': company.tenantId},
        name: {lastName: company.address?.companyName},
        emailAddress: {value: company.emailAddress},
        address: {street: company.address?.street, zipcode: company.address?.zipcode, city: company.address?.city},
      };
    } else if (!company.companyId && company.phoneNumber) {
      result = {
        tenantId: {'tenant-id': company.tenantId},
        name: {lastName: company.address?.companyName},
        emailAddress: {value: company.emailAddress},
        address: {street: company.address?.street, zipcode: company.address?.zipcode, city: company.address?.city},
        phoneNumber: {value: company.phoneNumber}
      };
    } else if (company.companyId && !company.phoneNumber) {
      result = {
        tenantId: {'tenant-id': company.tenantId},
        companyId: {'company-id': company.companyId},
        name: {lastName: company.address?.companyName},
        emailAddress: {value: company.emailAddress},
        address: {street: company.address?.street, zipcode: company.address?.zipcode, city: company.address?.city},
      };
    }
    return result;
  }
}
