<div class="card mt-n4 mx-n4">
    <div class="bg-soft-warning">
        <div class="card-body pb-0 px-4">
            <div class="row mb-3">
                <div class="col-md">
                    <div class="row align-items-center g-3">
                        <div class="col-md-auto">
                            <div class="avatar-md">
                                <div class="avatar-title bg-white rounded-circle">
                                    <img src="assets/images/brands/slack.png" alt="" class="avatar-xs">
                                </div>
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="d-flex">
                                <div class="">
                                    <h4 class="fw-bold">{{selectedCustomer?.mainAddress | fullName}}</h4>
                                    <h5 class="text-muted">Ausführungsort</h5>
                                    <small>{{order?.operationAddress| fullName}} </small> <br>
                                    <small>{{order?.operationAddress?.street}}</small> <br>
                                    <small>{{order?.operationAddress?.zipcode}} {{order?.operationAddress?.city}}</small>
                                </div>
                                <div class="ms-auto mt-auto">
                                    <button type="button" class="btn btn-sm btn-soft-primary waves-effect waves-light"
                                    (click)="openMap()">
                                        Navigation öffnen<i class="ri-map-2-line align-middle"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ul ngbNav #nav="ngbNav" class="nav nav-tabs-custom border-bottom-0">
            <li [ngbNavItem]="1" class="nav-item">
                <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                    {{'labels.task' | translate}}
                </a>
                <ng-template ngbNavContent>
                    <div class="card">
                        <div class="card-body">
                            <form [formGroup]="taskForm">
                                <div class="row form-group">
                                    <div class="col-12">

                                        <input type="hidden" id="taskId" formControlName="id">
                                        <div>
                                            <small class="text-uppercase text-muted">{{ 'labels.description' | translate }}</small>
                                            <textarea class="form-control" id="taskDescription"
                                                      formControlName="description"
                                                      rows="3"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div class="col-12">
                                        <div>
                                            <small class="text-uppercase text-muted">{{ 'labels.taskType' | translate }}</small>
                                            <input type="text" class="form-control" id="taskTypeId"
                                                   formControlName="taskTypeId">
                                        </div>
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div class="col-12">
                                        <small class="text-uppercase text-muted">{{ 'labels.startdate' | translate }}</small>
                                        <input class="form-control" placeholder="dd.MM.yyyy"
                                               name="dp" ngbDatepicker #taskStartDate="ngbDatepicker" id="taskStartDate"
                                               formControlName="startDate"
                                               autocomplete="off" (click)="taskStartDate.toggle()">
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div class="col-12">
                                        <small class="text-uppercase text-muted">{{ 'labels.enddate' | translate }}</small>
                                        <input class="form-control" placeholder="dd.MM.yyyy"
                                               name="dp" ngbDatepicker #taskEndDate="ngbDatepicker" id="taskEndDate"
                                               formControlName="endDate"
                                               autocomplete="off" (click)="taskEndDate.toggle()">
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div class="col-12 col-md-6">
                                        <small class="text-uppercase text-muted">{{ 'labels.status' | translate }}</small>
                                        <select type="text" class="form-control" formControlName="state"
                                                (change)="saveTask()">
                                            <option *ngFor="let state of allowedTaskStates" [value]="state">
                                                {{ state.toString() | taskStatus | translate }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="pt-3 border-top border-top-dashed mt-4"
                                     *ngIf="files && files.length > 0">
                                    <h6 class="mb-3 fw-semibold text-uppercase">{{'labels.documents' | translate}}</h6>
                                    <div class="row g-3">
                                        <div class="col-xxl-4 col-lg-6" *ngFor="let file of files">
                                            <div class="border rounded border-dashed p-2">
                                                <div class="d-flex align-items-center">
                                                    <div class="flex-shrink-0 me-3">
                                                        <div class="avatar-sm">
                                                            <div class="avatar-title bg-light text-secondary rounded fs-24">
                                                                <i class="ri-folder-zip-line"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="flex-grow-1 overflow-hidden">
                                                        <h5 class="fs-13 mb-1"><a
                                                                href="javascript: void(0);"
                                                                class="text-body text-truncate d-block">{{file.fileName}}</a></h5>
                                                    </div>
                                                    <div class="flex-shrink-0 ms-2">
                                                        <div class="d-flex gap-1">
                                                            <button type="button"
                                                                    class="btn btn-icon text-muted btn-sm fs-18" (click)="openFile(file)">
                                                                <i class="ri-download-2-line"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- end row -->
                                </div>
                            </form>
                        </div>
                        <div class="card-footer">
                            <button type="button" class="btn btn-outline-primary"
                                    (click)="navigateToOrder()">{{ 'labels.openOrder' | translate}}</button>
                        </div>
                    </div>
                </ng-template>
            </li>
            <li [ngbNavItem]="2" class="nav-item" *ngIf="task?.formTemplate?.length > 0">
                <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                    {{'labels.forms' | translate}}
                </a>
                <ng-template ngbNavContent>
                    <app-formtemplates [templates]="templates" [order]="order" [task]="task"></app-formtemplates>
                </ng-template>
            </li>
            <li [ngbNavItem]="3" class="nav-item" *ngIf="selectedCustomer?.contactPersons?.length > 0">
                <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                    {{'labels.contactPersons' | translate}}
                </a>
                <ng-template ngbNavContent>
                    <app-contactpersons [order]="order" [customer]="selectedCustomer"></app-contactpersons>
                </ng-template>
            </li>
            <li [ngbNavItem]="4" class="nav-item">
                <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                    {{'labels.documents' | translate}}
                </a>
                <ng-template ngbNavContent>
                    <app-documents *ngIf="order?.id" [order]="order"
                                   [customer]="selectedCustomer"></app-documents>
                </ng-template>
            </li>
            <li [ngbNavItem]="5" class="nav-item">
                <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
                    {{'labels.messages' | translate}}
                </a>
                <ng-template ngbNavContent>
                    <app-chatcomponent [messages]="task.chatMessages"
                                       [order]="order"
                                       [task]="task"></app-chatcomponent>

                </ng-template>
            </li>
        </ul>
    </div>
</div>
<div [ngbNavOutlet]="nav" class="mt-2"></div>
