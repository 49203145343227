import {
  Component,
  Injectable, OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {Company} from '../../../core/models/company/company.model';

import {allCompaniesSelect, CompanyState} from '../../../core/models/company/store';
import {Store} from '@ngrx/store';
import {companySelectedAction} from '../../../core/models/company/store/company.actions';
import {Observable, Subscription} from 'rxjs';
import {NgbModalRef} from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CompanyEditModalDialogComponent} from "../company-edit-modal-dialog/company-edit-modal-dialog.component";

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html'
})
@Injectable()
export class CompanyListComponent implements OnInit, OnDestroy {
  loadingIndicator = true;

  companyModal: NgbModalRef;

  companies$: Observable<Company[]> = this.companyStore.select(allCompaniesSelect);
  companiesSubscription: Subscription;

  companies: Company[];
  companiesFiltered: Company[];

  searchTerm: string;

  constructor(private companyStore: Store<CompanyState>,
              private modalService: NgbModal,) {
    this.companiesSubscription = this.companies$.subscribe(companies => {
      this.companies = companies;
      this.companiesFiltered = companies;
    });
  }

  ngOnInit(): void {
  }

  onSelect(selected): void {
    const selectedCompany: Company = selected;
    this.companyStore.dispatch(companySelectedAction({payload: selectedCompany}));
    this.openDialog();
  }

  updateFilter(event): void {
    let val = event.target.value.toLowerCase();
    this.searchTerm = val;
    if (val.length > 0) {
      this.companiesFiltered = this.companies.filter(
        type => type.emailAddress?.toLowerCase().includes(val.toLowerCase()) ||
          type.phoneNumber?.toLowerCase().includes(val.toLowerCase()) ||
          type.address?.companyName?.toLowerCase().includes(val.toLowerCase()) ||
          type.address?.city?.toLowerCase().includes(val.toLowerCase()) ||
          type.address?.zipcode?.toLowerCase().includes(val.toLowerCase()) ||
          type.address?.street?.toLowerCase().includes(val.toLowerCase()));
    } else {
      this.companiesFiltered = this.companies;
    }
  }

  newCompany(): void {
    this.companyStore.dispatch(companySelectedAction({payload: null}));
    this.openDialog();
  }

  openDialog(): void {
    this.companyModal = this.modalService.open(CompanyEditModalDialogComponent, {
      size: 'md',
      animation: true,
      centered: true, backdrop: 'static', keyboard: false
    });
  }

  ngOnDestroy(): void {
  }
}
