export class Role {
  constructor(
    public id: string,
    public name: string,
    public description: string
  ){}

  public static fromService(role: any): Role {
    return new Role(
      null, role.value, role.description
    );
  }

  public static fromServiceArray(roleArray: any): Role[] {
    const roles: Role[] = [];
    if (roleArray) {
      for (const role of roleArray) {
        roles.push(Role.fromService(role));
      }
    }
    return roles;
  }

  public static toService(role: Role): any {
    return {
      value: role.name,
      description: role.description
    };
  }

  public static toServiceArray(roleArray: Role[]): any {
    const roles = [];
    if (roleArray) {
      for (const role of roleArray) {
        roles.push(Role.toService(role));
      }
    }
    return roles;
  }
}
