import {JsonClassType, JsonProperty, ObjectMapper} from 'jackson-js';

export class OrderType {
  @JsonProperty() @JsonClassType({type: () => [String]})
  id: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  description: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  initials: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  color: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  formTemplate: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  rgbaColor: string;
  @JsonProperty() @JsonClassType({type: () => [String]})
  companyId: string;
  @JsonProperty() @JsonClassType({type: () => [Array, [String]]})
  documentIds: string[];

  constructor(
    id: string,
    description: string,
    initials: string,
    color: string,
    formTemplate: string,
    rgbaColor: string,
    companyId: string,
    documentIds: string[]
  ){
    this.id = id;
    this.description = description;
    this.initials = initials;
    this.color = color;
    this.formTemplate = formTemplate;
    this.rgbaColor = rgbaColor;
    this.companyId = companyId;
    this.documentIds = documentIds;
  }


  public static fromServiceArray(orderTypeArray: any): OrderType[] {
    const orderTypes: OrderType[] = [];
    for (const orderType of orderTypeArray) {
      orderTypes.push(OrderType.fromService(orderType));
    }
    return orderTypes;
  }

  public static fromService(orderType: any): OrderType {
    const objectMapper = new ObjectMapper();
    let documentIds = [];
    if (orderType.documentIds) {
      documentIds = orderType.documentIds
    }
    return objectMapper.parse<OrderType>( JSON.stringify( {
      id: orderType.id,
      description: orderType.description,
      initials: orderType.initials,
      color: orderType.color,
      formTemplate: orderType.formTemplate,
      rgbaColor: OrderType.hexColor(orderType.color),
      companyId: orderType.companyId,
      documentIds: documentIds
  }), {mainCreator: () => [OrderType]});
  }

  public static toService(orderType: OrderType, companyId: string): any {
    return {
      id: orderType.id,
      description: orderType.description,
      initials: orderType.initials,
      color: orderType.color,
      formTemplate: orderType.formTemplate,
      companyId,
      documentIds: orderType.documentIds
    };
  }

  public static hexColor(color: string): string {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(color)) {
      c = color.substring(1).split('');
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      // tslint:disable-next-line:no-bitwise
      return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0.1)';
    }
    throw new Error('Bad Hex');
  }
}
