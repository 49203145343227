import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {NgbDropdownModule, NgbNavModule, NgbToastModule, NgbTypeaheadModule} from '@ng-bootstrap/ng-bootstrap';
import {LanguageService} from '../core/services/language.service';
import {TranslateModule} from '@ngx-translate/core';

// Component pages
import {LayoutComponent} from './layout.component';
import {VerticalComponent} from './vertical/vertical.component';
import {TopbarComponent} from './topbar/topbar.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {FooterComponent} from './footer/footer.component';
import {FormsModule} from "@angular/forms";
import {PerfectScrollbarModule} from "ngx-perfect-scrollbar";
import {PagesModule} from "../pages/pages.module";
import {ToastsContainer} from "../core/services/Toast/toasts-container.component";


@NgModule({
    declarations: [
        LayoutComponent,
        VerticalComponent,
        TopbarComponent,
        SidebarComponent,
        FooterComponent,
        ToastsContainer
    ],
    imports: [
        NgbToastModule,
        CommonModule,
        RouterModule,
        PerfectScrollbarModule,
        NgbDropdownModule,
        NgbNavModule,
        TranslateModule,
        NgbTypeaheadModule,
        FormsModule,
        PagesModule,
    ],
    providers: [LanguageService]
})
export class LayoutsModule {
}
