<ul ngbNav #customNav="ngbNav" [activeId]="1" class="nav nav-tabs-custom border-bottom-0"
    role="tablist">
    <li [ngbNavItem]="1" class="nav-item">
        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
            {{'labels.employeedata' | translate}}
        </a>
        <ng-template ngbNavContent>
            <form [formGroup]="personForm" (ngSubmit)="savePerson()">
                <div class="modal-body">
                    <div class="row g-3">
                        <div class="col-12">
                            <input type="hidden" id="userId" formControlName="userId">
                            <input type="hidden" id="tenantId" formControlName="tenantId">
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-md-6">
                            <small class="text-uppercase text-muted">{{ 'labels.firstname' | translate }}*</small>
                            <input type="text" class="form-control" id="firstName" formControlName="firstName"
                                   (ngModelChange)="nameChanged()"
                                   [class.invalid-Input-Formfeedback]="personForm.controls.firstName.errors?.required && personForm.controls.firstName.touched">
                            <div class="invalid-Formfeedback"
                                 *ngIf="personForm.controls.firstName.errors?.required && personForm.controls.firstName.touched">
                                <small>{{ 'labels.requiredField' | translate}}</small>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <small class="text-uppercase text-muted">{{ 'labels.name' | translate }}*</small>
                            <input type="text" class="form-control" id="name" formControlName="lastName"
                                   (ngModelChange)="nameChanged()"
                                   [class.invalid-Input-Formfeedback]="personForm.controls.lastName.errors?.required && personForm.controls.lastName.touched">
                            <div class="invalid-Formfeedback"
                                 *ngIf="personForm.controls.lastName.errors?.required && personForm.controls.lastName.touched">
                                <small>{{ 'labels.requiredField' | translate}}</small>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-12 col-md-6">
                            <small class="text-uppercase text-muted">{{ 'labels.email' | translate }}*</small>
                            <input type="text" class="form-control" id="email" formControlName="emailAddress"
                                   (ngModelChange)="emailChanged()"
                                   [class.invalid-Input-Formfeedback]="personForm.controls.emailAddress.errors?.required && personForm.controls.emailAddress.touched">
                            <div class="invalid-Formfeedback"
                                 *ngIf="personForm.controls['emailAddress'].errors?.eMailAddressExists">
                                <small>{{ 'labels.emailExistsError' | translate }}</small>
                            </div>
                            <div class="invalid-Formfeedback"
                                 *ngIf="personForm.controls.emailAddress.errors?.required && personForm.controls.emailAddress.touched">
                                <small>{{ 'labels.requiredField' | translate}}</small>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <small class="text-uppercase text-muted">{{ 'labels.initials' | translate }}*</small>
                            <input type="text" class="form-control" id="initials" formControlName="initials"
                                   [class.invalid-Input-Formfeedback]="personForm.controls.initials.errors?.required && personForm.controls.initials.touched">
                            <div class="invalid-Formfeedback"
                                 *ngIf="personForm.controls.initials.errors?.required && personForm.controls.initials.touched">
                                <small>{{ 'labels.requiredField' | translate}}</small>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12 col-md-6">
                            <small class="text-uppercase text-muted">{{ 'labels.color' | translate }}*</small>
                            <input
                                    [colorPicker]="personForm.get('color').value"
                                    [value]="personForm.get('color').value"
                                    (colorPickerChange)="personForm.get('color').setValue($event)"
                                    [style.background]="personForm.get('color').value"
                                    class="form-control colorPicker"
                                    formControlName="color"
                                    cpAlphaChannel="disabled"
                                    id="color"
                                    [class.invalid-Input-Formfeedback]="personForm.controls.color.errors?.required && personForm.controls.color.touched">
                            <div class="invalid-Formfeedback"
                                 *ngIf="personForm.controls.color.errors?.required && personForm.controls.color.touched">
                                <small>{{ 'labels.requiredField' | translate}}</small>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <small class="text-uppercase text-muted">{{ 'labels.roles' | translate }}</small>
                            <select type="text" class="form-control" id="group" formControlName="group" [compareWith]="compare">
                                <option *ngFor="let group of groups" [ngValue]="group">
                                    {{ group.description }}
                                </option>
                            </select>
                        </div>
                        <div class="col-12 col-md-4" *ngIf="selectedUser?.userId">
                            <small class="text-uppercase text-muted">{{ 'labels.activationMail' | translate }}</small><br>
                            <button type="button" (click)="resendEmails()"
                                    class="btn btn-outline-dark">{{ 'labels.resend' | translate }}</button>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-12">
                            <small class="text-uppercase text-muted">{{ 'labels.companies' | translate }}</small><br>
                            <ng-select
                                    [items]="companiesForTag"
                                    [multiple]=true
                                    bindLabel="display"
                                    bindValue="value"
                                    [hideSelected]="true"
                                    formControlName="companies">

                                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                    <span class="ng-value-label">{{item.display}}</span>
                                    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                                </ng-template>

                                <ng-template ng-option-tmp let-item="item">
                                    {{item.display}}
                                </ng-template>

                            </ng-select>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="hstack gap-2 justify-content-end">
                        <button type="button" class="btn btn-light"
                                [disabled]="!personForm.touched"
                                (click)="resetForm()">{{ 'labels.clear' | translate }}</button>
                        <button type="submit" class="btn btn-success"
                                [disabled]="!personForm.valid">{{ 'labels.save' | translate }}</button>
                    </div>
                </div>
            </form>
        </ng-template>
    </li>
    <li [ngbNavItem]="2" class="nav-item">
        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
            {{'labels.tasks' | translate}}
        </a>
        <ng-template ngbNavContent>
            <app-task-list-person></app-task-list-person>
        </ng-template>
    </li>
    <li [ngbNavItem]="3" class="nav-item">
        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
            {{ 'labels.messageSettings'| translate}}
        </a>
        <ng-template ngbNavContent>
            <app-configuration></app-configuration>

        </ng-template>
    </li>
    <li [ngbNavItem]="4" class="nav-item">
        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
            {{ 'labels.vacationEntitlement'| translate}}
        </a>
        <ng-template ngbNavContent>
            <app-vacation-entitlement-edit></app-vacation-entitlement-edit>
        </ng-template>
    </li>
    <li [ngbNavItem]="5" class="nav-item">
        <a ngbNavLink class="nav-link" data-bs-toggle="tab" role="tab">
            {{ 'labels.vacation'| translate}}
        </a>
        <ng-template ngbNavContent>
            <app-vacation-list></app-vacation-list>
            <app-vacation-edit [employeeId]="selectedUser.userId"></app-vacation-edit>
        </ng-template>
    </li>
</ul>

<div class="row">
    <div class="col-lg-12">
        <div class="tab-content text-muted">
            <div [ngbNavOutlet]="customNav"></div>
        </div>
    </div>
    <!-- end col -->
</div>
