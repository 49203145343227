<form [formGroup]="entitlementForm" (ngSubmit)="saveEntitlement()">
    <div class="modal-body">
        <div class="row g-3">
            <div class="col-12">
                <input type="hidden" id="employeeId" formControlName="employeeId">
                <input type="hidden" id="vacationEntitlementId" formControlName="vacationEntitlementId">
            </div>
        </div>

        <div class="row mb-3 ">
            <ng-container formGroupName="validFrom">
                <div class="col-12 col-md-4">
                    <small class="text-uppercase text-muted">{{ 'labels.vacationEntitlementYear' | translate }}*</small>
                    <input type="text" class="form-control" id="year" formControlName="year">
                    <div class="invalid-Formfeedback"
                         *ngIf="entitlementForm?.get('validFrom')['controls'].year.errors?.required && entitlementForm?.get('validFrom')['controls'].year.touched">
                        <small>{{ 'labels.requiredField' | translate}}</small>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <small class="text-uppercase text-muted">{{ 'labels.vacationEntitlementMonth' | translate }}
                        *</small>
                    <select type="text" class="form-control" id="month" formControlName="month" [compareWith]="compare">
                        <option *ngFor="let month of monthList" [ngValue]="month.Value">
                            {{ month.Text }}
                        </option>
                    </select>
                    <div class="invalid-Formfeedback"
                         *ngIf="entitlementForm?.get('validFrom')['controls'].month.errors?.required && entitlementForm?.get('validFrom')['controls'].month.touched">
                        <small>{{ 'labels.requiredField' | translate}}</small>
                    </div>
                </div>
            </ng-container>
            <div class="col-12 col-md-4">
                <small class="text-uppercase text-muted">{{ 'labels.vacationEntitlement' | translate }}*</small>
                <input type="text" class="form-control" id="annualVacationDays" formControlName="annualVacationDays">
                <div class="invalid-Formfeedback"
                     *ngIf="entitlementForm?.get('annualVacationDays').errors?.required && entitlementForm?.get('annualVacationDays').touched">
                    <small>{{ 'labels.requiredField' | translate}}</small>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="hstack gap-2 justify-content-end">
            <button type="button" class="btn btn-light"
                    [disabled]="!entitlementForm.touched"
                    (click)="resetForm()">{{ 'labels.clear' | translate }}</button>
            <button type="submit" class="btn btn-success"
                    [disabled]="!entitlementForm.valid">{{ 'labels.save' | translate }}</button>
        </div>
    </div>
</form>
