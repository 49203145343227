<div class="card">
    <ng-container *ngIf="selectedCustomer; else noSelectedCustomer">
        <div class="card-body text-center">
            <div class="position-relative d-inline-block">
                <div class="avatar-lg customer-avatar-title rounded-circle">{{initials}}</div>
                <span class="contact-active position-absolute rounded-circle bg-success"></span><span
                    class="visually-hidden"></span>
            </div>
            <h5 class="mt-4 mb-1">{{selectedCustomer.mainAddress | fullName }}</h5>
            <div class="text-muted cursor-pointer " (click)="changeMainAddress()">
                {{selectedCustomer.mainAddress.street}} <br>
                {{selectedCustomer.mainAddress.zipcode + ' ' + selectedCustomer.mainAddress.city}}
                <i class=" ri-pencil-line icon-xs"></i>
            </div>
            <p class="text-muted">{{'labels.externalId' | translate}} {{selectedCustomer?.externalId?.id}}</p>

            <ul class="list-inline mb-0">
                <li class="list-inline-item avatar-xs cursor-pointer" (click)="addNewContact()"
                    ngbTooltip="{{'labels.addContact'|translate}}">
                    <a class="avatar-title bg-soft-success text-success fs-15 rounded">
                        <i class=" ri-mail-add-line"></i>
                    </a>
                </li>
                <li class="list-inline-item avatar-xs cursor-pointer" (click)="addNewContactPerson()"
                    ngbTooltip="{{'labels.addContactPerson'|translate}}">
                    <a class="avatar-title bg-soft-secondary text-info fs-15 rounded">
                        <i class="ri-contacts-book-line"></i>
                    </a>
                </li>
                <li class="list-inline-item avatar-xs cursor-pointer" (click)="addNewAddress()"
                    ngbTooltip="{{'labels.addAddress'|translate}}">
                    <a class="avatar-title bg-soft-danger text-danger fs-15 rounded">
                        <i class="ri-map-pin-line"></i>
                    </a>
                </li>
                <li class="list-inline-item avatar-xs cursor-pointer" (click)="navigateToNewOrder(null)"
                    ngbTooltip="{{'labels.newOrder'|translate}}">
                    <a class="avatar-title bg-soft-info text-info fs-15 rounded">
                        <i class="ri-book-open-line"></i>
                    </a>
                </li>
                <li class="list-inline-item avatar-xs cursor-pointer" (click)="openHistory(null)" ngbTooltip="Historie">
                    <a class="avatar-title bg-soft-warning text-warning fs-15 rounded">
                        <i class="ri-chat-history-line"></i>
                    </a>
                </li>

            </ul>
        </div>
        <div class="card-body">
            <!-- Contacts -->
            <div class="table-responsive table-card">
                <table class="table table-borderless mb-0">
                    <tbody>
                    <ng-container *ngIf="selectedCustomer.contacts.length > 0">
                        <h6 class="text-muted text-uppercase fw-semibold mb-3 ps-3">{{'labels.Contacts'|translate}}</h6>
                        <tr *ngFor="let contact of selectedCustomer.contacts; let i = index">
                            <td class="fw-medium" scope="row">{{contact.contactType.description}}</td>
                            <td>{{contact.value}}</td>
                            <td (click)="selectContact(i)" class="cursor-pointer">
                                <i class=" ri-pencil-line icon-xs text-muted"></i>
                            </td>
                        </tr>
                    </ng-container>
                    <!-- Contactpersons -->

                    <ng-container *ngIf="selectedCustomer.contactPersons.length > 0">
                        <h6 class="text-muted text-uppercase fw-semibold mb-3 mt-3 ps-3">{{'labels.contactPersons'|translate}}</h6>
                        <ng-container *ngFor="let contactPerson of selectedCustomer.contactPersons; let i = index">
                            <tr>
                                <td class="fw-medium" scope="row">{{contactPerson.personRole}}</td>
                                <td>
                                    {{contactPerson.firstName}} {{contactPerson.name}}
                                    <ng-container
                                            *ngFor="let contact of contactPerson.contactPersonContacts; let j = index">
                                        <br>
                                        {{contact.contactType.description}} {{contact.value}}
                                    </ng-container>
                                </td>
                                <td (click)="selectContactPerson(i)" class="cursor-pointer">
                                    <i class=" ri-pencil-line icon-xs text-muted"></i>
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container>

                    <!-- addresses -->
                    <ng-container *ngIf="selectedCustomer.customerAddresses.length > 0">
                        <h6 class="text-muted text-uppercase fw-semibold mb-3 mt-3 ps-3">{{'labels.addresses'|translate}}</h6>
                        <tr *ngFor="let address of selectedCustomer.customerAddresses; let i = index">
                            <td class="fw-medium" scope="row">{{address.addressType.description}}</td>
                            <td>{{address | fullName}}
                                <br>
                                {{address.street}} <br>
                                {{ address.zipcode + ' ' + address.city}}
                            </td>
                            <td (click)="selectAddress(i)" class="cursor-pointer">
                                <i class=" ri-pencil-line icon-xs text-muted"></i>
                            </td>
                        </tr>
                    </ng-container>
                    <!-- orders -->

                    <ng-container *ngIf="selectedCustomer.orders.length > 0">
                        <h6 class="text-muted text-uppercase fw-semibold mb-3 mt-3 ps-3">{{'labels.orders'|translate}}</h6>
                        <tr *ngFor="let order of selectedCustomer.orders; let i = index">
                            <td class="fw-medium" scope="row">{{order.externalId?.id}}</td>
                            <td>{{order.startDate | date: 'dd.MM.yyyy HH:mm'}}
                                - {{order.endDate | date: 'dd.MM.yyyy HH:mm'}}</td>
                            <td (click)="navigateToNewOrder(i)" class="cursor-pointer">
                                <i class="ri-eye-line text-muted"></i>
                            </td>
                        </tr>
                    </ng-container>
                    </tbody>
                </table>
            </div>
        </div><!--end card-->
    </ng-container>
    <ng-template #noSelectedCustomer>
        <div class="card-body text-center">
            <div class="w-25 w-sm-50 pt-3 mx-auto fs-100">
                <i class="las la-hand-pointer"></i>
            </div>
            <div class="text-center pb-5 mt-2">
                <h6 class="fs-18 fw-semibold lh-base">{{'labels.noSelectedCustomer'|translate}} </h6>
            </div>
        </div>
    </ng-template>
</div>

<ng-template #addNewAddressModal let-addNewAddressModal>
    <div class="modal-header bg-soft-info p-3">
        <h4 class="modal-title">{{ 'labels.addCustomerAddress' | translate }}</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal"
                (click)="resetAndCloseDialogCustomerAddress()"></button>
    </div>
    <form [formGroup]="customerAddress" (ngSubmit)="saveAddress()">
        <div class="modal-body">
            <div class="row g-3">
                <div class="mb-3 col-12">
                    <input type="hidden" id="customerAddressId" formControlName="id">
                </div>
                <div class="col-12" formGroupName="addressType">
                    <small class="text-uppercase text-muted">{{ 'labels.addressType' | translate }}*</small>
                    <select type="text" class="form-control" id="addressType" formControlName="id"
                            (change)="selectedAddressType($event)">
                        <option></option>
                        <option *ngFor="let addressType of addressTypes | async" [ngValue]="addressType.id">
                            {{ addressType.description }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12 col-md-6">
                    <small class="text-uppercase text-muted">{{ 'labels.firstname' | translate }}</small>
                    <input type="text" class="form-control" id="customerAddressFirstName" formControlName="firstName">
                </div>
                <div class="col-12 col-md-6">
                    <small class="text-uppercase text-muted">{{ 'labels.name' | translate }}</small>
                    <input type="text" class="form-control" id="customerAddressName" formControlName="name">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12 col-md-12">
                    <small class="text-uppercase text-muted">{{ 'labels.companyName' | translate }}</small>
                    <input type="text" class="form-control" id="customerAddressCompanyName"
                           formControlName="companyName">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <small class="text-uppercase text-muted">{{ 'labels.street' | translate }}*</small>
                    <input type="text" class="form-control" id="customerAddressStreet" formControlName="street">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12 col-md-6">
                    <small class="text-uppercase text-muted">{{ 'labels.zipcode' | translate }}*</small>
                    <input type="text" class="form-control" id="customerAddressZipcode" formControlName="zipcode">
                </div>
                <div class="col-12 col-md-6">
                    <small class="text-uppercase text-muted">{{ 'labels.city' | translate }}*</small>
                    <input type="text" class="form-control" id="customerAddressCity" formControlName="city">
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="hstack gap-2 justify-content-end">
                <button type="submit" class="btn btn-success" [disabled]="!customerAddress.valid"
                        (click)="addNewAddressModal.dismiss()">{{ 'labels.save' | translate}}</button>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #addNewContactPersonModal let-addNewContactPersonModal>
    <div class="modal-header bg-soft-info p-3">
        <h4 class="modal-title">{{ 'labels.addContactPerson' | translate }}</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                id="close-modal-new-contact-person"
                (click)="resetAndCloseDialogContactPerson()"></button>
    </div>
    <form [formGroup]="contactPerson" (ngSubmit)="saveContactPerson()">
        <div class="modal-body">
            <div class="row g-3">
                <div class="mb-3 col-12">
                    <input type="hidden" id="contactPersonId" formControlName="contactPersonId">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12 col-md-6">
                    <small class="text-uppercase text-muted">{{ 'labels.firstname' | translate }}</small>
                    <input type="text" class="form-control" id="contactPersonFirstName" formControlName="firstName">
                </div>
                <div class="col-12 col-md-6">
                    <small class="text-uppercase text-muted">{{ 'labels.name' | translate }}</small>
                    <input type="text" class="form-control" id="contactPersonLastName" formControlName="name">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12 col-md-12">
                    <small class="text-uppercase text-muted">{{ 'labels.personRole' | translate }}</small>
                    <input type="text" class="form-control" id="contactPersonRole" formControlName="personRole">
                </div>
            </div>
        </div>
        <div class="modal-header p-3">
            <h4 class="modal-title">{{ 'labels.Contacts' | translate }}</h4>
        </div>
        <div class="modal-body">
            <div class="row mb-3 float-end">
                <i class="ri-add-circle-line" (click)="addContactPersonContact()"></i>
            </div>
            <div class="clearfix"></div>
            <div formArrayName="contactPersonContacts">
                <div *ngFor="let contactPersonContacts of contactPerson.get('contactPersonContacts')['controls']; let i = index">
                    <div formGroupName="{{ i }}">
                        <input type="hidden" id="contactPersonContactId{{ i }}" formControlName="id">
                        <div class="row mb-3">
                            <div class="col-6" formGroupName="contactType">
                                <small class="text-uppercase text-muted">{{ 'labels.ContactType' | translate }}*</small>
                                <select type="text" class="form-control" id="contactPersonContactType{{ i }}"
                                        formControlName="id" (change)="selectedContactType($event)">
                                    <option *ngFor="let contactType of contactTypes | async" [ngValue]="contactType.id">
                                        {{ contactType.description }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-6">
                                <small class="text-uppercase text-muted">{{ 'labels.ContactValue' | translate }}
                                    *</small>
                                <input type="text" class="form-control" id="contactPersonContactValue{{ i }}"
                                       formControlName="value">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="hstack gap-2 justify-content-end">
                <button type="submit" class="btn btn-success" [disabled]="!contactPerson.valid"
                        (click)="addNewContactPersonModal.dismiss()">{{ 'labels.save' | translate}}</button>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #addNewContactModal let-addNewContactModal>
    <div class="modal-header bg-soft-info p-3">
        <h4 class="modal-title">{{ 'labels.addContact' | translate }}</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal-new-contact"
                (click)="resetAndCloseDialogContact()"></button>
    </div>
    <form [formGroup]="contact" (ngSubmit)="saveContact()">
        <div class="modal-body">
            <div class="row g-3">
                <div class="mb-3 col-12">
                    <input type="hidden" id="contactId" formControlName="id">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12" formGroupName="contactType">
                    <small class="text-uppercase text-muted">{{ 'labels.ContactType' | translate }}*</small>
                    <select type="text" class="form-control" id="contactType" formControlName="id"
                            (change)="selectedContactType($event)">
                        <option *ngFor="let contactType of contactTypes | async" [ngValue]="contactType.id">
                            {{ contactType.description }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <small class="text-uppercase text-muted">{{ 'labels.ContactValue' | translate }}*</small>
                    <input type="text" class="form-control" id="contactValue" formControlName="value">
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="hstack gap-2 justify-content-end">
                <button type="submit" class="btn btn-success" [disabled]="!contact.valid"
                        (click)="addNewContactModal.dismiss()">{{ 'labels.save' | translate}}</button>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #changeMainAddressModal let-changeMainAddressModal>
    <div class="modal-header bg-soft-info p-3">
        <h4 class="modal-title">{{ 'labels.addCustomer' | translate }}</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                id="close-modal-change-main-address"
                (click)="changeMainAddressModal.dismiss('Cross click')"></button>
    </div>
    <form [formGroup]="customerForm" (ngSubmit)="saveCustomer()">
        <div class="modal-body">
            <div class="row g-3">
                <div class="mb-3 col-12">
                    <input type="hidden" id="id" formControlName="id">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <div class="mt-3 form-check form-check-flat form-check-primary">
                        <label class="form-check-label">
                            <input type="checkbox" class="form-check-input" name="isCompany"
                                   formControlName="isCompany"
                                   (click)="clickIsCompany()">
                            <em class="input-frame"></em>
                            {{ 'labels.isCompany' | translate }}
                        </label>
                    </div>
                </div>
            </div>
            <div formGroupName="mainAddress">
                <div class="row mt-3" *ngIf="!isCompany; else elseBlock">
                    <div class="col-12 col-md-6">
                        <small class="text-uppercase text-muted">{{ 'labels.firstname' | translate }}</small>
                        <input type="text" class="form-control" id="firstName" formControlName="firstName">
                    </div>
                    <div class="col-12 col-md-6">
                        <small class="text-uppercase text-muted">{{ 'labels.name' | translate }}</small>
                        <input type="text" class="form-control" id="name" formControlName="name">
                    </div>
                </div>
                <ng-template #elseBlock>
                    <div class="row mt-3">
                        <div class="col-12">
                            <small class="text-uppercase text-muted">{{ 'labels.companyName' | translate }}</small>
                            <input type="text" class="form-control" id="companyName" formControlName="companyName">
                        </div>
                    </div>
                </ng-template>
                <div class="row mt-3">
                    <div class="col-12">
                        <small class="text-uppercase text-muted">{{ 'labels.street' | translate }}</small>
                        <input type="text" class="form-control" id="mainStreet" formControlName="street">
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12 col-md-6">
                        <small class="text-uppercase text-muted">{{ 'labels.zipcode' | translate }}</small>
                        <input type="text" class="form-control" id="mainZipcode" formControlName="zipcode">
                    </div>
                    <div class="col-12 col-md-6">
                        <small class="text-uppercase text-muted">{{ 'labels.city' | translate }}</small>
                        <input type="text" class="form-control" id="mainCity" formControlName="city">
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <small class="text-uppercase text-muted">{{ 'labels.remark' | translate }}</small>
                    <textarea class="form-control" id="remark" formControlName="remark" rows="4"></textarea>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="hstack gap-2 justify-content-end">
                <button type="submit" class="btn btn-success"
                        (click)="changeMainAddressModal.dismiss()">{{ 'labels.save' | translate}}</button>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #createNewContactType let-createNewContactType>
    <div class="modal-header bg-soft-info p-3">
        <h5 class="modal-title" id="exampleModalLabel">{{'labels.addContactType' | translate}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal-contact-type"
                (click)="createNewContactType.dismiss('Cross click')"></button>
    </div>
    <app-contacttype-edit [autoFocus]="true"></app-contacttype-edit>
    <div class="modal-footer">
        <div class="hstack gap-2 justify-content-end">
            <button type="button" class="btn btn-outline-dark"
                    (click)="createNewContactType.dismiss()">{{ 'labels.close' | translate}}</button>
        </div>
    </div>
</ng-template>

<ng-template #createNewAddressType let-createNewAddressType>
    <div class="modal-header bg-soft-info p-3">
        <h5 class="modal-title">{{'labels.addAddressType' | translate}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                id="close-modal-new-address-type"
                (click)="createNewAddressType.dismiss('Cross click')"></button>
    </div>
    <app-addresstype-edit [autoFocus]="true"></app-addresstype-edit>
    <div class="modal-footer">
        <div class="hstack gap-2 justify-content-end">
            <button type="button" class="btn btn-outline-dark"
                    (click)="createNewAddressType.dismiss()">{{ 'labels.close' | translate}}</button>
        </div>
    </div>
</ng-template>


<ng-template #historyModal let-historyModal>
    <div class="modal-header bg-soft-info p-3">
        <h4 class="modal-title">{{'labels.history' | translate}}</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                id="close-modal-history"
                (click)="resetAndCloseDialogHistory()"></button>
    </div>
    <div class="modal-body history">
        <div class="row">
            <div class="col-lg-12">
                <div>

                    <div class="timeline">
                        <div *ngFor="let history of selectedCustomerHistory;let index = index;"
                             [ngClass]="index % 2 == 0 ? 'timeline-item left' : 'timeline-item right'">
                            <i class="icon ri-chat-history-line"></i>
                            <div class="date">{{history.modificationTime | date: 'dd.MM.yyyy HH:mm:ss'}}</div>
                            <div class="content">
                                <div class="d-flex">
                                    <div class="flex-grow-1 ms-3">
                                        <h5 class="fs-15">{{history.changedBy}}</h5>
                                        <p class="text-muted mb-2">{{history.eventDescription}}</p>
                                        <p class="text-muted mb-2">{{history.changeDescription}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!--end col-->
        </div><!--end row-->
    </div>
</ng-template>
