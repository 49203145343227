<!-- Start Breadcrumbs -->
<app-breadcrumbs title="menu.datev" [breadcrumbItems]="breadCrumbItems"></app-breadcrumbs>
<!-- End Breadcrumbs -->


<app-datev-overview-state></app-datev-overview-state>

<!-- datev kachel -->
<div class="row">
    <div class="col">
        <div class="card mb-2">
            <div class="card-body">
                <div class="d-flex mb-3">
                    <div class="flex-shrink-0 avatar-sm">
                        <div class="avatar-title bg-light rounded">
                            <img src="https://www.datev.de/web/de/media/tech_datev/layout_datev/images_datev/logo_datev/5c3ccfa02d0a370222b028ee_logo_2x.png"
                                 alt="" class="avatar-xxs"/>
                        </div>
                    </div>
                    <div class="flex-grow-1 ms-3">
                        <h5 class="fs-15 mb-1">DATEV</h5>
                        <p class="text-muted mb-2">Kunden- und Auftragsimport</p>
                    </div>
                    <div class="flex-grow-0 vstack gap-3">
                        <span [ngClass]="(batch?.state === 'FINISHED' || batch?.state === 'RUNNING') ? 'badge mx-auto me-0 badge-soft-success' : (batch?.state === 'WAITING') ? 'badge mx-auto me-0 badge-soft-light': 'badge mx-auto me-0 badge-soft-danger'">{{ 'batchState.' + batch?.state | translate }}</span>
                        <button type="button" class="btn btn-sm btn-soft-primary waves-effect waves-light"
                                (click)="openTransformation()">
                            Konfiguration<i class="ri-arrow-right-s-line align-middle"></i>
                        </button>
                    </div>
                </div>
                <ng-container *ngIf="batch">
                <h6>{{batch?.progress?.finished}} / {{batch?.progress?.total}} Datensätze verarbeitet</h6>
                <div class="progress">
                    <div class="progress-bar progress-bar-striped" role="progressbar"
                         [attr.aria-valuenow]="batch?.progress?.progressPct" aria-valuemin="0" aria-valuemax="100" [style]="'width:' +  batch?.progress?.progressPct + '%'"></div>
                </div>
                </ng-container>
            </div>

            <!-- übersicht liste -->

            <div class="card-body mx-md-3">
                <ul class="list-group">
                    <li class="list-group-item d-flex justify-content-between border-dashed">
                        {{ 'datevImportState.FINISHED' | translate }} <span class="badge badge-outline-dark">{{datevImportOverview | datevRecordCountByState: 'FINISHED'}}</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between border-dashed align-items-center">
                        {{ 'datevImportState.MAPPING_ISSUES' | translate }} <span class="badge badge-outline-dark">{{datevImportOverview | datevRecordCountByState: 'MAPPING_ISSUES'}}</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between border-dashed align-items-center">
                        {{ 'datevImportState.ERRONEOUS' | translate }} <span class="badge badge-outline-dark">{{datevImportOverview | datevRecordCountByState: 'ERRONEOUS'}}</span>
                    </li>
                </ul>
            </div>

            <!-- footer -->
            <div class="card-body border-top border-top-dashed" *ngIf="batch">
                <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                        <h6 class="text-muted" *ngIf="batch?.timestamps?.startDateTime"><i
                                class="ri-time-line align-middle"></i> {{batch?.timestamps?.startDateTime + 'Z' | date: 'dd.MM.yyyy HH:mm' : 'GMT' }}
                        </h6>
                    </div>
                    <div class="flex-shrink-0 text-end">
                        <button class="btn btn-success btn-label right ms-auto" (click)="startImportRecords()"><i
                                class="ri-play-line label-icon align-bottom fs-16 ms-2"></i> {{'labels.importRecords' | translate}}
                        </button>
                    </div>
                </div>
            </div>
        </div><!--end card-->

    </div><!--end col-->
</div><!--end row-->

<ng-template #datevTransformation let-datevTransformation>
    <ng-container>
        <div class="modal-header bg-soft-info p-3">
            <h5 class="modal-title" id="exampleModalLabel">{{'labels.datevConfiguration' | translate}}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal"
                    (click)="datevTransformation.dismiss('Cross click')"></button>
        </div>
        <div class="px-3 mx-n3 mb-2" >
            <div class="modal-body">
                <div class="row pb-1">
                    <div class="col-12">
                        <h4 class="fs-16">{{'labels.datevConfigurationCaption' | translate}}</h4>
                        <p class="text-muted">{{'labels.datevConfigurationDescription' | translate}}</p>
                    </div>
                </div>

                <div class="card">
                    <div class="card-body">
                        <!--
                        <div class="row g-3 mb-3">
                            <div class="col">
                                <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" role="switch"
                                           id="onlyMappingIssues">
                                    <label class="form-check-label" for="onlyMappingIssues">&nbsp;Nur Mappingprobleme
                                        anzeigen</label>
                                </div>
                            </div>
                        </div>
                        -->
                        <div class="row">
                            <div class="col" [perfectScrollbar] style="max-height: 500px;">
                                <ngb-accordion class="kanban-accordion" animation="" activeIds=""
                                               [closeOthers]="true">
                                    <ng-container
                                            *ngFor="let datevCompanyMapping of datevTypeMappingMap | keyvalue; let i = index">
                                        <ngb-panel id="lead-{{i}}">
                                            <ng-template ngbPanelTitle>
                                                    <a class="w-100 p-3 text-start d-block" data-bs-toggle="collapse"
                                                       role="button"
                                                       aria-expanded="false" aria-controls="leadDiscovered">
                                                        <div class="d-flex align-items-center mb-1">
                                                            <h5 class="card-title text-uppercase fw-semibold fs-15 flex-grow-1">{{getUpspotName(datevCompanyMapping)}}</h5>
                                                            <span [ngClass]="hasMappingIssues(datevCompanyMapping) ? 'badge badge-soft-danger text-end' : 'badge badge-soft-success text-end'">{{ (hasMappingIssues(datevCompanyMapping) ? 'labels.mappingIssues' : 'labels.noMappingIssues') | translate}}</span>
                                                        </div>
                                                        <p class="text-muted mb-0 text-uppercase">{{getDatevName(datevCompanyMapping)}}</p>
                                                    </a>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                                <div class="mt-3 mb-3 ms-3 me-3">
                                                    <ngb-accordion activeIds="" [closeOthers]="false">
                                                        <ng-container
                                                                *ngFor="let datevMapping of datevCompanyMapping.value | keyvalue; let j = index">
                                                            <ngb-panel id="static-{{j}}">
                                                                <ng-template ngbPanelTitle>
                                                                    <a class="d-flex align-items-center text-start"
                                                                       data-bs-toggle="collapse"
                                                                       role="button"
                                                                       aria-expanded="false" aria-controls="leadDiscovered1">
                                                                        <div class="avatar-xs flex-shrink-0">
                                        <span class="avatar-title bg-soft-primary rounded-circle fs-1">
                                            <i class="ri-settings-5-line text-primary feather-icon-align icon-xs"></i>
                                        </span>
                                                                        </div>
                                                                        <div class="flex-grow-1 ms-3">
                                                                            <h6 class="fs-14 mb-1">{{ 'datevTransformationComponent.' + datevMapping.key | translate }}</h6>
                                                                        </div>
                                                                    </a>
                                                                </ng-template>
                                                                <ng-template ngbPanelContent>
                                                                    <table class="table table-hover table-striped align-middle table-nowrap mb-0">
                                                                        <thead>
                                                                        <tr>
                                                                            <th scope="col">DATEV</th>
                                                                            <th scope="col">Zuordnung</th>
                                                                            <th scope="col">Ignorieren</th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        <tr *ngFor="let datevType of datevMapping.value; let k = index">
                                                                            <td><input class="form-control"
                                                                                       [value]="datevType.mappingSource"
                                                                                       disabled></td>
                                                                            <td>
                                                                                <ng-container [ngSwitch]="datevType.ident">
                                                                                    <ng-container *ngSwitchCase="'COMPANY_ID'">
                                                                                        <select type="text" class="form-control"
                                                                                                id="companyId{{i}}"
                                                                                                [(ngModel)]="datevType.conversion"
                                                                                                (change)="selectedCompany($event)">
                                                                                            <option *ngFor="let company of companies"
                                                                                                    [ngValue]="company.companyId">
                                                                                                {{ company.address.companyName }}
                                                                                            </option>
                                                                                        </select>
                                                                                    </ng-container>
                                                                                    <ng-container
                                                                                            *ngSwitchCase="'CONTACT_TYPE'">
                                                                                        <select type="text" class="form-control"
                                                                                                id="contactType{{i}}"
                                                                                                [(ngModel)]="datevType.conversion"
                                                                                                (change)="selectedContactType($event, datevCompanyMapping.key, datevType)">
                                                                                            <ng-container
                                                                                                    *ngFor="let contactType of contactTypes">
                                                                                                <option *ngIf="(contactType.companyId === datevCompanyMapping.key) || (contactType.companyId === 'upspot')"
                                                                                                        [ngValue]="contactType.id">
                                                                                                    {{ contactType.description }}
                                                                                                </option>
                                                                                            </ng-container>
                                                                                        </select>
                                                                                    </ng-container>
                                                                                    <ng-container *ngSwitchCase="'ADDRESS_ID'">
                                                                                        <select type="text" class="form-control"
                                                                                                id="addressType{{i}}"
                                                                                                [(ngModel)]="datevType.conversion"
                                                                                                (change)="selectedAddressType($event, datevCompanyMapping.key, datevType)">
                                                                                            <ng-container
                                                                                                    *ngFor="let addressType of addressTypes">
                                                                                                <option *ngIf="(addressType.companyId === datevCompanyMapping.key) || (addressType.companyId === 'upspot')"
                                                                                                        [ngValue]="addressType.id">
                                                                                                    {{ addressType.description }}
                                                                                                </option>
                                                                                            </ng-container>
                                                                                        </select>
                                                                                    </ng-container>
                                                                                    <ng-container *ngSwitchCase="'ORDER_TYPE'">
                                                                                        <select type="text" class="form-control"
                                                                                                id="orderType{{i}}"
                                                                                                [(ngModel)]="datevType.conversion"
                                                                                                (change)="selectedOrderType($event, datevCompanyMapping.key, datevType)">
                                                                                            <ng-container
                                                                                                    *ngFor="let orderType of orderTypes">
                                                                                                <option *ngIf="(orderType.companyId === datevCompanyMapping.key) || (orderType.companyId === 'upspot')"
                                                                                                        [ngValue]="orderType.id">
                                                                                                    {{ orderType.description }}
                                                                                                </option>
                                                                                            </ng-container>
                                                                                        </select>
                                                                                    </ng-container>
                                                                                    <ng-container *ngSwitchCase="'TASK_TYPE'">
                                                                                        <select type="text" class="form-control"
                                                                                                id="taskType{{i}}"
                                                                                                [(ngModel)]="datevType.conversion"
                                                                                                (change)="selectedTaskTypeAction($event, datevCompanyMapping.key, datevType)">
                                                                                            <ng-container
                                                                                                    *ngFor="let taskType of taskTypes">
                                                                                                <option *ngIf="(taskType.companyId === datevCompanyMapping.key) || (taskType.companyId === 'upspot')"
                                                                                                        [ngValue]="taskType.id">
                                                                                                    {{ taskType.description }}
                                                                                                </option>
                                                                                            </ng-container>
                                                                                        </select>
                                                                                    </ng-container>
                                                                                </ng-container>
                                                                            </td>
                                                                            <td>
                                                                                <div class="form-check form-switch">
                                                                                    <input class="form-check-input"
                                                                                           type="checkbox"
                                                                                           role="switch" id="SwitchCheck2"
                                                                                           name="ignoreMapping"
                                                                                           [(ngModel)]="datevType.ignoreMapping"
                                                                                           [ngModelOptions]="{standalone: true}"
                                                                                           [checked]="datevType.ignoreMapping"
                                                                                           (click)="isIgnored()">
                                                                                    <label class="form-check-label"
                                                                                           for="SwitchCheck2">&nbsp;ja/nein</label>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </ng-template>
                                                            </ngb-panel>
                                                        </ng-container>
                                                    </ngb-accordion>
                                                </div>
                                            </ng-template>
                                        </ngb-panel>
                                    </ng-container>
                                </ngb-accordion>
                            </div><!--end col-->
                        </div><!-- row -->
                    </div>

                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="hstack gap-2 justify-content-end">
                <button type="button" class="btn btn-outline-primary me-2 mb-2 mb-md-0"
                        [disabled]="!dataChanged" id="saveOrderType"
                        (click)="save()">{{ 'labels.save' | translate }}</button>
            </div>
        </div>

    </ng-container>
</ng-template>
